import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
import { StorageKey } from '@emme/shared/model/enums';
import { usePersistedState } from 'hooks/usePersistedState';
export var PlaceOrderTab;
(function (PlaceOrderTab) {
    PlaceOrderTab["Provide"] = "Provide";
    PlaceOrderTab["Subscribe"] = "Subscribe";
})(PlaceOrderTab || (PlaceOrderTab = {}));
export const PlaceOrderTabContext = createContext([
    PlaceOrderTab.Provide,
    console.error,
]);
export function PlaceOrderTabProvider({ children }) {
    const value = usePersistedState(PlaceOrderTab.Provide, StorageKey.EMME_PLACE_ORDER_FORM);
    return _jsx(PlaceOrderTabContext.Provider, { value: value, children: children });
}
