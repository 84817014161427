import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { RouteEnum } from '@emme/shared/model/enums';
import { AlertContext } from 'context/AlertProvider';
import { AuthPageLayout } from 'layout/AuthPage';
const VerificationPageLayout = ({ children, title, captcha, onResend, }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const userInfo = location.state;
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const { setAlert } = useContext(AlertContext);
    const handleResendEmail = async () => {
        setIsSendingEmail(true);
        try {
            if (!userInfo) {
                throw new Error('Missing user information, please refresh page');
            }
            let reCaptchaToken;
            if (executeRecaptcha) {
                reCaptchaToken = await executeRecaptcha(captcha);
            }
            else {
                throw new Error();
            }
            await onResend({ userId: userInfo?.id, reCaptchaToken });
            setAlert(`An email will be sent shortly to ${userInfo?.email}`, 'success', 2500);
        }
        catch (error) {
            setAlert(error?.message || error || 'Something went wrong', 'error');
        }
        setIsSendingEmail(false);
    };
    const handleLogout = () => {
        navigate(RouteEnum.Login, { replace: true });
    };
    useEffect(() => {
        if (!userInfo?.email) {
            navigate(RouteEnum.Login, { replace: true });
        }
    }, [navigate, userInfo]);
    return !userInfo?.email ? null : (_jsx(AuthPageLayout, { columnTitle: title, children: _jsxs(Stack, { spacing: 3, pt: 2, alignItems: "flex-start", children: [_jsx(Typography, { variant: "subtitle2", children: children }), _jsx(LoadingButton, { loading: isSendingEmail, onClick: () => handleResendEmail && handleResendEmail(), type: "button", color: "secondary", sx: {
                        pl: 0,
                        '&:hover': { backgroundColor: 'inherit' },
                    }, children: _jsx(Typography, { variant: "subtitle2", children: "Resend email." }) }), _jsxs(Stack, { direction: "row", alignItems: "center", spacing: 2, children: [_jsx(Typography, { variant: "subtitle2", children: "Or" }), _jsx(Button, { onClick: handleLogout, type: "button", color: "secondary", sx: {
                                px: 0,
                                '&:hover': { backgroundColor: 'inherit' },
                            }, children: _jsx(Typography, { variant: "subtitle2", children: "go to Login page" }) }), _jsx(Typography, { variant: "subtitle2", component: "span", children: "to sign in with a different email." })] })] }) }));
};
export default VerificationPageLayout;
