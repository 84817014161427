import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import { SubscriptionMaxLiquidityProvider } from 'context/SubscriptionMaxLiquidityProvider';
import { PlaceOrderTabProvider } from 'context/PlaceOrderTabProvider';
import { ConfirmOrderProvider } from 'context/ConfirmOrderProvider';
import { OrderTabsProvider } from 'context/OrderTabsProvider';
import { SpreadProvider } from 'context/SpreadProvider';
import { useBreakpoint } from 'hooks/useBreakpoint';
import MobileDashboardLayout from 'layout/Dashboard';
const DashboardRoot = () => {
    const { tablet } = useBreakpoint();
    return (_jsx(SpreadProvider, { children: _jsx(PlaceOrderTabProvider, { children: _jsx(OrderTabsProvider, { children: _jsx(SubscriptionMaxLiquidityProvider, { children: _jsx(ConfirmOrderProvider, { children: tablet ? (_jsx(MobileDashboardLayout, { children: _jsx(Outlet, {}) })) : (_jsx(Outlet, {})) }) }) }) }) }));
};
export default DashboardRoot;
