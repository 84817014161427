import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { checkMaxValue } from 'utils/util';
import { CoinIcon } from 'components/CoinIcon';
export const CoinAmountCell = ({ columnProps, data }) => {
    const amount = new BigNumber(data?.amount || '');
    return (_jsxs(Stack, { direction: "row", justifyContent: columnProps?.align || 'flex-end', alignItems: "center", children: [_jsx(Typography, { component: "span", color: data?.color ||
                    (columnProps?.addColor && !amount.eq(0)
                        ? amount.gt(0)
                            ? (t) => t.palette.success.main
                            : (t) => t.palette.error.main
                        : undefined), pr: 2, sx: columnProps?.addBold ? { fontWeight: 600 } : {}, children: checkMaxValue(amount, {
                    digits: columnProps?.numberDecimals || 8,
                    isPrice: data?.coin === 'emme',
                    sign: columnProps?.addColor && amount.gt(0) ? '+' : '',
                }) }), _jsx(CoinIcon, { coin: data?.coin }), columnProps?.addDays && (_jsx(Typography, { component: "span", color: "textSecondary", pl: 2, children: "days" }))] }));
};
CoinAmountCell.defaultProps = {
    columnProps: {},
};
CoinAmountCell.alignment = ColumnAlign.LEFT;
