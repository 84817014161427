import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { AlertContext } from 'context/AlertProvider';
import { RouteEnum } from '@emme/shared/model/enums';
import MainLayout from 'layout/MainLayout';
import { LoadingOverlay } from 'components/LoadingOverlay';
const OpenMarket = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState('Market');
    const [{ exchangeId, base, quote }, setParams] = useState({});
    const { setAlert } = useContext(AlertContext);
    const { selectedExchange, changeExchange, changeLiquidityMarket, markets, exchanges } = useContext(LiquidityContext);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params && location?.search) {
            setParams({
                exchangeId: params.get('exchangeId') || undefined,
                base: params.get('base') || undefined,
                quote: params.get('quote') || undefined,
            });
        }
        else {
            setAlert('Invalid search params', 'error', 5000);
            navigate(RouteEnum.Dashboard, { replace: true });
        }
    }, [location?.search]);
    useEffect(() => {
        if (exchangeId && exchanges?.length) {
            if (exchanges.some(({ id }) => id === exchangeId)) {
                changeExchange(exchangeId);
            }
            else {
                setIsLoading(false);
                setTitle('Exchange');
            }
        }
    }, [changeExchange, exchangeId, exchanges, setAlert]);
    useEffect(() => {
        const newMarket = (markets || []).find(({ baseCoin, quoteCoin }) => baseCoin === base && quoteCoin === quote);
        if (base && quote && newMarket && selectedExchange?.id === exchangeId) {
            changeLiquidityMarket({
                id: newMarket.id,
                baseCoin: newMarket.baseCoin,
                quoteCoin: newMarket.quoteCoin,
            });
            navigate(RouteEnum.Dashboard, { replace: true });
        }
        else if (markets?.length && markets[0]?.exchangeId === exchangeId) {
            setIsLoading(false);
        }
    }, [markets]);
    return (_jsx(MainLayout, { hideFooter: true, children: _jsx(LoadingOverlay, { isLoading: isLoading, size: 100, zIndex: 1, sx: { minHeight: 'calc(100vh - 80px)' }, children: _jsx(Stack, { alignItems: "center", justifyContent: "center", pt: 12, children: _jsxs(Card, { sx: {
                        maxWidth: '460px',
                        width: '100%',
                        minHeight: '190px',
                        backgroundColor: (t) => t.palette.neutralsTwo.main,
                        p: 8,
                        borderRadius: 3,
                    }, elevation: 0, children: [_jsx(CardHeader, { title: `${title} currently not available`, titleTypographyProps: { variant: 'h4' } }), _jsxs(CardContent, { sx: { typography: 'subtitle1' }, children: [_jsxs(Box, { children: ["Selected ", title.toLocaleLowerCase(), " is not registered or does not exist."] }), _jsxs(Box, { children: ["You can", ' ', _jsxs(Link, { component: RouterLink, to: RouteEnum.Login, replace: true, children: ["go to dashboard", ' '] }), "and choose a supported ", title.toLocaleLowerCase(), " from our list."] })] })] }) }) }) }));
};
export default OpenMarket;
