import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useRef, useState } from 'react';
import { VictoryTheme, VictoryBar, VictoryAxis, VictoryArea } from 'victory';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { SpreadContext } from 'context/SpreadProvider';
import { EmmeVictoryChart } from 'components/EmmeVictoryChart';
import { SpreadSlider } from 'components/Slider';
import { EmmeTabs } from 'components/Tabs';
import { getLiquidityPriceData, getLiquidityVolumeData, getLiquidityPercentageData } from '../data';
const tabs = [{ label: 'Provision' }, { label: 'Subscription' }];
export function EMMELiquidityChart() {
    const theme = useTheme();
    const { dashboardSpread, updateDashboardSpread } = useContext(SpreadContext);
    const [tab, setTab] = useState(tabs[0].label);
    const topBarData = useRef(getLiquidityPriceData());
    const bottomBarData = useRef(getLiquidityVolumeData());
    const bottomCurveData = useRef(getLiquidityPercentageData());
    return (_jsxs(Stack, { height: "100%", children: [_jsxs(Stack, { direction: "row", sx: { maxWidth: '600px' }, children: [_jsx(EmmeTabs, { tabs: tabs, value: tab, onChange: setTab, withBackground: true }), _jsx(SpreadSlider, { value: dashboardSpread, onChange: updateDashboardSpread, sx: { flex: 'auto' } })] }), _jsxs(EmmeVictoryChart, { theme: VictoryTheme.material, sx: { flex: 'auto', flexBasis: 0 }, domainPadding: 20, children: [_jsx(VictoryAxis, { dependentAxis: true, orientation: "right", style: {
                            grid: {
                                stroke: theme.palette.neutralsThree.main,
                                strokeDasharray: 'none',
                            },
                        } }), _jsx(VictoryAxis, { tickCount: topBarData.current.length / 20, style: {
                            grid: {
                                stroke: theme.palette.neutralsThree.main,
                                strokeDasharray: 'none',
                            },
                        } }), _jsx(VictoryBar, { data: topBarData.current, barRatio: 1.5, style: {
                            data: {
                                fill: ({ datum }) => datum.x % 3 ? theme.palette.error.main : theme.palette.success.main,
                                stroke: theme.palette.neutralOne.main,
                                strokeWidth: 1,
                            },
                        } })] }), _jsxs(EmmeVictoryChart, { theme: VictoryTheme.material, sx: {
                    flex: 'auto',
                    flexBasis: 0,
                    '@media screen and (min-height: 1000px)': {
                        maxHeight: '30%',
                    },
                }, domainPadding: 25, children: [_jsx(VictoryAxis, { dependentAxis: true, orientation: "left", style: {
                            grid: {
                                stroke: theme.palette.neutralsThree.main,
                                strokeDasharray: 'none',
                            },
                        } }), _jsx(VictoryAxis, { dependentAxis: true, orientation: "right", style: {
                            grid: {
                                stroke: theme.palette.neutralsThree.main,
                                strokeDasharray: 'none',
                            },
                        } }), _jsx(VictoryAxis, { tickCount: topBarData.current.length / 20, style: {
                            grid: {
                                stroke: theme.palette.neutralsThree.main,
                                strokeDasharray: 'none',
                            },
                        } }), _jsx(VictoryBar, { data: bottomBarData.current, barRatio: 1, style: {
                            data: {
                                stroke: theme.palette.primary.main,
                                fill: '#024979',
                                strokeWidth: 2,
                            },
                        } }), _jsx(VictoryArea, { data: bottomCurveData.current, style: {
                            data: {
                                stroke: '#58BD7C',
                                fill: '#58BD7C44',
                                strokeWidth: 2,
                            },
                        }, interpolation: "natural" })] })] }));
}
