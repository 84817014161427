import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteEnum } from '@emme/shared/model/enums';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { createExternallyResolvablePromise } from 'utils/util';
import liquidityContext from './liquidityMarket/liquidityContext';
import { OnboardingContext } from './OnboardingProvider';
export const ConfirmOrderContext = createContext({
    confirmAndPlaceOrder: console.error,
    closeConfirmOrder: console.error,
    orderData: undefined,
    isPaneOpen: false,
});
export function ConfirmOrderProvider({ children }) {
    const { tablet } = useBreakpoint();
    const navigate = useNavigate();
    const { selectedLiquidityMarket } = useContext(liquidityContext);
    const { open } = useContext(OnboardingContext);
    const [orderData, setOrderData] = useState();
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const confirmPromise = useRef();
    const confirmAndPlaceOrder = useCallback((data) => {
        setOrderData(data);
        setIsPaneOpen(true);
        confirmPromise.current = createExternallyResolvablePromise();
        return confirmPromise.current;
    }, []);
    const closeConfirmOrder = useCallback((isSuccess) => {
        if (tablet && isSuccess) {
            navigate(RouteEnum.Dashboard);
            setTimeout(() => {
                setIsPaneOpen(false);
            }, 300);
        }
        else {
            setIsPaneOpen(false);
        }
        if (confirmPromise.current) {
            isSuccess ? confirmPromise.current.resolve() : confirmPromise.current.reject();
            confirmPromise.current = undefined;
        }
        setTimeout(() => {
            setOrderData(undefined);
        }, 300);
    }, [navigate, tablet]);
    const confirmContextValue = useMemo(() => ({
        confirmAndPlaceOrder,
        closeConfirmOrder,
        orderData,
        isPaneOpen,
    }), [confirmAndPlaceOrder, closeConfirmOrder, orderData, isPaneOpen]);
    useEffect(() => {
        if (!tablet) {
            closeConfirmOrder(false);
        }
    }, [tablet, open, closeConfirmOrder, selectedLiquidityMarket?.id]);
    return (_jsx(ConfirmOrderContext.Provider, { value: confirmContextValue, children: children }));
}
