import { jsx as _jsx } from "react/jsx-runtime";
import GridLayout from 'react-grid-layout';
import { WidgetInitializer } from '../components/WidgetInitializer';
import { PanelProvider } from '../providers/PanelProvider';
const DASHBOARD_WIDTH = 1000;
const MAX_COLUMNS = 8;
const ROW_HEIGHT = 50;
export const Panel = ({ widgets, context, grid }) => {
    const columns = grid ? grid.w : MAX_COLUMNS;
    const width = (columns / MAX_COLUMNS) * DASHBOARD_WIDTH;
    return (_jsx(PanelProvider, { initialValue: context, children: _jsx(GridLayout, { cols: columns, rowHeight: ROW_HEIGHT, width: width, layout: widgets.map((widget) => ({ ...widget.grid, i: widget.id })), children: widgets.map((widget) => (_jsx("div", { children: _jsx(WidgetInitializer, { widget: widget }) }, widget.id))) }) }));
};
Panel.defaultProps = {
    grid: undefined,
};
