import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { RouteEnum } from '@emme/shared/model/enums';
import { AlertContext } from 'context/AlertProvider';
import { useInvitationCode } from '@emme/shared/queries/Auth';
import { AuthPageLayout } from 'layout/AuthPage';
import { AlreadyHaveAnAccountLink } from 'components/AlreadyHaveAnAccountLink';
import { CodeInput } from './components/CodeInput';
const SignupCodeVerificationPage = () => {
    const { setAlert } = useContext(AlertContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [code, setCode] = useState('');
    const { mutateAsync, isLoading } = useInvitationCode();
    const checkedCodeAfterMount = useRef(false);
    const invitationCodeParam = new URLSearchParams(location.search).get('invitationCode');
    const checkInvitationCode = useCallback(async (data) => {
        if (!data)
            return;
        let isValid = false;
        let msg = 'Invitation Code is incorrect';
        try {
            isValid = await mutateAsync(data);
            if (isValid) {
                setAlert('Invitation code is valid, you may now signup for free', 'success', 2500);
                navigate(RouteEnum.Signup, { state: data });
            }
        }
        catch {
            msg = 'Something went wrong';
        }
        if (!isValid) {
            setAlert(msg, 'error');
            if (invitationCodeParam) {
                navigate(RouteEnum.SignupCodeVerification, { replace: true });
            }
        }
        return isValid;
    }, [invitationCodeParam, mutateAsync, navigate, setAlert]);
    useEffect(() => {
        if (!checkedCodeAfterMount.current) {
            checkInvitationCode(invitationCodeParam || undefined);
            checkedCodeAfterMount.current = true;
        }
    }, [invitationCodeParam, checkInvitationCode]);
    const handleSubmit = () => {
        !isLoading && checkInvitationCode(code);
    };
    return (_jsx(AuthPageLayout, { columnTitle: "Enter Invitation Code", children: _jsxs(Stack, { gap: 3, children: [_jsx(CodeInput, { onChange: setCode, submit: handleSubmit, isLoading: isLoading }), _jsx(LoadingButton, { variant: "contained", type: "button", fullWidth: true, size: "large", onClick: handleSubmit, loading: isLoading, disabled: !code, children: _jsx(Typography, { variant: "h6", children: "Submit" }) }), _jsx(AlreadyHaveAnAccountLink, {})] }) }));
};
export default SignupCodeVerificationPage;
