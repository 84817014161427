import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { useLiquidityMarketsTickerData } from '@emme/shared/queries/LiquidityMarkets';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { NumberCell, CoinNameCell } from 'components/Table/cells';
import { CoinIcon } from 'components/CoinIcon';
import { EmmeTable } from 'components/Table';
const headCells = [
    {
        id: 'id',
        label: 'Base',
        Component: CoinNameCell,
    },
    {
        id: 'price',
        label: 'Price',
        Component: NumberCell,
        align: ColumnAlign.LEFT,
    },
];
const MarketSelectLogoBody = ({ userValidMarkets, enableSearch, onSelect, }) => {
    const [search, setSearch] = useState('');
    const [searchField, setsSearchField] = useState('');
    const { changeLiquidityMarket, selectedLiquidityMarket, markets, isLoading: isLoadingMarkets, } = useContext(LiquidityContext);
    const allMarkets = userValidMarkets || markets;
    const { refetch: refetchTicker } = useLiquidityMarketsTickerData(selectedLiquidityMarket?.id);
    const quoteCoins = [
        ...new Set(allMarkets.map(({ quoteCoin }) => quoteCoin).filter(Boolean)),
    ];
    const selectedQuoteCoin = selectedLiquidityMarket?.quoteCoin || quoteCoins[0];
    const baseCoins = [
        ...new Set(allMarkets
            .filter((market) => market.baseCoin && market.quoteCoin === selectedQuoteCoin)
            .map(({ baseCoin }) => baseCoin)),
    ];
    const filteredBaseCoins = search
        ? baseCoins.filter((coin) => coin.toLowerCase().includes(search.toLowerCase()))
        : baseCoins;
    const getBaseCoinPrice = (baseCoin) => allMarkets.find((market) => market.baseCoin === baseCoin && market.quoteCoin === selectedQuoteCoin)?.ticker?.exchangeLastTradePrice || null;
    const debouncedChangeHandler = useRef(debounce((e) => {
        setSearch(e.target.value);
    }, 500));
    useEffect(() => {
        if (selectedLiquidityMarket) {
            refetchTicker();
        }
    }, [refetchTicker, selectedLiquidityMarket]);
    const handleChangeTab = (_event, newValue) => {
        if (newValue) {
            const newMarket = allMarkets.find((market) => market.quoteCoin === newValue);
            if (newMarket) {
                changeLiquidityMarket({
                    id: newMarket?.id,
                    baseCoin: newMarket?.baseCoin,
                    quoteCoin: newMarket?.quoteCoin,
                });
            }
        }
    };
    const handleBaseAction = async (baseCoin) => {
        if (baseCoin) {
            const newMarket = allMarkets.find((market) => market.baseCoin === baseCoin && market.quoteCoin === selectedQuoteCoin);
            if (newMarket) {
                changeLiquidityMarket({
                    id: newMarket?.id,
                    baseCoin: newMarket?.baseCoin,
                    quoteCoin: newMarket?.quoteCoin,
                });
            }
            if (enableSearch) {
                setTimeout(() => {
                    setSearch('');
                    setsSearchField('');
                }, 100);
            }
            onSelect && onSelect();
        }
    };
    return (_jsxs(Stack, { children: [_jsx(Tabs, { value: selectedQuoteCoin, onChange: handleChangeTab, variant: "scrollable", scrollButtons: "auto", TabIndicatorProps: {
                    style: { display: 'none' },
                }, sx: { px: 5, pt: 5 }, children: quoteCoins.map((coin) => (_jsx(Tab, { label: coin, value: coin, icon: _jsx(Box, { sx: { pr: 1 }, children: _jsx(CoinIcon, { coin: coin }) }), iconPosition: "start", disabled: selectedQuoteCoin === coin, sx: {
                        typography: 'subtitle2',
                        minHeight: '28px',
                        minWidth: '0px',
                        bgcolor: (t) => {
                            if (selectedQuoteCoin === coin) {
                                return t.palette.neutralsThree.main;
                            }
                            return 'inherit';
                        },
                        ':disabled': {
                            color: (t) => t.palette.text.primary,
                        },
                        m: 0,
                        mr: 2,
                    } }, coin))) }), enableSearch && (_jsx(TextField, { onChange: (e) => {
                    setsSearchField(e.target.value);
                    debouncedChangeHandler.current(e);
                }, value: searchField, fullWidth: true, placeholder: "Search", InputProps: {
                    startAdornment: _jsx(SearchOutlinedIcon, {}),
                }, disabled: !baseCoins.length })), _jsx(EmmeTable, { rows: filteredBaseCoins.map((baseCoin) => ({
                    id: baseCoin,
                    price: getBaseCoinPrice(baseCoin),
                    isSelected: selectedLiquidityMarket?.baseCoin === baseCoin,
                })), inputHeadCells: headCells, tableMinWidth: 250, onRowClick: handleBaseAction, initialSortBy: "id", sortValues: {
                    price: {
                        isNumber: true,
                    },
                }, isLoading: isLoadingMarkets, customVerticalPadding: [4, undefined, 2] })] }));
};
MarketSelectLogoBody.defaultProps = {
    userValidMarkets: undefined,
    enableSearch: false,
    onSelect: undefined,
};
export default MarketSelectLogoBody;
