import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { getErrorFormatter, lowerCaseRegex, numberRegex, specialCharacterRegex, upperCaseRegex, } from 'utils/util';
import { AlreadyHaveAnAccountLink } from 'components/AlreadyHaveAnAccountLink';
import { PasswordTextField } from 'components/Inputs';
const validationSchema = Yup.object({
    password: Yup.string()
        .label('Password')
        .min(8, 'Length must be at least 8 letters!')
        .max(100, 'Length must be less than 100 letters!')
        .matches(lowerCaseRegex, 'Password should contain at least 1 small letter')
        .matches(upperCaseRegex, 'Password should contain at least 1 capital letter')
        .matches(numberRegex, 'Password should contain at least 1 number')
        .matches(specialCharacterRegex, 'Password should contain at least 1 special character (e.g. !#$%&*-?@^)')
        .required(),
    confirmPassword: Yup.string().when('password', (password, field) => {
        if (!password)
            return field;
        return field
            .required('Confirm password is required')
            .oneOf([Yup.ref('password')], 'Passwords must match');
    }),
});
const NewPasswordForm = ({ onSubmit, loading }) => {
    const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, isValid } = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema,
        onSubmit: ({ password }) => onSubmit({ password }),
    });
    const formatError = getErrorFormatter(touched, errors);
    const touchAllFields = () => {
        setFieldTouched('password');
        setFieldTouched('confirmPassword');
    };
    return (_jsxs(Stack, { component: "form", onSubmit: handleSubmit, autoComplete: "off", noValidate: true, gap: 3, children: [_jsx(PasswordTextField, { label: "Password", value: values.password, onChange: handleChange, onBlur: () => setFieldTouched('password'), name: "password", fullWidth: true, autoComplete: "new-password", helperText: formatError('password'), error: touched.password && Boolean(errors.password) }), _jsx(PasswordTextField, { label: "Confirm password", value: values.confirmPassword, onChange: handleChange, onBlur: () => setFieldTouched('confirmPassword'), name: "confirmPassword", fullWidth: true, autoComplete: "new-confirmPassword", helperText: formatError('confirmPassword'), error: touched.confirmPassword && Boolean(errors.confirmPassword) }), _jsx("div", { role: "button", onClick: () => touchAllFields(), onKeyPress: () => touchAllFields(), tabIndex: -1, children: _jsx(LoadingButton, { variant: "contained", type: "submit", fullWidth: true, size: "large", loading: loading, disabled: !touched?.password || !isValid, children: _jsx(Typography, { variant: "h6", children: "Continue" }) }) }), _jsx(AlreadyHaveAnAccountLink, {})] }));
};
export default NewPasswordForm;
