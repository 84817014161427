import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { useUserNotificationsData } from '@emme/shared/queries/Users';
import { EmmeTable } from 'components/Table';
import { DateCell } from 'components/Table/cells';
const headCells = [
    {
        id: 'text',
        label: 'Message',
        wrap: true,
    },
    {
        id: 'createdAt',
        label: 'Date',
        Component: DateCell,
    },
];
const NotificationsPage = () => {
    const [size, setSize] = useState(15);
    const [pageNumber, setPageNumber] = useState(0);
    const { data: { notifications } = {}, isLoading } = useUserNotificationsData({
        size,
        pageNumber,
        read: 'all',
    });
    const handleChangePage = (newPage) => {
        setPageNumber(newPage);
    };
    const handleChangeRowsPerPage = (newPageSize) => {
        setSize(newPageSize);
        setPageNumber(0);
    };
    return (_jsxs(Stack, { justifyContent: "flex-start", alignItems: "stretch", children: [_jsx(Typography, { variant: "h6", mb: 6, mx: 5, children: "Activity" }), !isLoading && !notifications?.elements.length ? (_jsx(Alert, { severity: "info", sx: { mx: 5 }, children: _jsx(Typography, { children: "You have no Notifications yet" }) })) : (_jsx(EmmeTable, { rows: notifications?.elements || [], inputHeadCells: headCells, customVerticalPadding: 4, page: pageNumber, rowsPerPage: size, rowsPerPageOptions: [10, 15, 25], onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, totalLength: notifications?.totalNumber, isLoading: isLoading }))] }));
};
export default NotificationsPage;
