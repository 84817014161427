import { jsx as _jsx } from "react/jsx-runtime";
import BigNumber from 'bignumber.js';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { checkMaxValue } from 'utils/util';
import { TypographyCell } from './TypographyCell';
export const NumberCell = ({ columnProps = {}, data }) => {
    const { numberDecimals, ...typographyProps } = columnProps;
    return (_jsx(TypographyCell, { columnProps: typographyProps, data: {
            text: data ? checkMaxValue(new BigNumber(data), { digits: numberDecimals }) : '-',
        } }));
};
NumberCell.alignment = ColumnAlign.RIGHT;
NumberCell.defaultProps = {
    columnProps: {},
};
