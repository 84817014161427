import { OverviewContext } from 'context/Overview/OverviewProvider';
import { useContext } from 'react';
export function useButtonLabel(label) {
    const { selectedStep, getStepState } = useContext(OverviewContext);
    const state = getStepState(selectedStep);
    if (state !== 'new') {
        return `Set ${label}`;
    }
    return `Update ${label}`;
}
