import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteEnum } from '@emme/shared/model/enums';
import { AlertContext } from 'context/AlertProvider';
import { useChangePassword } from '@emme/shared/queries/Auth';
import { AuthPageLayout } from 'layout/AuthPage';
import { useActivationParams } from 'hooks/useActivationParams';
import NewPasswordForm from './components/NewPasswordForm';
const NewPasswordPage = () => {
    const navigate = useNavigate();
    const activationInfo = useActivationParams();
    const { setAlert } = useContext(AlertContext);
    const updatePassword = useChangePassword();
    const handleSubmit = async ({ password }) => {
        try {
            await updatePassword.mutateAsync({ ...activationInfo, newPassword: password });
            setAlert('Account password successfully updated', 'success', 5000);
            navigate(RouteEnum.Login, { replace: true });
        }
        catch (error) {
            setAlert(error?.message || error || 'Something went wrong', 'error');
        }
    };
    return (_jsx(AuthPageLayout, { columnTitle: "New Password", children: _jsx(NewPasswordForm, { onSubmit: handleSubmit, loading: updatePassword.isLoading }) }));
};
export default NewPasswordPage;
