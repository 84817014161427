import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
const OverviewCardLayout = ({ icon, title, children, sx, ...rest }) => (_jsxs(Stack, { justifyContent: "space-between", sx: {
        bgcolor: 'neutralOne.dark',
        borderRadius: 1,
        height: '100%',
        px: [3, undefined, undefined, 5],
        pt: [4, undefined, undefined, 6],
        pb: [3, undefined, undefined, 4],
        gap: 4,
        ...sx,
    }, typography: "h4", ...rest, children: [_jsxs(Stack, { direction: "row", alignItems: "center", spacing: 3, children: [Boolean(icon) && (_jsx(Box, { sx: {
                        width: ['25px', undefined, undefined, '35px'],
                        height: ['25px', undefined, undefined, '35px'],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        my: '-10px',
                    }, children: icon })), _jsx(Box, { children: title })] }), children] }));
OverviewCardLayout.defaultProps = {
    icon: null,
};
export default OverviewCardLayout;
