import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteEnum } from '@emme/shared/model/enums';
import { AlertContext } from 'context/AlertProvider';
import { useDeviceActivation, useResendDeviceEmail } from '@emme/shared/queries/Auth';
import { ActivationFailedPageLayout, AuthPageLayout } from 'layout/AuthPage';
import { useActivationParams } from 'hooks/useActivationParams';
import { getFingerPrint } from 'utils/fingerprint';
import DeviceActivationForm from './components/DeviceActivationForm';
const DeviceActivationPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isExpired, setIsExpired] = useState(false);
    const activationInfo = useActivationParams();
    const activateDevice = useDeviceActivation();
    const { mutateAsync: resend } = useResendDeviceEmail();
    const { setAlert } = useContext(AlertContext);
    const checkParams = useCallback(async () => {
        if (!activationInfo)
            return;
        if (!activationInfo?.deviceName || !activationInfo?.deviceId) {
            navigate(RouteEnum.Login, { replace: true });
            setAlert('Activation link is incorrect', 'error');
        }
        else {
            setIsLoading(false);
        }
    }, [activationInfo]);
    useEffect(() => {
        checkParams();
    }, [checkParams]);
    const handleSubmit = async ({ deviceName, isTrust }) => {
        try {
            const { browserName } = getFingerPrint();
            await activateDevice.mutateAsync({
                ...activationInfo,
                deviceName,
                shouldTrust: isTrust,
                browserName,
            });
            setAlert('Device Authorized', 'success', 2500);
            navigate(RouteEnum.Login, { replace: true });
        }
        catch (error) {
            if (error === 'The code has expired') {
                setIsExpired(true);
            }
            else {
                setIsExpired(false);
                setAlert(error || 'Device authorization failed', 'error');
            }
        }
    };
    const handleResend = async (data) => {
        const { deviceFingerprint } = getFingerPrint();
        await resend({ ...data, deviceFingerprint });
    };
    if (!isLoading && !activateDevice?.isError) {
        return (_jsx(AuthPageLayout, { columnTitle: "Authorize New Device", children: _jsx(DeviceActivationForm, { name: activationInfo?.deviceName || '', onSubmit: handleSubmit, loading: activateDevice.isLoading }) }));
    }
    return (_jsx(ActivationFailedPageLayout, { title: !activateDevice?.isError ? 'Authorize New Device' : 'Failed to Authorize New Device', onResend: handleResend, showResendBtn: isExpired, isLoading: isLoading, captcha: "APPLICATION_PAGE_DEVICE_CONFIRMATION_RESEND", children: isExpired ? (_jsxs(_Fragment, { children: ["Verification link has expire", _jsx("br", {}), _jsx("br", {}), "Please try again by receiving a new email or by logging in once more."] })) : (_jsxs(_Fragment, { children: ["Device verification was unsuccessful.", _jsx("br", {}), _jsx("br", {}), _jsx("b", { children: "The verification has to be done in the same browser and on the same device." }), _jsx("br", {}), _jsx("br", {}), "Verification will fail if, for instance, the verification link opens Chrome by default but you are using Safari. Please try logging in again."] })) }));
};
export default DeviceActivationPage;
