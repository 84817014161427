import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { Fragment, useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { withStyles } from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { useLiquidityMarketsTickerData } from '@emme/shared/queries/LiquidityMarkets';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
const TickerTableCell = withStyles({
    root: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: '16px',
        whiteSpace: 'initial',
    },
})(TableCell);
const TickerInfo = () => {
    const [percentage, setPercentage] = useState(undefined);
    const { selectedLiquidityMarket } = useContext(LiquidityContext);
    const { data: ticker } = useLiquidityMarketsTickerData(selectedLiquidityMarket?.id);
    const { data: quoteCoin } = useMarketInfoLatestCoinData(selectedLiquidityMarket?.quoteCoin);
    useEffect(() => {
        setPercentage(new BigNumber(ticker?.exchangePercentageChange24h || ''));
    }, [ticker]);
    const renderTable = (table) => (_jsxs(Fragment, { children: [_jsxs(TableRow, { children: [_jsx(TickerTableCell, { children: _jsxs(Typography, { minWidth: "130px", variant: "h6", children: [table.head[0], " ", table.head[1]] }) }), ['Change', 'High', 'Low', 'Volume'].map((row) => (_jsx(TickerTableCell, { children: _jsxs(Typography, { color: "textSecondary", children: ["24h ", row, row !== 'Change' ? ` (${table.head[1]})` : ''] }) }, row)))] }), _jsx(TableRow, { children: table.body.map((row, index) => (_jsx(TickerTableCell, { children: _jsx(Typography, { color: index === 1 ? (percentage?.gte(0) ? 'success.main' : 'error.main') : 'default', my: 2, children: row }) }, `${row}_${index}`))) })] }, `${table.head[0]}_${table.head[1]}`));
    return (_jsx(TableContainer, { children: _jsx(Table, { size: "small", children: _jsx(TableBody, { children: [
                    {
                        head: [
                            `${ticker?.exchangeLastTradePrice || '-'}`,
                            `${selectedLiquidityMarket?.quoteCoin || '-'}`,
                        ],
                        body: [
                            `$ ${(quoteCoin &&
                                ticker &&
                                new BigNumber(quoteCoin?.priceUsd)
                                    .multipliedBy(new BigNumber(ticker?.exchangeLastTradePrice))
                                    .toFixed(2)) ||
                                '-'}`,
                            `${percentage?.isNaN() ? '-' : percentage?.toFixed(2)}%`,
                            `${ticker?.exchangeHigh24h || '-'}`,
                            `${ticker?.exchangeLow24h || '-'}`,
                            `${ticker?.exchangeVolume24h || '-'}`,
                        ],
                    },
                ].map((table) => renderTable(table)) }) }) }));
};
export default TickerInfo;
