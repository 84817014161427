import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useLiquidityMarketsData } from '@emme/shared/queries/LiquidityMarkets';
import { useMarketInfoCoinsData } from '@emme/shared/queries/Coins';
import { OverviewContext } from 'context/Overview/OverviewProvider';
import VirtualizeAutocomplete from 'components/VirtualizeAutocomplete';
import { CoinIcon } from 'components/CoinIcon';
export const defaultSelection = 'Will decide later';
export function StepSearch() {
    const [allCoins, setAllCoins] = useState([]);
    const { completeSelectedStep, setPreferredCoin } = useContext(OverviewContext);
    const { data: allMarkets, isLoading: isLoadingMarkets } = useLiquidityMarketsData({
        includeTicker: false,
    });
    const { data: coinData, isLoading: isLoadingCoinData, isFetching: isFetchingCoinData, } = useMarketInfoCoinsData((allCoins || []).join(','));
    useEffect(() => {
        if ((allMarkets?.totalNumber || 0) === 0) {
            setAllCoins([]);
        }
        else {
            setPreferredCoin(defaultSelection);
            setAllCoins([
                defaultSelection,
                ...new Set((allMarkets?.elements || []).flatMap(({ quoteCoin, baseCoin }) => [quoteCoin, baseCoin])),
            ]);
        }
    }, [allMarkets, setPreferredCoin]);
    const handleCompleteClick = useCallback(() => {
        completeSelectedStep();
    }, [completeSelectedStep]);
    return (_jsxs(Stack, { gap: 6, children: [_jsx(Typography, { variant: "h6", children: "Search" }), _jsxs(Stack, { gap: 5, sx: {
                    borderRadius: 2,
                    bgcolor: ({ palette }) => `${palette.neutralsFour.main}20`,
                    p: 5,
                    color: ({ palette }) => palette.text.primary,
                }, children: [_jsx(Typography, { variant: "subtitle1", color: "inherit", children: "What coin would you like to generate liquidity for?" }), _jsx(VirtualizeAutocomplete, { autoComplete: true, autoHighlight: true, clearOnEscape: true, loading: isLoadingMarkets || (isLoadingCoinData && isFetchingCoinData), options: allCoins || [], disableClearable: true, defaultValue: defaultSelection, onInputChange: (event, value) => {
                            if (!event)
                                return;
                            setPreferredCoin(value || '');
                        }, renderOption: (props, option) => [
                            props,
                            _jsx(Stack, { direction: "row", gap: 2, minWidth: "150px", justifyContent: "space-between", alignItems: "center", children: option !== allCoins[0] ? (_jsxs(_Fragment, { children: [_jsxs(Stack, { direction: "row", gap: 2, alignItems: "center", children: [_jsx(CoinIcon, { coin: option, size: 16 }), " ", option] }), "$", new BigNumber(coinData?.find((c) => c.symbol === option)?.priceUsd || 0).toFixed(2)] })) : (option) }),
                        ], sx: { maxWidth: '250px' }, disabled: isLoadingMarkets || (isLoadingCoinData && isFetchingCoinData), renderInput: ({ ...rest }) => (_jsx(TextField, { autoComplete: "coin", sx: {
                                '.MuiOutlinedInput-root': {
                                    bgcolor: ({ palette }) => `${palette.neutralsFour.main}50`,
                                    borderRadius: 12,
                                    '.MuiAutocomplete-input': {
                                        pl: 5,
                                    },
                                },
                            }, ...rest })) })] }), _jsx(Button, { type: "button", variant: "outlined", color: "inherit", onClick: handleCompleteClick, sx: { minWidth: '260px', alignSelf: 'center' }, children: "Continue" })] }));
}
