import { useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeLiquidity = 10 * 1000;
export function useLiquidityMarketOrderBookSubscription(id) {
    return useQuery(['liquidity-markets', 'order-book', 'subscription', id], createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: `/liquidity-markets/${id}/order-book/subscriptions`,
        transformData: (data) => data.orders,
    }), {
        staleTime: intervalTimeLiquidity,
        onError: (error) => error,
        refetchInterval: intervalTimeLiquidity,
        refetchOnWindowFocus: true,
        enabled: Boolean(id),
    });
}
export function useLiquidityMarketOrderBookProvision(id) {
    return useQuery(['liquidity-markets', 'order-book', 'provision', id], createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: `/liquidity-markets/${id}/order-book/provisions`,
        transformData: (data) => data.orders,
    }), {
        staleTime: intervalTimeLiquidity,
        onError: (error) => error,
        refetchInterval: intervalTimeLiquidity,
        refetchOnWindowFocus: true,
        enabled: Boolean(id),
    });
}
