import { useMutation } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
export function useResendSignupEmail() {
    return useMutation(createFetchFn({
        requiresAuth: false,
        method: 'POST',
        url: '/users/signup/activate/resend',
    }), {
        mutationKey: ['resend-email'],
        retry: false,
    });
}
export function useResendDeviceEmail() {
    return useMutation(createFetchFn({
        requiresAuth: false,
        method: 'POST',
        url: '/users/devices/confirm/resend',
    }), {
        mutationKey: ['resend-device-email'],
        retry: false,
    });
}
