import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { OnboardingContext } from 'context/OnboardingProvider';
import { ApiKeyDialog, getLink } from 'components/Dialogs';
import OnboardingContentText from '../OnboardingContentText';
const Step3 = () => {
    const [selectedExchangeId, setSelectedExchangeId] = useState('');
    const { changeActiveStep, activeStep } = useContext(OnboardingContext);
    const handleSubmit = useCallback(() => {
        changeActiveStep(activeStep + 1);
    }, [changeActiveStep, activeStep]);
    return (_jsxs(_Fragment, { children: [_jsxs(OnboardingContentText, { children: ["As a provider, you will need to set up an API key from your exchange to be able to place orders.", ' ', _jsx(Link, { href: getLink(selectedExchangeId), target: "_blank", rel: "noreferrer noopener", "aria-label": "how-to-api-key", pb: 7, children: "How do I get an API key?" })] }), _jsx(Stack, { direction: "row", alignItems: "center", justifyContent: "center", gap: 4, pt: 8, children: _jsx(ApiKeyDialog, { buttonText: _jsx(Typography, { component: "span", children: "Add API key" }), onboarding: true, changeForm: setSelectedExchangeId, onSubmit: handleSubmit }) })] }));
};
export default Step3;
