import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useChangeUserPassword, useUserInfoData } from '@emme/shared/queries/Users';
import { AlertContext } from 'context/AlertProvider';
import { getErrorFormatter, lowerCaseRegex, numberRegex, specialCharacterRegex, upperCaseRegex, } from 'utils/util';
import { PasswordTextField } from 'components/Inputs';
const validationSchema = Yup.object({
    currentPassword: Yup.string()
        .label('Current password')
        .min(8, 'Length must be at least 8 letters!')
        .max(100, 'Length must be less than 100 letters!')
        .required(),
    newPassword: Yup.string()
        .label('New password')
        .min(8, 'Length must be at least 8 letters!')
        .max(100, 'Length must be less than 100 letters!')
        .matches(lowerCaseRegex, 'Password should contain at least 1 small letter')
        .matches(upperCaseRegex, 'Password should contain at least 1 capital letter')
        .matches(numberRegex, 'Password should contain at least 1 number')
        .matches(specialCharacterRegex, 'Password should contain at least 1 special character (e.g. !#$%&*-?@^)')
        .required(),
    confirmPassword: Yup.string().when('newPassword', (password, field) => {
        if (!password)
            return field;
        return field
            .required('Confirm new password is required')
            .oneOf([Yup.ref('newPassword')], 'Passwords must match');
    }),
});
const ChangePasswordForm = () => {
    const [passwordFieldKey, setPasswordKey] = useState(0);
    const { setAlert } = useContext(AlertContext);
    const updatePassword = useChangeUserPassword();
    const { data: user } = useUserInfoData();
    const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, isValid, isSubmitting, resetForm, } = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema,
        onSubmit: async ({ currentPassword, newPassword }) => {
            try {
                await updatePassword.mutateAsync({ oldPassword: currentPassword, newPassword });
                resetForm();
                setPasswordKey((current) => current + 1);
                setAlert('Password updated successfully', 'success', 2500);
            }
            catch (error) {
                setAlert(error?.message || error, 'error');
            }
        },
    });
    const formatError = getErrorFormatter(touched, errors);
    const touchAllFields = () => {
        setFieldTouched('currentPassword');
        setFieldTouched('newPassword');
        setFieldTouched('confirmPassword');
    };
    return (_jsxs(Stack, { component: "form", gap: 4, mx: 5, onSubmit: handleSubmit, noValidate: true, autoComplete: "off", children: [_jsx(Box, { sx: { display: 'none' }, children: _jsx("input", { type: "email", name: "username", value: user?.email, readOnly: true, autoComplete: "off" }) }), _jsx(PasswordTextField, { label: "Current Password", value: values.currentPassword, onChange: handleChange, onBlur: () => setFieldTouched('currentPassword'), name: "currentPassword", autoComplete: "password", helperText: formatError('currentPassword'), error: touched.currentPassword && Boolean(errors.currentPassword), disabled: isSubmitting }, `current${passwordFieldKey}`), _jsx(PasswordTextField, { label: "New Password", value: values.newPassword, onChange: handleChange, onBlur: () => setFieldTouched('newPassword'), name: "newPassword", autoComplete: "new-password", helperText: formatError('newPassword'), error: touched.newPassword && Boolean(errors.newPassword), disabled: isSubmitting }, `new${passwordFieldKey}`), _jsx(PasswordTextField, { label: "Confirm password", value: values.confirmPassword, onChange: handleChange, onBlur: () => setFieldTouched('confirmPassword'), name: "confirmPassword", autoComplete: "new-confirmPassword", helperText: formatError('confirmPassword'), error: touched.confirmPassword && Boolean(errors.confirmPassword), disabled: isSubmitting }, `confirm${passwordFieldKey}`), _jsx(Box, { alignSelf: "flex-end", role: "button", onClick: () => touchAllFields(), onKeyPress: () => touchAllFields(), tabIndex: -1, children: _jsx(LoadingButton, { loading: isSubmitting, variant: "contained", type: "submit", size: "large", disabled: !touched.currentPassword || !isValid, children: _jsx(Typography, { variant: "h6", children: "Save settings" }) }) })] }));
};
export default ChangePasswordForm;
