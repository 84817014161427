import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { VictoryChart, VictoryScatter, Selection, VictoryLine, VictoryTheme, VictoryAxis, } from 'victory';
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { emmeBody3 } from '@emme/shared/material';
const initialPoints = [
    { index: 0, x: 1, y: 3 },
    { index: 1, x: 2, y: 1 },
    { index: 2, x: 3, y: 4 },
];
const DraggableChart = () => {
    const theme = useTheme();
    const [points, setPoints] = useState(initialPoints);
    const [maxX, setMaxX] = useState(undefined);
    const [maxY, setMaxY] = useState(undefined);
    const [minX, setMinX] = useState(undefined);
    const [minY, setMinY] = useState(undefined);
    const updateData = (point, index) => {
        const currentPoints = points;
        currentPoints[index] = point;
        setPoints([...currentPoints]);
    };
    return (_jsxs(Stack, { children: [_jsx(Typography, { variant: "body3", children: _jsxs(Stack, { direction: "row", alignItems: "stretch", children: [_jsx(Box, { color: "text.secondary", children: "Price:" }), _jsx(Box, { pl: 2, minWidth: "100px", children: "xy $EMME" }), _jsx(Box, { pl: 2, color: "text.secondary", children: "Spread:" }), _jsx(Box, { pl: 2, textAlign: "right", children: "z.ab%" })] }) }), _jsxs(VictoryChart, { domain: { x: [-0.5, 4.5], y: [0, 8.5] }, height: 320, theme: VictoryTheme.material, style: {
                    parent: {
                        marginTop: -25,
                        marginBottom: -8,
                    },
                }, children: [_jsx(VictoryAxis, { theme: VictoryTheme.material, standalone: false, crossAxis: false, tickValues: [-0.5, 0, 1, 2, 3, 4], tickFormat: (t) => (t < 0 ? '' : `${new BigNumber(t).toFixed(2)}%`), style: {
                            axis: {
                                stroke: theme.palette.neutralsFour.main,
                                strokeWidth: 1,
                            },
                            ticks: {
                                strokeWidth: 0,
                            },
                            tickLabels: {
                                ...emmeBody3,
                                fill: theme.palette.neutralsSix.main,
                            },
                            grid: {
                                stroke: ({ tick }) => tick < 0 ? theme.palette.neutralsFour.main : theme.palette.neutralsThree.main,
                                pointerEvents: 'none',
                                strokeDasharray: 'none',
                            },
                        } }), _jsx(VictoryAxis, { dependentAxis: true, theme: VictoryTheme.material, standalone: false, crossAxis: false, tickFormat: (t) => `${new BigNumber(t).toFixed(2)}`, style: {
                            axis: {
                                stroke: theme.palette.neutralsFour.main,
                                strokeWidth: 1,
                            },
                            ticks: {
                                strokeWidth: 0,
                            },
                            tickLabels: {
                                ...emmeBody3,
                                fill: theme.palette.neutralsSix.main,
                            },
                            grid: {
                                stroke: theme.palette.neutralsThree.main,
                                pointerEvents: 'none',
                                strokeDasharray: 'none',
                            },
                        }, offsetX: 50, orientation: "right" }), _jsx(VictoryLine, { data: points, sortKey: "index" }), _jsx(VictoryScatter, { data: points, size: 8, style: {
                            data: {
                                fill: (active) => (active ? 'red' : 'grey'),
                                strokeWidth: 10,
                                stroke: 'none', // with an invisible stroke
                            },
                        }, events: [
                            {
                                target: 'data',
                                eventHandlers: {
                                    onMouseOver: () => ({
                                        mutation: (props) => ({ ...props, active: true }),
                                    }),
                                    onMouseDown: () => ({
                                        mutation: (props) => ({ ...props, dragging: true }),
                                    }),
                                    onMouseMove: (evt, targetProps) => {
                                        const { scale, datum, dragging } = targetProps;
                                        if (dragging) {
                                            let { x, y } = Selection.getSVGEventCoordinates(evt);
                                            const { index } = datum;
                                            const point = { index, x: scale.x.invert(x), y: scale.y.invert(y) };
                                            const pointMaxX = index === points.length - 1 ? 4 : points[index + 1].x;
                                            const pointMinX = index === 0 ? 0.25 : points[index - 1].x;
                                            if (point.x > pointMaxX) {
                                                point.x = pointMaxX;
                                                if (maxX === undefined) {
                                                    setMaxX(x);
                                                }
                                                x = maxX || x;
                                            }
                                            if (point.x < pointMinX) {
                                                point.x = pointMinX;
                                                if (minX === undefined) {
                                                    setMinX(x);
                                                }
                                                x = minX || x;
                                            }
                                            if (point.y > 8) {
                                                point.y = 8;
                                                if (maxY === undefined) {
                                                    setMaxY(y);
                                                }
                                                y = maxY || y;
                                            }
                                            if (point.y < 0.25) {
                                                point.y = 0.25;
                                                if (minY === undefined) {
                                                    setMinY(y);
                                                }
                                                y = minY || y;
                                            }
                                            updateData(point, index); // call your event handler to set state for your app
                                            return { mutation: () => ({ ...targetProps, x, y }) }; // actually move the points
                                        }
                                        return null;
                                    },
                                    onMouseUp: () => ({
                                        mutation: (props) => ({ ...props, dragging: false, active: false }),
                                    }),
                                    onMouseLeave: () => ({
                                        mutation: (props) => ({ ...props, dragging: false, active: false }),
                                    }),
                                },
                            },
                        ] })] })] }));
};
export default DraggableChart;
