import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import BigNumber from 'bignumber.js';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { AlertContext } from 'context/AlertProvider';
import { useCheckUsersExchangeBalances, useUserExchangesBalanceData, } from '@emme/shared/queries/Users';
import { useLiquidityMarketsData } from '@emme/shared/queries/LiquidityMarkets';
import { useMarketInfoCoinsData } from '@emme/shared/queries/Coins';
import { ColumnAlign } from '@emme/shared/model/table.model';
import MarketSelectLogoBody from 'components/MarketSelectLogoBody';
import { CoinAmountCell, NumberCell } from 'components/Table/cells';
import { MarketSelectLogoButton } from 'components/Buttons';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { EmmeTable } from 'components/Table';
import OnboardingContentText from '../OnboardingContentText';
import OnboardingStepper from '../OnboardingStepper';
const getExchangeLink = (selectedExchangeId) => {
    switch (selectedExchangeId) {
        case 'binance':
            return 'https://www.binance.com';
        case 'coinbase-pro':
            return 'https://pro.coinbase.com';
        case 'bittrex-global':
            return 'https://global.bittrex.com';
        case 'ftx-us':
            return 'https://ftx.us';
        default:
            return 'https://wiki.em.me';
    }
};
const getFilteredExchangeBalances = (selectedExchangeId, allExchanges, allMarkets) => {
    const { balances: selectedExchangeBalances } = allExchanges?.find((exchange) => exchange?.exchangeId === selectedExchangeId) || {};
    return (selectedExchangeBalances || [])?.map((b) => {
        const findMarket = allMarkets?.elements.find((market) => market.baseCoin === b.currency || market.quoteCoin === b.currency);
        const minimum = findMarket
            ? findMarket?.baseCoin === b.currency
                ? findMarket.minimumBaseOrderVolume
                : findMarket.minimumQuoteOrderVolume
            : '0';
        const requiredAmount = new BigNumber(minimum).minus(new BigNumber(b.available));
        return {
            id: b.currency,
            ...b,
            minimum,
            required: {
                amount: requiredAmount.gt(0) ? requiredAmount.toFixed() : '0',
                coin: b.currency || '-',
            },
        };
    });
};
const Step4 = () => {
    const { selectedExchange, selectedLiquidityMarket, markets, isLoading: isLoadingSelectedExchange, } = useContext(LiquidityContext);
    const { setAlert } = useContext(AlertContext);
    const { data: allExchangeBalances, isLoading: isLoadingBalances, refetch: refetchBalances, } = useUserExchangesBalanceData(true);
    const { mutateAsync: recheckUserExchangeBalance, isLoading: isLoadingRecheck } = useCheckUsersExchangeBalances();
    const { data: allMarkets, isLoading: isLoadingMarkets } = useLiquidityMarketsData({
        includeTicker: false,
        exchangeId: selectedExchange?.id,
    });
    const mergedExchangeBalances = getFilteredExchangeBalances(selectedExchange?.id, allExchangeBalances, allMarkets);
    const userCoins = mergedExchangeBalances?.map((m) => m.currency);
    const validMarkets = markets.filter((m) => userCoins.includes(m.quoteCoin) || userCoins.includes(m.baseCoin));
    const { data: coinData, isLoading: isLoadingCoinData, isFetching: isFetchingCoinData, } = useMarketInfoCoinsData((userCoins || []).join(','));
    const hasNoBalances = mergedExchangeBalances && mergedExchangeBalances?.length === 0;
    const hasNotEnoughBalances = (mergedExchangeBalances?.filter((b) => new BigNumber(b.available).gte(new BigNumber(b.minimum)))
        ?.length || 0) === 0 ||
        (validMarkets && validMarkets?.length === 0);
    let minRequiredBalance = [];
    const balanceHeadCells = [
        {
            id: 'currency',
            label: 'Assets',
        },
        {
            id: 'available',
            Component: NumberCell,
            label: 'Available',
            componentProps: {
                numberDecimals: 4,
            },
        },
        {
            id: 'minimum',
            Component: NumberCell,
            label: 'Minimum',
            componentProps: {
                numberDecimals: 4,
            },
        },
    ];
    if (hasNotEnoughBalances) {
        balanceHeadCells.push({
            id: 'required',
            label: 'Need',
            Component: CoinAmountCell,
            align: ColumnAlign.RIGHT,
            componentProps: {
                addBold: true,
                numberDecimals: 4,
            },
        });
        minRequiredBalance = mergedExchangeBalances
            .map((m) => {
            const priceUsd = coinData?.find((c) => c.symbol === m.currency)?.priceUsd || '0';
            return {
                currency: m.currency,
                required: new BigNumber(m.required.amount || 0),
                minPrice: new BigNumber(priceUsd).multipliedBy(new BigNumber(m.required.amount)),
            };
        })
            .filter((p) => p?.minPrice.gt(0))
            .sort((a, b) => a.minPrice.toNumber() - b.minPrice.toNumber());
    }
    let availableCoins = [];
    if (hasNoBalances) {
        availableCoins = [
            ...new Set(markets.flatMap((m) => [m.baseCoin.toUpperCase(), m.quoteCoin.toUpperCase()])),
        ].sort((a, b) => {
            const firstCoins = ['BTC', 'USD'];
            const includesA = firstCoins.includes(a);
            const includesB = firstCoins.includes(b);
            if (includesA && !includesB)
                return -1;
            if (!includesA && includesB)
                return 1;
            return a.localeCompare(b);
        });
    }
    const recheckBalance = async () => {
        let isValid = false;
        let msg = 'Not enough balance. Please increase your balance and try again';
        try {
            const res = await recheckUserExchangeBalance({});
            const filtered = getFilteredExchangeBalances(selectedExchange?.id, res, allMarkets);
            if (filtered.length === 0) {
                throw new Error('notValid');
            }
            const filteredCoins = filtered?.map((m) => m.currency);
            const filteredMarkets = markets.filter((m) => filteredCoins.includes(m.quoteCoin) || filteredCoins.includes(m.baseCoin));
            if (filteredMarkets.length === 0) {
                throw new Error('notValid');
            }
            if ((filtered?.filter((b) => new BigNumber(b.available).gte(new BigNumber(b.minimum)))
                ?.length || 0) === 0) {
                throw new Error('notValid');
            }
            isValid = true;
            refetchBalances();
        }
        catch (error) {
            if (error?.message !== 'notValid') {
                msg = error?.message || error || 'Failed to load balances';
            }
        }
        if (!isValid) {
            setAlert(msg, 'error');
        }
    };
    const isLoadingStepData = isLoadingBalances ||
        isLoadingMarkets ||
        (isLoadingCoinData && isFetchingCoinData) ||
        isLoadingSelectedExchange;
    return (_jsxs(_Fragment, { children: [_jsx(Box, { pt: 5, children: _jsx(OnboardingStepper, { disableNext: hasNoBalances || hasNotEnoughBalances }) }), _jsx(LoadingOverlay, { isLoading: isLoadingStepData, size: 50, children: _jsxs(Stack, { gap: 5, children: [_jsx(Typography, { align: "center", variant: "h4", pt: 8, children: hasNoBalances
                                ? 'Insufficient Balance'
                                : hasNotEnoughBalances
                                    ? 'Get Sufficient Balance'
                                    : 'Select Market' }), _jsxs(Card, { sx: {
                                width: '100%',
                                backgroundColor: 'transparent',
                                border: '1px solid',
                                borderColor: (t) => hasNoBalances || hasNotEnoughBalances
                                    ? t.palette.error.main
                                    : t.palette.primary.main,
                            }, elevation: 0, children: [_jsx(CardHeader, { title: _jsxs(Stack, { direction: "row", gap: 4, alignItems: "center", children: [_jsx(InfoOutlinedIcon, { color: hasNoBalances || hasNotEnoughBalances ? 'error' : 'primary' }), _jsx(OnboardingContentText, { children: hasNoBalances
                                                    ? `Your API credentials were imported successfully, but you don’t have assets in the selected exchange.
                        Please increase your balance before proceeding,
                        such as transferring ${availableCoins.slice(0, 5).join(', ')}${availableCoins.length > 5 ? ', ...' : ''} from another source to your account.`
                                                    : hasNotEnoughBalances
                                                        ? `Your API credentials were imported successfully, but you don't have enough
                  balance to provide liquidity on EM.ME.`
                                                        : 'Congratulations! Your API credentials were imported successfully.' })] }), sx: hasNoBalances ? {} : { pb: 0 } }), !hasNoBalances && (_jsxs(CardContent, { sx: { maxHeight: '200px' }, children: [_jsx(Divider, {}), _jsx(EmmeTable, { rows: mergedExchangeBalances || [], tableMinWidth: 100, inputHeadCells: balanceHeadCells, isLoading: isLoadingBalances })] }))] }), (hasNotEnoughBalances || hasNoBalances) && (_jsxs(OnboardingContentText, { children: [hasNotEnoughBalances && (_jsxs(_Fragment, { children: ["Your closest balance is ", _jsx("b", { children: minRequiredBalance[0]?.currency }), ", where you need", ' ', _jsx("b", { children: minRequiredBalance[0]?.required.toFormat(4) }), " (approximately", ' ', _jsxs("b", { children: ["$", minRequiredBalance[0]?.minPrice.toFormat(2), ")"] }), " to be able to place orders on EM.ME.", _jsx("br", {})] })), _jsx(Link, { href: getExchangeLink(selectedExchange?.id || ''), target: "_blank", rel: "noreferrer noopener", "aria-label": "exchange-link", children: "Go to your exchange" }), ' ', "and increase your balance."] })), hasNoBalances || hasNotEnoughBalances ? (_jsx(Box, { display: "flex", justifyContent: "center", children: _jsx(LoadingButton, { type: "button", variant: "outlined", color: "inherit", onClick: recheckBalance, loading: isLoadingRecheck, children: _jsx(Typography, { children: "I have enough balance, check again" }) }) })) : (_jsxs(_Fragment, { children: [_jsx(OnboardingContentText, { children: "With your current balance, these are the markets in which you can provide liquidity:" }), _jsx(Card, { sx: {
                                        width: '100%',
                                        backgroundColor: (t) => t.palette.neutralOne.main,
                                    }, elevation: 0, children: _jsx(CardContent, { sx: { maxHeight: '160px', pt: 0 }, children: _jsx(MarketSelectLogoBody, { userValidMarkets: validMarkets }) }) }), _jsx(Typography, { variant: "subtitle1", color: "textPrimary", children: _jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "flex-start", gap: 4, children: [_jsx(Box, { children: "Currently Selected:" }), _jsx(MarketSelectLogoButton, { disabled: true, tabIndex: -1, sx: {
                                                    '&.Mui-disabled': {
                                                        color: (t) => t.palette.neutralsEight.main,
                                                    },
                                                }, quoteCoin: selectedLiquidityMarket?.quoteCoin, baseCoin: selectedLiquidityMarket?.baseCoin })] }) })] }))] }) })] }));
};
export default Step4;
