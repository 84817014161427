import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useLiquidityMarketsTickerData } from '@emme/shared/queries/LiquidityMarkets';
import { useBreakpoint } from 'hooks/useBreakpoint';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import MarketSelectLogo from './components/MarketSelectLogo';
import ExchangeSelectButton from './components/ExchangeSelectButton';
const MobileInfoTab = () => {
    const { phone } = useBreakpoint();
    const { selectedLiquidityMarket } = useContext(liquidityContext);
    const { data: ticker } = useLiquidityMarketsTickerData(selectedLiquidityMarket?.id);
    const percentageChange = Number(ticker?.exchangePercentageChange24h);
    const formattedPercentage = Number.isNaN(percentageChange)
        ? '-'
        : `${percentageChange > 0 ? '+' : ''}${percentageChange.toFixed(2)}`;
    return (_jsxs(Stack, { gap: 6, mt: 3, children: [_jsxs(Box, { sx: { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 4, mx: 2 }, children: [_jsx(ExchangeSelectButton, {}), _jsx(MarketSelectLogo, { customSize: phone ? undefined : 14 })] }), _jsxs(Stack, { direction: "row", gap: 3, sx: {
                    alignItems: 'center',
                    opacity: ticker ? 1 : 0,
                    transition: 'opacity 0.2s',
                }, children: [_jsxs(Typography, { variant: "h6", component: "span", children: [ticker?.exchangeLastTradePrice || '-', " ", selectedLiquidityMarket?.quoteCoin, ' '] }), _jsxs(Typography, { variant: "body1", component: "span", color: percentageChange >= 0 ? 'secondary' : 'error', children: [formattedPercentage, "%"] })] })] }));
};
export default MobileInfoTab;
