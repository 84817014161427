import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CoinIcon } from 'components/CoinIcon';
import { useUserBalanceData } from '@emme/shared/queries/Users';
import BigNumber from 'bignumber.js';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
export function MobileEmmeCard() {
    const { data: balance } = useUserBalanceData();
    const { data: emmeCoinData } = useMarketInfoLatestCoinData('emme');
    const emmeBalance = balance ? new BigNumber(balance.available).toFormat(4) : '-';
    const usdBalance = balance && emmeCoinData
        ? new BigNumber(balance.available).multipliedBy(emmeCoinData.priceUsd).toFormat(2)
        : '-';
    return (_jsxs(Stack, { gap: 5, children: [_jsx(CoinIcon, { coin: "emme", size: 50 }), _jsxs("div", { children: [_jsx(Typography, { variant: "h4", pb: 3, children: emmeBalance }), _jsx(Typography, { variant: "h6", color: "text.secondary", children: `$${usdBalance}` })] })] }));
}
