import { StorageKey } from '../model/enums';
export function get(key) {
    const value = localStorage.getItem(key);
    try {
        const parsedValue = value ? JSON.parse(value) : value;
        return parsedValue;
    }
    catch (error) {
        localStorage.removeItem(key);
        console.info(`function: 'localStorage.get',
      message: 'Could not parse value for key:${key} value:${value}',
      cause: ${error}`);
    }
}
export function set(key, value) {
    let stringifiedValue;
    try {
        stringifiedValue = JSON.stringify(value);
    }
    catch (error) {
        console.info(`function: 'localStorage.set',
      message: 'Could not stringify value for key:${key} value:${value}',
      cause: ${error}`);
        return;
    }
    try {
        localStorage.setItem(key, stringifiedValue);
    }
    catch (error) {
        console.info(`function: 'localStorage.set',
      message: 'Could not save to localStorage for key:${key} value:${value}',
      cause: ${error}`);
    }
}
export function remove(key) {
    localStorage.removeItem(key);
}
export function clear() {
    localStorage.removeItem(StorageKey.EMME_TOKEN);
    localStorage.removeItem(StorageKey.EMME_REMEMBER_ME);
}
