/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { NotificationTypeEnum } from '@emme/shared/model/enums';
import { WSS_URL } from '@emme/shared/apiConfig';
import { unreadQueryKey } from '@emme/shared/queries/Users';
import { OnlineStatusContext } from 'context/OnlineStatusProvider';
import { SocketContext } from 'context/SocketProvider';
let websocket = null;
const baseURL = WSS_URL;
const SocketControl = () => {
    const queryClient = useQueryClient();
    const { isOpen, updateIsOpen, closeSocket, currentMessages, removeMessage } = useContext(SocketContext);
    const { isConnected } = useContext(OnlineStatusContext);
    const isMounted = useRef(true);
    useEffect(() => {
        if (!isConnected || isOpen || Boolean(websocket) || !baseURL)
            return;
        websocket = new WebSocket(baseURL);
        websocket.onopen = () => {
            console.log('websocket connected');
            updateIsOpen(true);
        };
        websocket.onmessage = (event) => {
            const { service, data } = JSON.parse(event.data);
            let queryKey;
            if (service === 'NOTIFICATION') {
                if (data?.type !== NotificationTypeEnum.SPECIAL) {
                    queryKey = ['users', 'me', 'notifications', 0, 100];
                    [(queryKey.concat(['all']), queryKey.concat(['unread']))].forEach((key) => {
                        if (!key || !data || Object.keys(data)?.length === 0)
                            return;
                        queryClient.setQueriesData(key, (oldData) => ({
                            ...oldData,
                            notifications: {
                                ...oldData?.notifications,
                                elements: [{ ...data }, ...(oldData?.notifications?.elements || [])],
                                totalNumber: (oldData?.notifications?.totalNumber || 0) + 1,
                            },
                        }));
                    });
                }
                else {
                    if (!unreadQueryKey || !data || Object.keys(data)?.length === 0)
                        return;
                    queryClient.setQueriesData(unreadQueryKey, (oldData) => {
                        if (!oldData?.specialNotifications) {
                            return {
                                ...oldData,
                                specialNotifications: {
                                    elements: [{ ...data }],
                                    totalNumber: 1,
                                },
                            };
                        }
                        return {
                            ...oldData,
                            specialNotifications: {
                                ...oldData?.specialNotifications,
                                elements: [{ ...data }, ...(oldData?.specialNotifications?.elements || [])],
                                totalNumber: (oldData?.specialNotifications?.totalNumber || 0) + 1,
                            },
                        };
                    });
                }
            }
            else {
                switch (service) {
                    case 'ROUNDS':
                        queryKey = ['rounds', 'current'];
                        break;
                    default:
                        queryKey = [''];
                        break;
                }
                if (!queryKey || !data || Object.keys(data)?.length === 0)
                    return;
                queryClient.setQueriesData(queryKey, (oldData) => {
                    switch (service) {
                        case 'ROUNDS':
                            queryClient.refetchQueries(['liquidity-markets'], { type: 'active' });
                            queryClient.refetchQueries(['users', 'me', 'balance'], { exact: true });
                            queryClient.refetchQueries(['users', 'me', 'exchanges', 'balances'], { exact: true });
                            return { ...oldData, ...data };
                        default:
                            return { ...oldData };
                    }
                });
            }
        };
        websocket.onclose = (event) => {
            console.log('websocket closed', event?.reason);
            websocket = null;
            if (isMounted.current) {
                closeSocket();
            }
        };
        websocket.onerror = () => {
            console.log('websocket error');
            websocket?.close();
        };
        return () => {
            if (websocket)
                websocket.close();
        };
    }, [queryClient, isConnected]);
    useEffect(() => {
        if (!websocket || websocket.readyState !== websocket.OPEN || !currentMessages?.length)
            return;
        websocket.send(currentMessages[0]);
        removeMessage(currentMessages[0]);
    }, [currentMessages]);
    useEffect(() => () => {
        isMounted.current = false;
    }, []);
    return null;
};
export default SocketControl;
