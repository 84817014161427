import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { ExternalLink } from '@emme/shared/icons';
export const HelpMenuItem = ({ icon, title, description, colorHex, onClick, href, }) => (_jsxs(Paper, { elevation: 0, sx: {
        display: 'flex',
        px: 5,
        gap: 4,
        height: '66px',
        alignItems: 'center',
        backgroundColor: `${colorHex}26`,
        ':hover': {
            backgroundColor: `${colorHex}40`,
            '.external-link-icon': {
                color: (t) => t.palette.text.primary,
            },
        },
        transition: 'background-color 0.2s',
        cursor: 'pointer',
        borderRadius: 3,
    }, onClick: onClick, href: href, component: href ? Link : 'div', target: href ? '_blank' : undefined, role: href ? 'link' : 'button', children: [icon, _jsxs(Stack, { gap: 3, children: [_jsxs(Stack, { gap: 1, direction: "row", children: [_jsx(Typography, { variant: "h6", component: "span", children: title }), href && (_jsx(Box, { className: "external-link-icon", sx: {
                                display: 'inline',
                                color: (t) => [t.palette.text.primary, undefined, t.palette.text.secondary],
                                transition: 'color 0.2s',
                            }, children: _jsx(ExternalLink, {}) }))] }), _jsx(Typography, { variant: "subtitle1", color: "text.primary", children: description })] })] }));
HelpMenuItem.defaultProps = {
    onClick: undefined,
    href: undefined,
    colorHex: '#B1B5C3',
};
