import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { getCellAlignment } from './tableUtil';
const TableLoadingSkeleton = ({ inputRowsPerPage, inputHeadCells }) => {
    const rows = Math.floor(inputRowsPerPage / 3) || 1;
    return (_jsx(TableBody, { children: [...Array(rows)].map((row, index) => (_jsx(TableRow, { tabIndex: -1, sx: { pb: 8 }, children: inputHeadCells.map((cell, i) => (_jsx(TableCell, { align: getCellAlignment(cell), variant: "body", style: { minWidth: cell?.minWidth || 0 }, sx: { border: 0 }, children: _jsx(Box, { justifyContent: getCellAlignment(cell), display: "flex", children: _jsx(Skeleton, { variant: "text", animation: "wave", width: "100%", height: "20px" }) }) }, i))) }, index))) }));
};
TableLoadingSkeleton.defaultProps = {
    inputRowsPerPage: 10,
};
export default TableLoadingSkeleton;
