import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteEnum } from '@emme/shared/model/enums';
import Stack from '@mui/material/Stack';
import PlayCircleOutlined from '@mui/icons-material/PlayCircleOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import { WIKI_URL, ZENDESK_URL } from 'config';
import { OnboardingContext, OnboardingType } from 'context/OnboardingProvider';
import { Zendesk } from '@emme/shared/icons';
import { HelpMenuItem } from './HelpMenuItem';
export const HelpMenu = ({ onClose }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { openOnboarding } = useContext(OnboardingContext);
    const handleOpenOnboarding = () => {
        if (!location.pathname.startsWith(RouteEnum.Dashboard)) {
            navigate(RouteEnum.Dashboard);
        }
        onClose && onClose();
        openOnboarding(OnboardingType.HELP);
    };
    return (_jsxs(Stack, { gap: 4, children: [_jsx(HelpMenuItem, { icon: _jsx(PlayCircleOutlined, { fontSize: "large" }), title: "Play Setup Wizard", description: "Get to know EM.ME and place live order", colorHex: "#58BD7D", onClick: handleOpenOnboarding }), _jsx(HelpMenuItem, { icon: _jsx(Zendesk, { width: 40 }), title: "EM.ME Zendesk", description: "Report or look at existing issues", href: ZENDESK_URL }), _jsx(HelpMenuItem, { icon: _jsx(BookOutlinedIcon, { fontSize: "large" }), title: "EM.ME Wiki", description: "Learn all there is to know about EM.ME", href: WIKI_URL })] }));
};
HelpMenu.defaultProps = {
    onClose: undefined,
};
