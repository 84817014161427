import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from '@mui/material/Grid';
import ExchangeSelectLogo from './components/ExchangeSelectLogo';
import MarketSelectLogo from './components/MarketSelectLogo';
import CurrentRound from './components/CurrentRound';
import TickerInfo from './components/TickerInfo';
const InfoTab = () => (_jsxs(Grid, { container: true, direction: "row", justifyContent: "flex-start", alignItems: "center", sx: {
        height: '100%',
        py: 2,
        overflowX: 'auto',
        overflowY: 'hidden',
    }, wrap: "nowrap", children: [_jsx(Grid, { item: true, p: 1, pr: 3, children: _jsx(ExchangeSelectLogo, {}) }), _jsx(Grid, { item: true, p: 1, pr: 3, children: _jsx(MarketSelectLogo, {}) }), _jsx(Grid, { item: true, p: 1, pr: 3, children: _jsx(CurrentRound, {}) }), _jsx(Grid, { item: true, p: 1, children: _jsx(TickerInfo, {}) })] }));
export default InfoTab;
