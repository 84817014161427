import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { RouteEnum } from '@emme/shared/model/enums';
const MobileDrawer = ({ user, children, drawerVariant, iconToShow: IconToShow, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { pathname } = useLocation();
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsOpen(open);
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { color: "inherit", "aria-label": "open drawer", onClick: toggleDrawer(true), edge: "start", size: "large", sx: { py: 0, px: 3 }, children: IconToShow && _jsx(IconToShow, {}) }), _jsx(SwipeableDrawer, { anchor: drawerVariant, open: isOpen, onClose: toggleDrawer(false), onOpen: toggleDrawer(true), style: { height: '100%' }, disableSwipeToOpen: (!user?.onboardingFinished && pathname.includes(RouteEnum.Dashboard)) ||
                    (typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)), children: _jsx(Box, { sx: drawerVariant !== 'bottom' ? { width: '250px', height: '100%' } : { py: 2 }, role: "presentation", onClick: toggleDrawer(false), onKeyDown: toggleDrawer(false), children: children }) })] }));
};
MobileDrawer.defaultProps = {
    user: undefined,
    drawerVariant: 'right',
    iconToShow: MenuOutlinedIcon,
};
export default MobileDrawer;
