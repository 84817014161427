import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
const DeviceActivationForm = ({ name, onSubmit, loading }) => {
    const [deviceName, setDeviceName] = useState(name);
    const [errorMessage, setErrorMessage] = useState('');
    const handleChange = (event) => {
        const { value } = event.target;
        setErrorMessage(!value ? 'Please input Device Name' : '');
        setDeviceName(value);
    };
    const hasError = Boolean(!deviceName && errorMessage);
    return (_jsxs(Stack, { gap: 3, justifyContent: "space-between", children: [_jsx(Typography, { variant: "subtitle1", pb: 7, children: "Do you want us to always authorize this device so we don\u2019t require additional confirmation each time you sign in?" }), _jsx(TextField, { label: "Device nickname", value: deviceName, onChange: handleChange, name: "deviceName", type: "text", fullWidth: true, required: true, error: hasError, helperText: errorMessage || ' ', autoFocus: true, autoComplete: "off" }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", gap: 3, pt: 3, children: [_jsx(LoadingButton, { variant: "outlined", type: "button", fullWidth: true, size: "large", loading: loading, disabled: hasError, color: "inherit", onClick: () => onSubmit({ deviceName, isTrust: false }), children: _jsx(Typography, { children: "Authorize once" }) }), _jsx(LoadingButton, { variant: "contained", type: "button", fullWidth: true, size: "large", loading: loading, disabled: hasError, onClick: () => onSubmit({ deviceName, isTrust: true }), children: _jsx(Typography, { children: "Authorize always" }) })] })] }));
};
export default DeviceActivationForm;
