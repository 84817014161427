import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import { updateLocalStorageData } from 'utils/util';
import './index.scss';
import App from './App';
const init = () => {
    updateLocalStorageData();
    const root = createRoot(document.getElementById('root'));
    root.render(_jsx(App, {}));
};
init();
