import BigNumber from 'bignumber.js';
import { LiquidityProvisionType } from '@emme/shared/model/enums';
import { genRandom64BitInt } from 'utils/util';
import { getDurationInDays } from './components/DurationInput';
export function processProvisionFormData(values, liquidityMarketId) {
    const minSpread = new BigNumber(values.spread[0] || 0);
    const maxSpread = new BigNumber(values.spread[1] || 0);
    const slope = minSpread.eq(maxSpread)
        ? new BigNumber(0)
        : new BigNumber(values.priceAtMax)
            .minus(values.priceAtMin)
            .dividedBy(maxSpread.minus(minSpread));
    const segment = `${values.spread.join(' ')} Linear ${slope.toFixed(10)} ${new BigNumber(values.priceAtMin)
        .minus(slope.multipliedBy(minSpread))
        .toFixed(10)}`;
    return {
        liquidityMarketId,
        minSpread: `${values.spread[0]}`,
        maxSpread: `${values.spread[1]}`,
        priceCurve: {
            segments: [segment],
        },
        baseVolume: `${values.baseVolume || 0}`,
        quoteVolume: `${values.quoteVolume || 0}`,
        provisionType: LiquidityProvisionType.SYMMETRICAL,
        duration: values.duration,
    };
}
export function processSubscriptionFormData(values, liquidityMarketId, spread) {
    const amountInQuote = new BigNumber(values.volume || 0).multipliedBy(2);
    const maxTotalCost = new BigNumber(values.maxTotalCost || 0);
    const sendMaxTotalCost = maxTotalCost.gt(0) &&
        maxTotalCost.lt(new BigNumber(values.price || 0)
            .multipliedBy(amountInQuote)
            .multipliedBy(getDurationInDays(values.duration)));
    return {
        liquidityMarketId,
        price: values.price,
        volume: amountInQuote.toFixed(8),
        maxTotalCost: sendMaxTotalCost ? values.maxTotalCost : null,
        duration: values.duration,
        spread: `${spread}`,
        seed: BigInt(`0x${values.seed}`).toString(),
    };
}
export function getAutofillProvisionFormData(orderBookSubscriptions, minQuoteVolume, minBaseVolume, spread = [1, 2], disableQuoteInput = false, disableBaseInput = false) {
    // price default
    let maxPrice = new BigNumber('100');
    // default spread
    let maxSpread = spread;
    const durationValue = 7;
    if (orderBookSubscriptions.length) {
        let defaultSpreadPriceArray = orderBookSubscriptions.filter((order) => {
            const sp = new BigNumber(order.spread);
            return sp.gte(maxSpread[0]) && sp.lte(maxSpread[1]);
        });
        if (defaultSpreadPriceArray.length === 0) {
            maxSpread = [1, 2];
            defaultSpreadPriceArray = orderBookSubscriptions.filter((order) => {
                const sp = new BigNumber(order.spread);
                return sp.gte(maxSpread[0]) && sp.lte(maxSpread[1]);
            });
        }
        if (defaultSpreadPriceArray.length) {
            maxPrice = new BigNumber(Math.max(...defaultSpreadPriceArray.map((arr) => parseFloat(arr.price))));
        }
        else {
            maxPrice = new BigNumber(orderBookSubscriptions[0].price);
            let maxPriceIndex = 0;
            for (let i = 1; i < orderBookSubscriptions.length; i += 1) {
                const tempMax = new BigNumber(orderBookSubscriptions[i].price);
                if (tempMax.gt(maxPrice)) {
                    maxPriceIndex = i;
                    maxPrice = tempMax;
                }
            }
            const spreadAtIndex = parseFloat(orderBookSubscriptions[maxPriceIndex].spread);
            maxSpread = [
                spreadAtIndex < maxSpread[0] ? spreadAtIndex : maxSpread[0],
                spreadAtIndex < maxSpread[1] ? maxSpread[1] : spreadAtIndex,
            ];
        }
    }
    return {
        duration: `${durationValue}d`,
        spread: maxSpread,
        priceAtMin: maxPrice.toFixed(8),
        priceAtMax: (maxSpread[0] === maxSpread[1] ? new BigNumber(0) : maxPrice).toFixed(8),
        quoteVolume: disableQuoteInput ? '' : minQuoteVolume.toFixed(8),
        baseVolume: disableBaseInput ? '' : minBaseVolume.toFixed(8),
    };
}
export function getAutofillSubscriptionFormData(provisionArray, exchangeLastTradePrice) {
    const priceValue = new BigNumber(provisionArray[provisionArray.length - 1]?.price || '100.00');
    const durationValue = 7;
    // volume calculate
    const quoteVolume = new BigNumber(2000)
        .dividedBy(priceValue)
        .dividedBy(durationValue * 2)
        .decimalPlaces(8);
    return {
        duration: `${durationValue}d`,
        volume: quoteVolume.toFixed(8),
        price: priceValue.toFixed(8),
        baseVolume: exchangeLastTradePrice
            ? quoteVolume.dividedBy(exchangeLastTradePrice).toFixed(8)
            : '',
        maxTotalCost: '0',
        seed: genRandom64BitInt().toString(16),
    };
}
