/* eslint-disable global-require */
let config = {};
try {
    config = require('./project-metadata.json');
}
catch { } // eslint-disable-line no-empty
// env
export const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
// version tooltip
export const VERSION = process.env.REACT_APP_VERSION || config?.version || '';
export const BRANCH = process.env.REACT_APP_BRANCH || config?.gitBranch || '';
export const COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH || config?.gitCommitHash || '';
export const REVISION_NUMBER = process.env.REACT_APP_REVISION_NUMBER || config?.gitRevisionNumber || '';
// settings
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY || '6Lcx2gkdAAAAAKvR7NOlbD16FU1BmjbOx6Y-Schy';
// links
export const ZENDESK_URL = process.env.REACT_APP_ZENDESK_URL || 'https://emmefoundation.zendesk.com/';
export const WIKI_URL = process.env.REACT_APP_WIKI_URL || 'https://wiki.em.me/';
export const PUBLICATION_URL = `${process.env.REACT_APP_PUBLICATION_DOMAIN || 'https://testnet.explore.veriblock.org/tx'}`;
// features
export const ENABLE_SIGNUP_CODE = process.env.REACT_APP_ENABLE_SIGNUP_CODE === 'true';
export const signupLinkText = ENABLE_SIGNUP_CODE ? 'Sign up with code' : 'Sign up for free';
export const WIZARD_RESTART_DAYS = Number(process.env.REACT_APP_WIZARD_RESTART_DAYS) || 14;
// testing
export const ENABLE_TESTING_VALUES = process.env.REACT_APP_DEV_TESTING === 'true';
// new features
export const ENABLE_EMME_LIQUIDITY_CHART = process.env.REACT_APP_ENABLE_EMME_LIQUIDITY_CHART === 'true';
export const ENABLE_THEMES = process.env.REACT_APP_ENABLE_THEME_TOGGLE === 'true';
export const ADVANCED_TAB = process.env.REACT_APP_ADVANCED_TAB === 'true';
export const OVERVIEW_PAGE = process.env.REACT_APP_OVERVIEW_PAGE === 'true';
export const WIDGET_PAGE = process.env.REACT_APP_WIDGET_PAGE === 'true';
