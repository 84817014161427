import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { RouteEnum } from '@emme/shared/model/enums';
import { useSelectedExchangeBalanceStatus } from 'hooks/useSelectedExchangeBalance';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { useLiquidityMarketOrdersData } from '@emme/shared/queries/LiquidityMarkets';
export function MobileOrdersInfo() {
    const navigate = useNavigate();
    const { selectedLiquidityMarket } = useContext(liquidityContext);
    const { isLoading } = useSelectedExchangeBalanceStatus();
    const { data: openProvisions, isLoading: isLoadingProvisions, isFetching: isFetchingProvisions, } = useLiquidityMarketOrdersData({
        liquidityMarketId: selectedLiquidityMarket?.id || '',
        open: true,
        type: 'provisions',
    });
    const { data: openSubscriptions, isLoading: isLoadingSubscriptions, isFetching: isFetchingSubscriptions, } = useLiquidityMarketOrdersData({
        liquidityMarketId: selectedLiquidityMarket?.id || '',
        open: true,
        type: 'subscriptions',
    });
    const isLoadingInfo = (isLoadingProvisions && isFetchingProvisions) ||
        (isLoadingSubscriptions && isFetchingSubscriptions) ||
        isLoading;
    const openOrderCount = (openProvisions?.totalNumber || 0) + (openSubscriptions?.totalNumber || 0);
    const handleClick = () => {
        if (!isLoadingInfo) {
            navigate(RouteEnum.AccountOrders);
        }
    };
    return (_jsx(Paper, { sx: {
            display: 'flex',
            backgroundColor: (t) => t.palette.neutralsTwo.main,
            p: 4,
            my: 6,
            textDecoration: 'none',
            typography: 'subtitle2',
            alignItems: 'center',
            minHeight: '44px',
        }, onClick: handleClick, children: isLoadingInfo ? (_jsxs(_Fragment, { children: [_jsx(Box, { pr: 1, children: _jsx(Skeleton, { animation: "wave", width: 75, height: 20 }) }), _jsx(Skeleton, { animation: "wave", width: 20, height: 20 }), _jsx(Box, { sx: { marginLeft: 'auto' }, children: _jsx(Skeleton, { animation: "wave", width: 90, height: 20 }) })] })) : (_jsxs(_Fragment, { children: [_jsx(Box, { pr: 1, children: "Open Orders" }), _jsx(Box, { color: "success.main", children: `(${openOrderCount})` }), _jsx(Box, { color: "success.main", sx: { marginLeft: 'auto' }, children: "View all orders \u203A" })] })) }));
}
