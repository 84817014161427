import { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { storage } from '@emme/shared';
export function usePersistedState(initialState, storageKey) {
    const persistedState = storage.get(storageKey);
    const [state, setState] = useState(persistedState === null ? initialState : persistedState);
    const debouncedLocalStorageSet = useRef(debounce(storage.set, 500));
    useEffect(() => {
        debouncedLocalStorageSet.current(storageKey, state);
    }, [state, storageKey]);
    return useMemo(() => [state, setState], [state]);
}
