import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useContext, useState } from 'react';
import BigNumber from 'bignumber.js';
import withStyles from '@mui/styles/withStyles';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useRoundProgress } from 'hooks/useRoundProgress';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
import { useRoundMarketData } from '@emme/shared/queries/Rounds';
import { PUBLICATION_URL } from 'config';
import { timeDuration } from 'utils/util';
import EmmeCircularProgress from 'components/EmmeCircularProgress';
const NoBorderTableCell = withStyles({
    root: {
        borderBottom: 'none',
        paddingRight: 0,
    },
})(TableCell);
const CurrentRound = () => {
    const { selectedLiquidityMarket } = useContext(LiquidityContext);
    const { data: marketData, refetch: refetchMarketData } = useRoundMarketData(selectedLiquidityMarket?.id || '');
    const { data: emmeCoinData } = useMarketInfoLatestCoinData('emme');
    const [anchorEl, setAnchorEl] = useState(null);
    const { progress, timeAgoString, timeRemainingString, current } = useRoundProgress();
    const handleOpen = (event) => {
        refetchMarketData();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    const rowHead = (value) => (_jsxs(TableRow, { children: [_jsx(NoBorderTableCell, { sx: { pl: 0 } }), _jsx(NoBorderTableCell, { sx: { pt: 6 }, children: _jsx(Typography, { variant: "subtitle1", children: value }) }), _jsx(NoBorderTableCell, {})] }));
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { direction: "row", justifyContent: "center", alignItems: "center", children: [_jsx(Button, { type: "button", onClick: handleOpen, sx: {
                            borderRadius: '90px',
                            p: 2,
                            minWidth: '150px',
                            minHeight: '35px',
                            ':hover': {
                                bgcolor: 'inherit',
                            },
                        }, color: "inherit", disableElevation: true, disableFocusRipple: true, disableRipple: true, disableTouchRipple: true, children: _jsxs(Stack, { justifyContent: "center", alignItems: "flex-start", children: [_jsxs(Stack, { direction: "row", justifyContent: "center", alignItems: "center", children: [_jsx(Typography, { variant: "h6", children: "Current Round" }), anchorEl ? (_jsx(KeyboardArrowUpOutlinedIcon, { fontSize: "small" })) : (_jsx(KeyboardArrowDownOutlinedIcon, { fontSize: "small" }))] }), _jsx(Typography, { variant: "h6", color: "primary", children: current?.incrementalId || '-' })] }) }), _jsx(EmmeCircularProgress, { value: progress, innerCircle: true, innerCircleGap: 8, size: 48, thickness: 1, color: "primary", trackColor: "neutralsTwo.main", "aria-label": "Round progress %" })] }), _jsx(Popover, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleClose, anchorReference: undefined, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'center' }, PaperProps: {
                    sx: {
                        minWidth: 370,
                        maxWidth: 400,
                        backgroundColor: (t) => t.palette.neutralOne.main,
                        py: 5,
                    },
                }, sx: { p: 0 }, children: _jsxs(Stack, { children: [_jsxs(Stack, { direction: "row", justifyContent: "center", alignItems: "center", children: [_jsx(Typography, { variant: "h6", component: "span", minWidth: 40, children: timeAgoString || '-' }), _jsx(Typography, { variant: "h6", component: "span", pr: 2, children: "/" }), _jsx(Typography, { variant: "h6", component: "span", minWidth: 40, children: current?.durationMs ? timeDuration(current.durationMs, true) : '-' }), _jsx(Typography, { variant: "h6", component: "span", px: 2, children: "-" }), _jsx(Typography, { variant: "h6", component: "span", pr: 2, children: "Remaining:" }), _jsx(Typography, { variant: "h6", component: "span", minWidth: 40, children: timeRemainingString || '-' })] }), _jsx(TableContainer, { sx: { width: '100%' }, children: _jsx(Table, { size: "small", children: _jsx(TableBody, { children: [
                                        {
                                            head: '24h Average',
                                            rest: [
                                                [
                                                    'Liquidity',
                                                    new BigNumber(marketData?.average24hQuoteLiquidity || 0).toFormat(2),
                                                    selectedLiquidityMarket?.quoteCoin,
                                                    new BigNumber(marketData?.average24hBaseLiquidity || 0).toFormat(2),
                                                    selectedLiquidityMarket?.baseCoin,
                                                ],
                                                [
                                                    'Price',
                                                    new BigNumber(marketData?.average24hPrice || 0).toFormat(2),
                                                    `$EMME/${selectedLiquidityMarket?.quoteCoin} day`,
                                                    `$${new BigNumber(marketData?.average24hPrice || 0)
                                                        .multipliedBy(emmeCoinData?.priceUsd || 0)
                                                        .toFormat(2)}`,
                                                    `/${selectedLiquidityMarket?.quoteCoin} day`,
                                                ],
                                            ],
                                        },
                                        {
                                            head: 'Current Round',
                                            rest: [
                                                [
                                                    'Liquidity',
                                                    new BigNumber(marketData?.currentQuoteLiquidity || 0).toFormat(2),
                                                    selectedLiquidityMarket?.quoteCoin,
                                                    new BigNumber(marketData?.currentBaseLiquidity || 0).toFormat(2),
                                                    selectedLiquidityMarket?.baseCoin,
                                                ],
                                                [
                                                    'Price',
                                                    new BigNumber(marketData?.currentPrice || 0).toFormat(2),
                                                    `$EMME/${selectedLiquidityMarket?.quoteCoin} day`,
                                                    `$${new BigNumber(marketData?.currentPrice || 0)
                                                        .multipliedBy(emmeCoinData?.priceUsd || 0)
                                                        .toFormat(2)}`,
                                                    `/${selectedLiquidityMarket?.quoteCoin} day`,
                                                ],
                                            ],
                                        },
                                    ].map((table) => (_jsxs(Fragment, { children: [rowHead(table.head), table.rest.map((row) => (_jsxs(TableRow, { children: [_jsx(NoBorderTableCell, { sx: { pl: 0 }, children: _jsx(Typography, { variant: "subtitle1", children: row[0] }) }), _jsx(NoBorderTableCell, { children: _jsxs(Typography, { children: [row[1], " ", row[2]] }) }), _jsx(NoBorderTableCell, { children: _jsxs(Typography, { children: [row[3], " ", row[4]] }) })] }, row[0])))] }, table.head))) }) }) }), current?.vbkTxId && (_jsx(Box, { px: 5, pt: 6, pb: 2, textAlign: "center", children: _jsx(Link, { href: `${PUBLICATION_URL}/${current.vbkTxId}`, target: "_blank", rel: "noreferrer noopener", "aria-label": "publication-data", children: "View Publication Data" }) }))] }) })] }));
};
export default CurrentRound;
