import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, forwardRef, useContext, useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';
import useMediaQuery from '@mui/material/useMediaQuery';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
const LISTBOX_PADDING = 8; // px
function renderRow({ data, index, style }) {
    const dataSet = data[index];
    return (_jsx(Typography, { component: "li", ...dataSet[0], noWrap: true, style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        }, children: dataSet[1] }));
}
const OuterElementContext = createContext({});
const OuterElementType = forwardRef((props, ref) => (_jsx(Box, { ref: ref, ...props, ...useContext(OuterElementContext) })));
function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current?.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}
// Adapter for react-window
const ListboxComponent = forwardRef((props, ref) => {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;
    const gridRef = useResetCache(itemCount);
    return (_jsx(Box, { ref: ref, children: _jsx(OuterElementContext.Provider, { value: other, children: _jsx(VariableSizeList, { itemData: itemData, height: (itemCount > 8 ? 8 : itemCount) * itemSize + 2 * LISTBOX_PADDING, width: "100%", ref: gridRef, outerElementType: OuterElementType, innerElementType: "div", itemSize: () => itemSize, overscanCount: 5, itemCount: itemCount, children: renderRow }) }) }));
});
const VirtualizeAutocomplete = ({ options, sx, ...rest }) => (_jsx(Autocomplete, { options: options, ListboxComponent: ListboxComponent, ...rest, sx: { pb: 3, ...sx } }));
export default VirtualizeAutocomplete;
