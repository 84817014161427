import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState, createContext } from 'react';
import { usePersistedState } from 'hooks/usePersistedState';
import { StorageKey } from '@emme/shared/model/enums';
import { OrderSideTabs, OrderTabs } from 'components/Tabs';
export const OrderTabsContext = createContext({
    activeTab: OrderTabs.OPEN_ORDERS,
    activeSideTab: OrderSideTabs.PROVISION,
    changeTabs: console.error,
});
export const OrderTabsProvider = ({ children }) => {
    const [activeTab, setActiveTab] = useState(OrderTabs.OPEN_ORDERS);
    const [activeSideTab, setActiveSideTab] = usePersistedState(OrderSideTabs.PROVISION, StorageKey.EMME_ORDERS_SIDE_TAB);
    const value = useMemo(() => ({
        activeTab,
        activeSideTab,
        changeTabs: (tab, sideTab) => {
            tab && setActiveTab(tab);
            sideTab && setActiveSideTab(sideTab);
        },
    }), [activeTab, activeSideTab, setActiveTab, setActiveSideTab]);
    return _jsx(OrderTabsContext.Provider, { value: value, children: children });
};
