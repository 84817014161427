import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { ChartTabs, LiquidityProvisionPriceFunction, StorageKey } from '@emme/shared/model/enums';
import { storage } from '@emme/shared';
import { BRANCH, COMMIT_HASH, WIZARD_RESTART_DAYS, REVISION_NUMBER, VERSION } from 'config';
import { AVAILABLE_INDICATORS, AVAILABLE_INTERVALS, AVAILABLE_STYLES, } from 'pages/DashboardPage/components/tradingViewOptions';
import { PlaceOrderTab } from 'context/PlaceOrderTabProvider';
import { OrderSideTabs } from 'components/Tabs';
export const maxPriceValue = new BigNumber(10000000); // 10M
export const maxCoinValue = new BigNumber(1000000000); // 1B
export const minOverviewCoinAmountUsd = 50;
export const lowerCaseRegex = /.*[a-z].*/;
export const upperCaseRegex = /.*[A-Z].*/;
export const numberRegex = /.*[0-9].*/;
export const numberAndLettersRegex = /[A-Za-z0-9]/;
// eslint-disable-next-line prefer-regex-literals
export const specialCharacterRegex = new RegExp('.*[~!@#$%\\^&*()\\-_=+\\|\\[{\\]};:\'",<.>/?].*');
export const dayJsDayOfYear = 'MMM DD, YY';
export const dayJsTime = 'HH:mm:ss';
export const Version = () => {
    if (!VERSION)
        return undefined;
    return {
        primary: `Frontend version: ${VERSION}`,
        secondary: `git: ${BRANCH}+${REVISION_NUMBER.trim()}-${COMMIT_HASH}`,
    };
};
export function getErrorFormatter(touched, errors) {
    return (fieldName) => (touched[fieldName] && errors[fieldName]) || ' ';
}
export function wizardRestart(time) {
    return time && dayjs().diff(dayjs(time), 'd') >= WIZARD_RESTART_DAYS;
}
export function timeAgo(time, syncDiff, noText) {
    const secondsElapsed = dayjs().add(syncDiff, 's').diff(dayjs(time), 's');
    const dayStart = dayjs(new Date().setHours(0, 0, secondsElapsed, 0));
    if (secondsElapsed < 60) {
        return noText ? `00:${dayStart.format('ss')}` : `${dayStart.format('s')} seconds`;
    }
    return noText
        ? `${dayStart.format('mm')}:${dayStart.format('ss')}`
        : `${dayStart.format('m')} min ${dayStart.format('s')} seconds`;
}
export function timeRemaining(time, duration, syncDiff, noText) {
    const secondsElapsed = dayjs().add(syncDiff, 's').diff(dayjs(time), 's');
    const secondsRemain = duration - secondsElapsed;
    const dayStart = dayjs(new Date().setHours(0, 0, secondsRemain, 0));
    if (secondsRemain < 60) {
        return noText ? `00:${dayStart.format('ss')}` : `${dayStart.format('s')} seconds`;
    }
    return noText
        ? `${dayStart.format('mm')}:${dayStart.format('ss')}`
        : `${dayStart.format('m')} min ${dayStart.format('s')} seconds`;
}
export function timeDuration(duration, noText) {
    const dayStart = dayjs(new Date().setHours(0, 0, duration / 1000, 0));
    if (duration < 60) {
        return noText ? `00:${dayStart.format('ss')}` : `${dayStart.format('s')} seconds`;
    }
    return noText
        ? `${dayStart.format('mm')}:${dayStart.format('ss')}`
        : `${dayStart.format('m')} min ${dayStart.format('s')} seconds`;
}
export function sleep(milliseconds) {
    return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
    });
}
export function genRandom64BitInt() {
    const array = new BigInt64Array(1);
    window.crypto.getRandomValues(array);
    const number = array[0];
    return number < 0 ? -number - 1n : number;
}
export function getPriceSpreadSegment(priceCurve, currentSpread) {
    if (priceCurve.segments.length === 1) {
        return priceCurve.segments[0].split(' ');
    }
    for (let i = priceCurve.segments.length - 1; i >= 0; i -= 1) {
        const splitSegment = priceCurve.segments[i].split(' ');
        if (currentSpread >= Number(splitSegment[0]) && currentSpread <= Number(splitSegment[1])) {
            return splitSegment;
        }
    }
    return [];
}
export function calculatePriceCurve(priceCurve, currentSpread) {
    const priceFunction = getPriceSpreadSegment(priceCurve, currentSpread);
    if (!priceFunction.length)
        return new BigNumber(0);
    switch (priceFunction[2].toLowerCase()) {
        case LiquidityProvisionPriceFunction.LINEAR:
            return new BigNumber(currentSpread)
                .multipliedBy(priceFunction[3] || 0)
                .plus(priceFunction[4] || 0);
        case LiquidityProvisionPriceFunction.ASYMPTOTIC:
        case LiquidityProvisionPriceFunction.QUADRATIC:
        default:
            return new BigNumber(0);
    }
}
export const calculatePriceProvisionArray = (array = [], spread = 0) => {
    const response = [];
    for (let i = 0; i < array.length; i += 1) {
        const { minSpread, maxSpread, priceCurve, baseVolume, quoteVolume } = array[i];
        if (spread >= Number(minSpread) && spread <= Number(maxSpread)) {
            const price = calculatePriceCurve(priceCurve, spread);
            response.push({
                ...array[i],
                price: price.toFixed(),
                id: `${i}:${priceCurve}:${minSpread}:${maxSpread}:${baseVolume}:${quoteVolume}`,
            });
        }
    }
    return response.sort((a, b) => Number(b.price) - Number(a.price));
};
export const formatSpread = (spread) => `${spread.toFixed(2)}%`;
export const formatPercentOfMax = (maximum) => (value) => `${new BigNumber(value).multipliedBy(100).dividedBy(maximum).toFixed(1)}%`;
export const checkAllowedKeys = (event) => event.code.includes('Digit') ||
    event.code.includes('Backspace') ||
    event.code.includes('Delete') ||
    event.code.includes('Arrow') ||
    event.code.includes('Period') ||
    event.code.includes('Comma') ||
    event.code.includes('Tab') ||
    (event.ctrlKey && (event.code === 'KeyV' || event.code === 'KeyC'));
export const checkMaxValue = (value, { digits = 8, isPrice = false, sign = '' }) => {
    if (value.isNaN())
        return '-';
    const maxValue = isPrice ? maxPriceValue : maxCoinValue;
    return value.abs().gt(maxValue)
        ? `${value.gt(0) ? '>' : '<'}${sign}${maxValue.toFormat(digits)}`
        : `${sign}${value.toFormat(digits)}`;
};
export const createExternallyResolvablePromise = () => {
    let resolveFn = null;
    let rejectFn = null;
    const promise = new Promise((resolve, reject) => {
        resolveFn = resolve;
        rejectFn = reject;
    });
    promise.resolve = resolveFn;
    promise.reject = rejectFn;
    return promise;
};
export function updateLocalStorageData() {
    const allKeys = Object.keys(localStorage);
    const invalidKeys = allKeys.filter((key) => {
        const value = storage.get(key);
        switch (key) {
            case StorageKey.EMME_SPREAD_DASHBOARD:
            case StorageKey.EMME_SPREAD_SUBSCRIBE:
                return !(typeof value === 'number' && value >= 0.25 && value <= 4);
            case StorageKey.EMME_CHART:
                return ![ChartTabs.TradingView, ChartTabs.SimulatedMarketDepth].includes(value);
            case StorageKey.EMME_CHART_STATE:
                return (!(typeof value === 'object') ||
                    !(typeof value.interval === 'number' &&
                        value.interval >= 0 &&
                        value.interval < AVAILABLE_INTERVALS.length) ||
                    !(typeof value.style === 'number' &&
                        value.style >= 0 &&
                        value.style < AVAILABLE_STYLES.length) ||
                    !(value.indicators instanceof Array &&
                        value.indicators.every((v) => !v || AVAILABLE_INDICATORS.find((i) => i.value === v))));
            case StorageKey.EMME_PLACE_ORDER_FORM:
                return !Object.keys(PlaceOrderTab).includes(value);
            case StorageKey.EMME_ORDERS_SIDE_TAB:
                return !Object.keys(OrderSideTabs).includes(value);
            case StorageKey.EMME_VOLUMES_CONNECTED:
                return typeof value !== 'boolean';
            default:
                return false;
        }
    });
    if (invalidKeys.length) {
        console.debug('Removing invalid localStorage items:', invalidKeys);
    }
    invalidKeys.forEach((key) => storage.remove(key));
}
