import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { UserExchangeCredentialsStatus } from '@emme/shared/model/enums';
import { PlaceOrderTab, PlaceOrderTabContext } from 'context/PlaceOrderTabProvider';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { ConfirmOrderContext } from 'context/ConfirmOrderProvider';
import { OnboardingContext } from 'context/OnboardingProvider';
import { SpreadContext } from 'context/SpreadProvider';
import { useUserBalanceData } from '@emme/shared/queries/Users';
import { useSelectedExchangeBalanceStatus } from 'hooks/useSelectedExchangeBalance';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { EmmeSubscribeProvideTabs } from 'components/Tabs';
import MissingToken from 'components/MissingToken';
import SubscriptionForm from './SubscriptionForm';
import ProvisionForm from './ProvisionForm';
import ConfirmOrder from './ConfirmOrder';
const PlaceOrder = ({ onClose }) => {
    const [activeTab, setActiveTab] = useContext(PlaceOrderTabContext);
    const { resetSubscribeFormSpread } = useContext(SpreadContext);
    const { isPaneOpen } = useContext(ConfirmOrderContext);
    const { activeStep, allSteps } = useContext(OnboardingContext);
    const { tablet } = useBreakpoint();
    const handleTabChange = useCallback((tab) => {
        setActiveTab(tab);
        if (tab === PlaceOrderTab.Subscribe) {
            // Make subscribe form spread slider 'untouched' when the tab is activated
            resetSubscribeFormSpread();
        }
    }, [setActiveTab, resetSubscribeFormSpread]);
    const { selectedLiquidityMarket, isLoading: isLoadingLiquidityState } = useContext(LiquidityContext);
    const { data: balance, isLoading: isLoadingBalance } = useUserBalanceData();
    const hasAvailableBalance = Number(balance?.available || 0);
    const { data: selectedExchangeBalanceStatus, isLoading: isLoadingSelectedExchangeBalance } = useSelectedExchangeBalanceStatus();
    const isLoading = Boolean(isLoadingLiquidityState || isLoadingBalance || isLoadingSelectedExchangeBalance);
    const showSubscription = () => hasAvailableBalance ? _jsx(SubscriptionForm, {}) : _jsx(MissingToken, { isBuy: true });
    const showProvision = () => {
        switch (selectedExchangeBalanceStatus) {
            case UserExchangeCredentialsStatus.OK:
                return _jsx(ProvisionForm, {});
            case UserExchangeCredentialsStatus.INVALID:
                return _jsx(MissingToken, { isInvalid: true });
            default:
                return _jsx(MissingToken, {});
        }
    };
    const onboardingDesktopView = allSteps[activeStep]?.stepNumber === 5 && !tablet;
    return (_jsxs(Stack, { height: "100%", sx: {
            position: 'relative',
            overflowX: 'hidden',
            backgroundColor: (t) => t.palette.neutralOne.dark,
            zIndex: onboardingDesktopView ? 1002 : undefined,
        }, children: [_jsxs(Stack, { direction: "row", alignItems: "center", children: [_jsx(Box, { sx: { flex: 'auto' }, children: _jsx(EmmeSubscribeProvideTabs, { value: activeTab, onChange: handleTabChange, disabled: onboardingDesktopView }) }), _jsx(IconButton, { sx: {
                            display: ['flex', undefined, 'none'],
                            borderRadius: (theme) => theme.spacing(2, 0, 0, 2),
                            backgroundColor: (theme) => theme.palette.neutralsTwo.main,
                            px: 4,
                        }, size: "small", onClick: onClose, children: _jsx(CloseOutlined, {}) })] }), isLoading ? (_jsx(LoadingOverlay, { isLoading: isLoading, size: 50, zIndex: 1, sx: { flex: 'auto' } })) : (_jsx(Box, { sx: {
                    opacity: !isLoading ? 1 : 0,
                    flex: 'auto',
                    overflow: 'auto',
                    pointerEvents: onboardingDesktopView ? 'none' : undefined,
                }, children: activeTab === PlaceOrderTab.Subscribe ? showSubscription() : showProvision() }, selectedLiquidityMarket?.id)), _jsx(Box, { sx: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 3,
                    borderRadius: 1,
                    transition: `transform 0.3s ${isPaneOpen ? 'ease-out' : 'ease-in'}`,
                    transform: isPaneOpen ? undefined : 'translateX(100%)',
                    backgroundColor: (t) => t.palette.neutralOne.dark,
                    overflow: 'auto',
                }, children: _jsx(ConfirmOrder, {}) })] }));
};
PlaceOrder.defaultProps = {
    onClose: undefined,
};
export default PlaceOrder;
