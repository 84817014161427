export var StorageKey;
(function (StorageKey) {
    StorageKey["EMME_REMEMBER_ME"] = "EMME_REMEMBER_ME";
    StorageKey["EMME_TOKEN"] = "EMME_TOKEN";
    StorageKey["EMME_DARK_MODE"] = "EMME_DARK_MODE";
    StorageKey["EMME_SELECTED_EXCHANGE"] = "EMME_SELECTED_EXCHANGE";
    StorageKey["EMME_SELECTED_LIQUIDITY_MARKET"] = "EMME_SELECTED_LIQUIDITY_MARKET";
    StorageKey["EMME_LAST_SELECTED_LIQUIDITY_MARKET"] = "EMME_LAST_SELECTED_LIQUIDITY_MARKET";
    StorageKey["EMME_SPREAD_SUBSCRIBE"] = "EMME_SPREAD_SUBSCRIBE";
    StorageKey["EMME_SPREAD_DASHBOARD"] = "EMME_SPREAD_DASHBOARD";
    StorageKey["EMME_CHART"] = "EMME_CHART";
    StorageKey["EMME_CHART_STATE"] = "EMME_CHART_STATE";
    StorageKey["EMME_PLACE_ORDER_FORM"] = "EMME_PLACE_ORDER_FORM";
    StorageKey["EMME_ORDERS_SIDE_TAB"] = "EMME_ORDERS_SIDE_TAB";
    StorageKey["EMME_VOLUMES_CONNECTED"] = "EMME_VOLUMES_CONNECTED";
})(StorageKey || (StorageKey = {}));
