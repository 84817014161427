import { useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
export function useDepthChartData(type, params) {
    return useQuery(['charts', type, params.exchangeId, params.baseCoin, params.quoteCoin], createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: `/charts/depth/${type}`,
        params,
    }), {
        staleTime: 10 * 1000,
        refetchInterval: 10 * 1000,
        refetchOnWindowFocus: true,
        enabled: Boolean(params.exchangeId && params.baseCoin && params.quoteCoin),
    });
}
