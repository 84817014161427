import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useUserOrdersData } from '@emme/shared/queries/Users';
import { useLiquidityMarketsData } from '@emme/shared/queries/LiquidityMarkets';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { ButtonCell, DateCell } from 'components/Table/cells';
import { EmmeTable } from 'components/Table';
import { EmmeOrderTabs, OrderSideTabs, OrderTabs } from 'components/Tabs';
import { OrdersTable } from 'components/OrdersTable';
const headCells = [
    {
        id: 'createdAt',
        label: 'Time',
        Component: DateCell,
    },
    { id: 'exchangeName', label: 'Exchange' },
    { id: 'marketName', label: 'Market' },
    {
        id: 'button',
        label: 'Action',
        Component: ButtonCell,
    },
];
const MyOrdersPage = () => {
    const [selectedOrderId, setSelectedOrderId] = useState();
    const [lastSelectedOrderId, setLastSelectedOrderId] = useState();
    const [activeTab, setActiveTab] = useState(OrderTabs.OPEN_ORDERS);
    const [activeSideTab, setActiveSideTab] = useState(OrderSideTabs.PROVISION);
    const [size, setSize] = useState(15);
    const [pageNumber, setPageNumber] = useState(0);
    const { exchanges } = useContext(LiquidityContext);
    const { data: allMarkets, isLoading: isLoadingMarkets } = useLiquidityMarketsData({
        includeTicker: false,
    });
    const isProvisionSide = activeSideTab === OrderSideTabs.PROVISION;
    const isOpenSide = activeTab === OrderTabs.OPEN_ORDERS;
    const { data: orders, isLoading: isLoadingOrders, refetch: refetchOrders, } = useUserOrdersData({
        size,
        pageNumber,
        open: isOpenSide,
        type: isProvisionSide ? 'provisions' : 'subscriptions',
    });
    const isLoading = isLoadingOrders || isLoadingMarkets;
    const tableData = orders?.elements || [];
    const handleChangePage = (newPage) => {
        setPageNumber(newPage);
    };
    const handleChangeRowsPerPage = (newPageSize) => {
        setSize(newPageSize);
        setPageNumber(0);
    };
    const selectedOrder = tableData.find(({ id }) => id === selectedOrderId);
    const selectedLiquidityMarket = allMarkets?.elements.find((m) => m.id === selectedOrder?.liquidityMarketId);
    return (_jsxs(Stack, { justifyContent: "flex-start", alignItems: "stretch", overflow: "auto", children: [_jsxs(Typography, { variant: "h6", mb: 6, mx: 5, children: ["Order", selectedOrderId ? ` ${selectedOrderId}` : 's'] }), _jsx(EmmeOrderTabs, { activeTab: activeTab, onChangeTab: (e) => {
                    setSelectedOrderId(undefined);
                    setLastSelectedOrderId(undefined);
                    setActiveTab(e);
                }, activeSideTab: activeSideTab, onChangeSideTab: (e) => {
                    setSelectedOrderId(undefined);
                    setLastSelectedOrderId(undefined);
                    setActiveSideTab(e);
                } }), !isLoading && !tableData.length ? (_jsx(Alert, { severity: "info", sx: { mt: 3, mx: 5 }, children: _jsxs(Typography, { children: ["You have no ", activeSideTab.toLowerCase(), "s in ", activeTab] }) })) : (_jsx(EmmeTable, { rows: tableData.map((data) => {
                    const currentMarket = (allMarkets?.elements || []).find(({ id }) => id === data?.liquidityMarketId);
                    return {
                        id: data.id,
                        createdAt: data.createdAt,
                        exchangeName: (exchanges || []).find((exchange) => exchange.id === currentMarket?.exchangeId)?.name,
                        marketName: `${currentMarket?.quoteCoin || ' - '}/${currentMarket?.baseCoin || ' - '}`,
                        button: selectedOrderId
                            ? {
                                label: 'Close',
                                onClick: () => {
                                    setSelectedOrderId(undefined);
                                },
                                endIcon: _jsx(KeyboardArrowUpOutlinedIcon, { sx: { ml: -2 } }),
                            }
                            : {
                                label: 'Details',
                                onClick: () => {
                                    setLastSelectedOrderId(data.id);
                                    setSelectedOrderId(data.id);
                                },
                                endIcon: _jsx(KeyboardArrowDownOutlinedIcon, { sx: { ml: -2 } }),
                            },
                    };
                }), inputHeadCells: headCells, customVerticalPadding: 3, page: pageNumber, rowsPerPage: size, rowsPerPageOptions: [10, 15, 25], tableMinWidth: 250, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, totalLength: orders?.totalNumber, isLoading: isLoading, selectedRowId: lastSelectedOrderId, collapsibleRowOpen: selectedOrderId })), Boolean(selectedOrderId && selectedOrder && selectedLiquidityMarket) && (_jsxs(_Fragment, { children: [_jsx(Divider, { sx: { m: (t) => t.spacing(2, 5, 5) } }), _jsx(OrdersTable, { orders: [selectedOrder], liquidityMarket: selectedLiquidityMarket, withCancelButton: isOpenSide, withTradesButton: true, onOrderCancel: () => {
                            setSelectedOrderId(undefined);
                            setLastSelectedOrderId(undefined);
                            refetchOrders();
                        }, type: isProvisionSide ? 'provisions' : 'subscriptions', verticalRows: true, tableMinWidth: 250 })] }))] }));
};
export default MyOrdersPage;
