import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useBreakpoint } from 'hooks/useBreakpoint';
import DesktopDashboard from './DesktopDashboard';
import MobileDashboard from './MobileDashboard';
import OnboardingDialog from './Onboarding';
const Dashboard = () => {
    const { tablet } = useBreakpoint();
    return (_jsxs(_Fragment, { children: [tablet ? _jsx(MobileDashboard, {}) : _jsx(DesktopDashboard, {}), _jsx(OnboardingDialog, {})] }));
};
export default Dashboard;
