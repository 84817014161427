import { useMutation } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
export function useChangePassword() {
    return useMutation(createFetchFn({
        requiresAuth: false,
        method: 'POST',
        url: '/users/password/update',
    }), {
        mutationKey: ['update-reset-password'],
        retry: false,
    });
}
export function useResetPassword() {
    return useMutation(createFetchFn({
        requiresAuth: false,
        method: 'POST',
        url: '/users/password/reset',
    }), {
        mutationKey: ['reset-password'],
        retry: false,
    });
}
