import { useCallback, useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { useUserExchangesBalanceData } from '@emme/shared/queries/Users';
export function useAvailableBalance() {
    const { selectedLiquidityMarket } = useContext(liquidityContext);
    const { data: exchangeBalances, isLoading, isFetched } = useUserExchangesBalanceData();
    const getAvailableBalance = useCallback((coin) => new BigNumber(exchangeBalances
        ?.find(({ exchangeId }) => exchangeId === selectedLiquidityMarket?.exchangeId)
        ?.balances.find(({ currency }) => currency === coin)?.available || 0), [exchangeBalances, selectedLiquidityMarket?.exchangeId]);
    return useMemo(() => {
        if (!exchangeBalances || !selectedLiquidityMarket) {
            return {
                maxBaseVolume: new BigNumber(0),
                maxQuoteVolume: new BigNumber(0),
                minBaseVolume: new BigNumber(0),
                minQuoteVolume: new BigNumber(0),
                disableQuoteInput: false,
                disableBaseInput: false,
                isLoading,
                isFetched,
                getAvailableBalance,
            };
        }
        const maxBase = getAvailableBalance(selectedLiquidityMarket?.baseCoin).decimalPlaces(8, BigNumber.ROUND_DOWN);
        const maxQuote = getAvailableBalance(selectedLiquidityMarket?.quoteCoin).decimalPlaces(8, BigNumber.ROUND_DOWN);
        const minBase = new BigNumber(selectedLiquidityMarket?.minimumBaseOrderVolume || 0).decimalPlaces(8, BigNumber.ROUND_UP);
        const minQuote = new BigNumber(selectedLiquidityMarket?.minimumQuoteOrderVolume || 0).decimalPlaces(8, BigNumber.ROUND_UP);
        return {
            maxBaseVolume: maxBase,
            maxQuoteVolume: maxQuote,
            minBaseVolume: minBase,
            minQuoteVolume: minQuote,
            disableQuoteInput: !maxQuote.toNumber() || maxQuote.lt(minQuote),
            disableBaseInput: !maxBase.toNumber() || maxBase.lt(minBase),
            isLoading,
            isFetched,
        };
    }, [exchangeBalances, getAvailableBalance, isLoading, isFetched, selectedLiquidityMarket]);
}
