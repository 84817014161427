import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState, Fragment } from 'react';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { OverviewContext, OverviewStatus } from 'context/Overview/OverviewProvider';
import { Step1Exchange, Step2Market, Step3Balance, Step4RiskLevel, StepConfirmation, StepSearch, } from './components/steps';
import { EarnMoreCard } from './components/EarnMoreCard';
import { EarningsCard } from './components/EarningsCard';
import { OrdersCard } from './components/OrdersCard';
import { EmmeCard } from './components/EmmeCard';
import { OverviewStepper } from './components/OverviewStepper';
const stepComponents = [
    StepSearch,
    Step1Exchange,
    Step2Market,
    Step3Balance,
    Step4RiskLevel,
    StepConfirmation,
];
const randomAlertStatus = ['success', 'info', 'warning', 'error'];
const Overview = () => {
    const [showAlert, setShowAlert] = useState(true);
    const [alertColor, setAlertColor] = useState('info');
    const { selectedStep, earningStatus } = useContext(OverviewContext);
    const StepComponent = stepComponents[selectedStep - 1] || Fragment;
    const showEarnMore = earningStatus === OverviewStatus.EARNING || earningStatus === OverviewStatus.PAUSED;
    useEffect(() => {
        setTimeout(() => {
            setAlertColor(randomAlertStatus[Math.floor(Math.random() * Math.floor(4))]);
        }, 2000);
    }, [alertColor]);
    return (_jsxs(Container, { maxWidth: "lg", disableGutters: true, sx: {
            px: [7, undefined, 9],
            pb: 9,
            display: 'grid',
            gridTemplateRows: 'auto auto 142px 1fr',
            gridTemplateColumns: 'minmax(300px, 380px) repeat(3, minmax(162px, 1fr))',
            gridTemplateAreas: `
          "header header header header"
          "message message message message"
          "earning emme-balance open-orders earn-more"
          "steps step-body step-body step-body"
        `,
            gap: (t) => [
                `${t.spacing(4)} ${t.spacing(3)}`,
                undefined,
                undefined,
                `${t.spacing(6)} ${t.spacing(4)}`,
            ],
            minHeight: 'calc(100vh - 80px)',
        }, children: [_jsx(Typography, { variant: "h4", sx: { gridArea: 'header', mt: 10, mb: [6, undefined, undefined, 4] }, children: "Overview" }), _jsx(Alert, { severity: alertColor, sx: {
                    gridArea: 'message',
                    opacity: showAlert ? 1 : 0,
                    visibility: showAlert ? 'visible' : 'hidden',
                    transition: 'all 0.2s',
                    height: '40px',
                    typography: 'body1',
                    px: 6,
                    color: ({ palette }) => palette[alertColor].main,
                    bgcolor: ({ palette }) => `${palette[alertColor].main}20`,
                    borderColor: 'transparent',
                    '.MuiAlert-action': { py: 2 },
                }, onClose: () => setShowAlert(false), children: "Welcome back" }), _jsx(Box, { sx: { gridArea: 'earning' }, children: _jsx(EarningsCard, {}) }), _jsx(Box, { sx: { gridArea: 'emme-balance' }, children: _jsx(EmmeCard, {}) }), _jsx(Box, { sx: { gridArea: 'open-orders' }, children: _jsx(OrdersCard, {}) }), _jsx(Box, { sx: {
                    gridArea: 'earn-more',
                    opacity: showEarnMore ? 1 : 0,
                    visibility: showEarnMore ? 'visible' : 'hidden',
                    transition: 'all 0.2s',
                }, children: _jsx(EarnMoreCard, {}) }), _jsx(Box, { sx: { gridArea: 'steps' }, children: _jsx(OverviewStepper, {}) }), earningStatus === OverviewStatus.LOADING ? (_jsx(Skeleton, { variant: "rectangular", sx: {
                    gridArea: 'step-body',
                    height: 'auto',
                    bgcolor: 'neutralOne.dark',
                    borderRadius: 3,
                } })) : (_jsx(Box, { sx: {
                    gridArea: 'step-body',
                    bgcolor: 'neutralOne.dark',
                    borderRadius: 3,
                    p: [6, undefined, undefined, 9],
                }, children: _jsx(StepComponent, {}) }))] }));
};
export default Overview;
