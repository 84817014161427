import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { ApiKeyIcon } from '@emme/shared/icons';
import { RouteEnum } from '@emme/shared/model/enums';
import AccountPageLayout from 'layout/AccountPageLayout';
const breadcrumbNameMap = {
    [RouteEnum.Account]: 'My Account',
    [RouteEnum.AccountInfo]: 'Profile Info',
    [RouteEnum.AccountAPI]: 'Api keys',
    [RouteEnum.AccountNotifications]: 'Notifications',
    [RouteEnum.AccountOrders]: 'My Orders',
    [RouteEnum.AccountDevices]: 'Trusted Devices',
};
const icons = {
    [RouteEnum.AccountInfo]: _jsx(PersonOutlinedIcon, {}),
    [RouteEnum.AccountAPI]: _jsx(ApiKeyIcon, { stroke: "currentColor" }),
    [RouteEnum.AccountNotifications]: _jsx(NotificationsOutlinedIcon, {}),
    [RouteEnum.AccountOrders]: _jsx(AssignmentOutlinedIcon, {}),
    [RouteEnum.AccountDevices]: _jsx(MonitorOutlinedIcon, {}),
};
const AccountRoot = () => {
    const location = useLocation();
    const [title, setTitle] = useState('My Account');
    useEffect(() => {
        if (location.pathname && breadcrumbNameMap) {
            setTitle(breadcrumbNameMap[location.pathname] || 'My Account');
        }
    }, [location]);
    return (_jsx(AccountPageLayout, { title: title, navigationList: breadcrumbNameMap, navigationIcons: icons, children: _jsx(Outlet, {}) }));
};
export default AccountRoot;
