import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as RouterLink } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { RouteEnum } from '@emme/shared/model/enums';
import { sharedAuthPageStyles } from 'layout/AuthPage';
import { isProduction } from 'config';
const TermsCheckbox = ({ touched, values, handleChange, errors, setFieldTouched }) => {
    const classes = sharedAuthPageStyles();
    const policyLink = (text) => isProduction ? (` ${text}`) : (_jsx(Link, { component: RouterLink, to: RouteEnum.PrivacyPolicy, target: "_blank", tabIndex: -1, children: text }));
    return (_jsxs(Box, { children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { color: "primary", checked: values, onChange: handleChange, name: "termsOfService", required: true, onBlur: setFieldTouched, size: "small" }), label: _jsxs(Typography, { variant: "subtitle2", className: classes.otherText, align: "justify", children: ["By signing up I agree that I\u2019m 18 years of age or older, to the", ' ', _jsxs(Typography, { variant: "subtitle2", color: "textPrimary", component: "span", children: ["User Agreements,", policyLink('Privacy Policy'), ", Cookie Policy, E-Sign Consent."] })] }), sx: { mr: 0, minWidth: '100%' } }), _jsx(FormHelperText, { error: true, sx: { mb: 5 }, children: touched && errors ? (_jsx(Typography, { variant: "subtitle2", component: "span", children: errors })) : (' ') })] }));
};
export default TermsCheckbox;
