import { useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
export function useExchangesData() {
    return useQuery(['exchanges'], createFetchFn({
        requiresAuth: false,
        apiType: 'exchange',
        method: 'GET',
        url: '/exchanges/enabled',
        transformData: (data) => data.exchanges,
    }), {
        staleTime: 60 * 1000,
        onError: (error) => error,
        refetchInterval: 60 * 1000,
        refetchOnWindowFocus: true,
    });
}
