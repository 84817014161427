import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { ColumnAlign } from '@emme/shared/model/table.model';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { useLiquidityMarketsTradesLastUser } from '@emme/shared/queries/LiquidityMarkets';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
import { formatSpread } from 'utils/util';
import { DateCell, CoinWithUSDPriceCell, VolumeCell, TypographyCell, PerformanceButtonCell, } from 'components/Table/cells';
import { EmmeTable } from 'components/Table';
import { ProvisionOrdersDialog } from 'components/Dialogs';
const TradeHistoryTab = () => {
    const [selectedTradeId, setSelectedTradeId] = useState();
    const { selectedLiquidityMarket } = useContext(LiquidityContext);
    const { data: trades, isLoading } = useLiquidityMarketsTradesLastUser({
        liquidityMarketId: selectedLiquidityMarket?.id || '',
    });
    const { data: emmeCoin } = useMarketInfoLatestCoinData('emme');
    const onCloseProvisionOrdersDialog = () => {
        setSelectedTradeId(undefined);
    };
    const headCells = [
        {
            id: 'createdAt',
            label: 'Time',
            Component: DateCell,
        },
        {
            id: 'price',
            label: `Price ($EMME/${selectedLiquidityMarket?.quoteCoin || '-'} days)`,
            Component: CoinWithUSDPriceCell,
        },
        {
            id: 'spread',
            label: 'Spread',
            align: ColumnAlign.RIGHT,
        },
        {
            id: 'volume',
            label: 'Liquidity',
            Component: VolumeCell,
        },
        {
            id: 'earned',
            label: '$EMME',
            Component: CoinWithUSDPriceCell,
            componentProps: {
                addColor: true,
            },
        },
        {
            id: 'source',
            label: 'Source',
            Component: TypographyCell,
        },
        {
            id: 'button',
            label: 'Performance',
            Component: PerformanceButtonCell,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(EmmeTable, { rows: (trades?.elements || []).map((data) => ({
                    id: data.id,
                    createdAt: data.createdAt,
                    price: {
                        value: data.price,
                        usdPrice: emmeCoin?.priceUsd,
                        isPrice: true,
                    },
                    spread: formatSpread(Number(data.spread)),
                    volume: {
                        base: { amount: data.baseVolume, coin: selectedLiquidityMarket?.baseCoin },
                        quote: { amount: data.quoteVolume, coin: selectedLiquidityMarket?.quoteCoin },
                    },
                    earned: {
                        value: data.provisionPayment,
                        usdPrice: emmeCoin?.priceUsd,
                    },
                    source: {
                        text: data?.source || '-',
                        color: data?.source && data?.source.toLowerCase() === 'provision'
                            ? (t) => t.palette.error.main
                            : (t) => t.palette.success.main,
                    },
                    button: {
                        loading: selectedTradeId === data.id,
                        onClick: () => setSelectedTradeId(data.id),
                        provisionStatus: data.provisionStatus,
                        provisionPerformance: data.provisionPerformance,
                    },
                })), inputHeadCells: headCells, isLoading: !selectedLiquidityMarket || isLoading, customVerticalPadding: 3, alternatingRowColors: true, stickyHeader: true }), _jsx(ProvisionOrdersDialog, { liquidityMarket: selectedLiquidityMarket, liquidityTradeId: selectedTradeId, onProvisionsFetched: onCloseProvisionOrdersDialog })] }));
};
export default TradeHistoryTab;
