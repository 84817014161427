import { useMutation, useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeLiquidity = 10 * 1000;
export function useLiquidityMarketsTradeLast({ liquidityMarketId, ...params }) {
    return useQuery(['liquidity-markets', 'trades', 'last2', liquidityMarketId], createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: `/liquidity-markets/${liquidityMarketId}/trades/last`,
        params,
        transformData: (data) => data.trades,
    }), {
        staleTime: intervalTimeLiquidity,
        onError: (error) => error,
        refetchInterval: intervalTimeLiquidity,
        refetchOnWindowFocus: true,
        enabled: Boolean(liquidityMarketId),
    });
}
export function useLiquidityMarketsTradesLastUser({ liquidityMarketId, ...params }) {
    return useQuery(['liquidity-markets', 'trades', liquidityMarketId], createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: `/liquidity-markets/${liquidityMarketId}/trades`,
        params,
        transformData: (data) => data.liquidityTrades,
    }), {
        staleTime: intervalTimeLiquidity,
        onError: (error) => error,
        refetchInterval: intervalTimeLiquidity,
        refetchOnWindowFocus: true,
        enabled: Boolean(liquidityMarketId),
    });
}
export function useLiquidityMarketsOrderTrades({ liquidityMarketId, side, orderId, ...params }) {
    return useQuery([
        'liquidity-markets',
        liquidityMarketId,
        side,
        orderId,
        'trades',
        params?.pageNumber || 0,
        params?.size || 100,
    ], createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: `/liquidity-markets/${liquidityMarketId}/orders/${side}/${orderId}/trades`,
        params,
        transformData: (data) => data.liquidityTrades,
    }), {
        staleTime: intervalTimeLiquidity,
        onError: (error) => error,
        refetchInterval: intervalTimeLiquidity,
        refetchOnWindowFocus: true,
        enabled: Boolean(liquidityMarketId && orderId),
    });
}
export function useProvisionLiquidityMarketsTradeVerification() {
    return useMutation((dto) => createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: `/liquidity-markets/${dto.liquidityMarketId}/trades/${dto.liquidityTradeId}/provision`,
    })(), {
        mutationKey: ['provision-trade-verification'],
        retry: false,
    });
}
