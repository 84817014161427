import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useEffect, useContext, useCallback, useMemo } from 'react';
import { StorageKey } from '@emme/shared/model/enums';
import { AlertContext } from 'context/AlertProvider';
import { storage } from '@emme/shared';
import { useLiquidityMarketsData } from '@emme/shared/queries/LiquidityMarkets';
import { useExchangesData } from '@emme/shared/queries/Exchanges';
import LiquidityContext from './liquidityContext';
import LiquidityReducer from './liquidityReducer';
import { SET_EXCHANGE, SET_LIQUIDITY_MARKET } from './actionTypes';
const LiquidityState = ({ children }) => {
    const { setAlert } = useContext(AlertContext);
    const initialState = {
        selectedLiquidityMarketId: undefined,
        selectedExchangeId: undefined,
        isLoading: true,
    };
    const [state, dispatch] = useReducer(LiquidityReducer, initialState);
    const { data: exchanges, error: errorExchange } = useExchangesData();
    const { data: markets, error: errorMarket } = useLiquidityMarketsData({
        exchangeId: state.selectedExchangeId,
        includeTicker: true,
    });
    const changeLiquidityMarket = useCallback((data, isAutoChange) => {
        if (!data?.id) {
            return;
        }
        dispatch({
            type: SET_LIQUIDITY_MARKET,
            payload: data?.id,
        });
        if (!isAutoChange) {
            storage.set(StorageKey.EMME_LAST_SELECTED_LIQUIDITY_MARKET, data);
        }
        storage.set(StorageKey.EMME_SELECTED_LIQUIDITY_MARKET, data);
    }, []);
    const changeExchange = useCallback((exchangeId) => {
        if (!exchangeId) {
            return;
        }
        dispatch({
            type: SET_EXCHANGE,
            payload: exchangeId,
        });
        storage.set(StorageKey.EMME_SELECTED_EXCHANGE, exchangeId);
    }, []);
    useEffect(() => {
        const exchangeId = storage.get(StorageKey.EMME_SELECTED_EXCHANGE);
        if (!exchanges || !exchanges.length) {
            return;
        }
        const validRememberedExchange = exchanges.find(({ id }) => id === exchangeId)?.id;
        changeExchange(validRememberedExchange || exchanges[0].id);
    }, [exchanges, changeExchange]);
    const checkStorage = useCallback(() => {
        const lastSavedMarket = storage.get(StorageKey.EMME_LAST_SELECTED_LIQUIDITY_MARKET);
        const savedMarket = storage.get(StorageKey.EMME_SELECTED_LIQUIDITY_MARKET);
        if (!markets || !markets?.elements?.length) {
            return;
        }
        // Fixes auto change market when user has multiple tabs open
        if (lastSavedMarket &&
            savedMarket &&
            lastSavedMarket?.id === savedMarket?.id &&
            state.selectedLiquidityMarketId) {
            return;
        }
        let firstQuoteMarket;
        let skipLast = false;
        let validRememberedMarket;
        if (!lastSavedMarket) {
            skipLast = true;
        }
        if (!skipLast) {
            validRememberedMarket = markets.elements.find(({ id, baseCoin, quoteCoin }, index) => {
                if (id === lastSavedMarket?.id || lastSavedMarket?.quoteCoin === quoteCoin) {
                    if (lastSavedMarket?.baseCoin === baseCoin) {
                        return true;
                    }
                    if (firstQuoteMarket === undefined) {
                        firstQuoteMarket = index;
                    }
                }
                return false;
            });
        }
        if (!validRememberedMarket) {
            firstQuoteMarket = undefined;
            validRememberedMarket = !savedMarket
                ? markets.elements[0]
                : markets.elements.find(({ id, baseCoin, quoteCoin }, index) => {
                    if (id === savedMarket?.id || savedMarket?.quoteCoin === quoteCoin) {
                        if (savedMarket?.baseCoin === baseCoin) {
                            return true;
                        }
                        if (firstQuoteMarket === undefined) {
                            firstQuoteMarket = index;
                        }
                    }
                    return false;
                });
        }
        if (!validRememberedMarket) {
            validRememberedMarket = markets.elements[firstQuoteMarket || 0];
        }
        changeLiquidityMarket({
            id: validRememberedMarket?.id,
            baseCoin: validRememberedMarket?.baseCoin,
            quoteCoin: validRememberedMarket?.quoteCoin,
        }, true);
    }, [changeLiquidityMarket, markets]);
    useEffect(() => {
        checkStorage();
    }, [checkStorage]);
    useEffect(() => {
        if (errorExchange)
            setAlert(errorExchange, 'error');
        if (errorMarket)
            setAlert(errorMarket, 'error');
    }, [errorMarket, errorExchange, setAlert]);
    const selectedLiquidityMarket = markets?.elements.find(({ id }) => id === state.selectedLiquidityMarketId);
    const selectedExchange = exchanges?.find(({ id }) => id === state.selectedExchangeId);
    const value = useMemo(() => ({
        selectedLiquidityMarket,
        selectedExchange,
        changeLiquidityMarket,
        changeExchange,
        exchanges: exchanges || [],
        markets: markets?.elements || [],
        isLoading: Boolean(!selectedExchange || !selectedLiquidityMarket),
    }), [
        selectedLiquidityMarket,
        selectedExchange,
        changeLiquidityMarket,
        changeExchange,
        exchanges,
        markets?.elements,
    ]);
    return _jsx(LiquidityContext.Provider, { value: value, children: children });
};
export default LiquidityState;
