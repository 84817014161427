import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
const PasswordTextField = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const Icon = showPassword ? VisibilityOutlinedIcon : VisibilityOffOutlinedIcon;
    return (_jsx(TextField, { type: showPassword ? 'text' : 'password', InputProps: {
            endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle visibility", onClick: () => setShowPassword((current) => !current), edge: "end", disableRipple: true, tabIndex: -1, size: "small", sx: {
                        color: (t) => t.palette.text.secondary,
                    }, children: _jsx(Icon, { fontSize: "small" }) }) })),
        }, required: true, ...props }));
};
export default PasswordTextField;
