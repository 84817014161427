import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { minOverviewCoinAmountUsd } from 'utils/util';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { OverviewContext } from 'context/Overview/OverviewProvider';
import { useMarketInfoCoinsData } from '@emme/shared/queries/Coins';
import { CoinNameCell, NumberCell } from 'components/Table/cells';
import { EmmeTable } from 'components/Table';
import { useButtonLabel } from './hooks';
import { OverviewMarketVolume } from '../OverviewMarketVolume';
const CheckboxCell = ({ data: { checked, onChange, disabled = false }, }) => (_jsx(Checkbox, { checked: checked, onChange: (_, value) => onChange(value), size: "small", disabled: disabled }));
const AmountCell = ({ data: { amount, onChange, disabled }, }) => (_jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "flex-end", gap: 3, sx: { minWidth: ['190px', undefined, undefined, '280px'] }, minHeight: 48, children: [!disabled && (_jsx(Slider, { size: "small", value: amount, min: minOverviewCoinAmountUsd, max: 1000, step: 1, onChange: (_, value) => onChange(value), marks: [
                { value: minOverviewCoinAmountUsd, label: `$${minOverviewCoinAmountUsd}` },
                { value: 1000, label: '$1000' },
            ], disabled: disabled })), _jsxs(Box, { sx: {
                backgroundColor: 'neutralOne.dark',
                border: '2px solid',
                borderColor: ({ palette }) => palette.neutralsThree.main,
                width: '55px',
                flex: 'none',
                py: 2,
                borderRadius: 2,
                textAlign: 'center',
                cursor: disabled ? 'default' : undefined,
            }, children: ["$", amount] })] }));
const headCells = [
    { id: 'checkbox', label: '', Component: CheckboxCell },
    {
        id: 'id',
        label: 'Coin',
        Component: CoinNameCell,
    },
    {
        id: 'priceUsd',
        label: 'Price (USD)',
        Component: NumberCell,
        align: ColumnAlign.RIGHT,
        componentProps: {
            numberDecimals: 2,
        },
    },
    {
        id: 'amount',
        label: 'Amount',
        Component: AmountCell,
    },
];
export function Step2Market() {
    const { completeSelectedStep, selectedCoins, toggleCoinSelected, changeCoinAmount } = useContext(OverviewContext);
    const buttonLabel = useButtonLabel('Market');
    const handleSet = () => {
        completeSelectedStep();
    };
    const { markets } = useContext(liquidityContext);
    const allCoins = markets
        .flatMap(({ quoteCoin, baseCoin }) => [quoteCoin, baseCoin])
        .filter((element, index, coins) => coins.indexOf(element) === index)
        .sort();
    const { data: coinData, isLoading: isLoadingCoinData, isFetching: isFetchingCoinData, } = useMarketInfoCoinsData((allCoins || []).join(','));
    const handleCoinCheckboxClick = (coinName) => () => {
        toggleCoinSelected(coinName);
    };
    const handleCoinAmountChange = (coinName) => (amount) => {
        changeCoinAmount(coinName, amount);
    };
    const sufficientAmount = selectedCoins.reduce((total, { amount }) => total + amount, 0) >= minOverviewCoinAmountUsd;
    return (_jsxs(Stack, { gap: 6, children: [_jsx(Typography, { variant: "h6", children: "Set Markets" }), _jsxs(Typography, { children: ["Choose markets you want to trade with", _jsx("br", {}), "Bigger amounts let you place bigger orders which potentially earn more $EMME"] }), _jsx(Box, { sx: {
                    border: ({ palette }) => `1px solid ${palette.neutralsFour.main}`,
                    borderRadius: 2,
                    p: 5,
                }, children: _jsx(EmmeTable, { rows: allCoins.map((coinName) => ({
                        id: coinName,
                        checkbox: {
                            checked: Boolean(selectedCoins.find(({ id }) => id === coinName)),
                            onChange: handleCoinCheckboxClick(coinName),
                            disabled: coinName === 'BTC',
                        },
                        priceUsd: coinData?.find((coin) => coin.symbol === coinName)?.priceUsd || null,
                        amount: {
                            amount: selectedCoins.find(({ id }) => id === coinName)?.amount || 0,
                            onChange: handleCoinAmountChange(coinName),
                            disabled: !selectedCoins.find(({ id }) => id === coinName),
                        },
                    })), inputHeadCells: headCells, isLoading: isLoadingCoinData && isFetchingCoinData, customVerticalPadding: 0, alternatingRowColors: true }) }), _jsx(OverviewMarketVolume, { coin: "BTC", type: "quote" }), _jsx(Button, { variant: "outlined", color: "inherit", onClick: handleSet, disabled: !sufficientAmount, sx: { alignSelf: 'center' }, children: buttonLabel })] }));
}
