import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Navbar from './Navbar';
import Footer from './Footer';
const MainLayout = ({ children, hideFooter }) => (_jsxs(_Fragment, { children: [_jsx(AppBar, { elevation: 0, sx: {
                borderBottom: '1px solid',
                borderColor: (t) => t.palette.neutralOne.dark,
                backgroundColor: (t) => t.palette.background.default,
            }, children: _jsx(Navbar, {}) }), _jsxs(Stack, { minHeight: "100vh", justifyContent: "space-between", pt: 12, gap: 10, children: [_jsx(Paper, { square: true, elevation: 0, children: children }), !hideFooter && _jsx(Footer, {})] })] }));
MainLayout.defaultProps = {
    hideFooter: false,
};
export default MainLayout;
