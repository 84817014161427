import { jsx as _jsx } from "react/jsx-runtime";
import { useUserInfoData } from '@emme/shared/queries/Users';
import { LoadingOverlay } from 'components/LoadingOverlay';
import Private from './Private';
import Public from './Public';
const Routes = () => {
    const { data: user, isLoading } = useUserInfoData();
    return (_jsx(LoadingOverlay, { isLoading: !user && isLoading, size: 100, zIndex: 1, sx: { minHeight: '100vh' }, children: !isLoading && (user ? _jsx(Private, {}) : _jsx(Public, {})) }));
};
export default Routes;
