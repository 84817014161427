// API links
const API_SCHEME = process.env.REACT_APP_API_SCHEME || 'https';
const API_SUBDOMAIN = process.env.REACT_APP_API_SUBDOMAIN || 'api';
const COIN_SUBDOMAIN = process.env.REACT_APP_COIN_SUBDOMAIN || 'coins';
const EXCHANGE_SUBDOMAIN = process.env.REACT_APP_EXCHANGE_SUBDOMAIN || 'exchanges';
export const API_TOP_DOMAIN = process.env.REACT_APP_API_TOP_DOMAIN || 'dev.em.me';
const WSS_SUBDIRECTORY = process.env.REACT_APP_WSS_SUBDIRECTORY || 'events';
export const API_URL = `${API_SCHEME}://${API_SUBDOMAIN}.${API_TOP_DOMAIN}`;
export const COIN_API_URL = `${API_SCHEME}://${COIN_SUBDOMAIN}.${API_TOP_DOMAIN}`;
export const EXCHANGE_API_URL = `${API_SCHEME}://${EXCHANGE_SUBDOMAIN}.${API_TOP_DOMAIN}`;
export const WSS_URL = `wss://${API_SUBDOMAIN}.${API_TOP_DOMAIN}/${WSS_SUBDIRECTORY}`;
// settings
export const ENABLE_COOKIES = process.env.REACT_APP_ENABLE_COOKIES === 'true';
