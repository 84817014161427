import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { isProduction } from 'config';
import { useAppInfoData } from '@emme/shared/queries/App';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { Version } from 'utils/util';
const VersionTooltip = ({ children }) => {
    const { tablet } = useBreakpoint();
    const { data: appInfo } = useAppInfoData();
    return (_jsx(Tooltip, { title: !tablet ? (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h6", color: "textSecondary", children: "App Version:" }), _jsxs(Typography, { variant: "subtitle2", children: [Version()?.primary, !isProduction && (_jsxs(_Fragment, { children: [_jsx("br", {}), Version()?.secondary] }))] }), _jsx(Divider, { sx: { my: 2 } }), _jsxs(Typography, { variant: "subtitle2", children: ["Backend version: ", appInfo?.version, !isProduction && (_jsxs(_Fragment, { children: [_jsx("br", {}), `git: ${appInfo?.gitBranch}+${appInfo?.gitRevisionNumber?.trim()}-
              ${appInfo?.gitCommitHash}`] }))] })] })) : (''), placement: "bottom-end", enterDelay: 1000, children: _jsx("span", { children: children }) }));
};
export default VersionTooltip;
