import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { ENABLE_SIGNUP_CODE } from 'config';
import { RouteEnum, UserRole } from '@emme/shared/model/enums';
import { AlertContext } from 'context/AlertProvider';
import { useSignup } from '@emme/shared/queries/Auth';
import { AuthPageLayout } from 'layout/AuthPage';
import SignupForm from './components/SignupForm';
const Signup = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const invitationCode = location.state;
    const signupUser = useSignup();
    const { setAlert, clearAlert } = useContext(AlertContext);
    const emailAlreadyRegisteredAlertKey = useRef();
    useEffect(() => {
        if (!invitationCode && ENABLE_SIGNUP_CODE) {
            navigate(RouteEnum.SignupCodeVerification, { replace: true });
        }
    }, [invitationCode, navigate]);
    const closeEmailAlreadyRegisteredAlert = () => {
        if (emailAlreadyRegisteredAlertKey.current) {
            clearAlert(emailAlreadyRegisteredAlertKey.current);
        }
    };
    const handleSubmit = async (formData) => {
        const sendData = {
            ...formData,
        };
        if (ENABLE_SIGNUP_CODE) {
            if (!invitationCode) {
                navigate(RouteEnum.SignupCodeVerification, { replace: true });
                setAlert('Invitation Code is missing', 'error');
                return;
            }
            sendData.invitationCode = invitationCode;
        }
        try {
            const data = await signupUser.mutateAsync(sendData);
            navigate(RouteEnum.EmailVerification, data.role === UserRole.INACTIVE_USER ? { state: data } : undefined);
        }
        catch (error) {
            if (error === 'This email is already registered') {
                emailAlreadyRegisteredAlertKey.current = setAlert(_jsxs(Stack, { justifyContent: "center", children: [_jsxs(Box, { children: [_jsx("i", { children: sendData?.email }), " is already registered."] }), _jsxs(Stack, { direction: "row", justifyContent: "center", spacing: 2, children: [_jsx(Box, { children: "You can sign up with a different account or " }), _jsx(Link, { component: RouterLink, to: RouteEnum.ForgotPassword, onClick: closeEmailAlreadyRegisteredAlert, color: "success.main", children: "recover your password here" }), "."] })] }), 'error', 60000);
            }
            else {
                setAlert(error || 'Unknown error', 'error');
            }
        }
    };
    return !invitationCode && ENABLE_SIGNUP_CODE ? null : (_jsx(AuthPageLayout, { columnTitle: "Sign up", children: _jsx(SignupForm, { onSubmit: handleSubmit, loading: signupUser.isLoading }) }));
};
export default Signup;
