import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useExchangesData } from '@emme/shared/queries/Exchanges';
import { useLiquidityMarketsData } from '@emme/shared/queries/LiquidityMarkets';
import liquidityContext from './liquidityContext';
export function OverviewLiquidityProvider({ children }) {
    const [selectedExchangeId, setSelectedExchangeId] = useState(undefined);
    const [selectedMarketId, setSelectedMarketId] = useState(undefined);
    // TODO: detect existing open order created from Overview
    // If it exists, set exchange/market based on it
    const { data: exchanges, isLoading: isLoadingExchanges } = useExchangesData();
    const { data: markets, isLoading: isLoadingMarkets } = useLiquidityMarketsData({
        exchangeId: selectedExchangeId,
        includeTicker: true,
    });
    const isLoading = isLoadingExchanges || isLoadingMarkets;
    const contextValue = useMemo(() => ({
        changeLiquidityMarket: ({ id }) => setSelectedMarketId(id),
        changeExchange: (id) => {
            setSelectedExchangeId(id);
            setSelectedMarketId(undefined);
        },
        exchanges: exchanges || [],
        markets: markets?.elements || [],
        selectedLiquidityMarket: markets?.elements.find(({ id }) => id === selectedMarketId),
        selectedExchange: exchanges
            ? exchanges.find(({ id }) => id === selectedExchangeId)
            : undefined,
        isLoading,
    }), [
        selectedMarketId,
        setSelectedMarketId,
        selectedExchangeId,
        setSelectedExchangeId,
        exchanges,
        markets?.elements,
        isLoading,
    ]);
    return _jsx(liquidityContext.Provider, { value: contextValue, children: children });
}
