import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Slider from '@mui/material/Slider';
const VolumeSlider = ({ minAllowed, maxAllowed, sx, onChange, ...sliderProps }) => {
    const disabledRailSx = {
        content: '""',
        display: 'block',
        height: 'inherit',
        width: 'auto',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        borderRadius: 'inherit',
        backgroundColor: (t) => t.palette.neutralsThree.main,
        color: (t) => t.palette.neutralsThree.main,
        zIndex: 1,
    };
    const addMarks = !sliderProps.disabled && minAllowed !== maxAllowed;
    const handleChange = useCallback((event, value, activeThumb) => {
        if (typeof value === 'number' && onChange) {
            const clampedValue = value === 0 ? 0 : Math.max(minAllowed, Math.min(maxAllowed, value));
            onChange({ ...event, target: { ...event.target, value: clampedValue } }, clampedValue, activeThumb);
        }
    }, [minAllowed, maxAllowed, onChange]);
    return (_jsx(Slider, { onChange: handleChange, ...sliderProps, sx: {
            ...sx,
            ...(sliderProps.disabled
                ? {}
                : {
                    '::before': {
                        ...disabledRailSx,
                        left: `calc(${(maxAllowed / sliderProps.max) * 100}% + 1px)`,
                        right: '0',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    },
                    '::after': {
                        ...disabledRailSx,
                        display: sliderProps.value === sliderProps.min ? 'block' : 'none',
                        left: '0',
                        right: `calc(${100 - (minAllowed / sliderProps.max) * 100}% + 1px)`,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    },
                    '.MuiSlider-thumb': {
                        zIndex: 2,
                    },
                    '.MuiSlider-mark': {
                        height: 8,
                        borderRadius: 0,
                        backgroundColor: '#1A4E75',
                        opacity: 1,
                        '&.MuiSlider-markActive': {
                            backgroundColor: 'currentColor',
                        },
                    },
                }),
        }, marks: addMarks
            ? [
                { value: minAllowed, label: '' },
                { value: maxAllowed, label: '' },
            ]
            : [] }));
};
export default VolumeSlider;
