import { jsx as _jsx } from "react/jsx-runtime";
import Button from '@mui/material/Button';
const QuickButton = ({ removeSpacing, children, sx, ...rest }) => (_jsx(Button, { type: "button", variant: "outlined", color: "inherit", tabIndex: -1, sx: {
        height: ['28px', undefined, '20px'],
        minWidth: '50px',
        fontSize: 11,
        lineHeight: 'normal',
        ...(!removeSpacing ? { my: 3 } : {}),
        ...sx,
    }, ...rest, children: children }));
QuickButton.defaultProps = {
    removeSpacing: false,
};
export default QuickButton;
