import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { RouteEnum } from '@emme/shared/model/enums';
import { sharedAuthPageStyles } from 'layout/AuthPage';
export function AlreadyHaveAnAccountLink() {
    const classes = sharedAuthPageStyles();
    return (_jsxs(Typography, { variant: "subtitle2", align: "center", className: classes.otherText, children: ["Already have an account?", _jsx(Link, { component: RouterLink, to: RouteEnum.Login, children: "Login" })] }));
}
