import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import { RouteEnum } from '@emme/shared/model/enums';
import { sharedAuthPageStyles } from 'layout/AuthPage';
import { getErrorFormatter } from 'utils/util';
import { signupLinkText } from 'config';
const validationSchema = Yup.object({
    email: Yup.string()
        .label('Email address')
        .email('It should be valid email address!')
        .required('Required'),
});
export const ForgotPasswordForm = ({ onSubmit, loading }) => {
    const classes = sharedAuthPageStyles();
    const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, isValid } = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit,
    });
    const formatError = getErrorFormatter(touched, errors);
    const handleEmailChange = (e) => {
        if (e.type === 'change') {
            setFieldTouched('email');
        }
        handleChange(e);
    };
    return (_jsxs("form", { onSubmit: handleSubmit, noValidate: true, children: [_jsx(TextField, { label: "Enter the account email", value: values.email, onChange: handleEmailChange, onBlur: () => setFieldTouched('email'), name: "email", fullWidth: true, autoComplete: "new-email", required: true, helperText: formatError('email'), error: touched.email && Boolean(errors.email), sx: {
                    mb: 4,
                } }), _jsx(LoadingButton, { variant: "contained", type: "submit", fullWidth: true, size: "large", loading: loading, disabled: !touched?.email || !isValid, children: _jsx(Typography, { variant: "h6", component: "span", children: "Continue" }) }), _jsx(Typography, { variant: "subtitle2", align: "center", className: classes.otherText, mt: 3, children: _jsx(Link, { component: RouterLink, to: RouteEnum.Login, children: "Nevermind, I got it" }) }), _jsxs(Typography, { variant: "subtitle2", align: "center", className: classes.otherText, children: ["Don\u2019t have an account?", _jsx(Link, { component: RouterLink, to: RouteEnum.Signup, children: signupLinkText })] })] }));
};
export default ForgotPasswordForm;
