import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import BigNumber from 'bignumber.js';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { keyframes } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import { calculatePriceCurve, formatSpread } from 'utils/util';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { AlertContext } from 'context/AlertProvider';
import { OrderTabsContext } from 'context/OrderTabsProvider';
import { ConfirmOrderContext } from 'context/ConfirmOrderProvider';
import { useCreateLiquidityMarketOrderProvision, useCreateLiquidityMarketOrderSubscription, useLiquidityMarketsTickerData, } from '@emme/shared/queries/LiquidityMarkets';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
import { OrderSideTabs, OrderTabs } from 'components/Tabs';
import { DashboardButton } from 'components/Buttons';
import { getDurationInDays, getFullDurationText } from './components/DurationInput';
import { SubscribeOrderButton } from './components/SubscribeOrderButton';
const pulse = keyframes `
  from {
    box-shadow: 0 0 0 0px #FF5C3870;
  }
  80% {
    box-shadow: 0 0 0 10px #FF5C3810;
  }
  to {
    box-shadow: 0 0 0 8px #FF5C3800;
  }
`;
const isProvision = (data, type) => type === 'provisions';
const isSubscription = (data, type) => type === 'subscriptions';
const formatPrice = (price, coin) => price && coin && (typeof price === 'string' || (BigNumber.isBigNumber(price) && !price.isNaN()))
    ? `${new BigNumber(price).toFormat(8)} ${coin}`
    : '-';
const Row = ({ children, ...rest }) => (_jsx(Stack, { direction: "row", justifyContent: "space-between", gap: 5, ...rest, children: children }));
const Left = ({ children }) => (_jsx(Typography, { color: "textSecondary", component: "div", children: children }));
const Right = ({ children }) => (_jsx(Typography, { align: "right", component: "div", sx: { wordBreak: 'break-word' }, children: children }));
const ConfirmOrder = () => {
    const { orderData, closeConfirmOrder } = useContext(ConfirmOrderContext);
    const { changeTabs } = useContext(OrderTabsContext);
    const { selectedLiquidityMarket } = useContext(liquidityContext);
    const { setAlert } = useContext(AlertContext);
    const { data: ticker } = useLiquidityMarketsTickerData(selectedLiquidityMarket?.id);
    const { data: baseCoinData } = useMarketInfoLatestCoinData(selectedLiquidityMarket?.baseCoin);
    const { mutateAsync: createProvisionOrder, isLoading: isCreatingProvision } = useCreateLiquidityMarketOrderProvision();
    const { mutateAsync: createSubscriptionOrder, isLoading: isCreatingSubscription } = useCreateLiquidityMarketOrderSubscription();
    if (!orderData)
        return null;
    const { data, type, onboarding } = orderData;
    const handleSubmit = async () => {
        try {
            if (isProvision(data, type)) {
                await createProvisionOrder(data);
            }
            else {
                await createSubscriptionOrder(data);
            }
            closeConfirmOrder(true);
            changeTabs(OrderTabs.OPEN_ORDERS, type === 'provisions' ? OrderSideTabs.PROVISION : OrderSideTabs.SUBSCRIPTION);
            setAlert('Successfully placed order', 'success', 2500);
        }
        catch (error) {
            closeConfirmOrder(false);
            const msg = typeof error === 'string' ? error : error?.message;
            setAlert(msg || 'Something went wrong', 'error');
        }
    };
    let spread;
    let price;
    let totalEarningsPerDay = 0;
    if (isProvision(data, type)) {
        const spreads = data.minSpread === data.maxSpread ? [data.minSpread] : [data.minSpread, data.maxSpread];
        spread = spreads.map((s) => formatSpread(Number(s))).join(' - ');
        price = spreads.map((s) => calculatePriceCurve(data.priceCurve, Number(s)).toFixed(8));
        totalEarningsPerDay = new BigNumber(data.baseVolume)
            .multipliedBy(baseCoinData?.priceBtc || 0)
            .plus(new BigNumber(data.quoteVolume))
            .multipliedBy(BigNumber.max(new BigNumber(price[0]), new BigNumber(price[1] || 0)));
        price = price.join(' to ');
    }
    else {
        spread = formatSpread(Number(data.spread));
        price = new BigNumber(data.price).toFormat(8);
    }
    return (_jsxs(Stack, { p: 5, gap: 5, children: [_jsxs(Typography, { variant: "h6", mb: 4, sx: { color: (t) => t.palette.primary.main, alignSelf: 'center' }, children: ["Confirm ", type === 'provisions' ? 'Provision' : 'Subscription', " Order"] }), _jsxs(Row, { children: [_jsx(Left, { children: "Duration:" }), _jsx(Right, { children: getFullDurationText(data.duration, false) })] }), _jsxs(Row, { children: [_jsx(Left, { children: "Spread:" }), _jsx(Right, { children: spread })] }), _jsxs(Row, { children: [_jsx(Left, { children: "Price:" }), _jsxs(Stack, { children: [_jsx(Right, { children: price }), _jsxs(Right, { children: ["$EMME per ", selectedLiquidityMarket?.quoteCoin, " liquidity days"] })] })] }), isSubscription(data, type) && (_jsxs(Row, { children: [_jsxs(Left, { children: ["Max Total Cost", _jsx("br", {}), "per day:"] }), _jsx(Right, { children: formatPrice(new BigNumber(data.price).multipliedBy(data.volume), '$EMME') })] })), isProvision(data, type) && (_jsxs(Row, { children: [_jsxs(Left, { children: ["Max Total Earnings", _jsx("br", {}), "per day:"] }), _jsxs(Right, { children: [totalEarningsPerDay ? totalEarningsPerDay.toFormat(8) : '-', " $EMME"] })] })), _jsxs(Row, { children: [_jsx(Left, { children: "Max Volume:" }), _jsxs(Stack, { children: [_jsx(Right, { children: formatPrice(isProvision(data, type) ? data.quoteVolume : new BigNumber(data.volume).dividedBy(2), selectedLiquidityMarket?.quoteCoin) }), _jsx(Right, { children: formatPrice(isProvision(data, type)
                                    ? data.baseVolume
                                    : new BigNumber(data.volume)
                                        .dividedBy(2)
                                        .dividedBy(ticker?.exchangeLastTradePrice || ''), selectedLiquidityMarket?.baseCoin) })] })] }), _jsx(Divider, {}), isProvision(data, type) && (_jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsx(Left, { children: "Max Total Earnings:" }), _jsxs(Right, { children: [totalEarningsPerDay
                                        ? totalEarningsPerDay
                                            .multipliedBy(new BigNumber(getDurationInDays(data.duration) || 0))
                                            .toFormat(8)
                                        : '-', ' ', "$EMME"] })] }), _jsx(Row, { typography: "body1", lineHeight: "20px", color: "text.secondary", mt: 3, children: "Please note that providing liquidity comes with the risk that some or all of the orders placed on your exchange account may be filled, adjusting your position in a manner similar to impermanent loss in AMMs (automated market maker)." })] })), isSubscription(data, type) && (_jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsx(Left, { children: "Fee:" }), _jsx(Right, { children: "0 $EMME" })] }), data.maxTotalCost ? (_jsxs(Row, { children: [_jsxs(Left, { children: ["Custom Max", _jsx("br", {}), "Total Cost:"] }), _jsx(Right, { children: formatPrice(data.maxTotalCost, '$EMME') })] })) : (_jsxs(Row, { children: [_jsxs(Left, { children: ["Max", _jsx("br", {}), "Total Cost:"] }), _jsx(Right, { children: formatPrice(new BigNumber(data.price)
                                    .multipliedBy(data.volume)
                                    .multipliedBy(getDurationInDays(data.duration)), '$EMME') })] }))] })), _jsxs(Stack, { direction: "row", justifyContent: "space-between", gap: 4, mt: 4, children: [_jsx(DashboardButton, { variant: "contained", size: "large", type: "button", color: "inherit", onClick: () => closeConfirmOrder(false), disabled: isCreatingProvision || isCreatingSubscription, sx: { minWidth: '130px' }, fullWidth: true, children: "Go Back" }), isSubscription(data, type) ? (_jsx(SubscribeOrderButton, { onClick: handleSubmit, loading: isCreatingSubscription, children: "Place Order" })) : (_jsx(LoadingButton, { variant: "contained", size: "large", type: "button", onClick: handleSubmit, loading: isCreatingProvision, color: "error", sx: {
                            minWidth: '130px',
                            animation: onboarding && !isCreatingProvision
                                ? `1.6s ease-in-out 0s infinite ${pulse}`
                                : undefined,
                        }, fullWidth: true, children: "Place Order" }))] })] }));
};
export default ConfirmOrder;
