/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { SocketContext } from 'context/SocketProvider';
import { AlertContext } from 'context/AlertProvider';
import { useCurrentRoundData } from '@emme/shared/queries/Rounds';
import { timeAgo, timeRemaining } from 'utils/util';
export function useRoundProgress() {
    const { isOpen, sendMessage } = useContext(SocketContext);
    const { setAlert } = useContext(AlertContext);
    const [{ progress, timeRemainingString, timeAgoString }, setState] = useState({
        progress: 0,
        timeRemainingString: undefined,
        timeAgoString: undefined,
    });
    const [outOfSyncSeconds, setOutOfSyncSeconds] = useState(0);
    const { isError, error, data: current } = useCurrentRoundData();
    useEffect(() => {
        if (isError && error) {
            setAlert(error, 'error');
        }
    }, [isError, error, setAlert]);
    useEffect(() => {
        if (isOpen) {
            sendMessage({ service: 'ROUNDS', type: 'SUBSCRIBE' });
        }
        return () => sendMessage({ service: 'ROUNDS', type: 'UNSUBSCRIBE' });
    }, [isOpen]);
    const updateValues = (outOfSync) => {
        if (!current)
            return;
        const secondsElapsed = dayjs()
            .add(outOfSync || outOfSyncSeconds, 's')
            .diff(dayjs(current?.startTime), 's');
        if (secondsElapsed <= current.durationMs / 1000) {
            setState({
                progress: Math.round((Math.abs(secondsElapsed) / (current.durationMs / 1000)) * 100),
                timeRemainingString: timeRemaining(current.startTime, (current?.durationMs || 0) / 1000, outOfSync || outOfSyncSeconds, true),
                timeAgoString: timeAgo(current.startTime, outOfSync || outOfSyncSeconds, true),
            });
        }
    };
    useEffect(() => {
        if (!current)
            return;
        if (current.durationMs && current.startTime) {
            const checkStartTime = Math.round(dayjs().diff(dayjs(current.startTime)) / 1000);
            const serverRemainingSeconds = Math.round((current.durationMs - current.remainingMs) / 1000);
            const difference = checkStartTime - serverRemainingSeconds;
            setOutOfSyncSeconds(0 - difference);
            updateValues(0 - difference);
        }
        else {
            setState({ timeAgoString, timeRemainingString, progress: 0 });
        }
        const timer = setInterval(() => {
            updateValues();
            if (progress > 100) {
                setState({ timeAgoString, timeRemainingString, progress: 0 });
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [current, outOfSyncSeconds]);
    useEffect(() => {
        const updateTitle = (text = '') => {
            document.title = `${text}EM.ME - Ethical Market Making Exchange`;
        };
        updateTitle(`Round: ${progress}% | `);
        return () => updateTitle();
    }, [progress]);
    return { progress, timeRemainingString, timeAgoString, current };
}
