export const customPalette = (mode, { primaryMain, secondaryMain, info, error, background, emmeError, emmePrimary, emmeSecondary, emmeInfo, neutralsOne, neutralsOneDark, neutralsTwo, neutralsThree, neutralsFour, neutralsFive, neutralsSix, neutralsSeven, neutralsEight, }) => ({
    mode,
    primary: {
        main: primaryMain,
        contrastText: neutralsSix,
    },
    secondary: {
        main: secondaryMain,
        contrastText: neutralsSix,
    },
    text: {
        primary: neutralsSix,
        secondary: neutralsFive,
    },
    background: {
        default: background,
        paper: background,
    },
    info: {
        main: primaryMain,
    },
    success: {
        main: secondaryMain,
    },
    error: {
        main: error,
        contrastText: neutralsEight,
    },
    warning: {
        main: info,
    },
    neutralOne: {
        main: neutralsOne,
        dark: neutralsOneDark,
    },
    neutralsTwo: {
        main: neutralsTwo,
    },
    neutralsThree: {
        main: neutralsThree,
    },
    neutralsFour: {
        main: neutralsFour,
    },
    neutralsFive: {
        main: neutralsFive,
    },
    neutralsSix: {
        main: neutralsSix,
    },
    neutralsSeven: {
        main: neutralsSeven,
    },
    neutralsEight: {
        main: neutralsEight,
    },
    emmeError: {
        main: emmeError,
    },
    emmePrimary: {
        main: emmePrimary,
    },
    emmeSecondary: {
        main: emmeSecondary,
    },
    emmeInfo: {
        main: emmeInfo,
    },
});
export const customTypography = ({ neutralsFive }) => ({
    body2: {
        color: neutralsFive,
    },
    subtitle1: {
        color: neutralsFive,
    },
});
export const customComponents = ({ primaryMain, neutralsOne, neutralsOneDark, neutralsTwo, neutralsThree, neutralsFour, neutralsFive, neutralsSix, neutralsEight, background, }) => ({
    MuiAppBar: {
        styleOverrides: {
            root: {
                backgroundColor: neutralsOne,
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            outlined: {
                borderColor: neutralsFour,
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                '&.Mui-disabled': {
                    color: neutralsFive,
                    opacity: 0.6,
                },
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                backgroundColor: neutralsTwo,
                '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: neutralsFour,
                },
                '.Mui-disabled': {
                    WebkitTextFillColor: neutralsFive,
                },
                '&.Mui-disabled': {
                    WebkitTextFillColor: neutralsFive,
                    opacity: 0.6,
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: neutralsTwo,
                    },
                },
            },
            notchedOutline: {
                borderColor: neutralsTwo,
            },
            adornedEnd: {
                color: neutralsSix,
            },
        },
    },
    MuiAvatar: {
        styleOverrides: {
            colorDefault: {
                backgroundColor: 'transparent',
                color: neutralsSix,
            },
        },
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: neutralsOne,
    //     },
    //   },
    // },
    MuiLoadingButton: {
        styleOverrides: {
            loadingIndicator: {
                color: primaryMain,
            },
        },
    },
    MuiTable: {
        styleOverrides: {
            stickyHeader: {
                '& .MuiTableCell-head': {
                    backgroundColor: neutralsOneDark,
                },
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                '&.MuiTableRow-hover:hover': {
                    backgroundColor: `${neutralsEight}12`,
                },
                '&.MuiTableRow-hover.Mui-selected': {
                    backgroundColor: `${neutralsEight}08`,
                },
                '&.MuiTableRow-hover.Mui-selected:hover': {
                    backgroundColor: `${neutralsEight}16`,
                },
            },
        },
    },
    MuiDialog: {
        styleOverrides: {
            paper: {
                backgroundColor: neutralsTwo,
                '& .MuiOutlinedInput-root': {
                    backgroundColor: neutralsThree,
                },
                '& .MuiTable-stickyHeader .MuiTableCell-head': {
                    backgroundColor: neutralsTwo,
                },
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                color: neutralsSix,
                backgroundColor: background,
                '&.tooltip-disabled': {
                    color: neutralsFive,
                },
            },
            arrow: {
                color: background,
            },
        },
    },
    MuiSlider: {
        styleOverrides: {
            markLabel: {
                color: neutralsSix,
            },
        },
    },
});
