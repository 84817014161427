import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { useLiquidityMarketOrdersData } from '@emme/shared/queries/LiquidityMarkets';
import { OrdersTable } from 'components/OrdersTable';
import { OrderSideTabs, OrderTabs } from 'components/Tabs';
const OrdersTab = ({ tab, sideTab }) => {
    const { selectedLiquidityMarket } = useContext(LiquidityContext);
    const orderType = sideTab === OrderSideTabs.SUBSCRIPTION ? 'subscriptions' : 'provisions';
    const { data: orders, refetch: refetchOrders, isLoading: isLoadingOrders, } = useLiquidityMarketOrdersData({
        liquidityMarketId: selectedLiquidityMarket?.id || '',
        open: tab === OrderTabs.OPEN_ORDERS,
        type: orderType,
    });
    return (_jsx(OrdersTable, { type: orderType, orders: orders?.elements || [], liquidityMarket: selectedLiquidityMarket, onOrderCancel: refetchOrders, withCancelButton: tab === OrderTabs.OPEN_ORDERS, withTradesButton: true, isLoading: !selectedLiquidityMarket || isLoadingOrders, customVerticalPadding: 3, alternatingRowColors: true, stickyHeader: true }));
};
export default OrdersTab;
