import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useCreateCredentials, useUserExchangeCredentialsData } from '@emme/shared/queries/Users';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { AlertContext } from 'context/AlertProvider';
import DialogLayout from 'layout/Dialogs/DialogLayout';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { getErrorFormatter } from 'utils/util';
import { ExchangeSelectInput } from 'components/Inputs';
import { LoadingOverlay } from 'components/LoadingOverlay';
const passphraseRequiredExchanges = ['coinbase-pro', 'bitmart'];
export const getLink = (selectedExchangeId) => {
    switch (selectedExchangeId) {
        case 'binance':
            return 'https://www.binance.com/en/support/faq/360002502072/';
        case 'coinbase-pro':
            return 'https://help.coinbase.com/en/exchange/managing-my-account/how-to-create-an-api-key';
        case 'bittrex-global':
            return 'https://bittrex.zendesk.com/hc/en-us/articles/360031921872-How-to-create-an-API-key-';
        default:
            return 'https://wiki.em.me/index.php/HowTo_Provide_Liquidity#Step_2:_Import_API_key';
    }
};
const validationSchema = yup.object({
    key: yup.string().required('Please input API Key'),
    passphrase: yup
        .string()
        .nullable(true)
        .when('selectedExchangeId', {
        is: (id) => passphraseRequiredExchanges.includes(id),
        then: yup.string().required('Please input your API passphrase'),
    }),
    secret: yup.string().required('Please input your API Secret'),
    confirmation: yup.boolean(),
});
const ApiKeyDialog = ({ isAccountPage, buttonText, buttonProps, onboarding, overview, changeForm, onSubmit, }) => {
    const [open, setOpen] = useState(false);
    const [selectedExchangeId, setSelectedExchangeId] = useState('');
    const { tablet } = useBreakpoint();
    const { data: availableCredentials, isLoading } = useUserExchangeCredentialsData();
    const { selectedExchange, changeExchange } = useContext(LiquidityContext);
    const { setAlert } = useContext(AlertContext);
    const credentialsAlreadyExist = Boolean(availableCredentials && availableCredentials.some((a) => a.exchangeId === selectedExchangeId));
    const createCredentials = useCreateCredentials();
    useEffect(() => {
        let newExchange = selectedExchange?.id || '';
        if (onboarding &&
            !overview &&
            availableCredentials &&
            availableCredentials?.length > 0 &&
            !availableCredentials.some((a) => a.exchangeId === newExchange)) {
            newExchange = availableCredentials[0]?.exchangeId || newExchange;
        }
        setSelectedExchangeId(newExchange);
        if (onboarding && changeForm) {
            changeForm(newExchange);
        }
    }, [selectedExchange, availableCredentials]);
    const { handleSubmit, handleChange, values, errors, touched, isValid, setFieldValue, setFieldTouched, setFieldError, resetForm, } = useFormik({
        enableReinitialize: true,
        initialValues: {
            selectedExchangeId: selectedExchangeId,
            key: '',
            passphrase: '',
            secret: '',
            confirmation: false,
        },
        validationSchema,
        onSubmit: async ({ secret, key, passphrase, confirmation }) => {
            if (!selectedExchangeId) {
                return;
            }
            if (credentialsAlreadyExist && !confirmation) {
                setFieldError('confirmation', 'Confirmation is required');
                return;
            }
            try {
                await createCredentials.mutateAsync({
                    exchangeId: selectedExchangeId,
                    secret,
                    extraData: passphrase || null,
                    key,
                });
                setAlert(`Successfully updated ${selectedExchangeId} API key`, 'success', 2500);
                changeExchange(selectedExchangeId);
                setOpen(false);
                onSubmit && onSubmit();
            }
            catch (error) {
                setAlert(error || 'Failed to save credentials', 'error');
            }
        },
    });
    useEffect(() => {
        resetForm();
    }, [open, resetForm]);
    const formatError = getErrorFormatter(touched, errors);
    const handleDialogClose = (e, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const formInputs = (_jsxs(_Fragment, { children: [_jsx(TextField, { name: "key", value: values.key, onChange: (e) => {
                    setFieldTouched('key');
                    handleChange(e);
                }, autoComplete: "new-key", required: true, label: "API Key", helperText: formatError('key'), error: touched.key && Boolean(errors.key), fullWidth: true }), _jsx(TextField, { label: "API Secret", value: values.secret, onChange: (e) => {
                    setFieldTouched('secret');
                    handleChange(e);
                }, autoComplete: "new-secret", name: "secret", fullWidth: true, required: true, helperText: formatError('secret'), error: touched.secret && Boolean(errors.secret) }), selectedExchangeId && passphraseRequiredExchanges.includes(selectedExchangeId) && (_jsx(TextField, { label: "API passphrase", value: values.passphrase, onChange: (e) => {
                    setFieldTouched('passphrase');
                    handleChange(e);
                }, autoComplete: "new-passphrase", name: "passphrase", fullWidth: true, required: Boolean(selectedExchangeId && passphraseRequiredExchanges.includes(selectedExchangeId)), helperText: formatError('passphrase'), error: touched.passphrase && Boolean(errors.passphrase) }))] }));
    const formSubmitButton = (_jsx(LoadingButton, { loading: createCredentials.isLoading, variant: "contained", type: "submit", fullWidth: true, size: "large", disabled: !touched.secret || !isValid || (credentialsAlreadyExist && !values.confirmation), children: _jsxs(Typography, { variant: "h6", children: [credentialsAlreadyExist ? 'Update' : 'Add', " API key"] }) }));
    const formContinueButton = (_jsx(Button, { variant: "contained", type: "button", color: "secondary", fullWidth: true, size: "large", disabled: !onboarding && values.confirmation, tabIndex: -1, onClick: () => {
            changeExchange(selectedExchangeId);
            setOpen(false);
            onSubmit && onSubmit();
        }, sx: { mb: onboarding && !values.confirmation ? 3 : 6 }, children: _jsx(Typography, { variant: "h6", color: "inherit", children: onboarding ? 'Continue with existing Key' : 'Switch to Exchange' }) }));
    const onboardingForm = (_jsxs(_Fragment, { children: [formContinueButton, !values.confirmation ? (_jsx(Button, { type: "button", color: "secondary", fullWidth: true, size: "large", tabIndex: -1, onClick: () => setFieldValue('confirmation', true), sx: { mb: 6 }, children: _jsx(Typography, { variant: "h6", color: "inherit", children: "Or update API key" }) })) : (_jsxs(_Fragment, { children: [formInputs, formSubmitButton] }))] }));
    const defaultForm = (_jsxs(_Fragment, { children: [formInputs, credentialsAlreadyExist && (_jsxs(_Fragment, { children: [_jsxs(Box, { children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { color: "primary", checked: values.confirmation, onChange: (e) => {
                                        setFieldTouched('confirmation');
                                        handleChange(e);
                                    }, name: "confirmation", onBlur: () => setFieldTouched('confirmation'), size: "small", sx: { p: 0 }, disabled: createCredentials.isLoading }), label: _jsx(Typography, { variant: "subtitle1", component: "span", pl: 2, children: "Confirm overwrite existing API Key" }), sx: { mx: 0 } }), _jsx(FormHelperText, { error: true, sx: { mb: 2 }, children: touched.confirmation && Boolean(errors.confirmation) ? (_jsx(Typography, { variant: "subtitle2", component: "span", children: errors.confirmation })) : (' ') })] }), !isAccountPage && formContinueButton] })), formSubmitButton] }));
    const form = (_jsxs(Stack, { justifyContent: "flex-start", alignItems: "flex-start", gap: 3, width: "240px", component: "form", onSubmit: handleSubmit, noValidate: true, p: 2, children: [!onboarding && (_jsx(Link, { href: getLink(selectedExchangeId), target: "_blank", rel: "noreferrer noopener", "aria-label": "how-to-api-key", mb: 7, children: "How do I get an API key?" })), _jsxs(LoadingOverlay, { isLoading: isLoading, size: 50, children: [!overview && (_jsxs(FormControl, { fullWidth: true, children: [_jsx(ExchangeSelectInput, { selectProps: {
                                    name: 'selectedExchangeId',
                                    sx: { height: '42px' },
                                }, value: selectedExchangeId, onChange: (id) => {
                                    setSelectedExchangeId(id);
                                    if (onboarding && changeForm) {
                                        changeForm(id || '');
                                    }
                                } }), _jsx(FormHelperText, { children: " " })] })), credentialsAlreadyExist ? (onboarding ? (onboardingForm) : (defaultForm)) : (_jsxs(_Fragment, { children: [formInputs, formSubmitButton] }))] })] }));
    return onboarding ? (form) : (_jsxs(_Fragment, { children: [_jsx(Button, { type: "button", variant: "outlined", color: "inherit", startIcon: _jsx(AddOutlinedIcon, {}), disableFocusRipple: true, onClick: () => setOpen(true), size: "large", ...buttonProps, children: buttonText }), _jsx(DialogLayout, { open: Boolean(open), onClose: handleDialogClose, title: "New API", PaperProps: {
                    ...(tablet
                        ? {
                            sx: {
                                m: 0,
                            },
                        }
                        : undefined),
                }, children: form })] }));
};
ApiKeyDialog.defaultProps = {
    isAccountPage: false,
    buttonProps: {},
    onboarding: false,
    overview: false,
    changeForm: undefined,
    onSubmit: undefined,
    buttonText: 'Add API key',
};
export default ApiKeyDialog;
