import { useMutation, useQueryClient } from '@tanstack/react-query';
import { StorageKey } from '../../model/enums';
import { createFetchFn } from '../../utils/axios';
import * as storage from '../../utils/localstorage';
export function useSignup() {
    return useMutation(createFetchFn({
        requiresAuth: false,
        method: 'POST',
        url: '/users/signup',
    }), {
        mutationKey: ['signup-user'],
        retry: false,
    });
}
export function useActivateAccount() {
    const queryClient = useQueryClient();
    return useMutation(createFetchFn({
        requiresAuth: false,
        method: 'POST',
        url: '/users/signup/activate',
    }), {
        mutationKey: ['activate-email'],
        retry: false,
        onSuccess: (data) => {
            if (data?.token?.token) {
                storage.set(StorageKey.EMME_TOKEN, data.token.token);
                queryClient.fetchQuery(['users', 'me']);
            }
        },
    });
}
export function useInvitationCode() {
    return useMutation((code) => createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: '/users/invitation-codes',
        params: { code },
        transformData: (data) => data.available,
    })(), {
        mutationKey: ['invitation-code'],
        retry: false,
    });
}
export function useDeviceActivation() {
    const queryClient = useQueryClient();
    return useMutation(createFetchFn({
        requiresAuth: false,
        method: 'POST',
        url: '/users/devices/confirm',
    }), {
        mutationKey: ['activate-device'],
        retry: false,
        onSuccess: (data) => {
            if (data?.token?.token) {
                storage.set(StorageKey.EMME_TOKEN, data.token?.token);
                queryClient.fetchQuery(['users', 'me']);
            }
        },
    });
}
