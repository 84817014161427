import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteEnum } from '@emme/shared/model/enums';
import { AlertContext } from 'context/AlertProvider';
import { getFingerPrint } from 'utils/fingerprint';
export function useActivationParams() {
    const location = useLocation();
    const navigate = useNavigate();
    const [activationInfo, setActivationInfo] = useState();
    const { setAlert } = useContext(AlertContext);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params) {
            const userId = params.get('userId');
            const codeId = params.get('codeId');
            const code = params.get('code');
            const deviceId = params.get('deviceId') || undefined;
            const deviceName = params.get('deviceName') || undefined;
            const { deviceFingerprint, browserName } = getFingerPrint();
            if (!userId || !codeId || !code) {
                navigate(RouteEnum.Login, { replace: true });
                setAlert('Activation link is incorrect', 'error');
            }
            else {
                setActivationInfo({
                    userId,
                    codeId,
                    code,
                    deviceId,
                    deviceName,
                    deviceFingerprint,
                    browserName,
                });
            }
        }
    }, [location.search, navigate, setAlert]);
    return activationInfo;
}
