import { jsx as _jsx } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { PlaceOrderTab } from 'context/PlaceOrderTabProvider';
const tabs = [
    { label: PlaceOrderTab.Provide, tooltip: 'Sell Liquidity' },
    { label: PlaceOrderTab.Subscribe, tooltip: 'Buy Liquidity' },
];
function EmmeSubscribeProvideTabs({ value, onChange, disabled }) {
    return (_jsx(Tabs, { TabIndicatorProps: {
            style: { display: 'none' },
        }, value: value, onChange: (event, newValue) => onChange(newValue), textColor: "inherit", allowScrollButtonsMobile: true, sx: {
            minHeight: 'initial',
            mx: 3,
            '[role="tablist"]': { gap: 3 },
        }, children: tabs.map((tab) => (_jsx(Tab, { value: tab.label, label: _jsx(Tooltip, { title: tab.tooltip || '', children: _jsx(Typography, { variant: "h6", component: "span", children: tab.label }) }), sx: {
                minWidth: 'initial',
                maxWidth: 'initial',
                my: 3,
                mx: 0,
                px: 3,
                pointerEvents: disabled ? 'none' : 'auto !important',
                minHeight: [35, undefined, 28],
                backgroundColor: 'transparent',
                '&:hover:not(.Mui-selected)': {
                    backgroundColor: (theme) => theme.palette.text.primary,
                    color: (theme) => theme.palette.neutralsTwo.main,
                },
                '&.Mui-selected': {
                    backgroundColor: (theme) => theme.palette.neutralsThree.main,
                },
                flex: 'auto',
                flexBasis: 0,
            } }, tab.label))) }));
}
EmmeSubscribeProvideTabs.defaultProps = {
    disabled: false,
};
export default EmmeSubscribeProvideTabs;
