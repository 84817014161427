import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useResendDeviceEmail } from '@emme/shared/queries/Auth';
import { getFingerPrint } from 'utils/fingerprint';
import { VerificationPageLayout } from 'layout/AuthPage';
const DeviceVerificationPage = () => {
    const location = useLocation();
    const userInfo = location.state;
    const { mutateAsync: resend } = useResendDeviceEmail();
    const handleResend = async (data) => {
        const { deviceFingerprint } = getFingerPrint();
        await resend({ ...data, deviceFingerprint });
    };
    return (_jsxs(VerificationPageLayout, { title: "Authorize New Device", captcha: "APPLICATION_PAGE_DEVICE_CONFIRMATION_RESEND", onResend: handleResend, children: ["You attempted to login in into your EM.ME account from a new device.", _jsx("br", {}), "To access EM.ME, click the verification button in the email we sent to", ' ', _jsx("b", { children: userInfo?.email }), ". The link will expire in 10 minutes.", _jsx("br", {}), _jsx("br", {}), "The verification has to be done in the same browser and on the same device.", _jsx("br", {}), "Verification will fail if, for instance, the verification link opens Chrome by default but you are using Safari."] }));
};
export default DeviceVerificationPage;
