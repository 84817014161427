import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useCallback, createContext, useMemo } from 'react';
import { useCheckAvailable } from '@emme/shared/queries/App';
import { useQueryClient } from '@tanstack/react-query';
export const OnlineStatusContext = createContext({
    isConnected: true,
    checkAvailability: () => null,
});
const OnlineStatusProvider = ({ children }) => {
    const [isConnected, setIsConnected] = useState(true);
    const queryClient = useQueryClient();
    const { mutateAsync: checkAvailable } = useCheckAvailable();
    const checkAvailability = useCallback(async () => {
        setIsConnected(false);
        queryClient.setDefaultOptions({
            queries: {
                staleTime: Infinity,
                retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 60000),
                retry: true,
            },
        });
        try {
            await checkAvailable();
            setIsConnected(true);
        }
        catch (error) {
            console.log('error checkAvailable, ', error);
        }
    }, [queryClient, checkAvailable]);
    const value = useMemo(() => ({
        isConnected,
        checkAvailability,
    }), [isConnected, checkAvailability]);
    return _jsx(OnlineStatusContext.Provider, { value: value, children: children });
};
export default OnlineStatusProvider;
