// This is the default dashboard that is used for the initial state
// We will need to persist the dashboard state into BE so that any added/modified widgets are saved across browsers
export const defaultDashboards = [
    {
        title: 'Welcome',
        id: 'a',
        context: {},
        widgets: [
            { id: 'aa', type: 'HelloWorld', grid: { x: 1, y: 1, w: 3, h: 1 } },
            { id: 'ab', type: 'HelloWorld', grid: { x: 3, y: 5, w: 2, h: 1 } },
            { id: 'ac', type: 'HowdyWorld', grid: { x: 0, y: 9, w: 1, h: 4 } },
        ],
    },
    {
        title: 'Second dashboard',
        id: 'b',
        widgets: [{ id: 'ba', type: 'HelloWorld', grid: { x: 0, y: 0, w: 4, h: 1 } }],
        context: {},
    },
];
