import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useRemoveCredentials, useUserExchangeCredentialsData } from '@emme/shared/queries/Users';
import { useExchangesData } from '@emme/shared/queries/Exchanges';
import { AlertContext } from 'context/AlertProvider';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { ButtonCell } from 'components/Table/cells';
import { ApiKeyDialog } from 'components/Dialogs';
import { EmmeTable } from 'components/Table';
const ExchangeCell = ({ data }) => (_jsx(Box, { borderRadius: 1, p: 3, bgcolor: (t) => t.palette.neutralsTwo.main, display: "inline-block", children: data }));
const headCells = [
    { id: 'exchangeName', label: 'Exchange', Component: ExchangeCell },
    { id: 'key', label: 'API Key', wrap: true },
    {
        id: 'button',
        label: 'Action',
        align: ColumnAlign.CENTER,
        Component: ButtonCell,
        componentProps: {
            label: 'Remove',
            endIcon: _jsx(DeleteOutlineOutlinedIcon, {}),
            sx: {
                border: (t) => `1px solid ${t.palette.error.main}`,
                backgroundColor: (t) => `${t.palette.error.main}20`,
                ':hover': {
                    backgroundColor: (t) => `${t.palette.error.main}60`,
                },
            },
        },
    },
];
const ApiKeys = () => {
    const [selectedId, setSelectedId] = useState(undefined);
    const { setAlert } = useContext(AlertContext);
    const { data: credentials, isLoading, error: errorCredential } = useUserExchangeCredentialsData();
    const { data: exchanges } = useExchangesData();
    const { mutateAsync: removeCredentials, isLoading: isLoadingRemoveCredentials } = useRemoveCredentials();
    useEffect(() => {
        if (errorCredential) {
            setAlert(errorCredential || 'Unknown error', 'error');
        }
    }, [errorCredential, setAlert]);
    const handleAction = (exchangeId) => async () => {
        if (!exchangeId)
            return;
        setSelectedId(exchangeId);
        try {
            await removeCredentials({ exchangeId });
            setAlert(`Successfully removed ${exchangeId} API key`, 'success', 2500);
        }
        catch (error) {
            setAlert(error || `Failed to remove ${exchangeId} credentials`, 'error');
        }
        setSelectedId(undefined);
    };
    return (_jsxs(Stack, { alignItems: !isLoading && !credentials?.length ? 'center' : 'stretch', children: [_jsxs(Typography, { variant: "h6", mb: 6, mx: 5, children: [_jsx(Typography, { component: "span", variant: "h6", color: "success.main", children: !isLoading && !credentials?.length ? 'Import' : 'Enabled' }), ' ', "API keys"] }), !isLoading && !credentials?.length ? (_jsx(Typography, { variant: "subtitle1", maxWidth: "350px", textAlign: "center", children: "Importing an API key from a supported exchange will enable EMME to place orders on your behalf." })) : (_jsx(EmmeTable, { rows: (credentials || []).map(({ exchangeId, key }) => ({
                    id: exchangeId,
                    key,
                    exchangeName: exchanges?.find(({ id }) => id === exchangeId)?.name,
                    button: {
                        onClick: handleAction(exchangeId),
                        disabled: isLoadingRemoveCredentials,
                        loading: selectedId === exchangeId,
                    },
                })), inputHeadCells: headCells, isLoading: isLoading, tableMinWidth: 250, customVerticalPadding: 2 })), _jsx(ApiKeyDialog, { isAccountPage: true, buttonText: "Import new API key", buttonProps: {
                    disabled: !exchanges?.length || isLoading,
                    sx: { mt: 9, alignSelf: 'center' },
                } })] }));
};
export default ApiKeys;
