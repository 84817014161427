import axios from 'axios';
import { Buffer } from 'buffer';
import { StorageKey } from '../model/enums';
import * as storage from './localstorage';
import { API_URL, COIN_API_URL, ENABLE_COOKIES, EXCHANGE_API_URL } from '../apiConfig';
function parseToken(tokenString) {
    const tokenPayloadBase64 = tokenString?.split('.')[1];
    const tokenPayload = Buffer.from(tokenPayloadBase64, 'base64').toString('ascii');
    return JSON.parse(tokenPayload);
}
function isTokenExpired(tokenString) {
    const expirationTime = parseToken(tokenString).exp;
    return Boolean(expirationTime) && expirationTime * 1000 < Date.now();
}
const getAxios = (requiresAuth, api) => {
    let token = storage.get(StorageKey.EMME_TOKEN);
    let baseURL = '';
    switch (api) {
        case 'exchange':
            baseURL = EXCHANGE_API_URL;
            break;
        case 'coin':
            baseURL = COIN_API_URL;
            break;
        default:
            baseURL = API_URL;
            break;
    }
    if (typeof token === 'object') {
        storage.clear();
        token = null;
    }
    if (requiresAuth && !token) {
        storage.clear();
        throw Error('API failed: No token found');
    }
    if (token && isTokenExpired(token)) {
        storage.clear();
        throw Error('API failed: Token expired');
    }
    const config = { baseURL, headers: {}, withCredentials: ENABLE_COOKIES };
    if (token && requiresAuth !== false) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return axios.create(config);
};
function handleResponseError(error) {
    if (error?.response?.status === 401) {
        storage.clear();
    }
    const msg = error?.response?.status === 401 && !error?.response?.data
        ? error?.response?.config?.url === '/users/login'
            ? 'Invalid credentials'
            : 'Authorization failed'
        : error?.response?.data;
    return msg || error?.message || 'API request failed';
}
export function createFetchFn({ requiresAuth, apiType, transformData, ...axiosConfig }) {
    return async (data) => {
        try {
            const axiosInst = getAxios(requiresAuth, apiType);
            const response = await axiosInst.request({
                data,
                ...axiosConfig,
            });
            return transformData ? transformData(response.data) : response.data;
        }
        catch (error) {
            const msg = handleResponseError(error?.response?.data || error);
            return Promise.reject(msg);
        }
    };
}
