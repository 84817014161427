import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { AlertContext } from 'context/AlertProvider';
import { useRemoveDevice, useUserDevices } from '@emme/shared/queries/Users';
import { ButtonCell, DateCell } from 'components/Table/cells';
import { EmmeTable } from 'components/Table';
const headCells = [
    { id: 'deviceName', label: 'Device Name' },
    {
        id: 'lastLoginAt',
        label: 'Last login',
        Component: DateCell,
    },
    { id: 'ipAddress', label: 'IP Address' },
    {
        id: 'button',
        label: 'Action',
        align: ColumnAlign.CENTER,
        Component: ButtonCell,
        componentProps: {
            label: 'Remove',
            endIcon: _jsx(DeleteOutlineOutlinedIcon, {}),
            sx: {
                border: (t) => `1px solid ${t.palette.error.main}`,
                backgroundColor: (t) => `${t.palette.error.main}20`,
                ':hover': {
                    backgroundColor: (t) => `${t.palette.error.main}60`,
                },
            },
        },
    },
];
const TrustedDevicesPage = () => {
    const [size, setSize] = useState(15);
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedId, setSelectedId] = useState(undefined);
    const { setAlert } = useContext(AlertContext);
    const { data: devices, isLoading, refetch } = useUserDevices({ size, pageNumber });
    const { mutateAsync: removeDevice, isLoading: isLoadingRemoveDevice } = useRemoveDevice();
    const handleChangePage = (newPage) => {
        setPageNumber(newPage);
    };
    const handleChangeRowsPerPage = (newPageSize) => {
        setSize(newPageSize);
        setPageNumber(0);
    };
    const handleAction = (id) => async () => {
        if (!id)
            return;
        setSelectedId(id);
        try {
            await removeDevice(id);
            setAlert('Successfully removed trusted device', 'success', 2500);
            refetch();
        }
        catch (error) {
            setAlert(error || 'Failed to remove trusted device', 'error');
        }
        setSelectedId(undefined);
    };
    return (_jsxs(Stack, { gap: 6, children: [_jsx(Typography, { variant: "h6", mx: 5, children: "Devices" }), _jsx(EmmeTable, { rows: (devices?.elements || []).map(({ id, deviceName, lastLoginAt, ipAddress }) => ({
                    id,
                    deviceName,
                    lastLoginAt,
                    ipAddress,
                    button: {
                        onClick: handleAction(id),
                        disabled: isLoadingRemoveDevice,
                        loading: selectedId === id,
                    },
                })), inputHeadCells: headCells, customVerticalPadding: 4, page: pageNumber, rowsPerPage: size, rowsPerPageOptions: [10, 15, 25], onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, totalLength: devices?.totalNumber, isLoading: isLoading, tableMinWidth: 250 })] }));
};
export default TrustedDevicesPage;
