import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { calculatePriceCurve, checkMaxValue, getPriceSpreadSegment } from 'utils/util';
export const PriceFunctionCell = ({ columnProps, data }) => {
    const currentSegment = 1;
    if (!data?.priceCurve)
        return _jsx(_Fragment, { children: "-" });
    const [minSpread, maxSpread] = getPriceSpreadSegment(data.priceCurve, currentSegment);
    const minSpreadPrice = calculatePriceCurve(data.priceCurve, Number(minSpread));
    const maxSpreadPrice = calculatePriceCurve(data.priceCurve, Number(maxSpread));
    const checkMaxOptions = { digits: 4, isPrice: true };
    return (_jsxs(Stack, { justifyContent: "center", alignItems: columnProps?.align || 'flex-end', gap: 2, children: [_jsxs(Typography, { variant: "body2", component: "span", children: [Number(minSpread).toFixed(2), "% - ", Number(maxSpread).toFixed(2), "%"] }), _jsx(Tooltip, { title: `$EMME/${data.coin || '-'} days`, disableInteractive: true, children: _jsxs(Typography, { component: "span", children: [checkMaxValue(minSpreadPrice, checkMaxOptions), " -", ' ', checkMaxValue(maxSpreadPrice, checkMaxOptions)] }) })] }));
};
PriceFunctionCell.alignment = ColumnAlign.RIGHT;
PriceFunctionCell.defaultProps = {
    columnProps: {},
};
