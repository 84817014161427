import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { RouteEnum } from '@emme/shared/model/enums';
import { AlertContext } from 'context/AlertProvider';
import { useResetPassword } from '@emme/shared/queries/Auth';
import { AuthPageLayout } from 'layout/AuthPage';
import ForgotPasswordForm from './components/ForgotPasswordForm';
const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const { setAlert } = useContext(AlertContext);
    const resetEmail = useResetPassword();
    const handleSubmit = async ({ email }) => {
        setIsSendingEmail(true);
        try {
            if (!email) {
                throw new Error();
            }
            let reCaptchaToken;
            if (executeRecaptcha) {
                reCaptchaToken = await executeRecaptcha('APPLICATION_PAGE_ACCOUNT_RECOVERY_CONFIRMATION_RESEND');
            }
            else {
                throw new Error();
            }
            await resetEmail.mutateAsync({ email, reCaptchaToken });
            setAlert(`If ${email} was used to create an account, instructions to reset your password will be sent  shortly. Please check your email.`, 'success', 5000);
            navigate(RouteEnum.Login, { replace: true });
        }
        catch (error) {
            setAlert(error?.message || error || 'Something went wrong', 'error');
            setIsSendingEmail(false);
        }
    };
    return (_jsx(AuthPageLayout, { columnTitle: "Recover Password", children: _jsx(ForgotPasswordForm, { onSubmit: handleSubmit, loading: isSendingEmail }) }));
};
export default ForgotPasswordPage;
