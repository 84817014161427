// fix for Invalid module name in augmentation, module '@mui/styles/defaultTheme' cannot be found.
import '@mui/styles';
/*                               0  1  2  3   4   5   6   7   8   9  10  11  12 */
const spacing = [0, 2, 4, 8, 12, 16, 20, 24, 32, 40, 50, 60, 80];
const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 1921,
    },
};
const defaultHTMLFontSize = 12;
const notoSansFontFamily = 'Noto-sans, sans-serif';
const poppinsFontFamily = 'Poppins, sans-serif';
const emmeH6 = {
    fontFamily: poppinsFontFamily,
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: 1.14,
};
const emmeBody1 = {
    fontWeight: 500,
    fontSize: 12,
    fontStyle: 'normal',
    lineHeight: 1,
};
export const emmeBody3 = {
    fontFamily: notoSansFontFamily,
    fontWeight: 400,
    fontSize: 10,
    fontStyle: 'normal',
    lineHeight: 1.4,
};
const emmeSubtitle1 = {
    fontFamily: poppinsFontFamily,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: 1.16,
};
export const themeBase = {
    typography: {
        htmlFontSize: defaultHTMLFontSize,
        fontFamily: notoSansFontFamily,
        fontSize: 12,
        h2: {
            fontFamily: poppinsFontFamily,
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: 30,
            lineHeight: 1.26,
        },
        h4: {
            fontFamily: poppinsFontFamily,
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: 20,
            lineHeight: 1,
        },
        h6: emmeH6,
        subtitle1: emmeSubtitle1,
        subtitle2: {
            fontFamily: poppinsFontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: 12,
            lineHeight: 1.16,
        },
        body1: emmeBody1,
        body2: {
            fontWeight: 500,
            fontSize: 10,
            fontStyle: 'italic',
            lineHeight: 1.1,
        },
        body3: emmeBody3,
    },
    breakpoints,
    spacing,
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h6: 'div',
                    h4: 'div',
                    subtitle1: 'div',
                    subtitle2: 'div',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 90,
                    textTransform: 'none',
                    fontFamily: poppinsFontFamily,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                sizeSmall: {
                    height: '20px',
                    ...emmeBody3,
                    'svg.MuiSvgIcon-root': {
                        fontSize: '12px',
                    },
                },
                sizeMedium: {
                    height: '28px',
                    ...emmeH6,
                },
                sizeLarge: {
                    height: '35px',
                    ...emmeH6,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    fontFamily: poppinsFontFamily,
                    fontWeight: 400,
                    lineHeight: 1,
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                        textAlign: 'right',
                        marginRight: 15,
                    },
                    '& input[type=number]::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                        textAlign: 'right',
                        marginRight: 15,
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                        textAlign: 'right',
                        marginRight: 15,
                    },
                },
                notchedOutline: {
                    borderWidth: '2px',
                },
                input: {
                    '&:-webkit-autofill::first-line': {
                        fontSize: defaultHTMLFontSize,
                        fontFamily: poppinsFontFamily,
                    },
                    '&:-webkit-autofill': {
                        transitionDelay: '99999s',
                        transitionProperty: 'background-color, color',
                        WebkitBoxShadow: '0 0 0 30px transparent inset !important',
                    },
                    paddingTop: '12.5px',
                    paddingBottom: '12.5px',
                },
                adornedEnd: {
                    ...emmeSubtitle1,
                },
            },
            defaultProps: {
                required: false,
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                },
                input: { py: 20 },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    transform: 'translate(14px, 13px) scale(1)',
                    '&[data-shrink="true"]': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                    },
                },
            },
            defaultProps: {
                required: false,
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '12px',
                    marginTop: '2px',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    borderStyle: 'solid',
                    margin: 5,
                    borderRadius: 90,
                    minWidth: 160,
                    padding: '6px 12px',
                    textTransform: 'none',
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                markLabel: {
                    '&[data-index="0"]': {
                        transform: 'translateX(0%)',
                    },
                    '&[data-index="1"]': {
                        transform: 'translateX(-100%)',
                    },
                    ...emmeBody3,
                },
                valueLabel: {
                    ...emmeBody3,
                },
            },
        },
        MuiPaper: {
            styleOverrides: { root: { backgroundImage: 'unset' } },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '@media screen and (min-width: 900px)': {
                    '& ::-webkit-scrollbar, & ::-webkit-scrollbar-corner, &::-webkit-scrollbar-track-piece': {
                        backgroundColor: 'transparent',
                        width: '8px',
                        height: '8px',
                    },
                    '& ::-webkit-scrollbar-thumb': {
                        // innerWidth: '12px',
                        // outerWidth: '20px',
                        width: '80px',
                        height: '70px',
                        backgroundColor: '#353945',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: '8px',
                    },
                },
            },
        },
        MuiAlert: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    alignItems: 'center',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '32px',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '32px',
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'none',
                color: 'secondary',
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                    border: 0,
                    ':first-of-type': {
                        paddingLeft: '16px',
                    },
                    ':last-of-type': {
                        paddingRight: '16px',
                    },
                },
                body: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    fontWeight: 500,
                    fontSize: 12,
                    fontStyle: 'normal',
                    lineHeight: 1,
                },
                displayedRows: {
                    fontWeight: 500,
                    fontSize: 12,
                    fontStyle: 'normal',
                    lineHeight: 1,
                },
                actions: {
                    svg: {
                        fontSize: 12,
                    },
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                placement: 'top',
            },
            styleOverrides: {
                popper: {
                    '&[data-popper-reference-hidden]': {
                        display: 'none',
                    },
                },
                tooltip: {
                    ...emmeBody1,
                    padding: '8px',
                    maxWidth: '300px',
                },
            },
        },
    },
};
