import { useMutation, useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeUsers = 30 * 1000;
export function useUserBalanceData() {
    return useQuery(['users', 'me', 'balance'], createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: '/users/me/balance',
    }), {
        staleTime: 10 * 1000,
        onError: (error) => error,
        refetchInterval: 10 * 1000,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
    });
}
export function useUserTransactionsBalance(params) {
    return useQuery(['users', 'me', 'balance', 'transactions'], createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: '/users/me/balance/transactions',
        params,
        transformData: (data) => data.transactions,
    }), {
        staleTime: intervalTimeUsers,
        onError: (error) => error,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
    });
}
export function useUserExchangesBalanceData(onboarding) {
    const balanceOnboarding = onboarding ? 2 * 60 * 1000 : intervalTimeUsers;
    return useQuery(['users', 'me', 'exchanges', 'balances'], createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: '/users/me/exchanges/balances',
        transformData: (data) => data.exchangeBalances,
    }), {
        staleTime: balanceOnboarding,
        onError: (error) => error,
        refetchInterval: balanceOnboarding,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
    });
}
export function useCheckUsersExchangeBalances() {
    return useMutation(createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: '/users/me/exchanges/balances',
        transformData: (data) => data.exchangeBalances,
    }), {
        mutationKey: ['check-user-exchange-balances'],
        retry: false,
    });
}
