import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { PlaceOrderTabContext, PlaceOrderTab } from 'context/PlaceOrderTabProvider';
import { OnboardingContext, OnboardingType } from 'context/OnboardingProvider';
import { ComingSoonTooltip } from 'components/Tooltips';
import OnboardingContentText from '../OnboardingContentText';
const Step1 = () => {
    const [, updatePlaceOrderTab] = useContext(PlaceOrderTabContext);
    const { onboardingType, activeStep, changeActiveStep } = useContext(OnboardingContext);
    const buttonText = onboardingType === OnboardingType.FIRST ? 'I am a' : 'Continue as';
    return (_jsxs(_Fragment, { children: [onboardingType === OnboardingType.WELCOME_BACK && (_jsx(OnboardingContentText, { mb: 5, children: "To refresh your memory, you can go through the onboarding process again or you can simply \"Skip Tour\" and continue where you've left." })), _jsxs(OnboardingContentText, { children: ["Providers earn $EMME using their own assets and exchange accounts.", _jsx("br", {}), "Subscribers spend $EMME to increase a market's liquidity.", _jsx("br", {}), "If you are not sure, you want to start as a", ' ', _jsx(Box, { component: "span", color: "error.main", children: "provider" }), "."] }), _jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "center", gap: 4, pt: 8, children: [_jsx(ComingSoonTooltip, { children: _jsx(Button, { type: "button", variant: "outlined", size: "small", color: "inherit", onClick: () => {
                                updatePlaceOrderTab(PlaceOrderTab.Subscribe);
                                changeActiveStep(activeStep + 1);
                            }, disabled: true, children: _jsxs(Typography, { children: [buttonText, " subscriber"] }) }) }), _jsx(Button, { type: "button", variant: "outlined", size: "small", color: "inherit", onClick: () => {
                            updatePlaceOrderTab(PlaceOrderTab.Provide);
                            changeActiveStep(activeStep + 1);
                        }, children: _jsxs(Typography, { children: [buttonText, " provider"] }) })] })] }));
};
export default Step1;
