/* eslint-disable import/no-extraneous-dependencies */
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { themeBase } from './themeBase';
import { customComponents, customPalette, customTypography } from './themeOptions';
const colors = {
    primaryMain: '#18A0FB',
    secondaryMain: '#58BD7D',
    info: '#FFD166',
    error: '#FF5C38',
    background: '#141416',
    emmeError: '#2F2020',
    emmePrimary: '#0D404C',
    emmeSecondary: '#0F8F57',
    emmeInfo: '#2B2C1E',
    neutralsOne: '#1A1C23',
    neutralsOneDark: '#18191D',
    neutralsTwo: '#23262F',
    neutralsThree: '#353945',
    neutralsFour: '#777E90',
    neutralsFive: '#B1B5C3',
    neutralsSix: '#E6E8EC',
    neutralsSeven: '#F4F5F6',
    neutralsEight: '#FCFCFD',
};
const createMuiTheme = (custom, options, ...args) => createTheme(deepmerge(custom, options), ...args);
const darkTheme = createMuiTheme({
    palette: { ...customPalette('dark', colors) },
}, { ...themeBase }, {
    typography: { ...customTypography(colors) },
    components: { ...customComponents(colors) },
});
export default darkTheme;
