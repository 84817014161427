export var NotificationTypeEnum;
(function (NotificationTypeEnum) {
    NotificationTypeEnum["WARNING"] = "WARNING";
    NotificationTypeEnum["INFO"] = "INFO";
    NotificationTypeEnum["ERROR"] = "ERROR";
    NotificationTypeEnum["SPECIAL"] = "SPECIAL";
})(NotificationTypeEnum || (NotificationTypeEnum = {}));
export var SpecialNotificationTypeEnum;
(function (SpecialNotificationTypeEnum) {
    SpecialNotificationTypeEnum["FIRST_ORDER"] = "FIRST_ORDER";
    SpecialNotificationTypeEnum["FIRST_GAIN"] = "FIRST_GAIN";
})(SpecialNotificationTypeEnum || (SpecialNotificationTypeEnum = {}));
