import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState, } from 'react';
import BigNumber from 'bignumber.js';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
const parseInput = (value) => new BigNumber(value.replaceAll(',', ''));
const formatKeepDecimals = (value) => {
    const [beforeDecimal, afterDecimal] = String(value).split('.');
    return (value &&
        `${new BigNumber(beforeDecimal || 0).toFormat(0)}${afterDecimal === undefined ? '' : '.'}${afterDecimal || ''}`);
};
const countSeparators = (value) => value.split(',').length - 1;
export default function CoinInputField({ decimals, min, max, clampRangeWhileTyping, coin, value, name, onChange, onKeyDown, onBlur, inputProps, FormHelperTextProps, setFieldError, ...props }) {
    const inputRef = useRef(null);
    const [cursor, setCursor] = useState(0);
    const [changeIncrement, setChangeIncrement] = useState(0);
    const lastFiredValue = useRef();
    const fireOnChange = useCallback((event, newValue, format, clamp) => {
        const bigNumberValue = parseInput(newValue);
        const violatedMinLimit = bigNumberValue.gt(0) && bigNumberValue.lt(min);
        const violatedMaxLimit = bigNumberValue.gt(max);
        const tooManyDecimals = newValue.indexOf('.') !== -1 && newValue.length - 1 - newValue.indexOf('.') > decimals;
        if (violatedMinLimit) {
            setFieldError(name, `Min value ${formatKeepDecimals(min)}`);
        }
        if (violatedMaxLimit) {
            setFieldError(name, `Max value ${formatKeepDecimals(max)}`);
        }
        if (newValue &&
            (bigNumberValue.isNaN() ||
                (!clamp && format && violatedMinLimit) ||
                (!clamp && violatedMaxLimit) ||
                tooManyDecimals)) {
            return false;
        }
        setFieldError(name, undefined);
        let processedValue;
        if (!newValue) {
            processedValue = '';
        }
        else if (clamp && format && !bigNumberValue.eq(0)) {
            processedValue = BigNumber.min(max, BigNumber.max(min, bigNumberValue)).toFixed(decimals);
        }
        else if (clamp && violatedMaxLimit) {
            processedValue = String(max);
        }
        else if (format) {
            processedValue = bigNumberValue.toFixed(decimals);
        }
        else {
            processedValue = newValue.replaceAll(',', '');
        }
        onChange &&
            onChange({
                ...event,
                type: 'change',
                target: { ...event.target, name, value: processedValue },
            });
        lastFiredValue.current = processedValue;
        return processedValue;
    }, [name, onChange, decimals, min, max, setFieldError]);
    const handleChange = useCallback((event) => {
        setChangeIncrement((c) => c + 1);
        const { selectionStart, value: inputValue } = event.target;
        const changedValue = fireOnChange(event, inputValue, false, clampRangeWhileTyping);
        const cursorPositionCorrection = changedValue
            ? countSeparators(new BigNumber(changedValue).toFormat().slice(0, selectionStart || 0)) -
                countSeparators(inputValue.slice(0, selectionStart || 0))
            : -1;
        setCursor((selectionStart || 0) + cursorPositionCorrection);
    }, [fireOnChange, clampRangeWhileTyping]);
    const handleKeyDown = useCallback((event) => {
        const step = `1e${-decimals}`;
        const inputValue = parseInput(event.target.value || '0');
        if (event.code === 'ArrowUp') {
            fireOnChange(event, inputValue.plus(step).toFixed(decimals), true, true);
        }
        else if (event.code === 'ArrowDown') {
            fireOnChange(event, inputValue.minus(step).toFixed(decimals), true, true);
        }
        onKeyDown && onKeyDown(event);
    }, [fireOnChange, decimals, onKeyDown]);
    const handleBlurFormat = useCallback((event) => {
        fireOnChange(event, inputRef.current.value, true, true);
        onBlur && onBlur(event);
    }, [fireOnChange, onBlur]);
    useEffect(() => {
        if (inputRef.current?.value && lastFiredValue.current !== value) {
            fireOnChange({}, inputRef.current.value, true, true);
        }
    }, [fireOnChange, value]);
    useEffect(() => {
        if (inputRef.current && inputRef.current === document.activeElement) {
            inputRef.current.setSelectionRange(cursor, cursor);
        }
    }, [cursor, changeIncrement]);
    return (_jsx(TextField, { inputRef: inputRef, value: formatKeepDecimals(value), onKeyDown: handleKeyDown, onChange: handleChange, onBlur: handleBlurFormat, inputProps: {
            ...inputProps,
            sx: { textAlign: 'right' },
            inputMode: 'decimal',
        }, InputProps: {
            endAdornment: (_jsx(InputAdornment, { position: "end", sx: { width: '60px' }, disableTypography: true, disablePointerEvents: true, children: coin })),
        }, FormHelperTextProps: {
            ...FormHelperTextProps,
            sx: {
                mx: 0,
                minHeight: 20,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                ...FormHelperTextProps?.sx,
            },
        }, fullWidth: true, ...props }));
}
CoinInputField.defaultProps = {
    decimals: 8,
    min: 0,
    max: Infinity,
    coin: undefined,
    clampRangeWhileTyping: false,
};
