import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import BreadCrumbRoutes from './BreadCrumbRoutes';
const TitleWithRoutes = ({ title, navigationList, phoneWidth, children, enableHome }) => (_jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: { height: '100px', mb: [2, 9] }, children: [_jsxs(Stack, { direction: "row", typography: "h4", alignItems: "center", spacing: 2, pl: [2, undefined, 0], children: [children, title] }), !phoneWidth && (_jsx(Typography, { variant: "subtitle2", align: "right", children: _jsx(BreadCrumbRoutes, { navigationList: navigationList, disableHome: !enableHome }) }))] }));
TitleWithRoutes.defaultProps = {
    phoneWidth: false,
    enableHome: false,
};
export default TitleWithRoutes;
