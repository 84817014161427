import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Lottie from 'lottie-react';
import Box from '@mui/material/Box';
import { PartyConeIcon } from '@emme/shared/icons';
const ConfettiAnimation = () => {
    const [animation, setAnimation] = useState(undefined);
    const load = useCallback(async () => {
        try {
            const res = await axios.get('data/confetti.json');
            setAnimation(res.data);
        }
        catch (error) {
            console.debug(error);
        }
    }, []);
    useEffect(() => {
        load();
    }, [load]);
    return (_jsxs(Box, { position: "relative", height: "150px", width: "150px", display: "flex", left: "10%", children: [animation && (_jsx(Lottie, { animationData: animation, loop: true, autoplay: true, style: { transform: 'matrix(0.71, 0.71, -0.71, 0.71, 0, 0)' } })), _jsx(Box, { position: "absolute", bottom: "15%", left: "15%", children: _jsx(PartyConeIcon, { width: "49px", height: "49px" }) })] }));
};
export default ConfettiAnimation;
