import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import { OnboardingContext } from 'context/OnboardingProvider';
import { ErrorLoadingButton, NoHoverButton } from 'components/Buttons';
const OnboardingFooter = () => {
    const { activeStep, showSkip, closeOnboarding, toggleSkip, allSteps } = useContext(OnboardingContext);
    if (activeStep === allSteps.length - 1) {
        return null;
    }
    const onClose = () => toggleSkip(false);
    return (_jsxs(Box, { display: "flex", alignItems: "center", justifyContent: "flex-end", children: [_jsx(NoHoverButton, { type: "button", color: "inherit", "aria-controls": "skip-onboarding", onClick: () => toggleSkip(true), sx: { mt: [7, undefined, 5] }, children: _jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "center", sx: {
                        borderBottom: '1px solid',
                        borderBottomColor: (t) => t.palette.neutralsSix.main,
                    }, children: [_jsx(Typography, { component: "span", children: "Skip Tour" }), _jsx(FastForwardOutlinedIcon, { fontSize: "small" })] }) }), _jsxs(Dialog, { open: showSkip, onClose: onClose, PaperProps: {
                    sx: {
                        width: '270px',
                        borderRadius: 3,
                        backgroundColor: (t) => t.palette.neutralOne.main,
                    },
                }, transitionDuration: 0, children: [_jsxs(DialogContent, { sx: { textAlign: 'center' }, children: [_jsx(FastForwardOutlinedIcon, { sx: { fontSize: '32px', mb: 4 } }), _jsx(Typography, { variant: "h6", children: "Are you sure you want to skip the tour?" })] }), _jsxs(DialogActions, { sx: { justifyContent: 'space-evenly', pt: 0, pb: 5 }, children: [_jsx(Button, { onClick: onClose, type: "button", variant: "outlined", size: "small", color: "inherit", children: "No, go back" }), _jsx(ErrorLoadingButton, { onClick: () => {
                                    onClose();
                                    closeOnboarding();
                                }, children: "Yes, skip" })] })] })] }));
};
export default OnboardingFooter;
