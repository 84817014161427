import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
const Footer = () => (_jsx(AppBar, { position: "static", elevation: 0, color: "inherit", component: "footer", sx: {
        top: 'auto',
        bottom: 0,
        borderTop: '1px solid',
        borderTopColor: (t) => t.palette.neutralsTwo.main,
        backgroundColor: (t) => t.palette.background.default,
        px: 3,
        height: '67px',
        justifyContent: 'center',
        alignItems: 'center',
    }, children: _jsxs(Typography, { variant: "subtitle1", component: "span", children: [`© ${new Date().getFullYear()} `, " Ethical Market Making Exchange\u2122. All rights reserved"] }) }));
export default Footer;
