import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { RouteEnum, UserExchangeCredentialsStatus } from '@emme/shared/model/enums';
import { useCurrentRoundData } from '@emme/shared/queries/Rounds';
import { EMMEHand } from '@emme/shared/icons';
import { useSelectedExchangeBalanceStatus } from 'hooks/useSelectedExchangeBalance';
import { PlaceOrderTab, PlaceOrderTabContext } from 'context/PlaceOrderTabProvider';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { AlertContext } from 'context/AlertProvider';
import { useAvailableBalance } from 'hooks/useAvailableBalance';
import MissingToken from 'components/MissingToken';
const ApiKeyOK = () => {
    const navigate = useNavigate();
    const { setAlert } = useContext(AlertContext);
    const { selectedLiquidityMarket } = useContext(LiquidityContext);
    const { disableBaseInput, disableQuoteInput, minBaseVolume, minQuoteVolume } = useAvailableBalance();
    const { data: currentRound } = useCurrentRoundData();
    const handleQuickClick = () => {
        if (disableBaseInput && disableQuoteInput) {
            setAlert(_jsxs(_Fragment, { children: [_jsxs(Box, { pb: 2, children: ["Insufficient ", selectedLiquidityMarket?.quoteCoin, " and", ' ', selectedLiquidityMarket?.baseCoin, " funds."] }), _jsxs(Box, { children: ["You need a minimum of ", minQuoteVolume.toFormat(8), " ", selectedLiquidityMarket?.quoteCoin, ' ', "and ", minBaseVolume.toFormat(8), " ", selectedLiquidityMarket?.baseCoin, " to be able to place quick provision order."] })] }), 'error');
            return;
        }
        navigate(RouteEnum.DashboardConfirmOrder);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Paper, { sx: {
                    backgroundColor: (t) => t.palette.neutralOne.main,
                    p: 5,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                }, children: [_jsx(EMMEHand, { width: 36, height: 36 }), _jsxs("div", { children: [_jsx(Typography, { variant: "subtitle1", fontWeight: "bold", color: "textPrimary", mb: 3, children: "Earn $EMME" }), _jsxs(Typography, { variant: "subtitle1", color: "textPrimary", children: ["Place a Provision Order and earn $EMME within", ' ', Math.floor((currentRound?.durationMs || 0) / 60000), " minutes!"] })] })] }), _jsxs(Stack, { gap: 3, children: [_jsx(Button, { color: "error", variant: "contained", size: "large", onClick: handleQuickClick, children: "Quick Provision Order" }), _jsx(Button, { color: "secondary", size: "large", component: RouterLink, to: RouteEnum.DashboardPlaceOrder, children: "Or place custom order" })] })] }));
};
const ApiKeyIncorrect = ({ status }) => (_jsxs(_Fragment, { children: [_jsx(MissingToken, { isInvalid: status === UserExchangeCredentialsStatus.INVALID }), _jsx(Button, { color: "secondary", size: "large", component: RouterLink, to: RouteEnum.DashboardPlaceOrder, sx: { flex: 'none' }, children: "Place custom order" })] }));
export function MobilePlaceOrderCTA() {
    const [, setTab] = useContext(PlaceOrderTabContext);
    const { data: exchangeStatus, isLoading: isLoadingExchangeBalance } = useSelectedExchangeBalanceStatus();
    const { isLoading: isLoadingAvailableBalance } = useAvailableBalance();
    const { isLoading: isLoadingRound } = useCurrentRoundData();
    const isLoading = isLoadingExchangeBalance || isLoadingAvailableBalance || isLoadingRound;
    useEffect(() => {
        if (exchangeStatus !== UserExchangeCredentialsStatus.OK) {
            setTab(PlaceOrderTab.Subscribe);
        }
    }, [setTab, exchangeStatus]);
    return (_jsxs(Stack, { gap: 10, height: "100%", justifyContent: "flex-end", children: [isLoading && (_jsxs(_Fragment, { children: [_jsx(Skeleton, { height: 80 }), _jsxs("div", { children: [_jsx(Skeleton, { height: 40 }), _jsx(Skeleton, { height: 40 })] })] })), !isLoading && exchangeStatus === UserExchangeCredentialsStatus.OK && _jsx(ApiKeyOK, {}), !isLoading && exchangeStatus !== UserExchangeCredentialsStatus.OK && (_jsx(ApiKeyIncorrect, { status: exchangeStatus }))] }));
}
