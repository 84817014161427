import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { DateCell } from './DateCell';
export const FromToDateCell = ({ columnProps: { hideLabels } = { hideLabels: false }, data, }) => (_jsxs(Stack, { alignItems: "stretch", gap: 2, maxWidth: "200px", children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", children: [!hideLabels && (_jsxs(Typography, { color: "textSecondary", component: "span", pr: 2, children: ["From:", ' '] })), _jsx(DateCell, { data: data.from })] }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", children: [!hideLabels && (_jsxs(Typography, { color: "textSecondary", component: "span", pr: 2, children: ["To:", ' '] })), _jsx(DateCell, { data: data.to })] })] }));
FromToDateCell.alignment = ColumnAlign.LEFT;
FromToDateCell.defaultProps = {
    columnProps: { hideLabels: false },
};
