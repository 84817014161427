import { jsx as _jsx } from "react/jsx-runtime";
import DisabledTooltip from './DisabledTooltip';
const PlaceOrderTooltip = ({ children, componentsProps, ...rest }) => (_jsx(DisabledTooltip, { componentsProps: {
        ...componentsProps,
        tooltip: {
            ...componentsProps?.tooltip,
            sx: {
                maxWidth: '290px',
                ...componentsProps?.tooltip?.sx,
            },
        },
    }, disableInteractive: true, enterDelay: 1000, enterNextDelay: 1000, ...rest, children: _jsx("span", { children: children }) }));
export default PlaceOrderTooltip;
