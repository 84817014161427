import { jsx as _jsx } from "react/jsx-runtime";
import Slider from '@mui/material/Slider';
import BigNumber from 'bignumber.js';
export function OverviewMarketSlider({ value, ...props }) {
    const valuePercentage = ((value - props.min) / (props.max - props.min)) * 100;
    const trackTopWidthComplement = (100 - Number(valuePercentage)) / 2;
    const roundedBottomTip = '46% 99%, 48% 99.5%, 50% 100%, 52% 99.5%, 54% 99%';
    return (_jsx(Slider, { orientation: "vertical", sx: {
            position: 'relative',
            height: '200px',
            width: '50px',
            borderRadius: 1,
            px: 1,
            '.MuiSlider-rail': {
                clipPath: `polygon(0% 0%, ${roundedBottomTip}, 100% 0%)`,
            },
            '.MuiSlider-track': {
                clipPath: `polygon(${trackTopWidthComplement}% 0%, ${roundedBottomTip}, ${100 - trackTopWidthComplement}% 0%)`,
                transition: 'height 50ms',
            },
            '.MuiSlider-thumb': {
                zIndex: 1,
                width: '100%',
                borderRadius: 1,
                '::before': {
                    content: `"$${new BigNumber(value).toFormat(0)}"`,
                    color: ({ palette }) => palette.text.primary,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                transition: 'bottom 50ms',
            },
            ...props.sx,
        }, value: value, ...props }));
}
