import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState, createContext } from 'react';
import { minOverviewCoinAmountUsd } from 'utils/util';
import { useOverviewStepErrors } from './hooks';
export var OverviewSteps;
(function (OverviewSteps) {
    OverviewSteps[OverviewSteps["SEARCH"] = 1] = "SEARCH";
    OverviewSteps[OverviewSteps["EXCHANGE"] = 2] = "EXCHANGE";
    OverviewSteps[OverviewSteps["MARKETS"] = 3] = "MARKETS";
    OverviewSteps[OverviewSteps["BALANCE"] = 4] = "BALANCE";
    OverviewSteps[OverviewSteps["RISK_LEVEL"] = 5] = "RISK_LEVEL";
    OverviewSteps[OverviewSteps["CONFIRMATION"] = 6] = "CONFIRMATION";
})(OverviewSteps || (OverviewSteps = {}));
export var OverviewStatus;
(function (OverviewStatus) {
    OverviewStatus[OverviewStatus["LOADING"] = 0] = "LOADING";
    OverviewStatus[OverviewStatus["ERROR"] = 1] = "ERROR";
    OverviewStatus[OverviewStatus["EARNING"] = 2] = "EARNING";
    OverviewStatus[OverviewStatus["WELCOME"] = 3] = "WELCOME";
    OverviewStatus[OverviewStatus["PAUSED"] = 4] = "PAUSED";
})(OverviewStatus || (OverviewStatus = {}));
export const OverviewContext = createContext({
    earningStatus: OverviewStatus.WELCOME,
    nextErrorStep: undefined,
    lastCompletedStep: 0,
    selectedStep: 0,
    getStepState: console.error,
    completeSelectedStep: console.error,
    changeSelectedStep: console.error,
    changeEarningStatus: console.error,
    selectedCoins: [],
    toggleCoinSelected: console.error,
    changeCoinAmount: console.error,
    riskLevel: undefined,
    setRiskLevel: console.error,
    preferredCoin: '',
    setPreferredCoin: console.error,
});
export function OverviewProvider({ children }) {
    const [lastCompletedStep, setLastCompletedStep] = useState(0);
    const [selectedStep, setSelectedStep] = useState(0);
    const [earningStatus, setEarningStatus] = useState(OverviewStatus.WELCOME);
    const [selectedCoins, setSelectedCoins] = useState([
        { id: 'BTC', amount: minOverviewCoinAmountUsd },
    ]);
    const [riskLevel, setRiskLevel] = useState();
    const [preferredCoin, setPreferredCoin] = useState('');
    const { stepErrors, isLoading } = useOverviewStepErrors(selectedCoins, riskLevel, lastCompletedStep);
    const firstErrorStep = stepErrors.findIndex((error) => error);
    const isInitialized = useRef(false);
    const completeSelectedStep = useCallback(() => {
        if (lastCompletedStep < OverviewSteps.CONFIRMATION) {
            setSelectedStep(selectedStep + 1);
        }
        if (lastCompletedStep < selectedStep) {
            setLastCompletedStep(selectedStep);
        }
    }, [selectedStep, lastCompletedStep, setLastCompletedStep]);
    const changeSelectedStep = useCallback((step) => {
        if (step && step <= lastCompletedStep + 1) {
            setSelectedStep(step);
        }
    }, [lastCompletedStep]);
    const getStepState = useCallback((step) => {
        if (stepErrors[step]) {
            return 'error';
        }
        if (step <= lastCompletedStep) {
            return 'completed';
        }
        return 'new';
    }, [lastCompletedStep, stepErrors]);
    const toggleCoinSelected = useCallback((coinName) => {
        setSelectedCoins((current) => {
            const coinIndex = current.findIndex(({ id }) => id === coinName);
            return coinIndex === -1
                ? // Add coin if it was not already selected
                    [...current, { id: coinName, amount: minOverviewCoinAmountUsd }]
                : // Remove coin if it was already selected
                    [...current.slice(0, coinIndex), ...current.slice(coinIndex + 1)];
        });
    }, []);
    const changeCoinAmount = useCallback((coinName, amount) => {
        setSelectedCoins((current) => {
            const coinIndex = current.findIndex(({ id }) => id === coinName);
            return coinIndex === -1
                ? current
                : [
                    ...current.slice(0, coinIndex),
                    { id: coinName, amount },
                    ...current.slice(coinIndex + 1),
                ];
        });
    }, []);
    useEffect(() => {
        setEarningStatus(firstErrorStep === -1 ? OverviewStatus.WELCOME : OverviewStatus.ERROR);
    }, [firstErrorStep]);
    useEffect(() => {
        // Skip if we don't have needed data yet or if we already initialized
        if (isLoading || isInitialized.current) {
            return;
        }
        // Set all steps up to the last OK step as completed
        const lastOKStep = firstErrorStep === -1 ? 0 : -1;
        setLastCompletedStep(lastOKStep);
        setSelectedStep(lastOKStep + 1);
        isInitialized.current = true;
    }, [isLoading, firstErrorStep]);
    const contextValue = useMemo(() => ({
        earningStatus: isLoading ? OverviewStatus.LOADING : earningStatus,
        nextErrorStep: firstErrorStep,
        lastCompletedStep,
        selectedStep,
        getStepState,
        completeSelectedStep,
        changeSelectedStep,
        changeEarningStatus: setEarningStatus,
        selectedCoins,
        toggleCoinSelected,
        changeCoinAmount,
        riskLevel,
        setRiskLevel,
        preferredCoin,
        setPreferredCoin,
    }), [
        earningStatus,
        isLoading,
        firstErrorStep,
        lastCompletedStep,
        selectedStep,
        getStepState,
        completeSelectedStep,
        changeSelectedStep,
        selectedCoins,
        toggleCoinSelected,
        changeCoinAmount,
        riskLevel,
        preferredCoin,
    ]);
    return _jsx(OverviewContext.Provider, { value: contextValue, children: children });
}
