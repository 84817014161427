import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as RouterLink } from 'react-router-dom';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
const useStyles = makeStyles((theme) => createStyles({
    navButton: {
        color: theme.palette.text.secondary,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.neutralsThree.main,
        },
        '&.Mui-selected': {
            color: 'inherit',
            backgroundColor: 'inherit',
            '&:hover': {
                backgroundColor: theme.palette.neutralsThree.main,
            },
        },
    },
}));
export const LinkRouter = (props) => (_jsx(Link, { underline: "always", ...props, component: RouterLink }));
const ListItemLink = ({ to, primaryText, iconChild: IconChild, phoneWidth, isSelected, }) => {
    const classes = useStyles();
    return (_jsxs(ListItemButton, { sx: phoneWidth
            ? undefined
            : { px: 3, ml: -3, mb: 5, borderRadius: '999px', display: 'inline-flex' }, component: RouterLink, to: to, divider: phoneWidth, selected: isSelected, className: classes.navButton, children: [IconChild !== undefined && (_jsx(ListItemAvatar, { sx: { minWidth: 'initial', mr: 3 }, children: _jsx(Avatar, { sx: {
                        backgroundColor: 'inherit',
                        color: 'inherit',
                        width: '24px',
                        height: '24px',
                        fontSize: '24px',
                        borderRadius: 'unset',
                    }, children: IconChild }) })), _jsx(ListItemText, { primary: primaryText, primaryTypographyProps: { variant: 'h6' } })] }));
};
ListItemLink.defaultProps = {
    iconChild: undefined,
    phoneWidth: false,
    isSelected: false,
};
export default ListItemLink;
