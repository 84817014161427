import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from 'context/AlertProvider';
import { useActivateAccount, useResendSignupEmail } from '@emme/shared/queries/Auth';
import { RouteEnum } from '@emme/shared/model/enums';
import { useActivationParams } from 'hooks/useActivationParams';
import { ActivationFailedPageLayout } from 'layout/AuthPage';
const AccountActivationPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isExpired, setIsExpired] = useState(false);
    const { mutateAsync: resend } = useResendSignupEmail();
    const { mutateAsync: onActivate } = useActivateAccount();
    const activationInfo = useActivationParams();
    const { setAlert } = useContext(AlertContext);
    const activate = useCallback(async () => {
        if (!activationInfo)
            return;
        try {
            await onActivate(activationInfo);
            setAlert('Account successfully activated', 'success', 2500);
            navigate(RouteEnum.Dashboard, { replace: true });
        }
        catch (error) {
            if (error === 'The code has expired') {
                setIsExpired(true);
            }
            else {
                setIsExpired(false);
                setAlert(error || 'Account activation failed', 'error');
            }
        }
        finally {
            setIsLoading(false);
        }
    }, [activationInfo]);
    useEffect(() => {
        activate();
    }, [activate]);
    return (_jsx(ActivationFailedPageLayout, { title: isExpired ? 'Activation Code has expired' : 'Something went wrong', onResend: resend, showResendBtn: isExpired, isLoading: isLoading, captcha: "APPLICATION_PAGE_CREATE_ACCOUNT_CONFIRMATION_RESEND", children: isExpired
            ? 'Account activation was unsuccessful.'
            : 'We were unable to activate your account. Please check your email and try again.' }));
};
export default AccountActivationPage;
