import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { ColumnAlign } from '@emme/shared/model/table.model';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { DebouncedSpreadContext, SpreadContext } from 'context/SpreadProvider';
import { calculatePriceProvisionArray } from 'utils/util';
import { useLiquidityMarketOrderBookSubscription, useLiquidityMarketOrderBookProvision, useLiquidityMarketsTickerData, } from '@emme/shared/queries/LiquidityMarkets';
import { CoinAmountCell } from 'components/Table/cells';
import { SpreadSlider } from 'components/Slider';
import { CoinIcon } from 'components/CoinIcon';
import { EmmeTable } from 'components/Table';
const subscriptionColor = (t) => t.palette.success.main;
const provisionColor = (t) => t.palette.error.main;
const MAX_ROWS = 50;
const OrderBookPriceFunctionCell = ({ columnProps, data: { spread, minSpread, maxSpread, price }, }) => {
    const spreadRow = () => {
        if (spread) {
            return `${new BigNumber(spread || '0').toFixed(2)}%`;
        }
        if (minSpread && maxSpread) {
            return `${new BigNumber(minSpread || '0').toFixed(2)}% - ${new BigNumber(maxSpread || '0').toFixed(2)}%`;
        }
        return '-';
    };
    return (_jsxs(Stack, { justifyContent: "center", alignItems: columnProps?.align || 'flex-end', gap: 2, color: columnProps?.color || 'inherit', children: [_jsx(Typography, { variant: "body2", component: "span", color: "inherit", children: spreadRow() }), _jsxs(Stack, { direction: "row", justifyContent: "center", alignItems: "center", children: [_jsx(Typography, { component: "span", color: "inherit", pr: 2, children: new BigNumber(price || 0).toFormat(8) }), _jsx(CoinIcon, { coin: "emme" })] })] }));
};
const OrderBookVolumeCell = ({ columnProps, data }) => (_jsx(Stack, { alignItems: "stretch", gap: 2, children: [data.quote, data.base].map((coinData, index) => (_jsx(CoinAmountCell, { columnProps: columnProps, data: coinData }, index ? 'quote' : 'base'))) }));
const headCellsSubscriptions = [
    {
        id: 'function',
        label: 'Price',
        Component: OrderBookPriceFunctionCell,
        componentProps: {
            align: 'flex-start',
            color: subscriptionColor,
        },
        align: ColumnAlign.LEFT,
    },
    {
        id: 'volumes',
        label: 'Volume',
        Component: OrderBookVolumeCell,
        align: ColumnAlign.RIGHT,
    },
];
const headCellsProvisions = [
    {
        id: 'function',
        label: 'Price',
        Component: OrderBookPriceFunctionCell,
        componentProps: {
            align: 'flex-start',
            color: provisionColor,
        },
        align: ColumnAlign.LEFT,
    },
    {
        id: 'volumes',
        label: 'Volume',
        Component: OrderBookVolumeCell,
        align: ColumnAlign.RIGHT,
    },
];
const OrderBook = () => {
    const topTableEl = useRef(null);
    const bottomTableEl = useRef(null);
    const [isResized, setIsResized] = useState(false);
    const [tableHeights, setTableHeights] = useState([0, 0]);
    const { selectedLiquidityMarket } = useContext(LiquidityContext);
    const { dashboardSpread, updateDashboardSpread } = useContext(SpreadContext);
    const { dashboardSpread: debouncedDashboardSpread } = useContext(DebouncedSpreadContext);
    const { data: orderBookSubscriptions, isLoading: isLoadingSubscriptions } = useLiquidityMarketOrderBookSubscription(selectedLiquidityMarket?.id || '');
    const { data: orderBookProvisions, isLoading: isLoadingProvisions } = useLiquidityMarketOrderBookProvision(selectedLiquidityMarket?.id || '');
    const { data: ticker } = useLiquidityMarketsTickerData(selectedLiquidityMarket?.id);
    useEffect(() => {
        const onResize = () => setIsResized(true);
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    });
    useEffect(() => {
        setTableHeights([
            topTableEl.current?.getBoundingClientRect().height || 0,
            bottomTableEl.current?.getBoundingClientRect().height || 0,
        ]);
        setIsResized(false);
    }, [isResized, topTableEl, bottomTableEl]);
    const provisionRows = useMemo(() => calculatePriceProvisionArray(orderBookProvisions, debouncedDashboardSpread)
        .slice(-MAX_ROWS)
        .map(({ id, minSpread, maxSpread, price, baseVolume, quoteVolume }) => ({
        id,
        function: {
            minSpread,
            maxSpread,
            price,
        },
        volumes: {
            base: {
                amount: baseVolume,
                coin: selectedLiquidityMarket?.baseCoin,
                color: provisionColor,
            },
            quote: {
                amount: quoteVolume,
                coin: selectedLiquidityMarket?.quoteCoin,
                color: provisionColor,
            },
        },
    })), [
        debouncedDashboardSpread,
        orderBookProvisions,
        selectedLiquidityMarket?.baseCoin,
        selectedLiquidityMarket?.quoteCoin,
    ]);
    const subscriptionRows = useMemo(() => (orderBookSubscriptions || []).slice(0, MAX_ROWS).map((data, index) => {
        const { spread, price, volume } = data;
        const quoteVolume = new BigNumber(volume).dividedBy(2);
        const baseVolume = quoteVolume.dividedBy(ticker?.exchangeLastTradePrice || 0);
        return {
            function: {
                spread,
                price,
            },
            volumes: {
                base: {
                    amount: baseVolume.toFixed(8),
                    coin: selectedLiquidityMarket?.baseCoin,
                    color: subscriptionColor,
                },
                quote: {
                    amount: quoteVolume.toFixed(8),
                    coin: selectedLiquidityMarket?.quoteCoin,
                    color: subscriptionColor,
                },
            },
            id: JSON.stringify(data) + index,
        };
    }), [
        orderBookSubscriptions,
        ticker?.exchangeLastTradePrice,
        selectedLiquidityMarket?.baseCoin,
        selectedLiquidityMarket?.quoteCoin,
    ]);
    return (_jsxs(Stack, { alignItems: "stretch", gap: 2, pb: 2, height: "100%", children: [_jsx(Typography, { variant: "h6", py: 4, px: 5, children: "Liquidity Order Book" }), _jsx(SpreadSlider, { value: dashboardSpread, onChange: updateDashboardSpread }), _jsx(Box, { children: _jsx(EmmeTable, { rows: [], inputHeadCells: headCellsProvisions, tableMinWidth: 300, hideBody: true }) }), _jsx(Box, { ref: topTableEl, sx: { flex: 'auto', flexBasis: 0, overflowY: 'auto' }, children: _jsx(EmmeTable, { rows: provisionRows, inputHeadCells: headCellsProvisions, tableMinWidth: 300, customVerticalPadding: 2, isLoading: !selectedLiquidityMarket || isLoadingProvisions, alternatingRowColors: true, limitRowsToHeight: tableHeights[0], customEmptyTableText: orderBookProvisions && orderBookProvisions?.length > 0
                        ? 'Choose different spread to view provision orders.'
                        : undefined, useEndRows: true }) }), _jsx(Divider, { variant: "middle", sx: {
                    mx: 5,
                    borderRadius: '1px',
                    borderWidth: '1px',
                    borderColor: (t) => t.palette.neutralsThree.main,
                    bgcolor: (t) => t.palette.neutralsThree.main,
                } }), _jsx(Box, { ref: bottomTableEl, sx: { flex: 'auto', flexBasis: 0, overflowY: 'auto' }, children: _jsx(EmmeTable, { rows: subscriptionRows, inputHeadCells: headCellsSubscriptions, tableMinWidth: 300, customVerticalPadding: 2, isLoading: !selectedLiquidityMarket || isLoadingSubscriptions, alternatingRowColors: true, limitRowsToHeight: tableHeights[1] }) })] }));
};
export default OrderBook;
