import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { RouteEnum } from '@emme/shared/model/enums';
import { useActivationParams } from 'hooks/useActivationParams';
import { AlertContext } from 'context/AlertProvider';
import { AuthPageLayout } from 'layout/AuthPage';
const ActivationFailedPageLayout = ({ isLoading, showResendBtn, children, title, captcha, onResend, }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const activationInfo = useActivationParams();
    const { setAlert } = useContext(AlertContext);
    const handleResendEmail = async () => {
        setIsSendingEmail(true);
        try {
            if (!activationInfo?.userId) {
                throw new Error('Missing user information, please re-signup');
            }
            let reCaptchaToken;
            if (executeRecaptcha) {
                reCaptchaToken = await executeRecaptcha(captcha);
            }
            else {
                throw new Error();
            }
            await onResend({ userId: activationInfo?.userId, reCaptchaToken });
            setAlert('An email will be sent shortly', 'success', 2500);
        }
        catch (error) {
            setAlert(error?.message || error || 'Something went wrong', 'error');
        }
        setIsSendingEmail(false);
    };
    return (_jsx(AuthPageLayout, { columnTitle: title, isLoading: isLoading, children: _jsxs(Stack, { spacing: 5, pt: 2, alignItems: "flex-start", children: [_jsx(Typography, { variant: "subtitle2", children: children }), showResendBtn && (_jsx(LoadingButton, { loading: isSendingEmail, onClick: handleResendEmail, type: "button", color: "secondary", sx: {
                        pl: 0,
                        '&:hover': { backgroundColor: 'inherit' },
                    }, children: _jsx(Typography, { variant: "subtitle2", children: "Resend email." }) })), _jsx(Link, { component: RouterLink, to: RouteEnum.Login, replace: true, variant: "subtitle2", children: "Go to Login page" })] }) }));
};
export default ActivationFailedPageLayout;
