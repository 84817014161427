import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { ProvisionStatus } from '@emme/shared/model/enums';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { formatSpread } from 'utils/util';
import { useLiquidityMarketsOrderTrades, useProvisionLiquidityMarketsTradeVerification, } from '@emme/shared/queries/LiquidityMarkets';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
import { AlertContext } from 'context/AlertProvider';
import { DialogTableLayout } from 'layout/Dialogs';
import { EmmeTable } from 'components/Table';
import { VolumeCell, DateCell, CoinWithUSDPriceCell, ButtonCell, PerformanceButtonCell, } from 'components/Table/cells';
import { ProvisionOrdersDialog } from 'components/Dialogs';
import { OrdersTable } from './OrdersTable';
const OrderTradesDialog = ({ liquidityMarket, selectedOrder, isProvisionSide, onClose, }) => {
    const [isLoadingProvision, setIsLoadingProvision] = useState(false);
    const [selectedTradeId, setSelectedTradeId] = useState();
    const [lastSelectedTradeId, setLastSelectedTradeId] = useState();
    const [size, setSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const containerRef = useRef(null);
    const [showMidTable, setShowMidTable] = useState(Boolean(containerRef));
    const { setAlert } = useContext(AlertContext);
    const { data: orderTrades, isLoading, isFetching, } = useLiquidityMarketsOrderTrades({
        liquidityMarketId: liquidityMarket?.id || '',
        side: isProvisionSide ? 'provisions' : 'subscriptions',
        orderId: selectedOrder?.id || '',
        size,
        pageNumber,
    });
    const { mutateAsync: verifyProvision } = useProvisionLiquidityMarketsTradeVerification();
    const { data: emmeCoin } = useMarketInfoLatestCoinData('emme');
    const onCloseProvisionOrdersDialog = () => {
        setSelectedTradeId(undefined);
    };
    const tradesHeadCells = [
        {
            id: 'createdAt',
            label: 'Time',
            Component: DateCell,
        },
        {
            id: 'price',
            label: `Price ($EMME/${liquidityMarket?.quoteCoin || '-'} days)`,
            Component: CoinWithUSDPriceCell,
        },
        {
            id: 'spread',
            label: 'Spread',
            align: ColumnAlign.CENTER,
        },
        {
            id: 'volume',
            label: 'Liquidity',
            Component: VolumeCell,
        },
        {
            id: 'earned',
            label: '$EMME',
            Component: CoinWithUSDPriceCell,
            componentProps: {
                addColor: true,
            },
        },
    ];
    const performanceButtonHeadCell = {
        id: 'button',
        label: 'Performance',
        Component: PerformanceButtonCell,
    };
    const backButtonHeadCell = {
        id: 'button',
        label: '',
        Component: ButtonCell,
    };
    const handleChangePage = (newPage) => {
        setPageNumber(newPage);
    };
    const handleChangeRowsPerPage = (newPageSize) => {
        setSize(newPageSize);
        setPageNumber(0);
    };
    const handleOpenProvisionOrder = async (row) => {
        setLastSelectedTradeId(row.id);
        setSelectedTradeId(row.id);
        if (row.provisionStatus === ProvisionStatus.SUCCESSFUL) {
            setShowMidTable(false);
            setTimeout(() => {
                setShowMidTable(true);
            }, 100);
            setIsLoadingProvision(false);
            return;
        }
        try {
            const data = await verifyProvision({
                liquidityMarketId: row?.marketId || '',
                liquidityTradeId: row?.id,
            });
            if (!data.provisions[0]) {
                setAlert('Could not fetch liquidity provision data', 'error');
            }
            const { roundIndex, attempts, state, error } = data.provisions[0];
            if (attempts > 0 || state === 'SUCCESSFUL') {
                setShowMidTable(false);
                setTimeout(() => {
                    setShowMidTable(true);
                }, 100);
            }
            else {
                if (state === 'RUNNING') {
                    setAlert(`Liquidity provision ${roundIndex} is running`, 'info');
                }
                if (state === 'FAILED') {
                    setAlert(error || `Liquidity provision ${roundIndex} failed`, 'error');
                }
                setSelectedTradeId(undefined);
            }
        }
        catch (error) {
            setAlert(error?.message || error, 'error');
        }
        setIsLoadingProvision(false);
    };
    const hasValidProvisionAttempts = selectedTradeId && !isLoadingProvision;
    return liquidityMarket && selectedOrder ? (_jsx(DialogTableLayout, { isOpen: true, title: `Trades in order ${selectedOrder.id}`, onClose: onClose, maxWidth: "lg", setMinHeight: true, children: _jsxs(Stack, { justifyContent: "flex-start", alignItems: "stretch", height: "100%", flexWrap: "nowrap", overflow: "hidden", children: [_jsx(Box, { children: _jsx(OrdersTable, { type: isProvisionSide ? 'provisions' : 'subscriptions', orders: [selectedOrder], liquidityMarket: liquidityMarket, customVerticalPadding: 3 }) }), _jsx(Divider, { sx: { pt: 2 } }), _jsxs(Stack, { height: "100%", overflow: "hidden", mt: 2, children: [_jsx(Box, { ref: containerRef, maxHeight: "100%", children: _jsx(Slide, { direction: "up", in: showMidTable, timeout: { appear: 500, enter: 200, exit: 0 }, appear: false, container: containerRef?.current, children: _jsx(Box, { height: "100%", children: _jsx(EmmeTable, { rows: (orderTrades?.elements || []).map((data) => ({
                                            id: data.id,
                                            createdAt: data.createdAt,
                                            price: {
                                                value: data.price,
                                                usdPrice: emmeCoin?.priceUsd,
                                                isPrice: true,
                                            },
                                            spread: formatSpread(Number(data.spread)),
                                            volume: {
                                                base: {
                                                    amount: data.baseVolume,
                                                    coin: liquidityMarket?.baseCoin,
                                                },
                                                quote: {
                                                    amount: data.quoteVolume,
                                                    coin: liquidityMarket?.quoteCoin,
                                                },
                                            },
                                            earned: {
                                                value: data.provisionPayment,
                                                usdPrice: emmeCoin?.priceUsd,
                                            },
                                            button: hasValidProvisionAttempts
                                                ? {
                                                    label: 'Back',
                                                    onClick: () => {
                                                        setSelectedTradeId(undefined);
                                                    },
                                                    startIcon: _jsx(KeyboardArrowLeftOutlinedIcon, { sx: { mr: -2 } }),
                                                }
                                                : {
                                                    onClick: () => {
                                                        setIsLoadingProvision(true);
                                                        handleOpenProvisionOrder(data);
                                                    },
                                                    loading: data?.id === selectedTradeId ? isLoadingProvision : false,
                                                    provisionStatus: data.provisionStatus,
                                                    provisionPerformance: data.provisionPerformance,
                                                },
                                        })), inputHeadCells: (hasValidProvisionAttempts
                                            ? [backButtonHeadCell]
                                            : [])
                                            .concat(tradesHeadCells)
                                            .concat(hasValidProvisionAttempts ? [] : [performanceButtonHeadCell]), collapsibleRowOpen: hasValidProvisionAttempts ? selectedTradeId : undefined, page: pageNumber, rowsPerPage: size, rowsPerPageOptions: [5, 10, 15], onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, selectedRowId: lastSelectedTradeId, totalLength: orderTrades?.totalNumber, isLoading: isLoading && isFetching, customVerticalPadding: 3, stickyHeader: true }) }) }) }), hasValidProvisionAttempts && (_jsx(Box, { pt: 2, overflow: "hidden", height: "100%", width: "100%", children: _jsx(ProvisionOrdersDialog, { liquidityMarket: liquidityMarket, liquidityTradeId: selectedTradeId, onProvisionsFetched: onCloseProvisionOrdersDialog, onlyTable: true }) }))] })] }) })) : null;
};
OrderTradesDialog.defaultProps = {
    selectedOrder: undefined,
};
export default OrderTradesDialog;
