import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import Brightness2OutlinedIcon from '@mui/icons-material/Brightness2Outlined';
import { RouteEnum, StorageKey } from '@emme/shared/model/enums';
import { storage } from '@emme/shared';
import { ENABLE_THEMES, OVERVIEW_PAGE, WIDGET_PAGE } from 'config';
import { ThemeContext, ThemeType } from 'context/ThemeProvider';
import { SocketContext } from 'context/SocketProvider';
import { NoHoverButton } from 'components/Buttons';
import NotificationsMenu from './NotificationsMenu';
import { HelpDialog } from './HelpDialog';
const CustomNavbarButton = ({ children, ...rest }) => {
    const { pathname } = useLocation();
    const isSelected = pathname.includes(rest.to);
    return (_jsx(NoHoverButton, { color: "inherit", type: "button", disableFocusRipple: true, disableRipple: true, sx: {
            px: 5,
            ':focus': {
                background: ({ palette }) => palette.neutralsTwo.main,
            },
        }, component: rest.to ? RouterLink : undefined, ...rest, children: _jsx(Typography, { variant: "h6", color: isSelected ? 'textPrimary' : 'inherit', fontWeight: isSelected ? 'bold' : undefined, children: children }) }));
};
const LoggedInLinks = ({ phoneWidth, user, handleLogout }) => {
    const { theme, setTheme } = useContext(ThemeContext);
    const { isOpen, sendMessage } = useContext(SocketContext);
    useEffect(() => {
        if (isOpen && user?.id) {
            const token = storage.get(StorageKey.EMME_TOKEN);
            sendMessage({
                service: 'NOTIFICATION',
                type: 'SUBSCRIBE',
                dataRequest: { token, userId: user.id },
            });
        }
        return () => sendMessage({ service: 'NOTIFICATION', type: 'UNSUBSCRIBE' });
    }, [isOpen]);
    return (_jsxs(_Fragment, { children: [!phoneWidth && (_jsxs(_Fragment, { children: [WIDGET_PAGE && _jsx(CustomNavbarButton, { to: RouteEnum.Widget, children: "Widgets" }), OVERVIEW_PAGE && (_jsx(CustomNavbarButton, { to: RouteEnum.Overview, children: "Overview" })), _jsx(CustomNavbarButton, { to: RouteEnum.Dashboard, children: "Dashboard" }), _jsx(CustomNavbarButton, { to: RouteEnum.Holdings, children: "Holdings" }), _jsx(CustomNavbarButton, { to: RouteEnum.Account, children: "My Account" }), _jsx(CustomNavbarButton, { onClick: handleLogout, children: "Logout" })] })), ENABLE_THEMES && (_jsx(IconButton, { color: "inherit", sx: { mr: 3 }, onClick: () => setTheme(theme === ThemeType.darkThemePref), size: "small", children: theme === ThemeType.darkThemePref ? (_jsx(Brightness2OutlinedIcon, { fontSize: "small" })) : (_jsx(Brightness4OutlinedIcon, { fontSize: "small" })) })), _jsx(HelpDialog, {}), _jsx(NotificationsMenu, {})] }));
};
LoggedInLinks.defaultProps = {
    user: undefined,
};
export default LoggedInLinks;
