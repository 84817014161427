import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteEnum } from '@emme/shared/model/enums';
import { PlaceOrderTab, PlaceOrderTabContext } from 'context/PlaceOrderTabProvider';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { ConfirmOrderContext } from 'context/ConfirmOrderProvider';
import { useLiquidityMarketOrderBookSubscription } from '@emme/shared/queries/LiquidityMarkets';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { useAvailableBalance } from 'hooks/useAvailableBalance';
import { ConfirmOrder } from '../components/PlaceOrder';
import { processProvisionFormData, getAutofillProvisionFormData, } from '../components/PlaceOrder/util';
const MobileConfirmOrderPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const orderDataInitialized = useRef(false);
    const { orderData, confirmAndPlaceOrder } = useContext(ConfirmOrderContext);
    const [activeTab, setActiveTab] = useContext(PlaceOrderTabContext);
    const { selectedLiquidityMarket } = useContext(liquidityContext);
    const { data: orderBookSubscriptions, isLoading: isLoadingOrderbook } = useLiquidityMarketOrderBookSubscription(selectedLiquidityMarket?.id || '');
    const { disableBaseInput, disableQuoteInput, minQuoteVolume, minBaseVolume } = useAvailableBalance();
    const allInitialized = !isLoadingOrderbook && selectedLiquidityMarket;
    const openedFromOnboarding = Boolean(location.state?.onboarding);
    useEffect(() => {
        if (activeTab !== PlaceOrderTab.Provide) {
            setActiveTab(PlaceOrderTab.Provide);
        }
    }, [activeTab, setActiveTab]);
    const placeAutofillOrder = useCallback(async () => {
        try {
            await confirmAndPlaceOrder({
                data: processProvisionFormData(getAutofillProvisionFormData(orderBookSubscriptions || [], minQuoteVolume, minBaseVolume, undefined, disableQuoteInput, disableBaseInput), selectedLiquidityMarket.id),
                type: 'provisions',
                onboarding: openedFromOnboarding,
            });
            navigate(RouteEnum.Dashboard);
        }
        catch {
            navigate(RouteEnum.Dashboard);
        }
    }, [
        openedFromOnboarding,
        confirmAndPlaceOrder,
        orderBookSubscriptions,
        minQuoteVolume,
        minBaseVolume,
        disableQuoteInput,
        disableBaseInput,
        selectedLiquidityMarket,
        navigate,
    ]);
    useEffect(() => {
        if (!orderData && allInitialized && !orderDataInitialized.current) {
            placeAutofillOrder();
            orderDataInitialized.current = true;
        }
    }, [orderData, allInitialized, placeAutofillOrder]);
    return orderData && activeTab === PlaceOrderTab.Provide ? (_jsx(ConfirmOrder, {})) : (_jsx(LoadingOverlay, { isLoading: true, size: 100, sx: { minHeight: '100vh' } }));
};
export default MobileConfirmOrderPage;
