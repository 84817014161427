import { useMutation, useQueryClient } from '@tanstack/react-query';
import { StorageKey } from '../../model/enums';
import { createFetchFn } from '../../utils/axios';
import * as storage from '../../utils/localstorage';
export function useLogin() {
    const queryClient = useQueryClient();
    return useMutation(createFetchFn({
        requiresAuth: false,
        method: 'POST',
        url: '/users/login',
    }), {
        mutationKey: ['login-user'],
        retry: false,
        onSuccess: (data) => {
            if (data?.token?.token) {
                storage.set(StorageKey.EMME_TOKEN, data.token?.token);
                queryClient.fetchQuery(['users', 'me']);
            }
        },
    });
}
export function useLogout() {
    const queryClient = useQueryClient();
    return useMutation(createFetchFn({
        requiresAuth: true,
        method: 'POST',
        url: '/users/logout',
    }), {
        mutationKey: ['logout-user'],
        retry: false,
        onSuccess: () => {
            storage.clear();
            queryClient.setQueriesData(['users'], () => null);
            queryClient.removeQueries(['users']);
            queryClient.removeQueries(['liquidity-markets']);
        },
    });
}
