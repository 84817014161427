import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { StorageKey } from '../../model/enums';
import { createFetchFn } from '../../utils/axios';
import * as storage from '../../utils/localstorage';
export function useUserInfoData() {
    const queryClient = useQueryClient();
    useEffect(() => {
        const checkTokenStorage = ({ key }) => {
            if (key === StorageKey.EMME_TOKEN) {
                queryClient.fetchQuery(['users', 'me']);
            }
        };
        window.addEventListener('storage', checkTokenStorage);
        return () => window.removeEventListener('storage', checkTokenStorage);
    }, [queryClient]);
    return useQuery(['users', 'me'], createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: '/users/me',
    }), {
        staleTime: 60 * 60 * 1000,
        onError: (error) => error,
        retry: false,
    });
}
export function useChangeUserPassword() {
    const queryClient = useQueryClient();
    return useMutation(createFetchFn({
        requiresAuth: true,
        method: 'POST',
        url: '/users/me/password/change',
    }), {
        mutationKey: ['update-password'],
        retry: false,
        onSuccess: (data) => {
            if (data?.token) {
                storage.set(StorageKey.EMME_TOKEN, data.token);
                queryClient.fetchQuery(['users', 'me']);
            }
        },
    });
}
export function useUpdateUserOnboarding() {
    const queryClient = useQueryClient();
    return useMutation((finished) => createFetchFn({
        requiresAuth: true,
        method: 'PUT',
        url: '/users/me/onboarding',
        data: { finished },
    })(), {
        mutationKey: ['update-onboarding'],
        retry: false,
        onMutate: (finished) => {
            queryClient.setQueryData(['users', 'me'], (user) => ({
                ...user,
                onboardingFinished: finished,
            }));
        },
        onError: (error, finished) => {
            queryClient.setQueryData(['users', 'me'], (user) => ({
                ...user,
                onboardingFinished: !finished,
            }));
        },
    });
}
