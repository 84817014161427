import { useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeCoins = 2 * 60 * 1000;
export function useMarketInfoLatestCoinData(coin) {
    return useQuery(['coins', 'market-info', 'latest', coin], createFetchFn({
        requiresAuth: false,
        apiType: 'coin',
        method: 'GET',
        url: `/coins/${coin}/market-info/latest`,
    }), {
        staleTime: intervalTimeCoins,
        onError: (error) => error,
        refetchInterval: intervalTimeCoins,
        refetchOnWindowFocus: true,
        enabled: Boolean(coin),
    });
}
// coins is a string of coins separated with comma
export function useMarketInfoCoinsData(coins) {
    return useQuery(['coins', 'market-info', coins], createFetchFn({
        requiresAuth: false,
        apiType: 'coin',
        method: 'GET',
        url: '/coins/market-info',
        params: { symbols: coins },
        transformData: (data) => data.coinsMarketInfo,
    }), {
        staleTime: intervalTimeCoins,
        onError: (error) => error,
        refetchInterval: intervalTimeCoins,
        refetchOnWindowFocus: true,
        enabled: Boolean(coins),
    });
}
