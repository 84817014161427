import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import FiberSmartRecordOutlined from '@mui/icons-material/FiberSmartRecordOutlined';
import { AlertContext } from 'context/AlertProvider';
const DepositForm = ({ userAddress }) => {
    const { setAlert } = useContext(AlertContext);
    return (_jsxs(Stack, { alignItems: "center", width: "384px", children: [_jsx(Avatar, { sx: {
                    width: '60px',
                    height: '60px',
                    bgcolor: (theme) => theme.palette.emmeSecondary.main,
                }, children: _jsx(AccountBalanceWalletOutlinedIcon, { color: "action" }) }), _jsx(Typography, { variant: "h6", mt: 8, children: "Address" }), _jsx(Typography, { variant: "subtitle1", align: "center", sx: { mt: 4, mb: 8 }, children: "Only send EMMEs to this address. Sending any other asset to this address may result in the loss of your deposit!" }), _jsx(TextField, { "aria-label": "Address", value: userAddress, placeholder: "User wallet address", disabled: true, InputProps: {
                    endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { type: "button", onClick: () => {
                                navigator.clipboard.writeText(userAddress);
                                setAlert(`Copied ${userAddress}`, 'success', 2500);
                            }, edge: "end", disableRipple: true, tabIndex: -1, disabled: !userAddress, children: _jsx(FiberSmartRecordOutlined, { sx: {
                                    fontSize: '16px',
                                } }) }) })),
                } }), _jsx(Typography, { variant: "body3", color: "textSecondary", align: "center", mt: 8, children: "Please be sure that the contract address is related to the tokens that you are depositing." })] }));
};
export default DepositForm;
