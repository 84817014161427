import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { PlaceOrderTooltip } from 'components/Tooltips';
import { ToggleQuickButton } from './ToggleQuickButton';
const maxDuration = 1000; // 1000 days
const durationRegex = /^((\d+d\s*)?(\s*)?(\d+h\s*)?(\s*)?(\d+m)?|\d*)$/;
const incompleteDurationSuffixRegex = /[d|h]\s?\d+$/;
const quickButtons = [
    { amount: '30d', amountName: 'Month' },
    { amount: '7d', amountName: 'Week' },
    { amount: '1d', amountName: 'Day' },
];
export const checkDurationInputValidity = ({ formDataDuration, currentRoundDurationMs, }) => {
    if (!formDataDuration || !currentRoundDurationMs)
        return false;
    const matchArray = formDataDuration.match(durationRegex);
    const currentDurationMin = currentRoundDurationMs / 60;
    let isValid = false;
    if (matchArray) {
        for (let index = 6; index >= 2; index -= 2) {
            const element = matchArray[index];
            const currentMatchValue = parseInt(element?.replace(/[^0-9]/g, '') || '0', 10);
            switch (index) {
                case 6:
                    if (currentMatchValue >= currentDurationMin) {
                        isValid = true;
                    }
                    break;
                case 4:
                    if (currentMatchValue >= currentDurationMin / 60) {
                        isValid = true;
                    }
                    break;
                default:
                    if (currentMatchValue >= currentDurationMin / 60 / 24) {
                        isValid = true;
                    }
                    break;
            }
            if (isValid) {
                index = 0;
            }
        }
    }
    return isValid;
};
const calculateDurationArray = (matchArray) => {
    const test = [];
    const temp = [0, 0, 0];
    for (let index = 6; index >= 2; index -= 2) {
        const element = matchArray[index]?.replace(/\s/g, '') || '0';
        const currentNumber = parseInt(element, 10);
        let value = 0;
        switch (index) {
            case 2:
                value = currentNumber + temp[1];
                break;
            case 4:
                temp[1] = Math.floor((currentNumber + temp[2]) / 24);
                value = currentNumber - temp[1] * 24 + temp[2];
                break;
            default:
                temp[2] = Math.floor(currentNumber / 60);
                value = currentNumber - temp[2] * 60;
                break;
        }
        test.unshift(`${value}`);
    }
    return test;
};
export const getFullDurationText = (value, includeZeroValues = true) => {
    const matchArray = value.match(durationRegex);
    return matchArray
        ? calculateDurationArray(matchArray)
            .map((test, index) => {
            switch (index) {
                case 0:
                    test += ' day';
                    break;
                case 1:
                    test += ' hour';
                    break;
                default:
                    test += ' minute';
                    break;
            }
            return `${test}${parseInt(test, 10) === 1 ? '' : 's'}`;
        })
            .filter((text) => (includeZeroValues ? true : !text.startsWith('0')))
            .join(', ')
        : '';
};
export const getDurationInDays = (value) => {
    const newValue = value.match(durationRegex);
    if (!value || !newValue) {
        return 0;
    }
    const days = parseInt(newValue[2] || '0', 10);
    const hours = parseInt(newValue[4] || '0', 10);
    const minutes = parseInt(newValue[6] || '0', 10);
    return days + hours / 24 + minutes / 1440;
};
function formatOrderDuration(value) {
    if (value === '1') {
        return '1h';
    }
    const matchArray = value.match(durationRegex);
    if (matchArray) {
        const tests = calculateDurationArray(matchArray);
        const isMaxDuration = parseInt(tests[0], 10) >= maxDuration;
        return tests
            .map((test, index) => {
            if ((index !== 0 && isMaxDuration) || parseInt(test, 10) === 0) {
                return undefined;
            }
            switch (index) {
                case 0:
                    test = isMaxDuration ? `${maxDuration}` : test;
                    test += 'd';
                    break;
                case 1:
                    test += 'h';
                    break;
                default:
                    test += 'm';
                    break;
            }
            return `${test}`;
        })
            .filter((v) => v !== undefined)
            .join(' ');
    }
    return '';
}
const DurationQuickButton = ({ children, ...rest }) => (_jsx(ToggleQuickButton, { sx: { my: 3 }, ...rest, children: children }));
const DurationInput = ({ setFieldValue, handleChange, setFieldError, name, ...rest }) => {
    const [isUsingCustomDuration, setIsUsingCustomDuration] = useState(false);
    const helperValue = getFullDurationText(rest.value);
    const { tablet } = useBreakpoint();
    const handleInputChange = async (e) => {
        const value = e?.target?.value;
        const suffixMatch = value.match(incompleteDurationSuffixRegex);
        let regexMatch;
        if (suffixMatch && suffixMatch.index !== undefined) {
            regexMatch = value.slice(0, suffixMatch.index + 1).match(durationRegex);
        }
        else {
            regexMatch = value.match(durationRegex);
        }
        if (!regexMatch) {
            setFieldError('Incorrect duration format');
            return;
        }
        e.target.value = value
            .trimStart()
            .replace(/\s\s+/g, ' ')
            .replace(/([d|h])(\d)$/, '$1 $2');
        handleChange(e);
        const maxValue = formatOrderDuration(value);
        if (value !== '1' && maxValue && value.trimEnd() !== maxValue && !suffixMatch) {
            setFieldValue(maxValue);
        }
    };
    const handleCustomQuickClick = () => {
        !rest.value && setFieldValue('7d');
        !isUsingCustomDuration && setFieldError(undefined);
        setIsUsingCustomDuration(!isUsingCustomDuration);
    };
    const handleQuickClick = (value) => {
        setIsUsingCustomDuration(false);
        setFieldValue(value);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { direction: "row", typography: "subtitle1", alignItems: "center", spacing: 1, children: [!tablet && (_jsx(PlaceOrderTooltip, { title: "Maximum amount of time you would like your order to stay open until it closes automatically.", disabledColor: rest.disabled, placement: "top-start", children: _jsx(InfoOutlinedIcon, { fontSize: "inherit", color: "inherit", sx: { cursor: 'help', display: 'flex' } }) })), _jsx(Box, { color: tablet ? 'text.primary' : undefined, component: "span", children: "Duration" })] }), _jsxs(Box, { display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: 4, mb: !isUsingCustomDuration ? 3 : undefined, children: [quickButtons.map(({ amount, amountName }) => (_jsx(DurationQuickButton, { onClick: () => handleQuickClick(amount), isActive: rest.value === amount && !isUsingCustomDuration, children: amountName }, amount))), _jsx(PlaceOrderTooltip, { title: "Set Custom Duration", placement: "top-end", children: _jsx(DurationQuickButton, { onClick: handleCustomQuickClick, isActive: isUsingCustomDuration, children: "Custom" }) })] }), isUsingCustomDuration && (_jsx(TextField, { onBlur: (e) => {
                    const value = e?.target?.value;
                    const suffixMatch = value.match(incompleteDurationSuffixRegex);
                    const formattedValue = formatOrderDuration(suffixMatch?.index ? value.slice(0, suffixMatch.index + 1) : value);
                    if (value !== formattedValue) {
                        setFieldError('Incorrect duration format');
                    }
                    setFieldValue(formattedValue);
                    if (quickButtons.some(({ amount }) => amount === value)) {
                        setIsUsingCustomDuration(false);
                    }
                    if (rest.onBlur) {
                        rest.onBlur(e);
                    }
                }, onChange: handleInputChange, name: name, required: true, InputProps: {
                    startAdornment: (_jsx(InputAdornment, { position: "start", sx: {
                            color: (t) => t.palette.text.secondary,
                            width: '60px',
                        }, disablePointerEvents: true, disableTypography: true, children: "1d 1h 1m" })),
                }, fullWidth: true, helperText: helperValue ? (_jsx(Typography, { variant: "body3", color: "text.secondary", children: helperValue })) : (' '), FormHelperTextProps: {
                    sx: { ml: 0 },
                }, ...rest, sx: {
                    mt: 5,
                    '.MuiOutlinedInput-root': {
                        pl: 3,
                        input: {
                            borderLeft: '2px solid',
                            borderLeftColor: (t) => t.palette.neutralOne.dark,
                            pl: 3,
                        },
                    },
                    ...rest.sx,
                } }))] }));
};
export default DurationInput;
