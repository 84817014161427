import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BigNumber from 'bignumber.js';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import withStyles from '@mui/styles/withStyles';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { ProvisionStatus } from '@emme/shared/model/enums';
import { ButtonCell } from '.';
const VerticalLinearProgress = withStyles((theme) => ({
    root: {
        borderRadius: 20,
        width: 3,
        height: '100%',
    },
    colorPrimary: {
        backgroundColor: theme.palette.neutralsThree.main,
    },
    bar: {
        borderRadius: 10,
        transform: ({ value }) => `translateY(${100 - value}%) !important`,
        backgroundColor: theme.palette.secondary.main,
    },
}))(LinearProgress);
export const getColorByProvisionStatus = (data) => {
    switch (data) {
        case ProvisionStatus.PENDING:
            return (t) => t.palette.warning.main;
        case ProvisionStatus.UNKNOWN:
        case ProvisionStatus.FAILED:
            return (t) => t.palette.error.main;
        default:
            break;
    }
};
export const PerformanceButtonCell = ({ columnProps = {}, data: { provisionStatus, provisionPerformance, ...buttonProps }, }) => {
    const success = provisionStatus === ProvisionStatus.SUCCESSFUL;
    const failed = !success && provisionStatus !== ProvisionStatus.PENDING;
    const progress = provisionStatus !== ProvisionStatus.PENDING
        ? new BigNumber(JSON.parse(provisionPerformance) || 0).multipliedBy(100).toNumber()
        : undefined;
    return (_jsxs(Stack, { direction: "row", alignItems: "stretch", justifyContent: "center", height: "100%", children: [_jsx(ButtonCell, { columnProps: columnProps, data: {
                    label: success ? 'Verify' : !failed ? 'Pending' : 'Failed',
                    sx: {
                        borderColor: getColorByProvisionStatus(provisionStatus) || 'inherit',
                    },
                    ...buttonProps,
                } }), _jsx(Tooltip, { title: !progress ? '' : `${progress}%`, disableInteractive: true, children: _jsx(Box, { minWidth: "3px", display: "flex", alignItems: "center", ml: 1, color: "neutralsThree.main", children: success && _jsx(VerticalLinearProgress, { variant: "determinate", value: progress || 0 }) }) })] }));
};
PerformanceButtonCell.alignment = ColumnAlign.CENTER;
PerformanceButtonCell.defaultProps = {
    columnProps: {},
};
