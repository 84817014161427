import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { MoneyBag } from '@emme/shared/icons';
import { OverviewContext, OverviewSteps } from 'context/Overview/OverviewProvider';
import { OverviewCardLayout } from 'layout/Overview';
export function EarnMoreCard() {
    const { changeSelectedStep } = useContext(OverviewContext);
    return (_jsx(OverviewCardLayout, { icon: _jsx(MoneyBag, { width: "100%", height: "100%" }), title: "Earn More", children: _jsxs(Stack, { gap: 4, alignItems: "flex-start", children: [_jsx(Box, { typography: "body1", color: "success.main", sx: { cursor: 'pointer' }, onClick: () => changeSelectedStep(OverviewSteps.RISK_LEVEL), children: "Increase your risk level \u203A" }), _jsx(Box, { typography: "body1", color: "success.main", sx: { cursor: 'pointer' }, onClick: () => changeSelectedStep(OverviewSteps.MARKETS), children: "Increase your order size \u203A" })] }) }));
}
