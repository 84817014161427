import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { checkMaxValue } from 'utils/util';
export const CoinWithUSDPriceCell = ({ columnProps = {}, data }) => {
    const amount = new BigNumber(data?.value || '');
    const hasColor = columnProps?.addColor && !amount.eq(0);
    const color = hasColor
        ? amount.gt(0)
            ? (t) => t.palette.success.main
            : (t) => t.palette.error.main
        : undefined;
    const usdAmount = amount.multipliedBy(data.usdPrice).abs();
    return (_jsxs(Stack, { justifyContent: "center", alignItems: columnProps?.align || 'flex-end', minWidth: columnProps?.align ? 0 : 100, gap: 2, children: [_jsx(Typography, { component: "span", color: color, children: checkMaxValue(amount, {
                    digits: columnProps?.numberDecimals || 8,
                    isPrice: data?.isPrice,
                    sign: columnProps?.addColor && amount.gt(0) ? '+' : '',
                }) }), _jsx(Typography, { variant: "body2", component: "span", color: color, children: checkMaxValue(usdAmount, {
                    digits: 2,
                    sign: `${hasColor
                        ? amount.gt(0)
                            ? '+'
                            : '-'
                        : usdAmount.decimalPlaces(2).eq(0) &&
                            !usdAmount.decimalPlaces(2).eq(usdAmount.decimalPlaces(8))
                            ? '>'
                            : ''}$`,
                }) })] }));
};
CoinWithUSDPriceCell.alignment = ColumnAlign.RIGHT;
CoinWithUSDPriceCell.defaultProps = {
    columnProps: {},
};
