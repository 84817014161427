import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { CoinIcon } from 'components/CoinIcon';
import DashboardButton from './DashboardButton';
const MarketSelectLogoButton = ({ quoteCoin, baseCoin, customSize, sx, ...rest }) => (_jsx(DashboardButton, { type: "button", variant: "contained", color: "inherit", ...rest, sx: {
        minWidth: '140px',
        '& .MuiButton-endIcon': { m: 0 },
        ...sx,
    }, children: _jsxs(Stack, { direction: "row", justifyContent: "center", alignItems: "center", spacing: 2, typography: "subtitle2", fontSize: customSize, children: [_jsx(CoinIcon, { coin: quoteCoin, size: customSize }), _jsx(Box, { minWidth: 30, children: quoteCoin }), _jsx(Box, { children: "/" }), _jsx(CoinIcon, { coin: baseCoin, size: customSize }), _jsx(Box, { minWidth: 30, children: baseCoin })] }) }));
MarketSelectLogoButton.defaultProps = {
    quoteCoin: undefined,
    baseCoin: undefined,
    customSize: undefined,
};
export default MarketSelectLogoButton;
