import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { useBreakpoint } from 'hooks/useBreakpoint';
const DialogTableLayout = ({ isOpen, title, subtitle, onClose, onNext, onPrev, children, setMinHeight, maxWidth, hasNavigationButtons, }) => {
    const { tablet } = useBreakpoint();
    return (_jsxs(Dialog, { open: isOpen, onClose: onClose, maxWidth: maxWidth, fullWidth: true, fullScreen: tablet, PaperProps: {
            sx: { backgroundColor: (t) => t.palette.neutralsTwo.main },
        }, children: [_jsxs(DialogTitle, { sx: { p: 5, py: tablet ? 1 : undefined }, children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap", children: [_jsxs(Stack, { direction: "row", flexWrap: "nowrap", alignItems: "center", spacing: 2, children: [hasNavigationButtons && (_jsx(IconButton, { type: "button", "aria-label": "close", onClick: () => onPrev && onPrev(), disabled: !hasNavigationButtons?.prev, children: _jsx(KeyboardDoubleArrowLeftOutlinedIcon, { sx: { width: '16px', height: '16px' } }) })), _jsx(Typography, { variant: "h6", children: title }), hasNavigationButtons && (_jsx(IconButton, { type: "button", "aria-label": "close", onClick: () => onNext && onNext(), disabled: !hasNavigationButtons?.next, children: _jsx(KeyboardDoubleArrowRightOutlinedIcon, { sx: { width: '16px', height: '16px' } }) }))] }), _jsx(IconButton, { type: "button", "aria-label": "close", onClick: onClose, edge: "start", children: _jsx(CloseOutlined, { sx: { width: '16px', height: '16px' } }) })] }), subtitle && (_jsx(Typography, { variant: "subtitle1", pt: 5, children: subtitle }))] }), _jsx(DialogContent, { sx: {
                    px: 0,
                    height: '600px',
                    ...(setMinHeight ? { minHeight: '500px' } : {}),
                    pb: tablet ? 0 : undefined,
                }, children: children })] }));
};
DialogTableLayout.defaultProps = {
    setMinHeight: false,
    maxWidth: 'md',
    subtitle: undefined,
    hasNavigationButtons: undefined,
    onNext: undefined,
    onPrev: undefined,
};
export default DialogTableLayout;
