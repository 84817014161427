import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
export const unreadQueryKey = ['users', 'me', 'notifications', 0, 100, 'unread'];
export const readQueryKey = ['users', 'me', 'notifications', 0, 100, 'read'];
const intervalTimeUsers = 30 * 1000;
function clearUnreadNotificationsLocally(queryClient) {
    queryClient.setQueryData(unreadQueryKey, (data) => ({
        specialNotifications: data?.specialNotifications || null,
        notifications: {
            ...(data?.notifications || {}),
            totalNumber: 0,
            size: 0,
            elements: [],
        },
    }));
    queryClient.invalidateQueries(unreadQueryKey);
    queryClient.invalidateQueries(readQueryKey);
}
const updateAllUserNotification = createFetchFn({
    requiresAuth: true,
    method: 'PUT',
    url: '/users/me/notifications',
    data: { isRead: true, includeNormalNotifications: true },
});
export function useUserNotificationsData(params) {
    const queryClient = useQueryClient();
    const { mutate } = useMutation(updateAllUserNotification, {
        mutationKey: ['update-all-notifications-check'],
        retry: false,
        onSuccess: () => clearUnreadNotificationsLocally(queryClient),
    });
    return useQuery(['users', 'me', 'notifications', params?.pageNumber || 0, params?.size || 100, params?.read], createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: '/users/me/notifications',
        params: {
            ...params,
            read: params.read === 'all' ? undefined : params.read === 'read',
            includesSpecial: params.read === 'unread' || undefined,
        },
    }), {
        staleTime: !params?.size ? 60 * 60 * 1000 : intervalTimeUsers,
        onError: (error) => error,
        onSuccess: () => {
            params?.read === 'all' && mutate();
        },
        refetchInterval: !params?.size ? 60 * 60 * 1000 : intervalTimeUsers,
        retry: false,
    });
}
export function useUpdateUserNotifications() {
    const queryClient = useQueryClient();
    return useMutation(({ notificationId, isRead }) => createFetchFn({
        requiresAuth: true,
        method: 'PUT',
        url: `/users/me/notifications/${notificationId}`,
        data: { isRead },
    })(), {
        mutationKey: ['update-notifications'],
        retry: false,
        onSuccess: (_res, { notificationId }) => {
            queryClient.setQueryData(unreadQueryKey, (data) => {
                const updatedNotifications = (data?.notifications?.elements || []).slice();
                const index = updatedNotifications.findIndex(({ id }) => id === notificationId);
                if (index > -1) {
                    updatedNotifications.splice(index, 1);
                }
                return {
                    specialNotifications: data?.specialNotifications || null,
                    notifications: {
                        ...(data?.notifications || {}),
                        totalNumber: updatedNotifications.length === 0 ? 0 : updatedNotifications.length - 1,
                        elements: updatedNotifications,
                    },
                };
            });
            queryClient.invalidateQueries(readQueryKey);
        },
    });
}
export function useUpdateAllUserNotifications() {
    const queryClient = useQueryClient();
    return useMutation(updateAllUserNotification, {
        mutationKey: ['update-all-notifications'],
        retry: false,
        onSuccess: () => clearUnreadNotificationsLocally(queryClient),
    });
}
