import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
const MAX_VALUE = 2n ** 64n / 2n - 1n;
export function CustomSeedInput({ onChange, value, ...props }) {
    const [errorMessage, setErrorMessage] = useState('');
    const setDisappearingError = (message) => {
        setErrorMessage(message);
        setTimeout(() => setErrorMessage(''), 2000);
    };
    const handleChange = (event) => {
        const rawSeed = event.target.value;
        if (rawSeed?.length > 16) {
            setDisappearingError('Maximum length of seed is 16 characters');
            return;
        }
        let parsedSeed = 0n;
        if (rawSeed) {
            try {
                parsedSeed = BigInt(`0x${rawSeed}`);
            }
            catch {
                setDisappearingError('Seed must be in hexadecimal format');
                return;
            }
            if (parsedSeed > MAX_VALUE) {
                setDisappearingError('Seed must be in 64-bit range');
                return;
            }
        }
        setErrorMessage('');
        onChange && onChange(event);
    };
    return (_jsx(TextField, { name: "seed", "aria-label": "Set Custom Seed", placeholder: "Set Custom Seed", value: value ? value.toUpperCase() : '', onChange: handleChange, error: Boolean(!value && errorMessage), helperText: errorMessage || ' ', autoComplete: "off", ...props }));
}
