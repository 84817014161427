import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeLiquidity = 10 * 1000;
export function useLiquidityMarketOrdersData({ liquidityMarketId, open, type, ...params }) {
    return useQuery(['liquidity-markets', liquidityMarketId, type, open], createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: `/liquidity-markets/${liquidityMarketId}/orders/${type}/${open ? 'open' : 'closed'}`,
        params,
        transformData: (data) => data.liquidityOrders,
    }), {
        staleTime: intervalTimeLiquidity,
        onError: (error) => error,
        refetchInterval: intervalTimeLiquidity,
        refetchOnWindowFocus: true,
        enabled: Boolean(liquidityMarketId),
    });
}
export function useCreateLiquidityMarketOrderProvision() {
    const queryClient = useQueryClient();
    return useMutation(({ liquidityMarketId, ...data }) => createFetchFn({
        requiresAuth: true,
        method: 'POST',
        url: `/liquidity-markets/${liquidityMarketId}/orders/provisions`,
        data,
    })(), {
        mutationKey: ['create-order-provision'],
        retry: false,
        onSuccess: (_resData, reqData) => {
            queryClient.invalidateQueries([
                'liquidity-markets',
                reqData.liquidityMarketId,
                'provisions',
                true,
            ]);
            queryClient.invalidateQueries([
                'liquidity-markets',
                'order-book',
                'provision',
                reqData.liquidityMarketId,
            ]);
            queryClient.invalidateQueries(['users', 'me', 'exchanges', 'balances']);
            queryClient.invalidateQueries(['liquidity-markets', 'ticker', reqData.liquidityMarketId]);
        },
    });
}
export function useCreateLiquidityMarketOrderSubscription() {
    const queryClient = useQueryClient();
    return useMutation(({ liquidityMarketId, ...data }) => createFetchFn({
        requiresAuth: true,
        method: 'POST',
        url: `/liquidity-markets/${liquidityMarketId}/orders/subscriptions`,
        data,
    })(), {
        mutationKey: ['create-order-subscription'],
        retry: false,
        onSuccess: (_resData, reqData) => {
            queryClient.invalidateQueries([
                'liquidity-markets',
                reqData.liquidityMarketId,
                'subscriptions',
                true,
            ]);
            queryClient.invalidateQueries([
                'liquidity-markets',
                'order-book',
                'subscription',
                reqData.liquidityMarketId,
            ]);
            queryClient.invalidateQueries(['users', 'me', 'balance']);
        },
    });
}
export function useCancelLiquidityMarketOrder({ liquidityMarketId, isSubscription, }) {
    const queryClient = useQueryClient();
    return useMutation((liquidityOrderId) => createFetchFn({
        requiresAuth: true,
        method: 'POST',
        url: `/liquidity-markets/${liquidityMarketId}/orders/${liquidityOrderId}/cancel`,
    })(), {
        mutationKey: ['cancel-order'],
        retry: false,
        onSuccess: () => {
            queryClient.invalidateQueries([
                'liquidity-markets',
                liquidityMarketId,
                isSubscription ? 'subscriptions' : 'provisions',
                true,
            ]);
            queryClient.invalidateQueries([
                'liquidity-markets',
                'order-book',
                isSubscription ? 'subscription' : 'provision',
                liquidityMarketId,
            ]);
            queryClient.invalidateQueries(isSubscription ? ['users', 'me', 'balance'] : ['users', 'me', 'exchanges', 'balances']);
        },
    });
}
export function useProvisionLiquidityMarketOrderVerification() {
    return useMutation((dto) => createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: `/liquidity-markets/${dto.liquidityMarketId}/orders/${dto.liquidityOrderId}/provision`,
    })(), {
        mutationKey: ['provision-order-verification'],
        retry: false,
    });
}
