import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState, createContext, useDeferredValue } from 'react';
import debounce from 'lodash.debounce';
import { storage } from '@emme/shared';
import { StorageKey } from '@emme/shared/model/enums';
import { usePersistedState } from 'hooks/usePersistedState';
export const DebouncedSpreadContext = createContext({
    subscribeFormSpread: 1,
    dashboardSpread: 1,
});
export const SpreadContext = createContext({
    subscribeFormSpread: 1,
    updateSubscribeFormSpread: console.error,
    dashboardSpread: 1,
    updateDashboardSpread: console.error,
    resetSubscribeFormSpread: console.error,
});
export function SpreadProvider({ children }) {
    const [lastTouchedSlider, setLastTouchedSlider] = useState('dashboard');
    const [touchedDashboardSlider, setTouchedDashboardSlider] = useState(false);
    const [touchedSubscribeFormSlider, setTouchedSubscribeFormSlider] = useState(false);
    const [subscribeFormSpread, setSubscribeFormSpread] = usePersistedState(1, StorageKey.EMME_SPREAD_SUBSCRIBE);
    const [dashboardSpread, setDashboardSpread] = usePersistedState(1, StorageKey.EMME_SPREAD_DASHBOARD);
    const debouncedSaveDashboardSpread = useMemo(() => debounce((value) => {
        storage.set(StorageKey.EMME_SPREAD_DASHBOARD, value);
    }, 500), []);
    const updateDashboardSpread = useCallback((value) => {
        setDashboardSpread(value);
        setLastTouchedSlider('dashboard');
        setTouchedDashboardSlider(true);
    }, [setDashboardSpread]);
    const updateSubscribeFormSpread = useCallback((value) => {
        setSubscribeFormSpread(value);
        setLastTouchedSlider('subscribeForm');
        setTouchedSubscribeFormSlider(true);
        debouncedSaveDashboardSpread(value);
    }, [setSubscribeFormSpread, debouncedSaveDashboardSpread]);
    const resetSubscribeFormSpread = useCallback(() => {
        setTouchedSubscribeFormSlider(false);
    }, []);
    const debouncedDashboardSpread = useDeferredValue(dashboardSpread);
    const debouncedSubscribeFormSpread = useDeferredValue(subscribeFormSpread);
    const debouncedSpreadContextValue = useMemo(() => ({
        subscribeFormSpread: touchedDashboardSlider && !touchedSubscribeFormSlider
            ? debouncedDashboardSpread
            : debouncedSubscribeFormSpread,
        dashboardSpread: lastTouchedSlider === 'dashboard' ? debouncedDashboardSpread : debouncedSubscribeFormSpread,
    }), [
        debouncedDashboardSpread,
        debouncedSubscribeFormSpread,
        lastTouchedSlider,
        touchedDashboardSlider,
        touchedSubscribeFormSlider,
    ]);
    const spreadContextValue = useMemo(() => ({
        subscribeFormSpread: touchedDashboardSlider && !touchedSubscribeFormSlider
            ? dashboardSpread
            : subscribeFormSpread,
        updateSubscribeFormSpread,
        dashboardSpread: lastTouchedSlider === 'dashboard' ? dashboardSpread : subscribeFormSpread,
        updateDashboardSpread,
        resetSubscribeFormSpread,
    }), [
        subscribeFormSpread,
        updateSubscribeFormSpread,
        dashboardSpread,
        updateDashboardSpread,
        lastTouchedSlider,
        touchedDashboardSlider,
        touchedSubscribeFormSlider,
        resetSubscribeFormSpread,
    ]);
    return (_jsx(DebouncedSpreadContext.Provider, { value: debouncedSpreadContextValue, children: _jsx(SpreadContext.Provider, { value: spreadContextValue, children: children }) }));
}
