import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeUsers = 30 * 1000;
export function useUserExchangeCredentialsData() {
    return useQuery(['users', 'me', 'exchanges', 'credentials'], createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: '/users/me/exchanges/credentials',
        transformData: (data) => data.credentials,
    }), {
        staleTime: intervalTimeUsers,
        onError: (error) => error,
        refetchInterval: intervalTimeUsers,
        refetchOnMount: true,
    });
}
export function useCreateCredentials() {
    const queryClient = useQueryClient();
    return useMutation(({ exchangeId, ...data }) => createFetchFn({
        requiresAuth: true,
        method: 'POST',
        url: `/users/me/exchanges/${exchangeId}/credentials`,
        data,
    })(), {
        mutationKey: ['create-credential'],
        retry: false,
        onSuccess: (_res, { exchangeId, ...data }) => {
            queryClient.refetchQueries(['users', 'me', 'exchanges', 'balances']);
            queryClient.setQueryData(['users', 'me', 'exchanges', 'credentials'], (existingCredentials) => (existingCredentials || [])
                .filter(({ exchangeId: existingExchangeId }) => existingExchangeId !== exchangeId)
                .concat([{ exchangeId, key: data.key }]));
        },
    });
}
export function useRemoveCredentials() {
    const queryClient = useQueryClient();
    return useMutation(({ exchangeId }) => createFetchFn({
        requiresAuth: true,
        method: 'DELETE',
        url: `/users/me/exchanges/${exchangeId}/credentials`,
    })(), {
        mutationKey: ['delete-credential'],
        retry: false,
        onSuccess: (_res, { exchangeId }) => {
            queryClient.refetchQueries(['users', 'me', 'exchanges', 'balances']);
            queryClient.setQueryData(['users', 'me', 'exchanges', 'credentials'], (existingCredentials) => {
                const newCredentials = (existingCredentials || []).slice();
                const index = newCredentials.findIndex(({ exchangeId: existingExchangeId }) => existingExchangeId === exchangeId);
                if (index > -1) {
                    newCredentials.splice(index, 1);
                }
                return newCredentials;
            });
        },
    });
}
