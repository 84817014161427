import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { unreadQueryKey, useUpdateAllUserNotifications, useUpdateUserNotifications, useUserNotificationsData, } from '@emme/shared/queries/Users';
import { RouteEnum, NotificationTypeEnum } from '@emme/shared/model/enums';
import { AlertContext } from 'context/AlertProvider';
import { dayJsDayOfYear, dayJsTime } from 'utils/util';
import { DisabledTooltip } from 'components/Tooltips';
const setAvatar = (type) => {
    switch (type) {
        case NotificationTypeEnum.WARNING:
            return _jsx(WarningAmberOutlinedIcon, { color: "warning" });
        case NotificationTypeEnum.ERROR:
            return _jsx(ErrorOutlineOutlinedIcon, { color: "error" });
        default:
            return _jsx(InfoOutlinedIcon, { color: "primary" });
    }
};
const filters = ['All', 'Unread', 'Read'];
const NotificationsMenu = () => {
    const queryClient = useQueryClient();
    const [anchorElNotifications, setAnchorElNotifications] = useState(null);
    const [toggleBtn, setToggleBtn] = useState('unread');
    const [loadingItemId, setLoadingItemId] = useState('');
    const { setAlert } = useContext(AlertContext);
    const { data: { notifications } = {}, isLoading } = useUserNotificationsData({
        read: toggleBtn,
    });
    const markAsRead = useUpdateUserNotifications();
    const markAllAsRead = useUpdateAllUserNotifications();
    const unreadNotifications = queryClient.getQueryData(unreadQueryKey)?.notifications;
    const unreadNotificationsFetching = Boolean(useIsFetching(unreadQueryKey));
    const handleClickNotifications = (event) => {
        setLoadingItemId('');
        setAnchorElNotifications(event.currentTarget);
    };
    const handleCloseNotifications = () => setAnchorElNotifications(null);
    const handleMarkAsRead = async (id) => {
        if (!id)
            return;
        setLoadingItemId(id);
        try {
            await markAsRead.mutateAsync({ notificationId: id, isRead: true });
        }
        catch {
            setAlert('Selected notification could not be marked as read!', 'error');
        }
        setLoadingItemId('');
    };
    const handleMarkAllAsRead = async () => {
        try {
            await markAllAsRead.mutateAsync();
        }
        catch {
            setAlert('Notifications could not be marked as read!', 'error');
        }
    };
    const handleChange = (event) => {
        setToggleBtn(event.target.value);
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { "aria-label": "show new notifications", color: "inherit", onClick: handleClickNotifications, size: "small", children: _jsx(Badge, { badgeContent: unreadNotifications?.totalNumber || 0, sx: {
                        '.MuiBadge-badge': {
                            fontSize: 12,
                            color: ({ palette }) => palette.neutralsTwo.main,
                            background: ({ palette }) => palette.primary.main,
                        },
                    }, children: _jsx(NotificationsOutlinedIcon, { fontSize: "small" }) }) }), _jsxs(Popover, { anchorEl: anchorElNotifications, open: Boolean(anchorElNotifications), onClose: handleCloseNotifications, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'center' }, PaperProps: {
                    sx: {
                        minHeight: 0,
                        maxHeight: 600,
                        width: 450,
                        backgroundColor: (t) => t.palette.neutralsTwo.main,
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }, children: [_jsxs(Stack, { direction: "row", p: (t) => t.spacing(0, 5, 2), minHeight: "45px", alignItems: "center", justifyContent: "space-between", children: [_jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle1", component: "span", pr: 5, color: "textPrimary", children: "Notifications" }), _jsx(Select, { value: toggleBtn, onChange: handleChange, size: "small", sx: {
                                            borderRadius: 2,
                                            p: 0,
                                            minWidth: '90px',
                                            height: '24px',
                                            backgroundColor: (t) => t.palette.neutralsThree.main,
                                            svg: {
                                                fontSize: '12px',
                                            },
                                        }, IconComponent: ExpandMoreOutlined, children: filters.map((text) => (_jsx(MenuItem, { value: text.toLowerCase(), children: _jsx(Typography, { variant: "subtitle1", component: "span", color: "text.primary", children: text }) }, text))) })] }), toggleBtn === 'unread' && (_jsx(LoadingButton, { onClick: handleMarkAllAsRead, disabled: markAsRead.isLoading ||
                                    unreadNotificationsFetching ||
                                    !notifications?.elements.filter((el) => !el.isRead)
                                        ?.length, loading: markAllAsRead.isLoading, loadingPosition: "end", endIcon: _jsx(CheckCircleOutlinedIcon, {}), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 12 }), disableElevation: true, disableRipple: true, disableFocusRipple: true, disableTouchRipple: true, size: "small", sx: {
                                    typography: 'subtitle1',
                                    '&:hover': { background: 'none' },
                                }, children: "Mark all as read" }))] }), _jsx(List, { sx: { overflow: 'auto' }, children: notifications?.elements?.length ? (notifications?.elements.map((notification) => (_jsxs(ListItem, { divider: true, alignItems: "flex-start", children: [_jsx(ListItemAvatar, { sx: { mt: 0 }, children: _jsx(Avatar, { children: setAvatar(notification?.type) }) }), _jsx(ListItemText, { primary: notification?.text, primaryTypographyProps: {
                                        textAlign: 'left',
                                        component: 'div',
                                        variant: 'subtitle2',
                                    }, secondary: dayjs(new Date(notification?.createdAt)).format(`${dayJsDayOfYear} ${dayJsTime}`), secondaryTypographyProps: {
                                        component: 'div',
                                        variant: 'subtitle1',
                                        pt: 3,
                                    } }), toggleBtn === 'unread' && (_jsx(DisabledTooltip, { title: "Mark as read", placement: "bottom", enterDelay: 500, enterNextDelay: 500, disabledColor: markAsRead.isLoading || markAllAsRead.isLoading || unreadNotificationsFetching, children: _jsx(Typography, { component: "span", color: "textSecondary", children: _jsx(LoadingButton, { onClick: () => handleMarkAsRead(notification?.id), disabled: markAsRead.isLoading ||
                                                markAllAsRead.isLoading ||
                                                unreadNotificationsFetching, loading: notification?.id === loadingItemId, sx: { minWidth: 0, p: 2, ml: 2 }, color: "inherit", children: _jsx(HighlightOffOutlinedIcon, { fontSize: "small" }) }) }) }))] }, notification.id)))) : !notifications?.elements?.length && isLoading ? (_jsx(Box, { sx: {
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                py: 7,
                                px: 2,
                            }, children: _jsx(CircularProgress, { color: "primary", size: 100 }) })) : (_jsx(ListItem, { disabled: true, sx: { py: 7, px: 2 }, children: _jsx(ListItemText, { primary: `You don’t have any ${toggleBtn} notifications`, primaryTypographyProps: { variant: 'h6', textAlign: 'center' } }) })) }), _jsx(Button, { sx: { m: 7, flex: 'none' }, component: RouterLink, to: RouteEnum.AccountNotifications, onClick: handleCloseNotifications, color: "primary", variant: "contained", size: "large", children: "See all notifications" })] })] }));
};
export default NotificationsMenu;
