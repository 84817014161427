import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Navbar from 'layout/Navbar';
import { PlaceOrder } from 'pages/DashboardPage/components/PlaceOrder';
import { InfoTab } from 'pages/DashboardPage/components/InfoTab';
import PrivateOrderBook from './components/PrivateOrderBook';
import { PaperItem } from './components/PaperItem';
import TradesTable from './components/TradesTable';
import OrderBook from './components/OrderBook';
import Charts from './components/Charts';
const DesktopDashboard = () => (_jsxs(Box, { sx: {
        display: 'grid',
        gridTemplateRows: 'auto minmax(0, 1fr)',
        gridTemplateColumns: ['1fr 300px', undefined, undefined, undefined, undefined, '1fr 350px'],
        gridTemplateAreas: `
        "header header"
        "middle-grid place-order"
      `,
        gap: '4px',
        height: '100vh',
        maxHeight: '100vh',
        minHeight: 0,
        backgroundColor: (t) => t.palette.background.default,
        pb: 2,
    }, children: [_jsx(Box, { sx: { gridArea: 'header' }, children: _jsx(Navbar, {}) }), _jsxs(Box, { sx: {
                gridArea: 'middle-grid',
                overflow: 'auto',
                display: 'grid',
                gridTemplateRows: [
                    '88px minmax(300px, 1fr) 250px',
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    '88px minmax(300px, 1fr) 350px',
                ],
                gridTemplateColumns: 'minmax(550px, 1fr) 330px',
                gridTemplateAreas: `
            "info-tab info-tab"
            "charts orderbook"
            "private-tables trades"
          `,
                gap: '4px',
                minHeight: 0,
                ml: 2,
            }, children: [_jsx(PaperItem, { sx: { gridArea: 'info-tab' }, children: _jsx(InfoTab, {}) }), _jsx(PaperItem, { sx: { gridArea: 'charts' }, children: _jsx(Charts, {}) }), _jsx(PaperItem, { sx: { gridArea: 'private-tables' }, children: _jsx(PrivateOrderBook, {}) }), _jsx(PaperItem, { sx: { gridArea: 'orderbook' }, children: _jsx(OrderBook, {}) }), _jsx(PaperItem, { sx: { gridArea: 'trades' }, children: _jsx(TradesTable, {}) })] }), _jsx(PaperItem, { sx: { gridArea: 'place-order', mr: 2 }, children: _jsx(PlaceOrder, {}) })] }));
export default DesktopDashboard;
