import { useMutation, useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const fetchAppInfo = createFetchFn({
    requiresAuth: false,
    method: 'GET',
    url: '/app/info',
});
export function useAppInfoData() {
    return useQuery(['app', 'info'], () => fetchAppInfo(), {
        onError: (error) => error,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: true,
    });
}
export function useCheckAvailable() {
    return useMutation(fetchAppInfo, {
        mutationKey: ['app-available'],
        retry: 10,
        retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
    });
}
