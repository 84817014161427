import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const EmmeCircularProgress = ({ sx, trackColor, innerCircle, innerCircleGap = 0, text, children, isLoading, ...props }) => {
    // Round the gap between outer circle and inner circle up to a round number
    const innerCircleSize = props.size - Math.ceil((innerCircleGap + (props.thickness || 3.6)) / 2) * 2;
    return (_jsxs(Box, { color: trackColor, sx: {
            position: 'relative',
            width: `${props.size}px`,
            height: `${props.size}px`,
            ...sx,
        }, children: [innerCircle && (_jsx(Box, { top: 0, left: 0, bottom: 0, right: 0, position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", children: _jsx(Box, { height: innerCircleSize, width: innerCircleSize, borderRadius: "100%", display: "flex", alignItems: "center", justifyContent: "center", bgcolor: "currentColor", children: children || (_jsx(Typography, { variant: "body3", color: props.color || 'textSecondary', children: text || `${Math.round(props.value || 0)}%` })) }) })), _jsx(CircularProgress, { ...props, variant: "determinate", color: "inherit", value: 100, "aria-hidden": true }), _jsx(CircularProgress, { sx: { position: 'absolute', left: 0 }, variant: isLoading ? 'indeterminate' : 'determinate', ...props })] }));
};
EmmeCircularProgress.defaultProps = {
    trackColor: 'neutralsThree.main',
    innerCircle: false,
    innerCircleGap: 0,
    text: undefined,
    children: undefined,
    isLoading: false,
};
export default EmmeCircularProgress;
