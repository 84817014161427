import { useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { DebouncedSpreadContext } from 'context/SpreadProvider';
import { SubscriptionMaxLiquidityContext } from 'context/SubscriptionMaxLiquidityProvider';
const createData = (spread, totalMidPoint, volume) => [
    { delta: -0.2, multiplier: 0.05 },
    { delta: -0.1, multiplier: 0.25 },
    { delta: 0, multiplier: 0.4 },
    { delta: 0.1, multiplier: 0.25 },
    { delta: 0.2, multiplier: 0.05 },
].map(({ delta, multiplier }) => ({
    rate: new BigNumber(totalMidPoint)
        .multipliedBy(new BigNumber(1).plus(new BigNumber(spread + delta).dividedBy(100)))
        .toFixed(16),
    quantity: volume.multipliedBy(multiplier).toFixed(16),
}));
export function useSimulatedDepthChartData(totalData, totalMidPoint) {
    const { subscribeFormSpread } = useContext(DebouncedSpreadContext);
    const { volume } = useContext(SubscriptionMaxLiquidityContext);
    const data = useMemo(() => volume.eq(0) || (!totalData.asks.length && !totalData.bids.length)
        ? undefined
        : {
            asks: totalData.asks.concat(createData(subscribeFormSpread, totalMidPoint, volume)),
            bids: totalData.bids.concat(createData(-subscribeFormSpread, totalMidPoint, volume)),
        }, [totalData, subscribeFormSpread, volume, totalMidPoint]);
    return data;
}
