import { jsx as _jsx } from "react/jsx-runtime";
import { QuickButton } from 'components/Buttons';
export const ToggleQuickButton = ({ children, isActive, ...props }) => (_jsx(QuickButton, { color: isActive ? 'primary' : 'inherit', variant: isActive ? 'contained' : 'outlined', ...props, sx: {
        my: 0,
        color: (t) => t.palette.text.primary,
        ...props.sx,
    }, children: children || (isActive ? 'Cancel' : 'Set') }));
ToggleQuickButton.defaultProps = {
    isActive: false,
};
