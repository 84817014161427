import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { keyframes } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { RouteEnum } from '@emme/shared/model/enums';
import { AnimatedLocationContext } from 'context/AnimatedLocationProvider';
import Navbar from '../Navbar';
const slideIn = keyframes `
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideOut = keyframes `
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;
const isRouteActive = (location, route) => location.pathname.replaceAll('/', '') === route.replaceAll('/', '');
const ANIMATED_ROUTES = [RouteEnum.DashboardPlaceOrder, RouteEnum.DashboardConfirmOrder];
const MobileDashboardLayout = ({ children }) => {
    const location = useLocation();
    const [animatedLocation, setAnimatedLocation] = useContext(AnimatedLocationContext);
    const [transitionStage, setTransitionStage] = useState('in');
    const isDashboardPage = isRouteActive(location, RouteEnum.Dashboard);
    useEffect(() => {
        if (location !== animatedLocation)
            setTransitionStage('out');
    }, [location, animatedLocation]);
    const handleAnimationEnd = useCallback(() => {
        if (transitionStage === 'out') {
            setTransitionStage('in');
            setAnimatedLocation(location);
        }
    }, [location, setAnimatedLocation, transitionStage]);
    const shouldAnimate = ANIMATED_ROUTES.find((route) => isRouteActive(animatedLocation, route));
    const animation = transitionStage === 'in' ? slideIn : slideOut;
    const animationDuration = shouldAnimate ? '0.3s' : '0ms';
    const animationFunction = transitionStage === 'in' ? 'ease-out' : 'ease-in';
    return (_jsxs(Box, { sx: {
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            height: '100vh',
            minHeight: 0,
            backgroundColor: (t) => t.palette.background.default,
        }, children: [_jsx(Box, { sx: {
                    backgroundColor: (t) => isDashboardPage ? t.palette.neutralsThree.main : t.palette.background.default,
                    transition: 'background-color 0.3s',
                }, children: _jsx(Navbar, { disabled: !isDashboardPage }) }), _jsx(Box, { sx: {
                    overflow: isDashboardPage ? undefined : 'auto',
                    animation: `${animation} ${animationDuration} ${animationFunction}`,
                }, onAnimationEnd: handleAnimationEnd, children: children })] }));
};
export default MobileDashboardLayout;
