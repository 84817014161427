import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CurrencyBitcoinOutlinedIcon from '@mui/icons-material/CurrencyBitcoinOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import EmmeCircularProgress from 'components/EmmeCircularProgress';
import { OverviewContext, OverviewStatus } from 'context/Overview/OverviewProvider';
const STEP_HEIGHT = 64;
const STEP_GAP = 12;
const EMME_CIRCLE_SIZE = 40;
const STEP_CIRCLE_SIZE = 21;
const stepArray = [
    {
        title: 'Earn $EMME',
        description: 'Complete the steps and start trading',
    },
    {
        Icon: SearchOutlinedIcon,
        title: 'Search',
        description: 'Choose coin to generate liquidity for',
    },
    {
        Icon: CompareArrowsOutlinedIcon,
        title: 'Set Exchange',
        description: 'Add your Exchange API key',
    },
    {
        Icon: CurrencyBitcoinOutlinedIcon,
        title: 'Set Markets',
        description: 'Set the possible markets to trade',
    },
    {
        Icon: AttachMoneyOutlinedIcon,
        title: 'Ensure Funds',
        description: 'Set sufficient balance to trade',
    },
    {
        Icon: SpeedOutlinedIcon,
        title: 'Set Risk Level',
        description: 'Higher risk can result in higher reward',
    },
    {
        Icon: TaskOutlinedIcon,
        title: 'Confirm',
        description: 'Preview and approve your settings',
    },
];
function StepCounter() {
    const { lastCompletedStep, earningStatus } = useContext(OverviewContext);
    const text = `${lastCompletedStep} / ${stepArray.length - 1}`;
    return (_jsx(EmmeCircularProgress, { size: EMME_CIRCLE_SIZE, innerCircle: true, innerCircleGap: 5, thickness: 1, color: 'emmeSecondary', value: (lastCompletedStep / (stepArray.length - 1)) * 100, isLoading: earningStatus === OverviewStatus.LOADING, "aria-label": `${text} steps completed`, children: _jsx(Typography, { variant: "body1", color: "textPrimary", children: text }) }));
}
function StepIcon({ index }) {
    const { lastCompletedStep, getStepState, earningStatus } = useContext(OverviewContext);
    const isLoading = earningStatus === OverviewStatus.LOADING;
    const hasIcon = !isLoading && index <= lastCompletedStep;
    const hasGreenDot = !isLoading && index === lastCompletedStep + 1;
    const hasGreyDot = isLoading || index > lastCompletedStep + 1;
    const hasError = !isLoading && getStepState(index) === 'error';
    return (_jsx(Box, { sx: { width: `${EMME_CIRCLE_SIZE}px`, display: 'flex', justifyContent: 'center' }, children: _jsx(Box, { sx: {
                width: `${STEP_CIRCLE_SIZE}px`,
                height: `${STEP_CIRCLE_SIZE}px`,
                borderRadius: '50%',
                backgroundColor: hasIcon || hasGreenDot ? 'neutralsThree.main' : 'neutralsTwo.main',
                transition: 'all 0.2s',
            }, children: _jsx(Box, { sx: {
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    backgroundColor: hasGreyDot
                        ? 'neutralsThree.main'
                        : hasError
                            ? 'error.main'
                            : 'emmeSecondary.main',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: hasIcon ? undefined : 'scale(0.35)',
                    transition: 'all 0.2s',
                }, children: hasIcon &&
                    (hasError ? (_jsx(CloseOutlinedIcon, { fontSize: "inherit" })) : (_jsx(CheckOutlinedIcon, { fontSize: "inherit" }))) }) }) }));
}
export function StepConnector() {
    const { lastCompletedStep, earningStatus } = useContext(OverviewContext);
    const numberOfGreenLines = earningStatus === OverviewStatus.LOADING ? 0 : lastCompletedStep + 1;
    const stepNegativeMargin = (STEP_HEIGHT - STEP_CIRCLE_SIZE) / 2;
    const firstStepNegativeMargin = (STEP_HEIGHT - EMME_CIRCLE_SIZE) / 2;
    const connectorHeight = STEP_GAP + 2 * stepNegativeMargin;
    const firstConnectorHeight = STEP_GAP + stepNegativeMargin + firstStepNegativeMargin;
    return (_jsx(Box, { sx: {
            width: `${EMME_CIRCLE_SIZE / 2}px`,
            borderRight: (t) => `1px solid ${t.palette.emmeSecondary.main}`,
            transition: 'all 0.2s',
            height: `${connectorHeight}px`,
            my: `-${stepNegativeMargin}px`,
            '&:nth-of-type(2)': {
                marginTop: `-${firstStepNegativeMargin}px`,
                height: `${firstConnectorHeight}px`,
            },
            [`&:nth-of-type(n+${numberOfGreenLines * 2 + 1})`]: {
                borderRightColor: (t) => t.palette.neutralsTwo.main,
            },
        } }));
}
export function OverviewStepper() {
    const { selectedStep, lastCompletedStep, getStepState, changeSelectedStep, earningStatus } = useContext(OverviewContext);
    const isStepDisabled = (step) => step > lastCompletedStep + 1 || earningStatus === OverviewStatus.LOADING;
    return (_jsx(Stack, { divider: _jsx(StepConnector, {}), children: stepArray.map(({ Icon, title, description }, index) => (_jsx(Stack, { direction: "row", alignItems: "center", gap: 3, onClick: () => changeSelectedStep(index), children: index === 0 ? (_jsxs(_Fragment, { children: [_jsx(StepCounter, {}), _jsxs(Stack, { gap: 3, px: 3, justifyContent: "center", height: STEP_HEIGHT, children: [_jsx(Typography, { variant: "h6", component: "span", children: title }), _jsx(Typography, { variant: "body1", component: "span", color: "textSecondary", children: description })] })] })) : (_jsxs(_Fragment, { children: [_jsx(StepIcon, { index: index }), _jsxs(Stack, { direction: "row", gap: 3, sx: {
                            height: `${STEP_HEIGHT}px`,
                            backgroundColor: index === selectedStep ? 'neutralsTwo.main' : 'background.default',
                            opacity: isStepDisabled(index) ? 0.5 : 1,
                            transition: 'all 0.2s',
                            px: 3,
                            borderRadius: 3,
                            alignItems: 'center',
                            cursor: isStepDisabled(index) || index === selectedStep ? undefined : 'pointer',
                        }, children: [_jsx(Box, { sx: {
                                    backgroundColor: getStepState(index) === 'error'
                                        ? 'error.main'
                                        : index === selectedStep
                                            ? 'primary.main'
                                            : isStepDisabled(index)
                                                ? 'emmePrimary.dark'
                                                : 'emmeSecondary.main',
                                    transition: 'background-color 0.2s',
                                    width: ['35px', undefined, undefined, '40px'],
                                    height: ['35px', undefined, undefined, '40px'],
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 2,
                                }, children: Icon && _jsx(Icon, {}) }), _jsxs(Stack, { gap: 3, justifyContent: "center", children: [_jsx(Typography, { variant: "h6", component: "span", children: title }), _jsx(Typography, { variant: "body1", component: "span", color: "textSecondary", children: description })] })] })] })) }, title))) }));
}
