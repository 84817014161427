import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { OnboardingContext } from 'context/OnboardingProvider';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { ExchangeAvatar } from 'components/ExchangeAvatar';
import { NoHoverButton } from 'components/Buttons';
import ExchangeSelectMenu from './ExchangeSelectMenu';
const ExchangeSelectLogo = () => {
    const buttonRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const { selectedExchange } = useContext(LiquidityContext);
    const { allSteps, activeStep } = useContext(OnboardingContext);
    const { tablet } = useBreakpoint();
    const onboardingTabletView = allSteps[activeStep]?.stepNumber === 2 && !tablet;
    useEffect(() => {
        setAnchorEl(buttonRef && onboardingTabletView ? buttonRef.current : null);
    }, [activeStep, onboardingTabletView]);
    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    return (_jsxs(_Fragment, { children: [_jsx(NoHoverButton, { ref: buttonRef, id: "exchange-button", type: "button", onClick: handleOpen, color: "inherit", disableFocusRipple: true, disableTouchRipple: true, disableRipple: true, disabled: !selectedExchange?.id, sx: {
                    ...(onboardingTabletView
                        ? {
                            zIndex: 1003,
                            border: '1px solid',
                            borderColor: (t) => t.palette.error.main,
                            pointerEvents: 'none',
                        }
                        : {}),
                }, children: _jsxs(Stack, { direction: "row", justifyContent: "center", alignItems: "center", flexWrap: "nowrap", spacing: 1, children: [_jsx(ExchangeAvatar, { exchange: selectedExchange?.id }), anchorEl ? (_jsx(KeyboardArrowUpOutlinedIcon, { fontSize: "small" })) : (_jsx(KeyboardArrowDownOutlinedIcon, { fontSize: "small" }))] }) }), _jsx(ExchangeSelectMenu, { anchorEl: anchorEl, onClose: handleClose })] }));
};
export default ExchangeSelectLogo;
