import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { MobilePlaceOrderCTA } from './components/MobilePlaceOrderCTA';
import { MobileOrdersInfo } from './components/MobileOrdersInfo';
import { MobileRoundCard } from './components/MobileRoundCard';
import { MobileEmmeCard } from './components/MobileEmmeCard';
import { MobileInfoTab } from '../../components/InfoTab';
const MobileDashboard = () => (_jsxs(Stack, { height: "100%", gap: 4, sx: { backgroundColor: (t) => t.palette.neutralsThree.main }, children: [_jsxs(Box, { sx: { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3, mx: 3 }, children: [_jsx(Paper, { sx: { p: 5 }, children: _jsx(MobileEmmeCard, {}) }), _jsx(Paper, { sx: { p: 5 }, children: _jsx(MobileRoundCard, {}) })] }), _jsxs(Stack, { sx: {
                flex: 'auto',
                backgroundColor: (t) => t.palette.background.default,
                p: 3,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
            }, children: [_jsx(MobileInfoTab, {}), _jsx(MobileOrdersInfo, {}), _jsx(MobilePlaceOrderCTA, {})] })] }));
export default MobileDashboard;
