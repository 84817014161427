import { jsx as _jsx } from "react/jsx-runtime";
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { dayJsDayOfYear, dayJsTime } from 'utils/util';
export const DateCell = ({ columnProps = {}, data }) => {
    let formattedDate = '-';
    const newDate = new Date(data);
    if (dayjs(newDate).isValid()) {
        formattedDate = dayjs(newDate).format(columnProps.format || `${dayJsDayOfYear} ${dayJsTime}`);
    }
    return _jsx(Typography, { component: "span", children: formattedDate });
};
DateCell.alignment = ColumnAlign.LEFT;
DateCell.defaultProps = {
    columnProps: {},
};
