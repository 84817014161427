const { ClientJS } = require('clientjs');
export const getFingerPrint = () => {
    const client = new ClientJS();
    const pdfViewerEnabled = navigator?.pdfViewerEnabled;
    const getFonts = client.getFonts();
    const isLocalStorage = client.isLocalStorage();
    const isSessionStorage = client.isSessionStorage();
    const getTimeZone = client.getTimeZone();
    const getLanguage = client.getLanguage();
    const getSystemLanguage = client.getSystemLanguage();
    const isCookie = client.isCookie();
    // TODO try without this for now, but the problem is that it will make the uniques less
    // const getCanvasPrint = client.getCanvasPrint();
    const getCanvasPrint = undefined;
    const isIE = client.isIE();
    const isChrome = client.isChrome();
    const isFirefox = client.isFirefox();
    const isSafari = client.isSafari();
    const isMobileSafari = client.isMobileSafari();
    const isOpera = client.isOpera();
    const isWindows = client.isWindows();
    const isMac = client.isMac();
    const isLinux = client.isLinux();
    const isUbuntu = client.isUbuntu();
    const isSolaris = client.isSolaris();
    const isMobile = client.isMobile();
    const isMobileMajor = client.isMobileMajor();
    const isMobileAndroid = client.isMobileAndroid();
    const isMobileOpera = client.isMobileOpera();
    const isMobileWindows = client.isMobileWindows();
    const isMobileBlackBerry = client.isMobileBlackBerry();
    const isMobileIOS = client.isMobileIOS();
    const isIphone = client.isIphone();
    const isIpad = client.isIpad();
    const isIpod = client.isIpod();
    const isJava = client.isJava();
    const isFlash = client.isFlash();
    const isMimeTypes = client.isMimeTypes();
    const isFont = client.isFont();
    const isCanvas = client.isCanvas();
    const getSoftwareVersion = client.getSoftwareVersion();
    const getDeviceXDPI = client.getDeviceXDPI();
    const getDeviceYDPI = client.getDeviceYDPI();
    const getMimeTypes = client.getMimeTypes();
    const userAgent = {
        browser: { name: client.getBrowser() },
        cpu: { architecture: client.getCPU() },
        device: {
            vendor: client.getDeviceVendor(),
            model: client.getDevice(),
            type: client.getDeviceType(),
        },
        engine: { name: client.getEngine() },
        os: { name: client.getOS(), version: client.getOSVersion() },
    };
    let browserName = userAgent.browser.name;
    if (browserName.toLowerCase() === 'chrome') {
        browserName = !navigator?.brave ? browserName : 'Brave';
    }
    return {
        deviceFingerprint: `${client.getCustomFingerprint(userAgent, pdfViewerEnabled, isLocalStorage, isSessionStorage, isIE, isChrome, isFirefox, isSafari, isMobileSafari, isOpera, isWindows, isMac, isLinux, isUbuntu, isSolaris, isMobile, isMobileMajor, isMobileAndroid, isMobileOpera, isMobileWindows, isMobileBlackBerry, isMobileIOS, isIphone, isIpad, isIpod, isJava, isFlash, isMimeTypes, isFont, isLocalStorage, isSessionStorage, isCookie, isCanvas, getSoftwareVersion, getDeviceXDPI, getDeviceYDPI, getMimeTypes, getTimeZone, getLanguage, getSystemLanguage, getCanvasPrint, getFonts)}`,
        browserName,
    };
};
