import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeUsers = 30 * 1000;
export function useUserDevices(params) {
    return useQuery(['users', 'me', 'devices', params?.pageNumber || 0, params?.size || 100], createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: '/users/me/devices',
        params,
        transformData: (data) => data.devices,
    }), {
        staleTime: !params?.size ? 60 * 60 * 1000 : intervalTimeUsers,
        onError: (error) => error,
        refetchInterval: !params?.size ? 60 * 60 * 1000 : intervalTimeUsers,
        retry: false,
    });
}
export function useRemoveDevice() {
    const queryClient = useQueryClient();
    return useMutation((deviceId) => createFetchFn({
        requiresAuth: true,
        method: 'DELETE',
        url: `/users/me/devices/${deviceId}`,
    })(), {
        mutationKey: ['delete-device'],
        retry: false,
        onSuccess: () => {
            queryClient.refetchQueries(['users', 'me', 'devices'], { type: 'active' });
        },
    });
}
