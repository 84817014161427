import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BigNumber from 'bignumber.js';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { ColumnAlign } from '@emme/shared/model/table.model';
import EmmeCircularProgress from 'components/EmmeCircularProgress';
import { CoinWithUSDPriceCell } from './CoinToUSDPriceCell';
export const SpentCell = ({ columnProps, data }) => {
    const percentage = data?.maxTotalCost
        ? new BigNumber(data?.value || 0).dividedBy(data.maxTotalCost).multipliedBy(100)
        : undefined;
    return (_jsxs(Stack, { direction: "row", justifyContent: columnProps?.align || 'flex-end', gap: 2, children: [_jsx(CoinWithUSDPriceCell, { columnProps: columnProps, data: data }), percentage && (_jsx(Tooltip, { title: `${percentage.toFixed(2)}%`, disableInteractive: true, children: _jsx("span", { children: _jsx(EmmeCircularProgress, { value: percentage.toNumber(), thickness: 6, size: 25 }) }) }))] }));
};
SpentCell.defaultProps = {
    columnProps: {},
};
SpentCell.alignment = ColumnAlign.RIGHT;
