import { useEffect, useRef } from 'react';
import BigNumber from 'bignumber.js';
export function useConnectInputs(nameA, nameB, valueA, valueB, ratio, setFieldValue, isEnabled = true) {
    const ratioRef = useRef('');
    const previousValueARef = useRef('');
    const previousValueBRef = useRef('');
    const previousIsEnabled = useRef(isEnabled);
    const lastChangedInput = useRef(nameA);
    useEffect(() => {
        ratioRef.current = ratio || '';
    }, [ratio]);
    useEffect(() => {
        let nameToSync;
        let changedValue;
        let valueToSync;
        let multiplier;
        const valueAChanged = previousValueARef.current !== valueA;
        const valueBChanged = previousValueBRef.current !== valueB;
        const isEnabledChanged = previousIsEnabled.current !== isEnabled;
        const shouldSyncB = valueAChanged || (isEnabledChanged && lastChangedInput.current === nameA);
        const shouldSyncA = valueBChanged || (isEnabledChanged && lastChangedInput.current === nameB);
        if (shouldSyncB) {
            changedValue = valueA;
            nameToSync = nameB;
            valueToSync = valueB;
            multiplier = new BigNumber(ratioRef.current);
            lastChangedInput.current = nameA;
        }
        else if (shouldSyncA) {
            changedValue = valueB;
            nameToSync = nameA;
            valueToSync = valueA;
            multiplier = new BigNumber(1).dividedBy(ratioRef.current);
            lastChangedInput.current = nameB;
        }
        if (!nameToSync || changedValue === undefined || !multiplier || !isEnabled) {
            return;
        }
        if (changedValue === '') {
            setFieldValue(nameToSync, '');
        }
        else {
            const syncedValue = new BigNumber(changedValue).multipliedBy(multiplier).decimalPlaces(8);
            if (!syncedValue.eq(valueToSync || '')) {
                setFieldValue(nameToSync, syncedValue.toFixed(8));
            }
        }
    }, [nameA, nameB, valueA, valueB, setFieldValue, isEnabled]);
    useEffect(() => {
        previousValueARef.current = valueA || '';
    }, [valueA]);
    useEffect(() => {
        previousValueBRef.current = valueB || '';
    }, [valueB]);
    useEffect(() => {
        previousIsEnabled.current = isEnabled;
    }, [isEnabled]);
}
