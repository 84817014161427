import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from 'react';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import { ENABLE_TESTING_VALUES } from 'config';
import { useLiquidityMarketsTradeLast } from '@emme/shared/queries/LiquidityMarkets';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { ProvisionStatus } from '@emme/shared/model/enums';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { useRoundProgress } from 'hooks/useRoundProgress';
import { dayJsDayOfYear, dayJsTime } from 'utils/util';
import { CoinAmountCell, getColorByProvisionStatus, TypographyCell, } from 'components/Table/cells';
import { ProvisionOrdersDialog } from 'components/Dialogs';
import { EmmeTable } from 'components/Table';
const TradesRoundCell = ({ columnProps, data: { created, count, ...rest }, }) => (_jsx(Tooltip, { title: created ? (_jsxs(Typography, { variant: "subtitle1", color: "textPrimary", lineHeight: "20px", children: [_jsx(Box, { children: dayjs(new Date(created)).format(`${dayJsDayOfYear} ${dayJsTime}`) }), count > 0 && (_jsxs(Box, { children: ["Current round matches:", ' ', _jsx(Box, { component: "span", px: 1, children: count })] }))] })) : (''), disableInteractive: true, placement: "top-start", children: _jsx("span", { children: _jsx(TypographyCell, { columnProps: columnProps, data: rest }) }) }));
const TradesButtonCell = ({ columnProps, data: { sx, ...rest } }) => (_jsx(LoadingButton, { type: "button", color: "inherit", variant: rest?.loading ? 'text' : 'outlined', size: "small", loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 12 }), ...columnProps, ...rest, sx: {
        borderRadius: (t) => t.spacing(2),
        px: 1,
        minWidth: '20px',
        ...sx,
    }, children: _jsx(SwapVertOutlinedIcon, {}) }));
const headCells = [
    {
        id: 'round',
        Component: TradesRoundCell,
        label: 'Round',
    },
    {
        id: 'price',
        label: 'Price',
        Component: CoinAmountCell,
        align: ColumnAlign.RIGHT,
        componentProps: {
            numberDecimals: 4,
        },
    },
    {
        id: 'quoteVolume',
        label: 'Liquidity',
        Component: CoinAmountCell,
        align: ColumnAlign.RIGHT,
    },
    {
        id: 'button',
        label: '',
        align: ColumnAlign.CENTER,
        Component: TradesButtonCell,
        componentProps: {
            'aria-label': 'Show round details',
        },
    },
];
const TradesTable = () => {
    const [selectedTradeId, setSelectedTradeId] = useState();
    const { selectedLiquidityMarket } = useContext(LiquidityContext);
    const { current } = useRoundProgress();
    const { data: trades, isLoading: isLoadingTrades } = useLiquidityMarketsTradeLast({
        liquidityMarketId: selectedLiquidityMarket?.id || '',
        size: ENABLE_TESTING_VALUES ? 500 : 75,
    });
    // Tweak for BE slow response on new round
    if (trades?.length &&
        current &&
        trades[0].roundIncrementalId !== current?.incrementalId &&
        trades[0].roundIncrementalId === (current?.incrementalId || 0) - 1) {
        trades.unshift({
            id: `-1_${current?.incrementalId}`,
            marketId: selectedLiquidityMarket?.id || trades[0]?.marketId,
            roundIncrementalId: current?.incrementalId,
            baseVolume: '-',
            quoteVolume: '-',
            price: '-',
            provisionStatus: ProvisionStatus.PENDING,
            createdAt: current.startTime,
            count: null,
        });
    }
    const selectedTradeIdIndex = selectedTradeId
        ? (trades || []).findIndex((t) => t.id === selectedTradeId)
        : undefined;
    const onCloseProvisionOrdersDialog = () => {
        setSelectedTradeId(undefined);
    };
    const findNavigationTradesId = useCallback((isNext) => {
        const indexChange = isNext ? 1 : -1;
        let elementId = null;
        let index = (selectedTradeIdIndex || 0) + indexChange;
        const newTrades = [...(trades || [])];
        while (!elementId && index >= 0 && index <= newTrades.length) {
            if (newTrades[index]?.provisionStatus === ProvisionStatus.SUCCESSFUL) {
                elementId = newTrades[index].id || null;
            }
            index += indexChange;
        }
        return elementId;
    }, [selectedTradeIdIndex, trades]);
    return (_jsxs(Stack, { sx: { height: '100%', maxHeight: '100%' }, children: [_jsx(Typography, { variant: "h6", py: 4, px: 5, children: "Market Trades" }), _jsx(Box, { sx: { flex: 'auto', flexBasis: 0, overflowY: 'auto' }, children: _jsx(EmmeTable, { rows: (trades || []).map((data) => ({
                        id: data.id,
                        round: {
                            created: data.createdAt,
                            count: data.count || 0,
                            text: `${data.roundIncrementalId || 0}`,
                        },
                        price: {
                            amount: data.price,
                            coin: 'emme',
                        },
                        quoteVolume: {
                            amount: data.quoteVolume,
                            coin: selectedLiquidityMarket?.quoteCoin,
                        },
                        button: {
                            onClick: () => setSelectedTradeId(data.id),
                            loading: selectedTradeId === data.id,
                            disabled: data?.id.includes('-1_'),
                            sx: { borderColor: getColorByProvisionStatus(data.provisionStatus) || 'inherit' },
                        },
                    })), inputHeadCells: headCells, tableMinWidth: 250, stickyHeader: true, isLoading: isLoadingTrades || !selectedLiquidityMarket }) }), _jsx(ProvisionOrdersDialog, { liquidityMarket: selectedLiquidityMarket, liquidityTradeId: selectedTradeId, onProvisionsFetched: onCloseProvisionOrdersDialog, navigationButtons: selectedTradeIdIndex !== undefined
                    ? {
                        prev: findNavigationTradesId(false),
                        next: findNavigationTradesId(true),
                    }
                    : undefined, onNavigate: (id) => setSelectedTradeId(id || undefined) })] }));
};
export default TradesTable;
