import { SET_EXCHANGE, SET_LIQUIDITY_MARKET } from './actionTypes';
export default (state, action) => {
    switch (action.type) {
        case SET_LIQUIDITY_MARKET:
            if (state.selectedLiquidityMarketId !== action.payload) {
                return { ...state, selectedLiquidityMarketId: action.payload };
            }
            return state;
        case SET_EXCHANGE:
            if (state.selectedExchangeId !== action.payload) {
                return {
                    ...state,
                    selectedExchangeId: action.payload,
                    selectedLiquidityMarketId: undefined,
                };
            }
            return state;
        default:
            return state;
    }
};
