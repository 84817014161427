import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import BigNumber from 'bignumber.js';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { CoinWithUSDPriceCell, VolumeCell, PriceFunctionCell, FromToDateCell, ButtonCell, TwoButtonCell, SpentCell, } from 'components/Table/cells';
import { formatSpread } from 'utils/util';
const tradesButtonColumnProps = { label: 'Trades' };
const cancelButtonColumnProps = {
    label: 'Cancel',
    sx: {
        border: (t) => `1px solid ${t.palette.error.main}`,
        backgroundColor: (t) => `${t.palette.error.main}20`,
        ':hover': {
            backgroundColor: (t) => `${t.palette.error.main}60`,
        },
    },
};
const getButtonHeadCell = ({ withCancelButton, withTradesButton, verticalRows, }) => {
    let buttonHeadCell;
    if (withTradesButton && withCancelButton) {
        buttonHeadCell = {
            id: 'button',
            label: 'Action',
            Component: TwoButtonCell,
            componentProps: {
                buttons: [cancelButtonColumnProps, tradesButtonColumnProps],
                verticalRows,
            },
        };
    }
    else if (withTradesButton || withCancelButton) {
        buttonHeadCell = {
            id: 'button',
            label: 'Action',
            Component: ButtonCell,
            componentProps: withTradesButton ? tradesButtonColumnProps : cancelButtonColumnProps,
        };
    }
    return buttonHeadCell;
};
const getCreatedAtHeadCell = (verticalRows) => ({
    id: 'createdAt',
    label: verticalRows ? (_jsxs(_Fragment, { children: ["From:", _jsx("br", {}), "To:"] })) : ('Time'),
    Component: FromToDateCell,
    componentProps: {
        hideLabels: verticalRows,
    },
});
export const getProvisionsHeadCells = (options) => {
    const buttonHeadCell = getButtonHeadCell(options);
    return [
        getCreatedAtHeadCell(options.verticalRows),
        {
            id: 'function',
            label: 'Price Range',
            Component: PriceFunctionCell,
        },
        {
            id: 'volume',
            label: 'Liquidity',
            Component: VolumeCell,
        },
        {
            id: 'provided',
            label: 'Provided Liquidity',
            Component: VolumeCell,
            componentProps: {
                addDays: true,
            },
        },
        {
            id: 'earned',
            label: 'Earned $EMME',
            Component: CoinWithUSDPriceCell,
        },
        {
            id: 'performance',
            label: 'Performance',
            Component: VolumeCell,
            componentProps: {
                addColor: true,
            },
        },
    ].concat(buttonHeadCell ? [buttonHeadCell] : []);
};
export const getSubscriptionsHeadCells = ({ quoteCoin, ...options }) => {
    const buttonHeadCell = getButtonHeadCell(options);
    return [
        getCreatedAtHeadCell(options.verticalRows),
        {
            id: 'price',
            label: `Price ($EMME/${quoteCoin || '-'} days)`,
            Component: CoinWithUSDPriceCell,
        },
        {
            id: 'spread',
            label: 'Spread',
            align: ColumnAlign.CENTER,
        },
        {
            id: 'volume',
            label: `Liquidity (${quoteCoin || '-'})`,
            Component: CoinWithUSDPriceCell,
        },
        {
            id: 'receivedLiquidity',
            label: `Received Liquidity (${quoteCoin || '-'} days)`,
            Component: CoinWithUSDPriceCell,
        },
        {
            id: 'spent',
            label: 'Spent $EMME',
            Component: SpentCell,
        },
    ].concat(buttonHeadCell ? [buttonHeadCell] : []);
};
export const getProvisionTableData = ({ data, liquidityMarket, emmeCoin, button, }) => ({
    id: data.id,
    createdAt: {
        from: data.createdAt,
        to: data.expirationDate,
    },
    function: {
        priceCurve: data.priceCurve,
        coin: liquidityMarket?.quoteCoin,
    },
    volume: {
        base: {
            amount: data.baseVolume,
            coin: liquidityMarket?.baseCoin,
        },
        quote: {
            amount: data.quoteVolume,
            coin: liquidityMarket?.quoteCoin,
        },
    },
    provided: {
        base: {
            amount: data.providedBaseLiquidity,
            coin: liquidityMarket?.baseCoin,
        },
        quote: {
            amount: data.providedQuoteLiquidity,
            coin: liquidityMarket?.quoteCoin,
        },
    },
    earned: {
        value: data.totalEarned,
        usdPrice: emmeCoin?.priceUsd,
    },
    performance: {
        base: {
            amount: new BigNumber(data.currentBaseVolume).minus(data.baseVolume).toFixed(8),
            coin: liquidityMarket?.baseCoin,
        },
        quote: {
            amount: new BigNumber(data.currentQuoteVolume).minus(data.quoteVolume).toFixed(8),
            coin: liquidityMarket?.quoteCoin,
        },
    },
    button,
});
export const getSubscriptionTableData = ({ data, emmeCoin, quoteCoin, button, }) => ({
    id: data.id,
    createdAt: {
        from: data.createdAt,
        to: data.expirationDate,
    },
    price: {
        value: data.price,
        usdPrice: emmeCoin?.priceUsd,
        isPrice: true,
    },
    spread: formatSpread(Number(data.spread)),
    volume: {
        value: data.volume,
        usdPrice: quoteCoin?.priceUsd,
    },
    receivedLiquidity: {
        value: data.receivedQuoteLiquidity,
        usdPrice: quoteCoin?.priceUsd,
    },
    spent: {
        value: data.totalCost,
        usdPrice: emmeCoin?.priceUsd,
        maxTotalCost: data.maxTotalCost,
    },
    button,
});
