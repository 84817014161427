import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { SpecialNotificationTypeEnum } from '@emme/shared/model/enums';
import { useUpdateUserNotifications, useUserNotificationsData } from '@emme/shared/queries/Users';
import { DialogLayout } from 'layout/Dialogs';
import { ConfettiAnimation } from './Animations';
const CongratulationDialog = () => {
    const [newSpecial, setNewSpecial] = useState(null);
    const { data: { specialNotifications } = {} } = useUserNotificationsData({ read: 'unread' });
    const markAsRead = useUpdateUserNotifications();
    useEffect(() => {
        if (specialNotifications?.elements?.length) {
            setNewSpecial(specialNotifications.elements[specialNotifications.elements.length - 1]);
        }
    }, [specialNotifications]);
    const quickType = () => {
        switch (newSpecial?.text) {
            case SpecialNotificationTypeEnum.FIRST_GAIN:
                return 'gained your FIRST $EMME';
            case SpecialNotificationTypeEnum.FIRST_ORDER:
                return 'placed your first order';
            default:
                console.info(`wrong notification text = ${newSpecial?.text}`);
                setNewSpecial(undefined);
                break;
        }
    };
    const handleClose = (id) => {
        if (id) {
            setNewSpecial(undefined);
            markAsRead.mutateAsync({ notificationId: id, isRead: true });
        }
    };
    return newSpecial ? (_jsx(DialogLayout, { open: true, onClose: () => handleClose(newSpecial?.id), PaperProps: {
            sx: { maxWidth: '320px' },
        }, children: _jsxs(Stack, { alignItems: "center", children: [_jsx(ConfettiAnimation, {}), _jsxs(Typography, { variant: "h6", align: "center", pb: 8, children: ["Congratulations! You successfully ", quickType(), "!"] }), _jsx(Button, { color: "primary", variant: "contained", onClick: () => handleClose(newSpecial?.id), sx: { minWidth: '170px' }, children: "Great!" })] }) })) : null;
};
export default CongratulationDialog;
