import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import OnboardingFooter from './OnboardingFooter';
import OnboardingStepper from './OnboardingStepper';
const OnboardingLayout = ({ title, subtitle, hidden, children, PaperProps, BackdropProps, sx, ...rest }) => (_jsx(Popover, { PaperProps: {
        ...PaperProps,
        sx: {
            borderRadius: 5,
            maxWidth: '490px',
            minWidth: '400px',
            p: 4,
            backgroundColor: (t) => t.palette.neutralsTwo.main,
            zIndex: 1001,
            '& .MuiOutlinedInput-root': {
                backgroundColor: (t) => t.palette.neutralsThree.main,
            },
            ...(PaperProps?.sx ? PaperProps.sx : {}),
            visibility: ['visible', undefined, hidden ? 'hidden' : 'visible'],
            opacity: [1, undefined, hidden ? 0 : 1],
            transition: 'all 0.1s ease',
        },
    }, BackdropProps: {
        ...BackdropProps,
        sx: { zIndex: 1000, ...(BackdropProps?.sx ? BackdropProps.sx : {}) },
    }, sx: { zIndex: 1001, ...sx }, ...rest, children: _jsxs(Box, { sx: {
            visibility: ['visible', undefined, hidden ? 'hidden' : 'visible'],
            opacity: [1, undefined, hidden ? 0 : 1],
            transition: 'all 0.1s ease',
        }, children: [(title || subtitle) && (_jsxs(Stack, { p: 5, children: [_jsx(OnboardingStepper, {}), _jsx(Typography, { align: "center", variant: "h4", pt: 8, children: title }), subtitle && (_jsx(Typography, { align: "center", variant: "h6", pt: 7, children: subtitle }))] })), _jsxs(Stack, { p: 5, ...(title || subtitle ? {} : { pt: 0 }), children: [children, _jsx(OnboardingFooter, {})] })] }) }));
OnboardingLayout.defaultProps = {
    title: undefined,
    subtitle: undefined,
};
export default OnboardingLayout;
