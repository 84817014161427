import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
import { useUserBalanceData } from '@emme/shared/queries/Users';
import { OverviewCardLayout } from 'layout/Overview';
import { CoinIcon } from 'components/CoinIcon';
export function EmmeCard() {
    const { data: balance } = useUserBalanceData();
    const { data: emmeCoinData } = useMarketInfoLatestCoinData('emme');
    const emmeBalance = balance ? new BigNumber(balance.available).toFormat(4) : '-';
    const usdBalance = balance && emmeCoinData
        ? new BigNumber(balance.available).multipliedBy(emmeCoinData.priceUsd).toFormat(2)
        : '-';
    return (_jsx(OverviewCardLayout, { icon: _jsx(CoinIcon, { coin: "emme", size: "100%" }), title: "$EMME", children: _jsxs(Stack, { gap: 3, children: [_jsx(Box, { children: emmeBalance }), _jsx(Typography, { variant: "subtitle1", color: "textSecondary", children: `$${usdBalance}` })] }) }));
}
