/* eslint-disable import/no-extraneous-dependencies */
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { themeBase } from './themeBase';
import { customComponents, customPalette, customTypography } from './themeOptions';
const colors = {
    primaryMain: '#18A0FB',
    secondaryMain: '#58BD7D',
    info: '#FFD166',
    error: '#FF5C38',
    background: '#FAFAFA',
    emmeError: '#FF5C38',
    emmePrimary: '#0D404C',
    emmeSecondary: '#0F8F57',
    emmeInfo: '#FFD166',
    neutralsOne: '#FCFCFD',
    neutralsOneDark: '#F4F5F6',
    neutralsTwo: '#E6E8EC',
    neutralsThree: '#B1B5C3',
    neutralsFour: '#777E90',
    neutralsFive: '#353945',
    neutralsSix: '#23262F',
    neutralsSeven: '#18191D',
    neutralsEight: '#1A1C23',
};
const createMuiTheme = (custom, options, ...args) => createTheme(deepmerge(custom, options), ...args);
const lightTheme = createMuiTheme({
    palette: { ...customPalette('light', colors) },
}, { ...themeBase }, {
    typography: { ...customTypography(colors) },
    components: { ...customComponents(colors) },
});
export default lightTheme;
