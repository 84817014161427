import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { RouteEnum } from '@emme/shared/model/enums';
import { useSelectedExchangeBalanceStatus } from 'hooks/useSelectedExchangeBalance';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { useLiquidityMarketOrdersData } from '@emme/shared/queries/LiquidityMarkets';
import { OverviewCardLayout } from 'layout/Overview';
export function OrdersCard() {
    const { selectedLiquidityMarket } = useContext(liquidityContext);
    const { isLoading } = useSelectedExchangeBalanceStatus();
    const { data: openProvisions, isLoading: isLoadingProvisions } = useLiquidityMarketOrdersData({
        liquidityMarketId: selectedLiquidityMarket?.id || '',
        open: true,
        type: 'provisions',
    });
    const { data: openSubscriptions, isLoading: isLoadingSubscriptions } = useLiquidityMarketOrdersData({
        liquidityMarketId: selectedLiquidityMarket?.id || '',
        open: true,
        type: 'subscriptions',
    });
    const isLoadingInfo = isLoadingProvisions || isLoadingSubscriptions || isLoading;
    const openOrderCount = (openProvisions?.totalNumber || 0) + (openSubscriptions?.totalNumber || 0);
    return (_jsx(OverviewCardLayout, { title: "Open Orders", children: isLoadingInfo ? (_jsxs(Stack, { gap: 3, children: [_jsx(Skeleton, { animation: "wave", width: 20, height: 20 }), _jsx(Box, { children: _jsx(Skeleton, { animation: "wave", width: 90, height: 20 }) })] })) : (_jsxs(Stack, { gap: 3, alignItems: "flex-start", children: [_jsx(Box, { children: openOrderCount }), _jsx(Typography, { color: "success.main", variant: "h6", fontSize: 12, component: RouterLink, to: RouteEnum.AccountOrders, sx: { textDecoration: 'none' }, children: "View all orders \u203A" })] })) }));
}
