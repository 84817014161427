import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { useCancelLiquidityMarketOrder } from '@emme/shared/queries/LiquidityMarkets';
import { AlertContext } from 'context/AlertProvider';
import { ErrorLoadingButton } from 'components/Buttons';
export function CancelOrderDialog({ type, liquidityMarket, orderId, onOrderCancel, onClose, }) {
    const { setAlert } = useContext(AlertContext);
    const { isLoading, mutateAsync } = useCancelLiquidityMarketOrder({
        liquidityMarketId: liquidityMarket?.id || '',
        isSubscription: type === 'subscriptions',
    });
    const cancelOrder = async () => {
        if (isLoading)
            return;
        if (!liquidityMarket || !orderId) {
            setAlert('Market was not selected', 'error', 2500);
            return;
        }
        try {
            await mutateAsync(orderId);
            setAlert('Order cancelled', 'success', 2500);
            if (liquidityMarket.id && onOrderCancel) {
                onOrderCancel();
            }
            onClose();
        }
        catch (error) {
            setAlert(error?.message || error, 'error');
        }
    };
    return (_jsxs(Dialog, { open: Boolean(orderId), onClose: onClose, PaperProps: {
            sx: { width: '270px', borderRadius: 4, backgroundColor: (t) => t.palette.neutralOne.main },
        }, children: [_jsxs(DialogContent, { sx: { textAlign: 'center' }, children: [_jsx(DeleteOutline, { sx: { fontSize: '32px', mb: 4 } }), _jsx(Typography, { variant: "h6", children: "A canceled order will be permanently removed." })] }), _jsxs(DialogActions, { sx: { justifyContent: 'space-evenly', pt: 0, pb: 5 }, children: [_jsx(Button, { onClick: onClose, type: "button", variant: "outlined", size: "small", color: "inherit", children: "No, keep order" }), _jsx(ErrorLoadingButton, { onClick: cancelOrder, loading: isLoading, children: "Yes, cancel order" })] })] }));
}
CancelOrderDialog.defaultProps = {
    onOrderCancel: undefined,
};
