import { useContext, useMemo } from 'react';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { useUserExchangesBalanceData } from '@emme/shared/queries/Users';
export function useSelectedExchangeBalanceStatus() {
    const { selectedExchange, isLoading: isLoadingLiquidityState } = useContext(liquidityContext);
    const { data: exchangeBalances, isLoading: isLoadingExchangeBalances } = useUserExchangesBalanceData();
    return useMemo(() => ({
        data: exchangeBalances?.find(({ exchangeId }) => exchangeId === selectedExchange?.id)?.status,
        isLoading: isLoadingLiquidityState || isLoadingExchangeBalances,
    }), [exchangeBalances, isLoadingExchangeBalances, isLoadingLiquidityState, selectedExchange?.id]);
}
