import { createContext } from 'react';
const liquidityContext = createContext({
    selectedLiquidityMarket: undefined,
    selectedExchange: undefined,
    changeLiquidityMarket: () => null,
    changeExchange: () => null,
    isLoading: true,
    exchanges: [],
    markets: [],
});
export default liquidityContext;
