export var ColumnSort;
(function (ColumnSort) {
    ColumnSort["ASC"] = "asc";
    ColumnSort["DESC"] = "desc";
})(ColumnSort || (ColumnSort = {}));
export var ColumnAlign;
(function (ColumnAlign) {
    ColumnAlign["RIGHT"] = "right";
    ColumnAlign["LEFT"] = "left";
    ColumnAlign["CENTER"] = "center";
})(ColumnAlign || (ColumnAlign = {}));
