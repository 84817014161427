import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import lightTheme from '@emme/shared/material/lightTheme';
import darkTheme from '@emme/shared/material/darkTheme';
import { StorageKey } from '@emme/shared/model/enums';
import { storage } from '@emme/shared';
import { ENABLE_THEMES } from 'config';
export var ThemeType;
(function (ThemeType) {
    ThemeType[ThemeType["darkThemePref"] = 0] = "darkThemePref";
    ThemeType[ThemeType["lightThemePref"] = 1] = "lightThemePref";
})(ThemeType || (ThemeType = {}));
export const ThemeContext = createContext({
    theme: ThemeType.darkThemePref,
    setTheme: () => null,
});
export const EMMEThemeProvider = ({ children }) => {
    const [currentTheme, setCurrentTheme] = useState(ThemeType.darkThemePref);
    const darkModeMediaQuery = useRef(window.matchMedia('(prefers-color-scheme: dark)'));
    useEffect(() => {
        const storageType = storage.get(StorageKey.EMME_DARK_MODE);
        if (typeof storageType === 'number') {
            if (ENABLE_THEMES) {
                setCurrentTheme(storageType);
            }
            else {
                setCurrentTheme(ThemeType.darkThemePref);
            }
        }
        const setThemeFromMediaQuery = (e) => {
            if (ENABLE_THEMES) {
                setCurrentTheme(e.matches ? ThemeType.darkThemePref : ThemeType.lightThemePref);
            }
            else {
                setCurrentTheme(ThemeType.darkThemePref);
            }
        };
        const mediaQuery = darkModeMediaQuery.current;
        mediaQuery?.addEventListener('change', setThemeFromMediaQuery);
        return () => {
            mediaQuery?.removeEventListener('change', setThemeFromMediaQuery);
        };
    }, []);
    useEffect(() => {
        storage.set(StorageKey.EMME_DARK_MODE, currentTheme);
    }, [currentTheme]);
    const setTheme = useCallback((data) => {
        setCurrentTheme(data ? ThemeType.lightThemePref : ThemeType.darkThemePref);
    }, []);
    function getTheme() {
        if (currentTheme === ThemeType.darkThemePref) {
            return darkTheme;
        }
        return lightTheme;
    }
    const selectedTheme = getTheme();
    const value = useMemo(() => ({
        theme: currentTheme,
        setTheme,
    }), [currentTheme, setTheme]);
    return (_jsx(ThemeContext.Provider, { value: value, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeProvider, { theme: selectedTheme, children: children }) }) }));
};
