import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { Fade } from '@mui/material';
import { OnboardingContext, OnboardingType } from 'context/OnboardingProvider';
import { useBreakpoint } from 'hooks/useBreakpoint';
import OnboardingLayout from './components/OnboardingDialogLayout';
import { Step1, Step2, Step3, Step4, Step5 } from './components/steps';
const stepArray = [
    {
        title: 'Welcome to EM.ME!',
        subtitle: 'Are you a provider or a subscriber?',
        Node: Step1,
        stepNumber: 1,
    },
    {
        title: 'Exchange Selection',
        Node: Step2,
        anchorElementID: 'exchange-button',
        anchorOriginH: 'right',
        transformOriginH: 'left',
        paperProps: {
            sx: { ml: '100px' },
        },
        sx: {},
        stepNumber: 2,
    },
    { title: 'Import API Key', Node: Step3, stepNumber: 3 },
    { Node: Step4, stepNumber: 4 },
    {
        title: 'Placing Order',
        Node: Step5,
        stepNumber: 5,
        anchorOriginH: 'left',
        transformOriginH: 'right',
        anchorElementID: 'provision-form',
    },
];
const OnboardingDialog = () => {
    const { tablet } = useBreakpoint();
    const dialogAllSteps = stepArray.slice();
    if (tablet) {
        dialogAllSteps.splice(1, 1);
    }
    const { open, hidden, closeOnboarding, activeStep, showSkip, changeAllSteps, onboardingType } = useContext(OnboardingContext);
    stepArray[0].title = `Welcome ${onboardingType !== OnboardingType.WELCOME_BACK ? '' : 'back '}to EM.ME!`;
    stepArray[0].subtitle =
        onboardingType !== OnboardingType.WELCOME_BACK
            ? 'Are you a provider or a subscriber?'
            : 'It seems you have been offline for some time..';
    useEffect(() => {
        changeAllSteps(dialogAllSteps || []);
    }, [dialogAllSteps, changeAllSteps]);
    useEffect(() => () => {
        if (onboardingType === OnboardingType.WELCOME_BACK ||
            onboardingType === OnboardingType.HELP) {
            closeOnboarding();
        }
    }, [onboardingType, closeOnboarding]);
    const anchorElement = document.getElementById(tablet && dialogAllSteps[activeStep]?.stepNumber === 5
        ? 'root'
        : dialogAllSteps[activeStep]?.anchorElementID || 'root');
    const Node = dialogAllSteps[activeStep]?.Node;
    const anchorOriginV = dialogAllSteps[activeStep]?.anchorOriginV || 'top';
    const anchorOriginH = dialogAllSteps[activeStep]?.anchorOriginH || 'center';
    const transformOriginV = dialogAllSteps[activeStep]?.transformOriginV || 'top';
    const transformOriginH = dialogAllSteps[activeStep]?.transformOriginH || 'center';
    const paperProps = tablet
        ? {
            sx: {
                borderRadius: 0,
                height: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
                minWidth: undefined,
                width: '100%',
            },
        }
        : dialogAllSteps[activeStep]?.paperProps || {};
    const sxProps = tablet ? {} : dialogAllSteps[activeStep]?.sx || { mt: '20vh' };
    return (_jsx(OnboardingLayout, { anchorEl: anchorElement, anchorOrigin: {
            vertical: anchorOriginV,
            horizontal: anchorOriginH,
        }, transformOrigin: {
            vertical: transformOriginV,
            horizontal: transformOriginH,
        }, PaperProps: {
            sx: paperProps.sx,
        }, marginThreshold: tablet ? 0 : 16, BackdropProps: {
            sx: { backgroundColor: showSkip ? 'transparent' : undefined },
        }, sx: { ...sxProps }, open: open, hidden: hidden, title: dialogAllSteps[activeStep]?.title, subtitle: dialogAllSteps[activeStep]?.subtitle, TransitionComponent: Fade, children: Node && _jsx(Node, {}) }));
};
export default OnboardingDialog;
