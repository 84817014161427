import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
export function LoadingOverlay({ children, isLoading, zIndex, size, sx, ...rest }) {
    return (_jsxs(Box, { sx: {
            position: 'relative',
            pointerEvents: isLoading ? 'none' : 'initial',
            ...sx,
        }, ...rest, children: [Boolean(children) && (_jsx(Box, { sx: {
                    opacity: isLoading ? 0 : 1,
                    visibility: isLoading ? 'hidden' : 'visible',
                    transition: 'all 0.2s',
                }, children: children })), isLoading && (_jsx(Box, { sx: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex,
                }, children: _jsx(CircularProgress, { size: size }) }))] }));
}
LoadingOverlay.defaultProps = {
    children: undefined,
    zIndex: undefined,
    size: undefined,
};
