import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ENABLE_EMME_LIQUIDITY_CHART } from 'config';
import { StorageKey, ChartTabs } from '@emme/shared/model/enums';
import { usePersistedState } from 'hooks/usePersistedState';
import { EmmeTabs } from 'components/Tabs';
import { SimulatedMarketDepthChart } from './components/SimulatedMarketDepthChart';
import { EMMELiquidityChart } from './components/EMMELiquidityChart';
import TradingViewChart from './components/TradingViewChart';
const tabs = [
    ...(!ENABLE_EMME_LIQUIDITY_CHART
        ? [{ label: ChartTabs.TradingView }, { label: ChartTabs.SimulatedMarketDepth }]
        : [
            { label: ChartTabs.TradingView },
            { label: ChartTabs.EMMELiquidity },
            { label: ChartTabs.SimulatedMarketDepth },
            { label: ChartTabs.LiquidityDepth, isDisabled: true },
        ]),
];
const tabComponents = [
    ...(!ENABLE_EMME_LIQUIDITY_CHART ? [null] : [null, EMMELiquidityChart]),
    SimulatedMarketDepthChart,
];
const Charts = () => {
    const [activeTab, setActiveTab] = usePersistedState(tabs[0].label, StorageKey.EMME_CHART);
    const isTradingViewActive = activeTab === tabs[0].label;
    const TabComponent = tabComponents[tabs.findIndex(({ label }) => label === activeTab)] || Fragment;
    return (_jsxs(Stack, { height: "100%", children: [_jsx(EmmeTabs, { value: activeTab, onChange: setActiveTab, tabs: tabs }), _jsx(Box, { sx: { p: 0, flex: 'auto', display: isTradingViewActive ? 'flex' : 'none' }, children: _jsx(TradingViewChart, {}) }), !isTradingViewActive && (_jsx(Box, { sx: { p: 0, flex: 'auto' }, children: _jsx(TabComponent, {}) }))] }));
};
export default Charts;
