import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { useCoinBalance } from 'context/Overview/hooks';
import { OverviewMarketSlider } from './OverviewMarketSlider';
const MAX_VOLUME = 1000;
export function OverviewMarketVolume({ coin, type }) {
    const [volume, setVolume] = useState(200);
    const { markets } = useContext(liquidityContext);
    const { data: coinData } = useMarketInfoLatestCoinData(coin);
    const balance = useCoinBalance(coin);
    const balanceUSD = balance.multipliedBy(coinData?.priceUsd || 0);
    const minimumVolume = Math.max(0, ...markets
        .filter(({ quoteCoin, baseCoin }) => (type === 'base' ? baseCoin : quoteCoin).toUpperCase() === coin.toUpperCase())
        .map(({ minimumBaseOrderVolume, minimumQuoteOrderVolume }) => Number(type === 'base' ? minimumBaseOrderVolume : minimumQuoteOrderVolume)));
    const availableBalanceHeightPercentage = BigNumber.min(balanceUSD, MAX_VOLUME)
        .dividedBy(MAX_VOLUME)
        .multipliedBy(100)
        .toNumber();
    const usedBalanceHeightPercentage = BigNumber.min(volume, balanceUSD)
        .dividedBy(BigNumber.min(balanceUSD, MAX_VOLUME))
        .multipliedBy(100)
        .toNumber();
    return (_jsxs(Box, { children: [_jsx(Box, { children: "Set your biggest order size" }), _jsxs(Stack, { direction: "row", alignItems: "flex-end", gap: 5, children: [_jsxs(Typography, { component: "div", children: ["Your current balance", _jsx("br", {}), `${balance.toFormat(2)} ${coin.toUpperCase()} ($${balanceUSD.toFormat(2)})`] }), _jsx(Box, { sx: { alignSelf: 'stretch', display: 'flex', alignItems: 'flex-end' }, children: _jsx(Box, { sx: {
                                height: `${availableBalanceHeightPercentage}%`,
                                width: '8px',
                                minHeight: '2px',
                                backgroundColor: ({ palette }) => palette.warning.main,
                                borderRadius: 1,
                            }, children: _jsx(Box, { style: {
                                    transform: `translateY(${(100 - usedBalanceHeightPercentage) / 2}%) scaleY(${usedBalanceHeightPercentage}%)`,
                                }, sx: {
                                    width: '100%',
                                    height: '100%',
                                    transition: 'transform 150ms ease',
                                    backgroundColor: ({ palette }) => palette.primary.main,
                                    borderRadius: 1,
                                    ...(usedBalanceHeightPercentage === 100
                                        ? {}
                                        : {
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                        }),
                                } }) }) }), _jsx(OverviewMarketSlider, { value: volume, onChange: (_, value) => {
                            setVolume(value);
                        }, min: minimumVolume, max: MAX_VOLUME }), _jsxs(Stack, { justifyContent: "space-between", sx: { alignSelf: 'stretch' }, children: [_jsxs(Typography, { component: "div", children: ["Maximum EM.ME will allow", _jsx("br", {}), "(up to $", new BigNumber(MAX_VOLUME).toFormat(2), ")"] }), _jsxs(Typography, { component: "div", children: ["Minimum for EM.ME to run", _jsx("br", {}), "(at least $", new BigNumber(minimumVolume).toFormat(2), ")"] })] })] })] }));
}
