import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { SignupPage, LoginPage, ForgotPasswordPage, NewPasswordPage, SignupCodeVerificationPage, AccountVerificationPage, AccountActivationPage, PrivacyPolicyPage, DeviceVerificationPage, DeviceActivationPage, } from 'pages';
import { RECAPTCHA_KEY, isProduction, ENABLE_SIGNUP_CODE } from 'config';
import { RouteEnum } from '@emme/shared/model/enums';
const NavigateToLogin = () => {
    const location = useLocation();
    return (_jsx(Navigate, { replace: true, to: RouteEnum.Login, state: { redirectedFrom: location.pathname + location.search } }));
};
const Public = () => (_jsxs(Routes, { children: [_jsx(Route, { path: RouteEnum.Login, element: _jsx(LoginPage, {}) }), ENABLE_SIGNUP_CODE && (_jsx(Route, { path: RouteEnum.SignupCodeVerification, element: _jsx(SignupCodeVerificationPage, {}) })), _jsx(Route, { path: RouteEnum.DeviceVerification, element: _jsx(GoogleReCaptchaProvider, { reCaptchaKey: RECAPTCHA_KEY, children: _jsx(DeviceVerificationPage, {}) }) }), _jsx(Route, { path: RouteEnum.DeviceActivation, element: _jsx(GoogleReCaptchaProvider, { reCaptchaKey: RECAPTCHA_KEY, children: _jsx(DeviceActivationPage, {}) }) }), _jsx(Route, { path: RouteEnum.Signup, element: _jsx(SignupPage, {}) }), _jsx(Route, { path: RouteEnum.EmailVerification, element: _jsx(GoogleReCaptchaProvider, { reCaptchaKey: RECAPTCHA_KEY, children: _jsx(AccountVerificationPage, {}) }) }), _jsx(Route, { path: RouteEnum.EmailActivation, element: _jsx(GoogleReCaptchaProvider, { reCaptchaKey: RECAPTCHA_KEY, children: _jsx(AccountActivationPage, {}) }) }), _jsx(Route, { path: RouteEnum.ForgotPassword, element: _jsx(GoogleReCaptchaProvider, { reCaptchaKey: RECAPTCHA_KEY, children: _jsx(ForgotPasswordPage, {}) }) }), _jsx(Route, { path: RouteEnum.ResetPassword, element: _jsx(NewPasswordPage, {}) }), !isProduction && _jsx(Route, { path: RouteEnum.PrivacyPolicy, element: _jsx(PrivacyPolicyPage, {}) }), _jsx(Route, { path: "*", element: _jsx(NavigateToLogin, {}) })] }));
export default Public;
