import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { OnboardingContext } from 'context/OnboardingProvider';
import { useExchangesData } from '@emme/shared/queries/Exchanges';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { ExchangeAvatar } from 'components/ExchangeAvatar';
const ExchangeSelectMenu = ({ anchorEl, onClose }) => {
    const { selectedExchange, changeExchange } = useContext(LiquidityContext);
    const { allSteps, activeStep } = useContext(OnboardingContext);
    const { tablet } = useBreakpoint();
    const onboardingTabletView = allSteps[activeStep]?.stepNumber === 2 && !tablet;
    const { data: exchanges } = useExchangesData();
    const handleChangeExchange = (id) => {
        if (exchanges && id) {
            onClose();
            changeExchange(id);
        }
    };
    return (_jsx(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: onClose, anchorReference: undefined, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'center' }, PaperProps: {
            sx: {
                maxHeight: 600,
                width: 210,
                backgroundColor: (t) => t.palette.neutralsThree.main,
                py: 0,
                ...(onboardingTabletView
                    ? { border: '1px solid', borderColor: (t) => t.palette.error.main }
                    : {}),
            },
        }, sx: { py: 0, ...(onboardingTabletView ? { zIndex: 1002, pointerEvents: 'none' } : {}) }, MenuListProps: { sx: { py: 0 } }, ...(onboardingTabletView
            ? { transitionDuration: { appear: 500, enter: 200, exit: 0 } }
            : {}), children: exchanges?.length &&
            exchanges.map((exchange) => {
                if (exchange?.id === selectedExchange?.id) {
                    return;
                }
                return (_jsxs(ListItemButton, { divider: true, onClick: () => handleChangeExchange(exchange?.id), children: [_jsx(Typography, { color: "textSecondary", component: "span", pr: 2, maxHeight: "40px", children: _jsx(ExchangeAvatar, { exchange: exchange?.id, sx: { height: 'auto' } }) }), _jsx(ListItemText, { primary: exchange?.name?.split(' ')[0], primaryTypographyProps: { variant: 'body1', mt: 1 } })] }, exchange.id));
            }) }));
};
export default ExchangeSelectMenu;
