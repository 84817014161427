import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useUserBalanceData, useUserExchangesBalanceData } from '@emme/shared/queries/Users';
import { useMarketInfoCoinsData } from '@emme/shared/queries/Coins';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { AlertContext } from 'context/AlertProvider';
import { UserExchangeCredentialsStatus } from '@emme/shared/model/enums';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { EmmeTable } from 'components/Table';
import { NumberCell } from 'components/Table/cells';
import { ExchangeSelectInput } from 'components/Inputs';
import MissingToken from 'components/MissingToken';
import HoldingsOverview from './components/HoldingsOverview';
const USDPriceComponent = ({ data: { value, priceUsd } }) => priceUsd ? (_jsxs(Typography, { component: "span", children: ["$", new BigNumber(value || '0').multipliedBy(priceUsd).toFormat(2)] })) : null;
const headCells = [
    {
        id: 'currency',
        label: 'Assets',
    },
    {
        id: 'available',
        Component: NumberCell,
        label: 'Available Balance',
    },
    {
        id: 'locked',
        Component: NumberCell,
        label: 'Locked by Exchange',
    },
    {
        id: 'provisionLocked',
        Component: NumberCell,
        label: 'Locked by EM.ME',
    },
    {
        id: 'total',
        Component: NumberCell,
        label: 'Total',
    },
    {
        id: 'usdTotal',
        align: ColumnAlign.RIGHT,
        Component: USDPriceComponent,
        label: 'Estimated USD Value',
    },
];
const Holdings = () => {
    const { selectedExchange, changeExchange } = useContext(LiquidityContext);
    const { setAlert } = useContext(AlertContext);
    const { data: balance, error: errorBalance } = useUserBalanceData();
    const { data: allExchangeBalances, error: errorExchangeBalance, isLoading: isLoadingExchangeBalances, } = useUserExchangesBalanceData();
    const { balances: selectedExchangeBalances, status: credentialsStatus } = allExchangeBalances?.find((exchange) => exchange?.exchangeId === selectedExchange?.id) || {};
    const { data: coinData, isLoading: isLoadingCoinData, isFetching: isFetchingCoinData, } = useMarketInfoCoinsData((selectedExchangeBalances || []).map((b) => b.currency).join(','));
    const isLoadingTable = isLoadingExchangeBalances || (isLoadingCoinData && isFetchingCoinData);
    useEffect(() => {
        if (errorBalance)
            setAlert(errorBalance, 'error');
        if (errorExchangeBalance)
            setAlert(errorExchangeBalance, 'error');
    }, [errorBalance, errorExchangeBalance, setAlert]);
    const showProblem = () => {
        switch (credentialsStatus) {
            case UserExchangeCredentialsStatus.NOT_PROVIDED:
                return _jsx(MissingToken, { isHoldings: true });
            case UserExchangeCredentialsStatus.INVALID:
                return _jsx(MissingToken, { isInvalid: true });
            default:
                return (_jsx(Alert, { severity: "info", sx: { justifyContent: 'center' }, children: _jsx(Typography, { children: "No assets for selected exchange" }) }));
        }
    };
    return (_jsxs(Container, { maxWidth: "lg", disableGutters: true, sx: { px: [7, undefined, 9] }, children: [_jsx(Typography, { variant: "h4", sx: { mt: 10, mb: 8 }, children: "Holdings" }), _jsx(HoldingsOverview, { isLoading: !balance, walletAddress: balance?.walletAddress, available: balance?.available }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", mt: 10, mb: 9, children: [_jsx(Typography, { variant: "h6", children: "Assets in Exchange" }), _jsx(ExchangeSelectInput, { selectProps: { sx: { borderRadius: '999px' } }, value: selectedExchange?.id || '', onChange: changeExchange, renderMenuItem: (exchange) => (_jsx(Typography, { variant: "subtitle2", component: "span", children: exchange })) })] }), isLoadingTable || selectedExchangeBalances?.length ? (_jsx(EmmeTable, { rows: (selectedExchangeBalances || []).map((b) => ({
                    id: b.currency,
                    ...b,
                    usdTotal: {
                        value: b.total,
                        priceUsd: coinData?.find((coin) => coin.symbol === b.currency)?.priceUsd,
                    },
                })), inputHeadCells: headCells, initialSortBy: "currency", sortValues: {
                    available: { isNumber: true },
                    locked: { isNumber: true },
                    provisionLocked: { isNumber: true },
                    total: { isNumber: true },
                    usdTotal: {
                        value: 'value',
                        isNumber: true,
                    },
                }, isLoading: isLoadingTable, customVerticalPadding: 3 })) : (showProblem())] }));
};
export default Holdings;
