import { useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeUsers = 30 * 1000;
export function useUserOrdersData({ open, type, ...params }) {
    return useQuery(['users', 'me', type, open, params.pageNumber || 0, params.size || 100], createFetchFn({
        requiresAuth: true,
        method: 'GET',
        url: `/users/me/${type}/${open ? 'open' : 'closed'}`,
        params,
        transformData: (data) => data.liquidityOrders,
    }), {
        staleTime: intervalTimeUsers,
        onError: (error) => error,
        refetchInterval: intervalTimeUsers,
        refetchOnWindowFocus: true,
    });
}
