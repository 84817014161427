import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { useContext, useEffect, useMemo, useRef } from 'react';
import BigNumber from 'bignumber.js';
import { VictoryArea, VictoryAxis, VictoryCursorContainer } from 'victory';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useDepthChartData } from '@emme/shared/queries/Charts';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { EmmeVictoryChart } from 'components/EmmeVictoryChart';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { useSimulatedDepthChartData } from '../hooks/useSimulatedDepthChartData';
import { NoData } from './NoData';
const MIN_DISPLAYED_SPREAD = 5;
const MAX_DISPLAYED_SPREAD = 100;
const MAX_BID_ASK_GAP = 0.2;
const TOOLTIP_WIDTH = 220;
const TOOLTIP_BASE_HEIGHT = 80;
const TOOLTIP_ROW_HEIGHT = 14;
const TOOLTIP_GAP = 10;
const sortData = (data) => ({
    bids: data?.bids.sort((a, b) => Number(b.rate) - Number(a.rate)) || [],
    asks: data?.asks.sort((a, b) => Number(a.rate) - Number(b.rate)) || [],
});
const cumulativeData = (array, isBid) => {
    let cumulativeY = 0;
    return array.flatMap(({ quantity, rate }) => {
        const y = Number(quantity) * Number(rate);
        const point = [
            { x: isBid ? Number(rate) + Number.EPSILON : Number(rate) - Number.EPSILON, y: cumulativeY },
            { x: Number(rate), y: cumulativeY + y },
        ];
        cumulativeY += y;
        return point;
    });
};
const getMidpoint = (sortedData) => (Number(sortedData.bids[0]?.rate || 0) + Number(sortedData.asks[0]?.rate || 0)) / 2;
const getLeftMostPoint = (totalMidPoint, spread) => totalMidPoint - totalMidPoint * (spread / 100);
const getRightMostPoint = (totalMidPoint, spread) => totalMidPoint + totalMidPoint * (spread / 100);
const filterBySpread = (sortedData, totalMidPoint, spread) => {
    const leftMostPoint = getLeftMostPoint(totalMidPoint, spread);
    const rightMostPoint = getRightMostPoint(totalMidPoint, spread);
    return {
        bids: sortedData.bids.filter(({ rate }) => Number(rate) >= leftMostPoint),
        asks: sortedData.asks.filter(({ rate }) => Number(rate) <= rightMostPoint),
    };
};
const findDisplayedSpread = (sortedData, totalMidPoint) => {
    // Get the smallest spread at which some data exists
    const smallestDataSpread = new BigNumber(sortedData.asks[0]?.rate)
        .dividedBy(totalMidPoint)
        .minus(1)
        .multipliedBy(100)
        .toNumber();
    // Divide smallestDataSpread by MAX_BID_ASK_GAP so that the gap between the highest bid and
    // the lowest ask takes up at most MAX_BID_ASK_GAP fraction of the chart
    const reducedGapSpread = smallestDataSpread / MAX_BID_ASK_GAP;
    // Then round up to the nearest (MIN_DISPLAYED_SPREAD * (power of 2)) so that x axis ticks are
    // displayed consistently at the same positions for any data (see getXAxisTickValues)
    let roundedSpread = MIN_DISPLAYED_SPREAD;
    while (roundedSpread < reducedGapSpread) {
        roundedSpread *= 2;
    }
    return smallestDataSpread > MAX_DISPLAYED_SPREAD ? 0 : roundedSpread;
};
const processChartData = (sortedData, totalMidPoint, spread) => {
    const filteredData = filterBySpread(sortedData, totalMidPoint, spread);
    const cumulativeBids = cumulativeData(filteredData.bids, true);
    const cumulativeAsks = cumulativeData(filteredData.asks, false);
    return [
        ...cumulativeBids,
        ...cumulativeAsks,
        { x: totalMidPoint, y: 0 },
        {
            x: getLeftMostPoint(totalMidPoint, spread),
            y: cumulativeBids[cumulativeBids.length - 1]?.y || 0,
        },
        {
            x: getRightMostPoint(totalMidPoint, spread),
            y: cumulativeAsks[cumulativeAsks.length - 1]?.y || 0,
        },
    ].sort((a, b) => a.x - b.x);
};
const validateData = (sortedData, totalMidPoint) => (!sortedData.bids.length || Number(sortedData.bids[0].rate) < totalMidPoint) &&
    (!sortedData.asks.length || Number(sortedData.asks[0].rate) > totalMidPoint);
const formatYAxisTick = (value) => {
    if (value >= 1000000) {
        return `${(value / 1000000).toFixed(value > 10000000 ? 0 : 1)}M`;
    }
    if (value >= 1000) {
        return `${(value / 1000).toFixed(value > 10000 ? 0 : 1)}k`;
    }
    return value.toString();
};
const getXAxisTickValues = (spread, totalMidPoint, isLargeScreen) => spread
    ? new Array(spread * 4 - 1)
        .fill(0)
        // Create an array with range [-2 * spread, ..., 0, ..., 2 * spread].
        // Values are doubled in case we will display 0.5% ticks - They are doubled so that all of the values
        // are integers and can be easily filtered using % in the next step.
        // eg Spread 2% -> [-4, -3, -2, -1, 0, 1, 2, 3, 4]
        .map((_, i) => -(spread * 2) + i + 1)
        // Skip items in the array - keep every second, fourth, etc item depending on spread range and screen size
        // eg Spread 2% and small screen -> [-4, -2, 0, 2, 4]
        .filter((doublePercentage) => {
        const n = Math.ceil(Math.log2(spread / MIN_DISPLAYED_SPREAD)) + (isLargeScreen ? 0 : 1);
        // Keep every n-th item:
        return doublePercentage % 2 ** n === 0;
    })
        // Divide values to 2 so that we get actual spread percentages. (values were doubled in the first step)
        // eg Spread 2% and small screen -> [-2, -1, 0, 1, 2]
        .map((doublePercentage) => doublePercentage / 2)
        // Map spread percentages to price so they are displayed on the X axis correctly
        .map((percentage) => totalMidPoint * (1 + percentage / 100))
    : [];
function DimensionSetter({ dimensions, ...newDimensions }) {
    const { x1, x2, y1, y2 } = dimensions.current;
    if (!x1 || !x2 || !y1 || !y2) {
        if (newDimensions.x1 === newDimensions.x2) {
            dimensions.current = { x1, x2, y1: newDimensions.y1, y2: newDimensions.y2 };
        }
        if (newDimensions.y1 === newDimensions.y2) {
            dimensions.current = { x1: newDimensions.x1, x2: newDimensions.x2, y1, y2 };
        }
    }
    return _jsx("g", {});
}
function Cursor({ dimensions, quoteCoin, totalData, emmeData, simulatedData, x: cursorX, datum: { x: xValue }, }) {
    const theme = useTheme();
    const { x1, x2, y1, y2 } = dimensions.current;
    const tooltipGlobalWidth = TOOLTIP_WIDTH - (y2 - y1 <= 300 ? 10 : 0);
    const tooltipGlobalHeight = TOOLTIP_BASE_HEIGHT - (y2 - y1 <= 300 ? 20 : 0);
    const tooltipGlobalRowHeight = TOOLTIP_ROW_HEIGHT - (y2 - y1 <= 300 ? 4 : 0);
    const price = new BigNumber(xValue).toFormat(8);
    const [totalVolume, emmeVolume, simulatedVolume] = [totalData, emmeData, simulatedData].map((data) => data?.find(({ x }) => x >= xValue)?.y || 0);
    const maxAllDataValue = [
        ...(totalData || []),
        ...(simulatedData || []),
        ...(emmeData || []),
    ].reduce((max, point) => Math.max(max, point.y), 0);
    const [valueYTotal, valueYEmme, valueYSimulated] = [totalVolume, emmeVolume, simulatedVolume].map((volume) => (maxAllDataValue === 0 ? 0 : y2 - (y2 - y1) * (volume / maxAllDataValue)));
    const tooltipHeight = tooltipGlobalHeight + (simulatedData ? tooltipGlobalRowHeight : 0);
    const tooltipX = cursorX + TOOLTIP_GAP + tooltipGlobalWidth >= x2
        ? cursorX - TOOLTIP_GAP - tooltipGlobalWidth
        : cursorX + TOOLTIP_GAP;
    const tooltipValueY = emmeVolume / maxAllDataValue > 0.01 && emmeVolume / maxAllDataValue < 1
        ? valueYEmme
        : Math.min(valueYSimulated, valueYTotal);
    const tooltipY = tooltipValueY + TOOLTIP_GAP + tooltipHeight >= y2
        ? tooltipValueY - TOOLTIP_GAP - tooltipHeight
        : tooltipValueY + TOOLTIP_GAP;
    return x1 && x2 && y1 && y2 ? (_jsxs("g", { children: [_jsx("line", { x1: 0, x2: 0, y1: y1, y2: y2, stroke: theme.palette.neutralsFour.main, strokeDasharray: "8 2", style: {
                    transform: `translateX(${cursorX}px)`,
                    transition: 'transform 0.05s',
                } }), (maxAllDataValue === 0 ? [tooltipValueY] : [valueYTotal, valueYEmme, valueYSimulated])
                .filter((value) => value !== y2)
                .map((value, index) => (_jsx("line", { x1: x1, x2: x2, y1: 0, y2: 0, stroke: theme.palette.neutralsFour.main, strokeDasharray: "8 2", style: {
                    transform: `translateY(${value}px)`,
                    transition: 'transform 0.1s',
                } }, index))), _jsx("foreignObject", { x: x1, y: y1, width: x2 - x1, height: y2 - y1, children: _jsx(Paper, { sx: {
                        width: tooltipGlobalWidth,
                        height: tooltipHeight,
                        display: 'flex',
                        justifyContent: 'center',
                    }, style: {
                        transform: `translateX(${tooltipX - x1}px) translateY(${tooltipY - y1}px)`,
                        transition: 'transform 0.05s',
                    }, children: _jsxs(Stack, { justifyContent: "center", gap: 3, height: "100%", children: [_jsxs(Typography, { component: "div", children: ["Price: ", price, " ", quoteCoin] }), _jsxs(Typography, { variant: "body3", component: "div", children: [_jsx(Box, { color: "primary.main", component: "span", children: "Market volume:" }), ' ', new BigNumber(totalVolume).toFormat(8), " ", quoteCoin, _jsx("br", {}), _jsx(Box, { color: "success.main", component: "span", children: "EM.ME generated:" }), ' ', new BigNumber(emmeVolume).toFormat(8), " ", quoteCoin, simulatedData && (_jsxs(_Fragment, { children: [_jsx("br", {}), "Simulated volume: ", new BigNumber(simulatedVolume).toFormat(8), " ", quoteCoin] }))] })] }) }) })] })) : (_jsx("g", {}));
}
export function SimulatedMarketDepthChart() {
    const theme = useTheme();
    const smallDesktop = useMediaQuery(theme.breakpoints.down('xl'));
    const largeDesktop = useMediaQuery(theme.breakpoints.up('xxl'));
    const laptop = useMediaQuery(theme.breakpoints.down('lg'));
    const tallScreen = useMediaQuery('@media screen and (min-height: 1000px)');
    const dimensionsRef = useRef({ x1: NaN, x2: NaN, y1: NaN, y2: NaN });
    useEffect(() => {
        const onResize = () => {
            dimensionsRef.current = { x1: NaN, x2: NaN, y1: NaN, y2: NaN };
        };
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    });
    const { selectedLiquidityMarket } = useContext(liquidityContext);
    const params = {
        exchangeId: selectedLiquidityMarket?.exchangeId,
        baseCoin: selectedLiquidityMarket?.baseCoin,
        quoteCoin: selectedLiquidityMarket?.quoteCoin,
    };
    const { data: totalData, isLoading: isLoadingTotal } = useDepthChartData('total', params);
    const { data: emmeData, isLoading: isLoadingEmme } = useDepthChartData('emme', params);
    const sortedTotalData = useMemo(() => sortData(totalData), [totalData]);
    const sortedEmmeData = useMemo(() => sortData(emmeData), [emmeData]);
    const hasTotalData = Boolean(sortedTotalData.asks.length && sortedTotalData.bids.length);
    const hasEmmeData = Boolean(sortedEmmeData.asks.length && sortedEmmeData.bids.length);
    const totalMidPoint = getMidpoint(hasTotalData ? sortedTotalData : sortedEmmeData);
    const rawSimulatedData = useSimulatedDepthChartData(sortedTotalData, totalMidPoint);
    const chartSpread = findDisplayedSpread(sortedTotalData, totalMidPoint);
    const isDataValid = chartSpread &&
        validateData(sortedTotalData, totalMidPoint) &&
        validateData(sortedEmmeData, totalMidPoint);
    const allChartData = useMemo(() => {
        if ((!hasTotalData && !hasEmmeData) || !isDataValid) {
            return [];
        }
        const totalChartData = !hasTotalData
            ? []
            : processChartData(sortedTotalData, totalMidPoint, chartSpread);
        const emmeChartData = !hasEmmeData
            ? []
            : processChartData(sortedEmmeData, totalMidPoint, chartSpread);
        const simulatedChartData = !hasTotalData || !rawSimulatedData
            ? undefined
            : processChartData(sortData(rawSimulatedData), totalMidPoint, chartSpread);
        return [
            { data: totalChartData, color: theme.palette.primary.main, name: 'total' },
            { data: emmeChartData, color: theme.palette.secondary.main, name: 'emme' },
            { data: simulatedChartData, color: theme.palette.neutralsSix.main, name: 'simulated' },
        ]
            .filter(({ data }) => data)
            .map((item) => ({
            ...item,
            maxValue: item.data.reduce((acc, { y }) => Math.max(acc, y), 0),
        }))
            .sort(({ maxValue: maxValueA }, { maxValue: maxValueB }) => maxValueB - maxValueA);
    }, [
        hasTotalData,
        hasEmmeData,
        rawSimulatedData,
        isDataValid,
        sortedTotalData,
        totalMidPoint,
        chartSpread,
        sortedEmmeData,
        theme.palette,
    ]);
    return isLoadingTotal || isLoadingEmme || !totalData || !emmeData ? (_jsx(LoadingOverlay, { isLoading: true, sx: { height: '100%' } })) : isDataValid && totalMidPoint && (hasEmmeData || hasTotalData) ? (_jsxs(_Fragment, { children: [_jsx("svg", { style: { height: 0, width: 0 }, children: _jsx("defs", { children: allChartData.map(({ color, name }) => (_jsxs("linearGradient", { id: `${name}Gradient`, x1: "0%", y1: "0%", x2: "0%", y2: "100%", children: [_jsx("stop", { offset: "0%", stopColor: `${color}88` }), _jsx("stop", { offset: "100%", stopColor: `${color}10` })] }, name))) }) }), _jsxs(EmmeVictoryChart, { sx: { height: '100%', mx: 4 }, containerComponent: _jsx(VictoryCursorContainer, { cursorLabel: () => '', cursorLabelOffset: { x: 0, y: 0 }, cursorComponent: _jsx(DimensionSetter, { dimensions: dimensionsRef }), cursorLabelComponent: _jsx(Cursor, { dimensions: dimensionsRef, quoteCoin: selectedLiquidityMarket?.quoteCoin || '-', totalData: allChartData.find(({ name }) => name === 'total')?.data, hasTotalData: hasTotalData, emmeData: allChartData.find(({ name }) => name === 'emme')?.data, hasEmmeData: hasEmmeData, simulatedData: allChartData.find(({ name }) => name === 'simulated')?.data }) }), children: [_jsx(VictoryAxis, { dependentAxis: true, orientation: "left", axisValue: getLeftMostPoint(totalMidPoint, chartSpread), style: {
                            grid: {
                                stroke: theme.palette.neutralsThree.main,
                                strokeDasharray: 'none',
                            },
                            tickLabels: {
                                ...theme.typography.body3,
                                fill: theme.palette.neutralsSix.main,
                            },
                            axis: {
                                stroke: theme.palette.neutralsFour.main,
                            },
                        }, tickFormat: formatYAxisTick, tickCount: tallScreen ? 20 : 10, crossAxis: false }), _jsx(VictoryAxis, { dependentAxis: true, orientation: "right", axisValue: getRightMostPoint(totalMidPoint, chartSpread), style: {
                            grid: {
                                stroke: theme.palette.neutralsThree.main,
                                strokeDasharray: 'none',
                            },
                            tickLabels: {
                                ...theme.typography.body3,
                                fill: theme.palette.neutralsSix.main,
                            },
                            axis: {
                                stroke: theme.palette.neutralsFour.main,
                            },
                        }, tickFormat: formatYAxisTick, tickCount: tallScreen ? 20 : 10, crossAxis: false }), _jsx(VictoryAxis, { style: {
                            grid: {
                                stroke: theme.palette.neutralsThree.main,
                                strokeDasharray: 'none',
                            },
                            tickLabels: {
                                ...theme.typography.body3,
                                fill: theme.palette.neutralsSix.main,
                            },
                            axis: {
                                stroke: theme.palette.neutralsFour.main,
                            },
                        }, tickValues: getXAxisTickValues(chartSpread, totalMidPoint, largeDesktop), tickFormat: (value) => `${new BigNumber(value).toFormat(smallDesktop ? (laptop ? 4 : 6) : 8)}\n(${Math.abs(100 - (value / totalMidPoint) * 100).toFixed(1)}%)`, crossAxis: false }), allChartData.map(({ data, color, name }) => (_jsx(VictoryArea, { data: data, style: { data: { stroke: color, fill: `url(#${name}Gradient)`, strokeWidth: 1 } } }, name)))] })] })) : (_jsx(NoData, { message: isDataValid ? 'There is not enough data do display this chart' : 'Invalid chart data' }));
}
