import { useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeLiquidity = 10 * 1000;
export function useLiquidityMarketsData(params) {
    return useQuery(['liquidity-markets', params?.exchangeId || 'all', params?.includeTicker], createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: '/liquidity-markets',
        params,
        transformData: (data) => data.liquidityMarkets,
    }), {
        staleTime: !params?.exchangeId ? 10 * 60 * 1000 : intervalTimeLiquidity,
        onError: (error) => error,
        onSuccess: (data) => {
            if (data?.elements?.length) {
                data.elements = data?.elements.filter((el) => !(el.exchangeId === 'mock-exchange' &&
                    el.baseCoin === 'TST' &&
                    el.quoteCoin === 'BTC'));
            }
            return data;
        },
        refetchInterval: !params?.exchangeId ? 10 * 60 * 1000 : intervalTimeLiquidity,
        refetchOnWindowFocus: true,
    });
}
