import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import { AlertContext } from 'context/AlertProvider';
const validationSchema = Yup.object({
    address: Yup.string().label('Address').required('Required'),
    amount: Yup.string()
        .label('Amount to withdraw')
        .required('Required')
        .test('not allowed', 'Must be a number', (value) => Boolean(value && new BigNumber(value || '0').gte(0))),
});
const WithdrawForm = ({ available }) => {
    const { setAlert } = useContext(AlertContext);
    const [checkboxValue, setCheckboxValue] = useState(false);
    let totalValue;
    const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, setFieldError, resetForm, } = useFormik({
        initialValues: {
            address: '',
            amount: '',
        },
        validationSchema,
        onSubmit: async (formData) => {
            const formTotal = new BigNumber(totalValue || 0);
            if (formTotal.eq(0)) {
                setFieldError('total', 'Total must be greater than 0');
                return;
            }
            if (formTotal.gt(available)) {
                setFieldError('total', 'Total is greater than available balance');
                return;
            }
            if (new BigNumber(formData?.amount || 0).eq(0)) {
                setFieldError('amount', 'Amount must be greater than 0');
                return;
            }
            try {
                // await createOrder.mutateAsync(sendData);
                resetForm();
                setAlert(`TEST - Successfully sent amount ${new BigNumber(formData.amount).toFormat(8)}`, 'success', 2500);
            }
            catch (error) {
                setAlert(error?.message || error, 'error');
            }
        },
    });
    const touchAllFields = () => {
        setFieldTouched('amount');
        setFieldTouched('address');
    };
    const feeValue = new BigNumber(values.amount || 0).multipliedBy(0.01);
    totalValue = new BigNumber(values.amount || 0).plus(feeValue);
    return (_jsxs(Stack, { component: "form", onSubmit: handleSubmit, noValidate: true, alignItems: "stretch", width: "250px", children: [_jsx(TextField, { "aria-label": "Address", placeholder: "Address", value: values.address || '', onChange: handleChange, onBlur: () => setFieldTouched('address', Boolean(values.address)), name: "address", fullWidth: true, required: true, error: touched.address && Boolean(errors.address), helperText: touched.address && errors.address }), _jsx(Typography, { variant: "body3", color: "textSecondary", mt: 3, mb: 7, pr: 3, children: "Do not send EMME unless you are certain the destination supports transactions. If it does not, you could permanently lose access to your coins." }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { color: "primary", size: "small", disableRipple: true, checked: checkboxValue, onChange: () => setCheckboxValue(!checkboxValue), sx: { py: 0 } }), label: _jsx(Typography, { variant: "subtitle1", color: "textPrimary", children: "Save address for next time" }) }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "stretch", flexWrap: "nowrap", sx: {
                    backgroundColor: (t) => t.palette.neutralOne.main,
                    py: 4,
                    px: 3,
                    my: 7,
                    borderRadius: 1,
                }, children: [_jsxs(Typography, { component: "div", children: ["Available", _jsx("br", {}), "balance"] }), _jsxs(Box, { textAlign: "right", children: [_jsxs(Typography, { component: "div", noWrap: true, children: [new BigNumber(available).toFormat(0), " $EMME"] }), _jsxs(Typography, { variant: "body2", color: "textSecondary", component: "div", noWrap: true, children: ["$", new BigNumber(available).toFormat(2)] })] })] }), _jsx(TextField, { "aria-label": "Amount to withdraw", placeholder: "Amount to withdraw", value: values.amount || '', onChange: handleChange, onBlur: () => setFieldTouched('amount', Boolean(values.amount)), name: "amount", fullWidth: true, required: true, error: touched.amount && Boolean(errors.amount), helperText: touched.amount && errors.amount }), _jsxs(Typography, { variant: "body3", color: "textSecondary", align: "right", mt: 3, mb: 6, children: [new BigNumber(1000000).toFormat(0), " $EMME daily withdrawal limit remaining.", _jsx("br", {}), "Transaction fee: ", new BigNumber(feeValue).toFormat(2), " EMME"] }), _jsx(TextField, { "aria-label": "Total", placeholder: "Total", disabled: true, InputProps: {
                    endAdornment: (_jsxs(InputAdornment, { position: "end", disableTypography: true, disablePointerEvents: true, children: [new BigNumber(totalValue).toFormat(2), " EMME"] })),
                } }), _jsx(Box, { mt: 8, role: "button", onClick: () => touchAllFields(), onKeyPress: () => touchAllFields(), tabIndex: -1, children: _jsx(LoadingButton, { variant: "contained", type: "submit", fullWidth: true, size: "large", loading: false, children: _jsx(Typography, { variant: "h6", component: "span", children: "Withdraw" }) }) })] }));
};
export default WithdrawForm;
