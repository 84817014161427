import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RouteEnum, UserRole } from '@emme/shared/model/enums';
import { AlertContext } from 'context/AlertProvider';
import { OnboardingContext, OnboardingType } from 'context/OnboardingProvider';
import { useLogin } from '@emme/shared/queries/Auth';
import { getFingerPrint } from 'utils/fingerprint';
import { wizardRestart } from 'utils/util';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { AuthPageLayout } from 'layout/AuthPage';
import LoginForm from './components/LoginForm';
const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { tablet } = useBreakpoint();
    const { setAlert } = useContext(AlertContext);
    const { openOnboarding } = useContext(OnboardingContext);
    const userLogin = useLogin();
    const onLogin = async (formData) => {
        const { deviceFingerprint, browserName } = getFingerPrint();
        if (!formData.email || !formData.password || !deviceFingerprint)
            return;
        const sendData = { ...formData, deviceFingerprint, browserName };
        try {
            const data = await userLogin.mutateAsync(sendData);
            if (!data?.userInfo.onboardingFinished) {
                openOnboarding(OnboardingType.FIRST);
            }
            else if (wizardRestart(data?.userInfo?.lastLoginAt)) {
                openOnboarding(OnboardingType.WELCOME_BACK);
            }
            if (data?.userInfo?.role === UserRole.INACTIVE_USER) {
                navigate(RouteEnum.EmailVerification, { state: data.userInfo });
            }
            else if (!data?.token) {
                navigate(RouteEnum.DeviceVerification, { state: data.userInfo });
            }
            else {
                navigate(location?.state?.redirectedFrom || RouteEnum.Dashboard);
            }
        }
        catch (err) {
            setAlert(err || 'Invalid credentials', 'error');
        }
    };
    return (_jsx(AuthPageLayout, { columnTitle: `Sign in${!tablet ? ' to EM.ME' : ''}`, children: _jsx(LoginForm, { onSubmit: onLogin, loading: userLogin.isLoading }) }));
};
export default Login;
