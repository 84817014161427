import { useCallback, useEffect, useState } from 'react';
export function useElementDimensions(elementRef) {
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        toJSON: console.error,
    });
    const updateDimensions = useCallback(() => {
        const rect = elementRef.current?.getBoundingClientRect();
        if (rect) {
            setDimensions(rect);
        }
    }, [elementRef]);
    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);
    return dimensions;
}
