import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import { ColumnAlign } from '@emme/shared/model/table.model';
import { ButtonCell } from './ButtonCell';
export const TwoButtonCell = ({ columnProps: { buttons, horizontal } = {
    buttons: [{}, {}],
    horizontal: false,
}, data: [topData, bottomData], }) => {
    const [topColumnProps = {}, bottomColumnProps = {}] = (buttons || []);
    return (_jsxs(Stack, { alignItems: "center", gap: 2, direction: horizontal ? 'row' : 'column', children: [_jsx(ButtonCell, { columnProps: topColumnProps, data: topData }), _jsx(ButtonCell, { columnProps: bottomColumnProps, data: bottomData })] }));
};
TwoButtonCell.alignment = ColumnAlign.CENTER;
TwoButtonCell.defaultProps = {
    columnProps: { buttons: [{}, {}], horizontal: false },
};
