import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { LinkRouter } from './ListItemLink';
const useStyles = makeStyles(() => createStyles({
    breadCrumb: {
        justifyContent: 'flex-end',
    },
}));
const BreadCrumbRoutes = ({ navigationList, disableHome }) => {
    const classes = useStyles();
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);
    return (_jsx(Breadcrumbs, { "aria-label": "breadcrumb", separator: _jsx(NavigateNextOutlinedIcon, { fontSize: "small" }), classes: { ol: classes.breadCrumb }, children: pathNames.map((value, index) => {
            const last = index === pathNames.length - 1;
            const to = `/${pathNames.slice(0, index + 1).join('/')}`;
            if (index === 0 && disableHome) {
                return (_jsx(Typography, { color: "inherit", children: navigationList[to] }, to));
            }
            return last ? (_jsx(Typography, { color: "textPrimary", children: navigationList[to] }, to)) : (_jsx(LinkRouter, { color: "inherit", to: to, children: navigationList[to] }, to));
        }) }));
};
BreadCrumbRoutes.defaultProps = {
    disableHome: false,
};
export default BreadCrumbRoutes;
