import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { SadFace } from '@emme/shared/icons';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { RouteEnum } from '@emme/shared/model/enums';
import { DialogLayout } from 'layout/Dialogs';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
import { ComingSoonTooltip } from 'components/Tooltips';
import WithdrawForm from './WithdrawForm';
import DepositForm from './DepositForm';
const HoldingsOverview = ({ isLoading, available, walletAddress }) => {
    const navigate = useNavigate();
    const { phone } = useBreakpoint();
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [isDeposit, setIsDeposit] = useState(false);
    const [isSuccess] = useState(false);
    const [anchorElMore, setAnchorElMore] = useState(null);
    const { data: emmeCoin } = useMarketInfoLatestCoinData('emme');
    const handleClickMore = (event) => setAnchorElMore(event.currentTarget);
    const handleCloseMore = () => setAnchorElMore(null);
    const handleDeposit = () => {
        setIsDeposit(true);
        setOpen(true);
    };
    const handleWithdraw = () => {
        setIsDeposit(false);
        setOpen(true);
    };
    const handleHistory = () => {
        navigate(RouteEnum.HoldingsHistory);
    };
    const onClose = () => {
        setOpen(false);
        // TODO for testing
        // setIsSuccess(!isDeposit);
        // setOpenSuccess(true);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: {
                    borderRadius: 1,
                    bgcolor: (t) => t.palette.neutralOne.dark,
                    p: [4, 8],
                }, children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", mb: 6, children: [_jsxs(Typography, { variant: "h6", children: [phone && false && (_jsx(IconButton, { type: "button", color: "inherit", "aria-label": "more", "aria-controls": "more-vertical", "aria-haspopup": "true", onClick: handleClickMore, sx: { pl: 0 }, disabled: true, children: _jsx(MoreVertOutlinedIcon, {}) })), "Overview"] }), !phone && (_jsxs("div", { children: [_jsx(ComingSoonTooltip, { children: _jsx(Button, { type: "button", color: "inherit", onClick: handleHistory, variant: "outlined", sx: { mr: 3 }, disabled: true, children: "Transaction History" }) }), _jsx(ComingSoonTooltip, { children: _jsx(Button, { type: "button", color: "inherit", onClick: handleDeposit, variant: "outlined", sx: { mr: 3 }, disabled: true, children: "Deposit" }) }), _jsx(ComingSoonTooltip, { children: _jsx(Button, { type: "button", color: "inherit", onClick: handleWithdraw, variant: "outlined", disabled: true, children: "Withdraw" }) })] }))] }), _jsx(Typography, { variant: "subtitle2", color: "text.primary", mb: 3, children: "Total balance" }), _jsxs(Stack, { direction: "row", mb: 2, children: [_jsx(Typography, { variant: "h6", children: isLoading ? (_jsx(Skeleton, { width: "45px", height: "15px" })) : (new BigNumber(available || 0).toFormat(8)) }), _jsx(Chip, { color: "success", label: "$EMME", size: "small", sx: {
                                    borderRadius: 1,
                                    mx: 2,
                                    height: '15px',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    '& .MuiChip-label': {
                                        px: 2,
                                    },
                                } })] }), _jsx(Typography, { variant: "subtitle2", color: "textSecondary", children: isLoading ? (_jsx(Skeleton, { width: "40px" })) : (`$${new BigNumber(available || 0).multipliedBy(emmeCoin?.priceUsd || 0).toFormat(2)}`) })] }), _jsx(DialogLayout, { open: open, onClose: onClose, title: `${isDeposit ? 'Deposit' : 'Withdraw'} EM.ME`, children: isDeposit ? (_jsx(DepositForm, { userAddress: walletAddress || '' })) : (_jsx(WithdrawForm, { available: available || '0' })) }), _jsx(DialogLayout, { open: openSuccess, onClose: () => setOpenSuccess(false), maxWidth: "xs", title: isSuccess ? (_jsxs(_Fragment, { children: ["Yay! ", _jsx(CelebrationOutlinedIcon, { fontSize: "inherit", color: "success" })] })) : (_jsxs(_Fragment, { children: ["Oops! ", _jsx(SadFace, { stroke: "inherit" })] })), children: isSuccess ? (_jsxs(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", children: [_jsxs(Typography, { fontWeight: "500", mb: 8, align: "center", component: "div", children: ["You successfully a withdrawal", ' ', _jsx(Typography, { fontWeight: "500", color: "success.main", component: "span", children: "100.00 $EMME" }), ' ', "from EM.ME"] }), _jsxs(List, { sx: {
                                width: '100%',
                                border: '1px solid',
                                borderColor: (th) => th.palette.neutralsThree.main,
                                borderRadius: '12px',
                                px: 7,
                                mb: 8,
                            }, children: [_jsxs(ListItem, { alignItems: "center", children: [_jsx(ListItemText, { primary: "Status", primaryTypographyProps: { variant: 'subtitle2', color: 'text.secondary' }, secondary: _jsx(Typography, { component: "span", variant: "subtitle2", color: "success.main", children: "Completed" }) }), _jsx(ListItemText, { primary: "Transaction ID", primaryTypographyProps: { variant: 'subtitle2', color: 'text.secondary' }, secondary: _jsx(Typography, { component: "span", variant: "subtitle2", fontWeight: "500", children: "0msx836930...87r398" }) })] }), _jsx(Divider, { variant: "middle", component: "li" }), _jsx(ListItem, { alignItems: "flex-start", children: _jsx(ListItemText, { primary: "Address", primaryTypographyProps: { variant: 'subtitle2', color: 'text.secondary' }, secondary: _jsx(Typography, { component: "span", variant: "subtitle2", fontWeight: "500", children: "0xf8aaa2b1842e796191e736434sda....479" }) }) })] }), _jsx(Button, { type: "button", onClick: () => setOpenSuccess(false), variant: "contained", fullWidth: true, startIcon: _jsx(ChevronLeftOutlinedIcon, {}), children: "Holdings" })] })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { fontWeight: "500", mb: 8, align: "center", children: "Couldn\u2019t broadcast the transaction to the blockchain. This is usually because of network congestion. Please, try again." }), _jsx(Button, { type: "button", onClick: () => {
                                setOpenSuccess(false);
                                setOpen(true);
                            }, variant: "contained", fullWidth: true, children: "Try Again" })] })) }), _jsxs(Menu, { id: "more-vertical", anchorEl: anchorElMore, keepMounted: true, open: Boolean(anchorElMore), onClose: handleCloseMore, anchorReference: undefined, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'center' }, children: [_jsx(MenuItem, { onClick: () => {
                            handleCloseMore();
                            handleHistory();
                        }, children: _jsx(ListItemText, { primary: "Transaction History" }) }), _jsx(Divider, { component: "li", variant: "middle" }), _jsx(MenuItem, { onClick: () => {
                            handleCloseMore();
                            handleDeposit();
                        }, children: _jsx(ListItemText, { primary: "Deposit" }) }), _jsx(Divider, { component: "li", variant: "middle" }), _jsx(MenuItem, { onClick: () => {
                            handleCloseMore();
                            handleWithdraw();
                        }, children: _jsx(ListItemText, { primary: "Withdraw" }) })] })] }));
};
export default HoldingsOverview;
