import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlined from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useBreakpoint } from 'hooks/useBreakpoint';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { DashboardButton } from 'components/Buttons';
import ExchangeSelectMenu from './ExchangeSelectMenu';
const ExchangeSelectButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { phone } = useBreakpoint();
    const { selectedExchange } = useContext(LiquidityContext);
    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    return (_jsxs(_Fragment, { children: [_jsx(DashboardButton, { variant: "contained", id: "exchange-button", type: "button", onClick: handleOpen, color: "inherit", disabled: !selectedExchange?.id, endIcon: anchorEl ? _jsx(KeyboardArrowUpOutlined, {}) : _jsx(KeyboardArrowDownOutlined, {}), sx: { fontSize: phone ? 12 : undefined }, children: selectedExchange?.name }), _jsx(ExchangeSelectMenu, { anchorEl: anchorEl, onClose: handleClose })] }));
};
export default ExchangeSelectButton;
