import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { RouteEnum } from '@emme/shared/model/enums';
import { OnboardingContext } from 'context/OnboardingProvider';
import { ConfirmOrderContext } from 'context/ConfirmOrderProvider';
import { useLiquidityMarketOrderBookSubscription } from '@emme/shared/queries/LiquidityMarkets';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { useAvailableBalance } from 'hooks/useAvailableBalance';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { getAutofillProvisionFormData, processProvisionFormData, } from 'pages/DashboardPage/components/PlaceOrder/util';
import OnboardingContentText from '../OnboardingContentText';
const Step5 = () => {
    const { tablet } = useBreakpoint();
    const navigate = useNavigate();
    const { hideOnboarding, closeOnboarding } = useContext(OnboardingContext);
    const { confirmAndPlaceOrder } = useContext(ConfirmOrderContext);
    const { selectedLiquidityMarket } = useContext(liquidityContext);
    const { data: orderBookSubscriptions, isLoading: isLoadingOrderbook } = useLiquidityMarketOrderBookSubscription(selectedLiquidityMarket?.id || '');
    const { disableBaseInput, disableQuoteInput, minQuoteVolume, minBaseVolume } = useAvailableBalance();
    const handlePlaceOrderClick = async () => {
        if (tablet) {
            closeOnboarding();
            navigate(RouteEnum.DashboardConfirmOrder, { state: { onboarding: true } });
        }
        else {
            try {
                hideOnboarding();
                await confirmAndPlaceOrder({
                    data: processProvisionFormData(getAutofillProvisionFormData(orderBookSubscriptions || [], minQuoteVolume, minBaseVolume, undefined, disableQuoteInput, disableBaseInput), selectedLiquidityMarket.id),
                    type: 'provisions',
                    onboarding: true,
                });
                closeOnboarding();
            }
            catch {
                closeOnboarding();
            }
        }
    };
    return (_jsxs(Stack, { alignItems: "center", gap: 3, children: [_jsx(OnboardingContentText, { align: "center", mb: 7, children: "You can now place a provision order!" }), _jsx(Button, { variant: "contained", color: "error", size: "large", onClick: handlePlaceOrderClick, disabled: !selectedLiquidityMarket || isLoadingOrderbook, children: "Quick Provision Order" }), _jsx(Button, { color: "secondary", size: "large", onClick: closeOnboarding, children: "Or finish the wizard" })] }));
};
export default Step5;
