import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
const DashboardButton = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.neutralsThree.main,
        '&:hover': {
            backgroundColor: theme.palette.neutralsThree.main,
        },
    },
}))(Button);
export default DashboardButton;
