import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import { createContext, useCallback, useContext, useEffect, useMemo, isValidElement, } from 'react';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Alert from '@mui/material/Alert';
import { OnlineStatusContext } from 'context/OnlineStatusProvider';
export const AlertContext = createContext({
    setAlert: console.error,
    clearAlert: console.error,
});
const MainAlert = withStyles(({ breakpoints }) => ({
    root: {
        minWidth: 250,
        [breakpoints.down('md')]: {
            minWidth: 100,
        },
        alignItems: 'center',
        borderRadius: 10,
    },
    action: {
        paddingTop: 0,
    },
    message: {
        display: 'flex',
    },
}))(Alert);
let globalKey = null;
const AlertProvider = ({ children }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { isConnected } = useContext(OnlineStatusContext);
    const setAlert = useCallback((msg, type, timeout = 5000) => {
        if (!isConnected)
            return;
        if (typeof msg === 'object' && !isValidElement(msg)) {
            msg = JSON.stringify(msg);
        }
        return enqueueSnackbar(msg, {
            autoHideDuration: timeout,
            preventDuplicate: true,
            content: (key, message) => (_jsx(MainAlert, { severity: type, onClose: () => closeSnackbar(key), variant: "filled", children: _jsx(Typography, { component: "span", children: message }) })),
        });
    }, [enqueueSnackbar, closeSnackbar]);
    useEffect(() => {
        if (!isConnected) {
            enqueueSnackbar('Network problems', {
                preventDuplicate: true,
                persist: true,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                content: (key, message) => {
                    globalKey = key;
                    return (_jsx(MainAlert, { severity: "error", variant: "filled", children: _jsx(Typography, { component: "span", children: message }) }));
                },
            });
        }
        else if (globalKey) {
            closeSnackbar(globalKey);
            globalKey = null;
        }
    }, [isConnected]);
    const value = useMemo(() => ({ setAlert, clearAlert: closeSnackbar }), [setAlert, closeSnackbar]);
    return _jsx(AlertContext.Provider, { value: value, children: children });
};
export default AlertProvider;
