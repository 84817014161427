import { jsx as _jsx } from "react/jsx-runtime";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
const TableLoadingSkeleton = ({ inputHeadCells, customEmptyTableText }) => (_jsx(TableBody, { children: _jsx(TableRow, { children: _jsx(TableCell, { colSpan: inputHeadCells?.length || 10, sx: {
                border: 0,
                whiteSpace: 'normal',
                typography: 'subtitle1',
                fontSize: 14,
            }, variant: "body", align: "center", children: customEmptyTableText }) }) }));
export default TableLoadingSkeleton;
