import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import { RouteEnum } from '@emme/shared/model/enums';
import { getErrorFormatter } from 'utils/util';
import { sharedAuthPageStyles } from 'layout/AuthPage';
import { signupLinkText } from 'config';
import { PasswordTextField } from 'components/Inputs';
const validationSchema = Yup.object({
    email: Yup.string()
        .label('Email address')
        .email('It should be valid email address!')
        .required('Required'),
    password: Yup.string()
        .label('Password')
        .min(8, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Required'),
});
export const LoginForm = ({ onSubmit, onIsDisabled, loading }) => {
    const classes = sharedAuthPageStyles();
    const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, isValid } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema,
        onSubmit,
    });
    const formatError = getErrorFormatter(touched, errors);
    useEffect(() => {
        if (onIsDisabled) {
            onIsDisabled(!touched?.email || !isValid);
        }
    }, [isValid, onIsDisabled, touched]);
    const handleInputChange = (e) => {
        const name = e?.target?.name;
        if (e.type === 'change' && name) {
            setFieldTouched(name);
        }
        handleChange(e);
    };
    return (_jsxs(Stack, { component: "form", onSubmit: handleSubmit, noValidate: true, gap: 3, children: [_jsx(TextField, { label: "Email Address", value: values.email, onChange: handleInputChange, name: "email", fullWidth: true, autoComplete: "email", helperText: formatError('email'), error: touched.email && Boolean(errors.email), inputProps: {
                    inputMode: 'email',
                } }), _jsx(PasswordTextField, { label: "Password", value: values.password, onChange: handleInputChange, name: "password", fullWidth: true, autoComplete: "current-password", helperText: formatError('password'), error: touched.password && Boolean(errors.password) }), _jsx(Typography, { variant: "subtitle2", align: "right", pb: 6, children: _jsx(Link, { component: RouterLink, to: RouteEnum.ForgotPassword, children: "Forgot password?" }) }), _jsx(LoadingButton, { variant: "contained", type: "submit", fullWidth: true, size: "large", loading: loading, disabled: !isValid, children: _jsx(Typography, { variant: "h6", children: "Login" }) }), _jsxs(Typography, { variant: "subtitle2", align: "center", className: classes.otherText, children: ["Don\u2019t have an account?", _jsx(Link, { component: RouterLink, to: RouteEnum.Signup, children: signupLinkText })] })] }));
};
LoginForm.defaultProps = {
    onIsDisabled: undefined,
};
export default LoginForm;
