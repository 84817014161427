import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import Box from '@mui/material/Box';
import { VictoryChart } from 'victory';
import { useElementDimensions } from 'hooks/useElementDimensions';
export function EmmeVictoryChart({ sx, children, ...victoryProps }) {
    const containerRef = useRef(null);
    const { width, height } = useElementDimensions(containerRef);
    return (_jsx(Box, { sx: sx, position: "relative", ref: containerRef, children: Boolean(width && height) && (_jsx(Box, { sx: { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }, children: _jsx(VictoryChart, { width: width, height: height, ...victoryProps, children: children }) })) }));
}
