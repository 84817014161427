import { useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeRounds = 30 * 1000;
export function useCurrentRoundData() {
    return useQuery(['rounds', 'current'], createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: '/rounds/current',
    }), {
        onError: (error) => error,
        cacheTime: 0,
        staleTime: 0,
        refetchOnWindowFocus: true,
        initialData: null,
    });
}
export function useRoundMarketData(liquidityMarketId) {
    return useQuery(['rounds', 'market-stats', liquidityMarketId], createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: `/rounds/market-stats/${liquidityMarketId}`,
    }), {
        staleTime: intervalTimeRounds,
        onError: (error) => error,
        refetchInterval: intervalTimeRounds,
        refetchOnWindowFocus: true,
        enabled: Boolean(liquidityMarketId),
    });
}
