import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { RouteEnum } from '@emme/shared/model/enums';
import { ApiKeyDialog } from 'components/Dialogs';
const MissingToken = ({ isBuy, isInvalid, isHoldings }) => {
    const navigate = useNavigate();
    const getText = () => {
        if (isHoldings) {
            return 'Missing API key for the selected exchange';
        }
        if (isBuy) {
            return 'You need EM.ME tokens to place buy orders.';
        }
        if (isInvalid) {
            return `Imported API key is missing the required permissions.
      Make sure you enable reading and trading options.`;
        }
        return 'Importing an API key is required before placing sell orders on EM.ME.';
    };
    return (_jsxs(Stack, { justifyContent: "center", alignItems: "center", height: "100%", px: 2, children: [_jsx(Typography, { variant: "h4", mb: 9, children: isBuy ? (_jsxs(_Fragment, { children: [_jsx(Box, { component: "span", color: "success.main", children: "Deposit" }), ' ', "EM.ME tokens"] })) : (_jsxs(_Fragment, { children: [_jsx(Box, { component: "span", color: isInvalid ? 'error.main' : 'success.main', children: isInvalid ? 'Invalid' : 'Import' }), ' ', "API key"] })) }), _jsx(Typography, { color: "text.secondary", maxWidth: isBuy ? '250px' : '300px', pb: 8, textAlign: "center", variant: "h6", children: getText() }), _jsx(ApiKeyDialog, { buttonProps: isBuy
                    ? {
                        onClick: () => navigate(RouteEnum.Holdings),
                    }
                    : {}, buttonText: _jsx(Typography, { component: "span", children: isBuy ? 'Holdings' : `${isInvalid ? 'Change' : 'Import'} API key` }) })] }));
};
MissingToken.defaultProps = {
    isBuy: false,
    isInvalid: false,
    isHoldings: false,
};
export default MissingToken;
