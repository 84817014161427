import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
const EmmeTabs = ({ value, tabs, onChange, formatLabel, withBackground, fullWidth, sx, }) => (_jsx(Box, { sx: sx, children: _jsx(Tabs, { TabIndicatorProps: {
            style: { display: 'none' },
        }, value: value, onChange: (event, newValue) => onChange(newValue), textColor: "inherit", allowScrollButtonsMobile: true, sx: {
            minHeight: 'initial',
            mx: [2, 3],
            '[role="tablist"]': { gap: [1, 2] },
        }, children: tabs.map((tab) => (_jsx(Tab, { value: tab.label, label: _jsx(Tooltip, { title: tab.tooltip || (tab.isDisabled && 'Coming soon') || '', children: _jsx(Typography, { variant: "subtitle2", component: "span", children: formatLabel ? formatLabel(tab.label) : tab.label }) }), disabled: tab?.isDisabled, sx: {
                minWidth: 'initial',
                maxWidth: 'initial',
                minHeight: 'initial',
                my: 3,
                mx: 0,
                px: 3,
                pointerEvents: 'auto !important',
                ...(withBackground
                    ? {
                        minHeight: 24,
                        backgroundColor: 'transparent',
                        '&:hover:not(.Mui-selected)': {
                            backgroundColor: (t) => t.palette.text.primary,
                            color: (t) => t.palette.neutralsTwo.main,
                        },
                        '&.Mui-selected': {
                            backgroundColor: (t) => formatLabel
                                ? formatLabel(tab.label) === 'P'
                                    ? `${t.palette.error.main}75`
                                    : `${t.palette.emmeSecondary.main}75`
                                : t.palette.neutralsThree.main,
                        },
                    }
                    : {}),
                flex: fullWidth ? 'auto' : undefined,
                flexBasis: fullWidth ? 0 : undefined,
            } }, tab.label))) }) }));
EmmeTabs.defaultProps = {
    withBackground: false,
    fullWidth: false,
    formatLabel: undefined,
};
export default EmmeTabs;
