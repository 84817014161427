export var RouteEnum;
(function (RouteEnum) {
    // Public
    RouteEnum["Login"] = "/login";
    RouteEnum["Signup"] = "/signup";
    RouteEnum["SignupCodeVerification"] = "/signup-verification";
    RouteEnum["DeviceVerification"] = "/device-verification";
    RouteEnum["DeviceActivation"] = "/device-activation";
    RouteEnum["EmailVerification"] = "/email-verification";
    RouteEnum["EmailActivation"] = "/email-activation";
    RouteEnum["ForgotPassword"] = "/forgot-password";
    RouteEnum["ResetPassword"] = "/reset-password";
    RouteEnum["PrivacyPolicy"] = "/privacy-policy";
    // Private
    RouteEnum["Overview"] = "/overview";
    RouteEnum["Widget"] = "/widgets";
    // Plugin redirect
    RouteEnum["OpenMarket"] = "/open-market";
    RouteEnum["Dashboard"] = "/dashboard";
    RouteEnum["DashboardPlaceOrder"] = "/dashboard/place-order";
    RouteEnum["DashboardConfirmOrder"] = "/dashboard/confirm-order";
    RouteEnum["Account"] = "/account";
    RouteEnum["AccountAPI"] = "/account/api";
    RouteEnum["AccountInfo"] = "/account/info";
    RouteEnum["AccountNotifications"] = "/account/notifications";
    RouteEnum["AccountOrders"] = "/account/orders";
    RouteEnum["AccountDevices"] = "/account/devices";
    RouteEnum["Holdings"] = "/holdings";
    RouteEnum["HoldingsHistory"] = "/holdings/history";
    RouteEnum["Settings"] = "/settings";
    RouteEnum["Help"] = "/help";
})(RouteEnum || (RouteEnum = {}));
