import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link as RouterLink } from 'react-router-dom';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { EMMELogo } from '@emme/shared/icons';
import backgroundLogo from 'assets/security.png';
import { RouteEnum } from '@emme/shared/model/enums';
import { VersionTooltip } from 'components/Tooltips';
import { useBreakpoint } from 'hooks/useBreakpoint';
const useStyles = makeStyles((theme) => createStyles({
    logoImage: {
        width: 145,
        marginTop: theme.spacing(11),
        marginLeft: theme.spacing(11),
    },
}));
export const sharedAuthPageStyles = makeStyles((theme) => createStyles({
    otherText: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        '& a': {
            paddingLeft: theme.spacing(3),
        },
    },
}));
const AuthPageLayout = ({ children, columnTitle, columnSubTitle, isLoading, contentMaxWidth, }) => {
    const classes = useStyles();
    const { tablet } = useBreakpoint();
    return (_jsx(Container, { maxWidth: "xl", disableGutters: true, children: _jsxs(Grid, { container: true, direction: "row", justifyContent: "center", alignItems: "stretch", sx: { width: '100%', minHeight: '100vh' }, wrap: "nowrap", children: [_jsx(Grid, { item: true, md: 4, sx: {
                        display: ['none', undefined, 'block'],
                        background: (t) => `${t.palette.neutralsTwo.main} left 20vh no-repeat url("${backgroundLogo}")`,
                    }, children: _jsx(VersionTooltip, { children: _jsx(Link, { component: RouterLink, to: RouteEnum.Login, color: "inherit", tabIndex: -1, "aria-label": "EMME Home", children: _jsx(EMMELogo, { className: classes.logoImage }) }) }) }), _jsx(Grid, { item: true, xs: 12, sm: 12, md: 8, sx: {
                        backgroundColor: (t) => t.palette.background.default,
                        zIndex: 1,
                        alignSelf: [undefined, undefined, 'center'],
                    }, children: _jsx(Grid, { container: true, direction: "column", alignItems: "center", justifyContent: "center", children: columnTitle ? (_jsxs(_Fragment, { children: [_jsx(Box, { pt: 11, pb: 7, display: tablet ? 'initial' : 'none', children: _jsx(VersionTooltip, { children: _jsx(Link, { component: RouterLink, to: RouteEnum.Login, color: "inherit", children: _jsx(EMMELogo, { fill: "currentColor", style: { width: 145 } }) }) }) }), _jsxs(Card, { sx: {
                                        maxWidth: contentMaxWidth,
                                        width: '100%',
                                        backgroundColor: (t) => t.palette.background.paper,
                                    }, elevation: 0, children: [_jsx(CardHeader, { title: columnTitle, titleTypographyProps: { variant: 'h2', align: 'center' }, subheader: columnSubTitle || null, subheaderTypographyProps: {
                                                variant: 'subtitle1',
                                                align: 'center',
                                            } }), _jsxs(CardContent, { sx: { minHeight: '30vh' }, children: [children && _jsx(Divider, { sx: { mb: 8 } }), isLoading ? (_jsx(Box, { sx: {
                                                        height: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }, children: _jsx(CircularProgress, { color: "primary", size: 50 }) })) : (children)] })] })] })) : (children) }) })] }) }));
};
AuthPageLayout.defaultProps = {
    children: undefined,
    columnTitle: null,
    columnSubTitle: null,
    isLoading: false,
    contentMaxWidth: '400px',
};
export default AuthPageLayout;
