import { jsx as _jsx } from "react/jsx-runtime";
import LoadingButton from '@mui/lab/LoadingButton';
export const SubscribeOrderButton = ({ children, sx, ...rest }) => (_jsx(LoadingButton, { variant: "contained", type: "button", size: "large", color: "secondary", fullWidth: true, sx: {
        minWidth: '130px',
        bgcolor: (t) => t.palette.emmeSecondary.main,
        ':hover': {
            bgcolor: (t) => `${t.palette.emmeSecondary.main}80`,
        },
        ...sx,
    }, ...rest, children: children }));
