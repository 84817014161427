import { jsx as _jsx } from "react/jsx-runtime";
import LoadingButton from '@mui/lab/LoadingButton';
const ErrorLoadingButton = ({ children, sx, ...rest }) => (_jsx(LoadingButton, { type: "button", variant: "outlined", size: "small", color: "inherit", ...rest, sx: {
        border: (t) => `1px solid ${t.palette.error.main}`,
        backgroundColor: (t) => `${t.palette.error.main}20`,
        ':hover': {
            backgroundColor: (t) => `${t.palette.error.main}60`,
        },
        ...sx,
    }, children: children }));
export default ErrorLoadingButton;
