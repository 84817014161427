import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { EmmeTabs } from '.';
export var OrderTabs;
(function (OrderTabs) {
    OrderTabs["OPEN_ORDERS"] = "Open Orders";
    OrderTabs["CLOSED_ORDERS"] = "Closed Orders";
    OrderTabs["TRADE_HISTORY"] = "Trade History";
})(OrderTabs || (OrderTabs = {}));
export var OrderSideTabs;
(function (OrderSideTabs) {
    OrderSideTabs["PROVISION"] = "Provision";
    OrderSideTabs["SUBSCRIPTION"] = "Subscription";
})(OrderSideTabs || (OrderSideTabs = {}));
const orderTabs = [
    { label: OrderTabs.OPEN_ORDERS },
    { label: OrderTabs.CLOSED_ORDERS },
    { label: OrderTabs.TRADE_HISTORY },
];
const sideTabs = [
    { label: OrderSideTabs.PROVISION },
    { label: OrderSideTabs.SUBSCRIPTION },
];
const EmmeOrderTabs = ({ activeTab, onChangeTab, activeSideTab, onChangeSideTab, showTradeHistory, }) => {
    const { phone } = useBreakpoint();
    const formatSideTabLabel = phone ? (tab) => tab.charAt(0) : undefined;
    const visibilitySx = {
        opacity: activeTab === OrderTabs.TRADE_HISTORY ? 0 : 1,
        visibility: activeTab === OrderTabs.TRADE_HISTORY ? 'hidden' : 'visible',
        transition: 'all 0.2s ease',
    };
    return (_jsxs(Stack, { direction: "row", sx: { justifyContent: ['space-between', 'flex-start'] }, children: [_jsx(EmmeTabs, { value: activeTab, onChange: onChangeTab, tabs: showTradeHistory ? orderTabs : orderTabs.slice(0, 2) }), !phone && (_jsx(Divider, { orientation: "vertical", variant: "middle", flexItem: true, sx: { ...visibilitySx, mr: 3 } })), _jsx(EmmeTabs, { value: activeSideTab, onChange: onChangeSideTab, tabs: sideTabs, withBackground: true, sx: { ...visibilitySx, mb: [3, 0] }, formatLabel: formatSideTabLabel })] }));
};
EmmeOrderTabs.defaultProps = {
    showTradeHistory: false,
};
export default EmmeOrderTabs;
