import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, createContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
export const BreakpointContext = createContext({
    phone: false,
    tablet: false,
    desktop: false,
});
export function BreakpointProvider({ children }) {
    const theme = useTheme();
    const phone = useMediaQuery(theme.breakpoints.down('sm'));
    const tablet = useMediaQuery(theme.breakpoints.down('md'));
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const value = useMemo(() => ({ phone, tablet, desktop }), [phone, tablet, desktop]);
    return phone || tablet || desktop ? (_jsx(BreakpointContext.Provider, { value: value, children: children })) : null;
}
