import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { ProvisionSide } from '@emme/shared/model/enums';
import { ColumnAlign, ColumnSort, } from '@emme/shared/model/table.model';
import { useProvisionLiquidityMarketsTradeVerification } from '@emme/shared/queries/LiquidityMarkets';
import { AlertContext } from 'context/AlertProvider';
import { DialogTableLayout } from 'layout/Dialogs';
import { PUBLICATION_URL } from 'config';
import { dayJsTime } from 'utils/util';
import { DateCell, TypographyCell, NumberCell } from '../Table/cells';
import { EmmeTable } from '../Table';
import EmmeCircularProgress from '../EmmeCircularProgress';
const TooltipComponent = ({ data: { text, tooltip } }) => (_jsx(Tooltip, { title: tooltip, disableInteractive: true, children: _jsx(Typography, { component: "span", children: text }) }));
const FilledComponent = ({ data: { filled } }) => (_jsx(Box, { sx: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '30px',
    }, children: !filled || filled.eq(0) ? (_jsx(Typography, { color: "textSecondary", children: "-" })) : (_jsx(Tooltip, { title: `${filled.toFixed(2)}%`, disableInteractive: true, children: _jsx("span", { children: _jsx(EmmeCircularProgress, { thickness: 5, value: filled.toNumber(), size: 16 }) }) })) }));
const ProvisionOrdersDialog = ({ liquidityMarket, liquidityTradeId, onlyTable, navigationButtons, onProvisionsFetched, onNavigate, }) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [hasNavigation, setHasNavigation] = useState(undefined);
    const [verificationResponse, setVerificationResponse] = useState();
    const { setAlert } = useContext(AlertContext);
    const { mutateAsync: verifyProvision, isLoading } = useProvisionLiquidityMarketsTradeVerification();
    const loadVerifications = useCallback(async () => {
        if (!liquidityTradeId)
            return;
        setHasNavigation(navigationButtons ? { prev: null, next: null } : undefined);
        try {
            const data = await verifyProvision({
                liquidityMarketId: liquidityMarket?.id || '',
                liquidityTradeId,
            });
            if (!data.provisions[0]) {
                setAlert('Could not fetch liquidity provision data', 'error');
            }
            const { roundIndex, attempts, state, error } = data.provisions[0];
            if (state === 'RUNNING') {
                setAlert(`Liquidity provision ${roundIndex} is running`, 'info');
            }
            if (state === 'FAILED') {
                setAlert(error || `Liquidity provision ${roundIndex} failed`, 'error');
            }
            if (attempts > 0 || state === 'SUCCESSFUL') {
                setVerificationResponse(data);
                setIsOpen(true);
            }
        }
        catch (error) {
            setAlert(error?.message || error, 'error');
        }
        finally {
            if (onProvisionsFetched && !onlyTable) {
                onProvisionsFetched();
            }
        }
        setTimeout(() => {
            setHasNavigation(navigationButtons);
        }, 100);
    }, [liquidityTradeId]);
    useEffect(() => {
        loadVerifications();
    }, [loadVerifications]);
    const closeDialog = useCallback(() => {
        setIsOpen(false);
        onlyTable
            ? setVerificationResponse(undefined)
            : setTimeout(() => setVerificationResponse(undefined), 200);
    }, [onlyTable]);
    const headCells = [
        {
            id: 'startTime',
            Component: DateCell,
            label: 'Start Time',
            componentProps: {
                format: onlyTable ? dayJsTime : undefined,
            },
        },
        {
            id: 'endTime',
            Component: DateCell,
            label: 'End Time',
            componentProps: {
                format: onlyTable ? dayJsTime : undefined,
            },
        },
        {
            id: 'side',
            label: 'Side',
            align: ColumnAlign.CENTER,
            Component: TypographyCell,
        },
        {
            id: 'quotePrice',
            Component: NumberCell,
            label: `Price (${liquidityMarket?.quoteCoin})`,
        },
        {
            id: 'baseSize',
            Component: NumberCell,
            label: `Amount (${liquidityMarket?.baseCoin})`,
        },
        {
            id: 'successful',
            label: 'Successful',
            align: ColumnAlign.CENTER,
            Component: TooltipComponent,
        },
        {
            id: 'filled',
            label: 'Filled',
            align: ColumnAlign.CENTER,
            Component: FilledComponent,
        },
    ];
    const { provisions: [provision], } = verificationResponse || { provisions: [{}] };
    const { attempts = 0, failures = 0, fills = 0, roundIndex, orderPlacements = [], vbkTxId = undefined, } = provision;
    const table = (_jsx(EmmeTable, { rows: orderPlacements.map((data, index) => ({
            startTime: data?.startTime || '-',
            endTime: data?.endTime || '-',
            baseSize: data?.baseSize,
            quotePrice: data?.quotePrice,
            id: JSON.stringify(data) + index,
            successful: {
                text: data.successful ? 'Yes' : 'No',
                tooltip: !data.successful && data.error ? data?.error : '',
            },
            side: {
                text: data.side === ProvisionSide.SELL ? 'Sell' : 'Buy',
                color: data.side === ProvisionSide.SELL
                    ? theme.palette.error.main
                    : theme.palette.success.main,
            },
            filled: {
                filled: new BigNumber(data?.fillRatio || '0').multipliedBy(100),
            },
        })), tableMinWidth: 450, inputHeadCells: headCells, stickyHeader: true, initialSortBy: "startTime", initialSortDirection: ColumnSort.ASC, sortValues: {
            baseSize: {
                isNumber: true,
            },
            quotePrice: {
                isNumber: true,
            },
            successful: {
                value: 'text',
            },
            side: {
                value: 'text',
            },
            filled: {
                value: 'filled',
                isBigNumber: true,
            },
        }, isLoading: isLoading }));
    const subtitle = (_jsxs(Stack, { direction: "row", justifyContent: "flex-start", alignItems: "center", gap: 4, flexWrap: "nowrap", children: [_jsxs(Box, { children: ["Attempted order placements:", ' ', _jsx(Typography, { component: "span", color: "textPrimary", children: attempts })] }), _jsxs(Box, { children: ["Placed orders:", ' ', _jsx(Typography, { component: "span", color: "textPrimary", children: attempts - failures })] }), _jsxs(Box, { children: ["Filled orders:", ' ', _jsx(Typography, { component: "span", color: "textPrimary", children: fills })] }), _jsxs(Box, { children: ["Failed order placements:", ' ', _jsx(Typography, { component: "span", color: "textPrimary", children: failures })] }), vbkTxId && (_jsx(Box, { children: _jsx(Link, { href: `${PUBLICATION_URL}/${vbkTxId}`, target: "_blank", rel: "noreferrer noopener", "aria-label": "dialog-publication-data", children: "View Publication Data" }) }))] }));
    return !onlyTable ? (_jsx(DialogTableLayout, { isOpen: isOpen, title: `Liquidity provision in round ${roundIndex} was successful`, subtitle: subtitle, onClose: closeDialog, onNext: () => onNavigate && onNavigate(hasNavigation?.next || null), onPrev: () => onNavigate && onNavigate(hasNavigation?.prev || null), hasNavigationButtons: hasNavigation, children: table })) : (_jsxs(Stack, { height: "100%", pt: 2, children: [_jsx(Typography, { variant: "subtitle1", px: 5, children: subtitle }), table] }));
};
ProvisionOrdersDialog.defaultProps = {
    liquidityTradeId: undefined,
    onlyTable: false,
    navigationButtons: undefined,
    onProvisionsFetched: () => { },
    onNavigate: undefined,
};
export default ProvisionOrdersDialog;
