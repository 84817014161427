import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { numberAndLettersRegex } from 'utils/util';
export function CodeInput({ onChange, submit, isLoading, digitCount }) {
    const [values, setValues] = useState(new Array(digitCount).fill(''));
    const [isPasted, setIsPasted] = useState(false);
    const inputRefs = useRef([]);
    useEffect(() => {
        onChange(values.every(Boolean) ? values.join('') : '');
        if (isPasted && values.every(Boolean)) {
            submit && submit();
        }
    }, [values, isPasted, onChange, submit]);
    useEffect(() => {
        setIsPasted(false);
    }, [isLoading]);
    const setValueAtIndex = (index, value) => {
        setValues((current) => [
            ...current.slice(0, index),
            value.toUpperCase(),
            ...current.slice(index + 1),
        ]);
    };
    const handleInputKeyDown = (inputIndex) => (event) => {
        if (values[inputIndex]) {
            if (event.keyCode === 13) {
                submit && submit();
            }
            else if (event.keyCode !== 9) {
                setValueAtIndex(inputIndex, '');
            }
        }
        else if (event.keyCode === 8) {
            inputRefs.current[inputIndex - 1]?.querySelector('input')?.focus();
        }
    };
    const handleInputChange = (inputIndex) => (event) => {
        const { value } = event.target;
        setIsPasted(false);
        const valueArray = value.split('');
        if (valueArray?.length > 1) {
            setIsPasted(true);
            valueArray.forEach((element, index) => {
                if (index >= (digitCount || 0) || (element && !numberAndLettersRegex.test(element))) {
                    return;
                }
                setValueAtIndex(index, element);
            });
            return;
        }
        if (value && !numberAndLettersRegex.test(value)) {
            return;
        }
        setValueAtIndex(inputIndex, value);
        if (value) {
            inputRefs.current[inputIndex + 1]?.querySelector('input')?.focus();
        }
    };
    return (_jsx(Stack, { direction: "row", gap: 4, justifyContent: "space-between", mb: 5, children: values.map((value, i) => (_jsx(TextField, { value: value, onChange: handleInputChange(i), onKeyDown: handleInputKeyDown(i), inputProps: {
                pattern: numberAndLettersRegex,
                sx: { textAlign: 'center', fontSize: 24, p: (digitCount || 0) > 6 ? 3 : undefined },
            }, ref: (el) => {
                inputRefs.current[i] = el;
            }, disabled: isLoading }, i))) }));
}
CodeInput.defaultProps = {
    digitCount: 8,
    submit: undefined,
    isLoading: false,
};
