import { jsx as _jsx } from "react/jsx-runtime";
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { ColumnAlign } from '@emme/shared/model/table.model';
export const ButtonCell = ({ columnProps = {}, data }) => (_jsx(LoadingButton, { type: "button", color: "inherit", variant: "outlined", size: "small", sx: {
        borderRadius: (t) => t.spacing(4),
        px: 2,
    }, ...columnProps, ...data, children: _jsx(Typography, { variant: "body3", component: "span", children: data.label || columnProps.label }) }));
ButtonCell.alignment = ColumnAlign.CENTER;
ButtonCell.defaultProps = {
    columnProps: {},
};
