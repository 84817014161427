import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useRef, useState, useTransition, } from 'react';
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useConnectInputs } from 'hooks/useConnectInputs';
import { useLiquidityMarketOrderBookProvision, useLiquidityMarketsTickerData, } from '@emme/shared/queries/LiquidityMarkets';
import { useUserBalanceData, useUserExchangesBalanceData } from '@emme/shared/queries/Users';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { SubscriptionMaxLiquidityContext } from 'context/SubscriptionMaxLiquidityProvider';
import { DebouncedSpreadContext, SpreadContext } from 'context/SpreadProvider';
import { ConfirmOrderContext } from 'context/ConfirmOrderProvider';
import { OnboardingContext } from 'context/OnboardingProvider';
import { AlertContext } from 'context/AlertProvider';
import { useCurrentRoundData } from '@emme/shared/queries/Rounds';
import { calculatePriceProvisionArray, formatSpread, genRandom64BitInt, maxCoinValue, maxPriceValue, } from 'utils/util';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { DisabledTooltip, PlaceOrderTooltip } from 'components/Tooltips';
import { MobileSpreadValue, SpreadSlider } from 'components/Slider';
import { CoinInputField } from 'components/Inputs';
import { QuickButton } from 'components/Buttons';
import DurationInput, { checkDurationInputValidity, getDurationInDays, } from './components/DurationInput';
import { PlaceOrderRowRightColumn, PlaceOrderRow } from './components/PlaceOrderRow';
import VolumeFormContainer from './components/VolumeFormContainer';
import { SubscribeOrderButton } from './components/SubscribeOrderButton';
import { CustomSeedInput } from './components/CustomSeedInput';
import { getAutofillSubscriptionFormData, processSubscriptionFormData } from './util';
import { ToggleQuickButton } from './components/ToggleQuickButton';
const createInitialValues = () => ({
    duration: '',
    price: '',
    volume: '',
    baseVolume: '',
    maxTotalCost: '0',
    seed: genRandom64BitInt().toString(16),
});
const initialRequired = {
    duration: 'Required',
    price: 'Required',
    volume: 'Required',
    baseVolume: 'Required',
};
const initialTouched = (value = false, maxTotalValue = false) => ({
    duration: value,
    price: value,
    volume: value,
    baseVolume: value,
    maxTotalCost: maxTotalValue,
});
const SubscriptionForm = () => {
    const initialValues = useRef(createInitialValues());
    const [{ values, touched, hasValidationError }, setState] = useState({
        values: initialValues.current,
        touched: initialTouched(),
        hasValidationError: initialRequired,
    });
    const [isUsingCustomMaxTotalCost, setIsUsingCustomMaxTotalCost] = useState(false);
    const [isPriceLockedToMarket, setIsPriceLockedToMarket] = useState(false);
    const [isUsingCustomSeed, setIsUsingCustomSeed] = useState(false);
    const { setVolume: setMaxLiquidityContextVolume } = useContext(SubscriptionMaxLiquidityContext);
    const { subscribeFormSpread: debouncedSubscribeFormSpread } = useContext(DebouncedSpreadContext);
    const { subscribeFormSpread, updateSubscribeFormSpread } = useContext(SpreadContext);
    const { selectedLiquidityMarket } = useContext(LiquidityContext);
    const { confirmAndPlaceOrder } = useContext(ConfirmOrderContext);
    const { open, activeStep } = useContext(OnboardingContext);
    const { tablet } = useBreakpoint();
    const { data: ticker, refetch: refetchTicker } = useLiquidityMarketsTickerData(selectedLiquidityMarket?.id);
    const { data: orderBookProvisions } = useLiquidityMarketOrderBookProvision(selectedLiquidityMarket?.id || '');
    const { data: currentRound } = useCurrentRoundData();
    const { data: exchangeBalances } = useUserExchangesBalanceData();
    const { data: balance } = useUserBalanceData();
    const { data: quoteCoinData } = useMarketInfoLatestCoinData(selectedLiquidityMarket?.quoteCoin);
    const { data: emmeCoinData } = useMarketInfoLatestCoinData('emme');
    const userAvailableBalance = new BigNumber(balance?.available || 0);
    const { setAlert } = useContext(AlertContext);
    const [, startTransition] = useTransition();
    const resetForm = () => {
        setIsUsingCustomMaxTotalCost(false);
        setIsUsingCustomSeed(false);
        setState({
            values: createInitialValues(),
            hasValidationError: initialRequired,
            touched: initialTouched(),
        });
    };
    useEffect(() => {
        if (selectedLiquidityMarket?.id) {
            refetchTicker();
        }
    }, [selectedLiquidityMarket, exchangeBalances, refetchTicker]);
    useEffect(() => () => setMaxLiquidityContextVolume(new BigNumber(0)), [selectedLiquidityMarket?.id, setMaxLiquidityContextVolume]);
    useEffect(() => {
        if (open && activeStep === 1) {
            resetForm();
        }
    }, [activeStep, open]);
    const setFieldError = useCallback((name, msg) => {
        setState((current) => ({
            ...current,
            touched: {
                ...current.touched,
                [name]: true,
            },
            hasValidationError: {
                ...current.hasValidationError,
                [name]: msg,
            },
        }));
    }, []);
    const touchAllFields = (value = true) => {
        setIsPriceLockedToMarket(false);
        setState({
            values,
            hasValidationError,
            touched: initialTouched(value, value ? isUsingCustomMaxTotalCost : false),
        });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedLiquidityMarket) {
            setAlert('market was not selected', 'error', 2500);
            return;
        }
        const isDurationValid = checkDurationInputValidity({
            formDataDuration: values?.duration,
            currentRoundDurationMs: (currentRound?.durationMs || 0) / 1000,
        });
        if (!isDurationValid) {
            const duration = (currentRound?.durationMs || 0) / (60 * 1000);
            const message = `Duration must be at least ${duration} minute${duration !== 1 ? 's' : ''}`;
            setFieldError('duration', message);
            setAlert(message, 'error');
            return;
        }
        try {
            await confirmAndPlaceOrder({
                data: processSubscriptionFormData(values, selectedLiquidityMarket.id, subscribeFormSpread),
                type: 'subscriptions',
            });
            resetForm();
        }
        catch { } // eslint-disable-line no-empty
    };
    const maxTotalCostPerDay = new BigNumber(values.price || 0)
        .multipliedBy(new BigNumber(values.volume || 0))
        .multipliedBy(2);
    const maxTotalCostCalculated = maxTotalCostPerDay
        .multipliedBy(getDurationInDays(values.duration))
        .decimalPlaces(8);
    const maxVolume = new BigNumber(maxCoinValue)
        .multipliedBy(1000) // 1,000B
        .dividedBy(new BigNumber(quoteCoinData?.priceUsd || 0))
        .dividedBy(2)
        .decimalPlaces(8);
    const setFieldValue = useCallback((name, value) => {
        setState((current) => ({
            values: {
                ...current.values,
                [name]: value,
            },
            touched: {
                ...current.touched,
                name: Boolean(Number(value)),
            },
            hasValidationError: {
                ...current.hasValidationError,
                [name]: new BigNumber(value || '0').eq(0) ? 'Required' : undefined,
            },
        }));
    }, []);
    useConnectInputs('baseVolume', 'volume', values.baseVolume, values.volume, ticker?.exchangeLastTradePrice || '', setFieldValue, true);
    useEffect(() => {
        const bigNumberValue = new BigNumber(values.baseVolume || 0);
        startTransition(() => {
            setMaxLiquidityContextVolume(bigNumberValue);
        });
    }, [setMaxLiquidityContextVolume, values.baseVolume]);
    const handleEstimatedValues = () => {
        setIsUsingCustomMaxTotalCost(false);
        setIsPriceLockedToMarket(false);
        // price calculate
        let provisionArray = calculatePriceProvisionArray(orderBookProvisions, subscribeFormSpread);
        if (provisionArray.length === 0) {
            // set spread
            updateSubscribeFormSpread(1);
            provisionArray = calculatePriceProvisionArray(orderBookProvisions, 1);
        }
        setState({
            values: getAutofillSubscriptionFormData(provisionArray, ticker?.exchangeLastTradePrice),
            hasValidationError: {},
            touched: initialTouched(true),
        });
    };
    useEffect(() => {
        if (isPriceLockedToMarket) {
            const provisionArray = calculatePriceProvisionArray(orderBookProvisions, debouncedSubscribeFormSpread);
            const price = new BigNumber(provisionArray[provisionArray.length - 1]?.price || '0').toFixed(8);
            setFieldValue('price', price);
        }
    }, [isPriceLockedToMarket, orderBookProvisions, debouncedSubscribeFormSpread, setFieldValue]);
    const handleAskClick = () => {
        setIsPriceLockedToMarket((current) => !current);
    };
    const handleSetTotalCostClick = async () => {
        if (!isUsingCustomMaxTotalCost) {
            setFieldValue('maxTotalCost', maxTotalCostCalculated.toFixed(8));
        }
        else {
            setFieldValue('maxTotalCost', '0');
            setFieldError('maxTotalCost', undefined);
        }
        setIsUsingCustomMaxTotalCost(!isUsingCustomMaxTotalCost);
    };
    const handleSetCustomSeedClick = () => {
        if (isUsingCustomSeed) {
            setFieldValue('seed', initialValues.current.seed);
        }
        setIsUsingCustomSeed(!isUsingCustomSeed);
    };
    const insufficientFunds = userAvailableBalance.lt(maxTotalCostCalculated);
    const handleChange = useCallback((event) => {
        if (event.type === 'change') {
            const { name, value } = event.target;
            setFieldValue(name, value);
        }
    }, [setFieldValue]);
    return (_jsxs(Stack, { component: "form", width: "100%", height: "100%", onSubmit: handleSubmit, noValidate: true, gap: 8, children: [_jsxs(Box, { px: 3, children: [_jsx(Box, { textAlign: "right", pt: 1, children: _jsx(Tooltip, { title: "Fill all inputs with estimated values", placement: "left", children: _jsx("span", { children: _jsx(QuickButton, { onClick: handleEstimatedValues, children: "Autofill values" }) }) }) }), _jsx(DurationInput, { setFieldValue: (value) => setFieldValue('duration', value), handleChange: handleChange, setFieldError: (msg) => setFieldError('duration', msg), name: "duration", variant: "outlined", value: values.duration, error: Boolean(touched.duration && hasValidationError.duration), disabled: !selectedLiquidityMarket, sx: { mt: 3 } }), tablet ? (_jsxs(Stack, { justifyContent: "flex-start", alignItems: "stretch", flexDirection: "column", sx: { pl: 0, pr: 1 }, children: [_jsx(Typography, { variant: "subtitle1", color: "textPrimary", mt: 4, children: "Spread" }), _jsxs(Stack, { alignItems: "center", gap: 4, flex: "auto", direction: "row", children: [_jsx(MobileSpreadValue, { spread: subscribeFormSpread }), _jsx(Slider, { value: subscribeFormSpread, getAriaValueText: formatSpread, valueLabelFormat: formatSpread, valueLabelDisplay: "auto", step: 0.05, min: 0.25, max: 4, name: "spread", onChange: (e, newValue) => updateSubscribeFormSpread(newValue), size: "small" })] })] })) : (_jsx(SpreadSlider, { value: subscribeFormSpread, onChange: updateSubscribeFormSpread, showTooltip: true, sx: { pl: 0, pr: 5 } })), _jsx(Stack, { direction: "row-reverse", children: _jsx(DisabledTooltip, { title: new BigNumber(ticker?.liquidityBestAskPrice || '0').eq(0)
                                ? 'Currently no Provisions available'
                                : '', disabledColor: new BigNumber(ticker?.liquidityBestAskPrice || '0').eq(0), children: _jsx(QuickButton, { onClick: handleAskClick, variant: isPriceLockedToMarket ? 'contained' : 'outlined', color: isPriceLockedToMarket ? 'primary' : 'inherit', disabled: new BigNumber(ticker?.liquidityBestAskPrice || '0').eq(0), startIcon: isPriceLockedToMarket ? _jsx(ToggleOnOutlinedIcon, {}) : _jsx(ToggleOffOutlinedIcon, {}), sx: {
                                    '& .MuiButton-startIcon': { mr: 2 },
                                }, children: "Ask" }) }) }), _jsx(PlaceOrderTooltip, { title: `$EMME you are willing to pay, per equivalent of 1 ${selectedLiquidityMarket?.quoteCoin} of liquidity during 1 day.`, disabledColor: !selectedLiquidityMarket, children: _jsx(CoinInputField, { label: "Price", value: values.price || '', onChange: handleChange, coin: "$EMME", variant: "outlined", name: "price", max: maxPriceValue.toNumber(), setFieldError: setFieldError, required: true, helperText: _jsxs(_Fragment, { children: [_jsxs(Typography, { variant: "body3", color: "text.secondary", children: ["Per ", selectedLiquidityMarket?.quoteCoin, " Liquidity days"] }), hasValidationError.price &&
                                        hasValidationError?.price !== 'Required' &&
                                        !isPriceLockedToMarket ? (_jsx(Typography, { variant: "body3", color: "error.main", align: "right", children: hasValidationError?.price || ' ' })) : (_jsxs(Typography, { variant: "body3", color: "text.primary", children: ["$", new BigNumber(values?.price || 0)
                                                .multipliedBy(new BigNumber(emmeCoinData?.priceUsd || 0))
                                                .abs()
                                                .toFormat(2)] }))] }), error: touched.price && Boolean(hasValidationError.price) && !isPriceLockedToMarket, disabled: !selectedLiquidityMarket, sx: { mt: 2 } }) })] }), _jsxs(VolumeFormContainer, { maxContentHeight: "500px", children: [_jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "flex-start", typography: "subtitle1", color: "text.primary", gap: 2, py: 5, children: ["Max Liquidity", _jsx(Tooltip, { title: `Both sides liquidity amounts are linked to each other. Changing ${selectedLiquidityMarket?.quoteCoin || '-'} or ${selectedLiquidityMarket?.baseCoin || '-'} values will update the other one.`, children: _jsx(InfoOutlinedIcon, { fontSize: "inherit", sx: { cursor: 'help' } }) })] }), _jsx(PlaceOrderTooltip, { title: `Maximum amount of ${selectedLiquidityMarket?.quoteCoin} liquidity you would like to subscribe to.
          You must subscribe to the same equivalent value in ${selectedLiquidityMarket?.baseCoin}.`, disabledColor: !selectedLiquidityMarket, children: _jsx(CoinInputField, { label: "Amount", value: values.volume || '', onChange: handleChange, coin: selectedLiquidityMarket?.quoteCoin || '', variant: "outlined", name: "volume", max: maxVolume.toNumber(), setFieldError: setFieldError, required: true, helperText: hasValidationError.volume && hasValidationError?.volume !== 'Required' ? (_jsx(Typography, { variant: "body3", color: "error.main", children: hasValidationError?.volume || ' ' })) : (' '), error: touched.volume && Boolean(hasValidationError.volume), FormHelperTextProps: {
                                sx: { justifyContent: 'flex-end' },
                            }, disabled: !selectedLiquidityMarket }) }), _jsx(PlaceOrderTooltip, { title: `Maximum amount of ${selectedLiquidityMarket?.baseCoin} liquidity you would like to subscribe to.
          You must subscribe to the same equivalent value in ${selectedLiquidityMarket?.quoteCoin}.`, disabledColor: !selectedLiquidityMarket, children: _jsx(CoinInputField, { label: "Amount", value: values.baseVolume || '', onChange: handleChange, coin: selectedLiquidityMarket?.baseCoin || '', variant: "outlined", name: "baseVolume", max: maxVolume.dividedBy(ticker?.exchangeLastTradePrice || '').toNumber(), setFieldError: setFieldError, required: true, helperText: hasValidationError.baseVolume && hasValidationError?.baseVolume !== 'Required' ? (_jsx(Typography, { variant: "body3", color: "error.main", children: hasValidationError?.baseVolume || ' ' })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body3", color: "text.secondary", children: "Combined Liquidity:" }), _jsxs(Typography, { variant: "body3", color: "text.primary", sx: { wordBreak: 'break-all' }, children: ["$", new BigNumber(values?.volume || 0)
                                                .multipliedBy(new BigNumber(quoteCoinData?.priceUsd || 0))
                                                .multipliedBy(2)
                                                .abs()
                                                .toFormat(2)] })] })), error: touched.baseVolume && Boolean(hasValidationError.baseVolume), FormHelperTextProps: hasValidationError.baseVolume && hasValidationError?.baseVolume !== 'Required'
                                ? { sx: { justifyContent: 'flex-end' } }
                                : {}, disabled: !selectedLiquidityMarket }) }), _jsxs(PlaceOrderRow, { mt: 3, children: [_jsx(Box, { children: "Available:" }), _jsxs(PlaceOrderRowRightColumn, { children: [new BigNumber(balance?.available || 0).toFormat(8), " $EMME"] })] }), _jsxs(PlaceOrderRow, { children: [_jsx(Box, { children: "Max Total Cost per day:" }), _jsxs(PlaceOrderRowRightColumn, { children: [!maxTotalCostPerDay.eq(0) ? maxTotalCostPerDay.toFormat(8) : '-', " $EMME"] })] }), _jsxs(PlaceOrderRow, { alignItems: "center", justifyContent: "flex-start", children: [insufficientFunds && (_jsx(Tooltip, { title: `Available balance (${userAvailableBalance.toFormat()} $EMME) is less than Max
                Total Cost. You may run out of EM.ME funds before the selected duration elapses.`, children: _jsx(ErrorOutlineOutlinedIcon, { fontSize: "inherit", color: "warning", sx: { cursor: 'help' } }) })), _jsx(Box, { mr: "auto", children: "Max Total Cost:" }), _jsxs(Box, { color: "text.primary", sx: { wordBreak: 'break-all' }, textAlign: "right", children: [!maxTotalCostCalculated.eq(0) ? maxTotalCostCalculated.toFormat(8) : '-', " $EMME"] }), _jsx(PlaceOrderTooltip, { title: !maxTotalCostCalculated.gt(0)
                                    ? 'In order to set Custom Max Total Cost, Max Total Must be greater than zero'
                                    : 'Set Custom Max Total Cost', placement: "top-end", disabledColor: !maxTotalCostCalculated.gt(0), children: _jsx(ToggleQuickButton, { onClick: handleSetTotalCostClick, disabled: !maxTotalCostCalculated.gt(0), isActive: isUsingCustomMaxTotalCost }) })] }), isUsingCustomMaxTotalCost && (_jsx(PlaceOrderTooltip, { title: "Maximum amount of $EMME you are willing to pay in total", disabledColor: !selectedLiquidityMarket || !maxTotalCostCalculated.gt(0), children: _jsx(CoinInputField, { value: values.maxTotalCost || '0', onChange: handleChange, coin: "$EMME", variant: "outlined", name: "maxTotalCost", max: maxTotalCostCalculated.toNumber(), setFieldError: setFieldError, required: true, helperText: hasValidationError.maxTotalCost &&
                                hasValidationError?.maxTotalCost !== 'Required' ? (_jsx(Typography, { variant: "body3", color: "error.main", children: hasValidationError?.maxTotalCost || ' ' })) : (_jsxs(Typography, { variant: "body3", color: "text.primary", sx: { wordBreak: 'break-all' }, children: ["$", new BigNumber(values?.maxTotalCost || 0)
                                        .multipliedBy(new BigNumber(emmeCoinData?.priceUsd || 0))
                                        .toFormat(2)] })), error: touched.maxTotalCost && Boolean(hasValidationError.maxTotalCost), disabled: !selectedLiquidityMarket || !maxTotalCostCalculated.gt(0), sx: { mb: 2 }, FormHelperTextProps: {
                                sx: { justifyContent: 'flex-end' },
                            } }) })), _jsxs(PlaceOrderRow, { alignItems: "center", justifyContent: "flex-start", children: [_jsxs(Box, { mr: "auto", children: [isUsingCustomSeed ? 'Custom' : 'Random', " seed:"] }), _jsx(Box, { color: "text.primary", children: values.seed.padStart(16, '0').toUpperCase() }), _jsx(PlaceOrderTooltip, { title: "Set Custom Seed", placement: "top-end", children: _jsx(ToggleQuickButton, { onClick: handleSetCustomSeedClick, isActive: isUsingCustomSeed }) })] }), isUsingCustomSeed && _jsx(CustomSeedInput, { onChange: handleChange, value: values.seed }), _jsx(Box, { sx: { flex: 'auto' } }), _jsxs(PlaceOrderRow, { py: 3, children: [_jsx(Box, { children: "fee:" }), _jsx(PlaceOrderRowRightColumn, { children: "0 $EMME" })] }), _jsx(Box, { role: "button", onClick: () => touchAllFields(), onKeyPress: () => touchAllFields(), tabIndex: -1, mt: 2, sx: {
                            position: ['fixed', undefined, 'initial'],
                            p: [3, undefined, 0],
                            backgroundColor: (t) => t.palette.neutralOne.main,
                            zIndex: 2,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }, children: _jsx(SubscribeOrderButton, { type: "submit", disabled: !selectedLiquidityMarket ||
                                !touched.duration ||
                                Object.keys(hasValidationError).some((key) => Boolean(hasValidationError[key])), children: "Place Order" }) })] })] }));
};
export default SubscriptionForm;
