import { useQuery } from '@tanstack/react-query';
import { createFetchFn } from '../../utils/axios';
const intervalTimeLiquidity = 10 * 1000;
export function useLiquidityMarketsTickerData(liquidityMarketId) {
    return useQuery(['liquidity-markets', 'ticker', liquidityMarketId], createFetchFn({
        requiresAuth: false,
        method: 'GET',
        url: `/liquidity-markets/${liquidityMarketId}/ticker`,
    }), {
        staleTime: intervalTimeLiquidity,
        onError: (error) => error,
        refetchInterval: intervalTimeLiquidity,
        refetchOnWindowFocus: true,
        enabled: Boolean(liquidityMarketId),
    });
}
