import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/system/Box';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { AlertContext } from 'context/AlertProvider';
import { DisabledTooltip } from 'components/Tooltips';
import { EmmeTabs } from 'components/Tabs';
import { defaultDashboards } from '../defaultDashboards';
import DashboardMenu from './DashboardMenu';
import { Panel } from '../widgets';
const CustomDashboards = () => {
    const [dashboards, setDashboards] = useState(defaultDashboards);
    const [activeDashboardLabel, setActiveDashboardLabel] = useState(defaultDashboards[0].title);
    const { setAlert } = useContext(AlertContext);
    const activeDashboard = dashboards.find(({ title }) => title === activeDashboardLabel);
    const handleAdd = () => {
        let index = 1;
        let newLabel = `dashboard ${(dashboards?.length || 0) + index}`;
        // eslint-disable-next-line no-loop-func
        while (index <= dashboards.length && dashboards.some(({ title }) => title === newLabel)) {
            index += 1;
            newLabel = `dashboard ${(dashboards?.length || 0) + index}`;
        }
        setDashboards([
            ...dashboards.concat({
                title: newLabel,
                id: `${(dashboards?.length || 0) + 1}a`,
                context: {},
                widgets: (dashboards?.length || 0) % 2
                    ? [
                        { id: 'aa', type: 'HelloWorld', grid: { x: 0, y: 0, w: 3, h: 1 } },
                        { id: 'ab', type: 'HelloWorld', grid: { x: 3, y: 5, w: 2, h: 1 } },
                    ]
                    : [{ id: 'aa', type: 'HelloWorld', grid: { x: 0, y: 0, w: 3, h: 1 } }],
            }),
        ]);
        setActiveDashboardLabel(newLabel);
    };
    const handleRemove = () => {
        if ((dashboards?.length || 0) <= 1) {
            setAlert('At least 1 panel must be available', 'error');
            return;
        }
        const newDashArray = dashboards.slice();
        const index = newDashArray?.findIndex(({ title }) => title === activeDashboardLabel);
        if (index > -1) {
            newDashArray.splice(index, 1);
            setAlert(`Successfully removed "${activeDashboardLabel}" panel`, 'success');
            setActiveDashboardLabel(newDashArray[index >= (newDashArray?.length || 0) - 1 ? newDashArray.length - 1 : index]
                ?.title);
            setDashboards([...newDashArray]);
        }
    };
    return (_jsxs(Stack, { children: [_jsxs(Stack, { direction: "row", alignItems: "center", bgcolor: ({ palette }) => palette.neutralsTwo.main, borderRadius: 1, m: 1, children: [_jsx(EmmeTabs, { value: activeDashboardLabel, onChange: setActiveDashboardLabel, tabs: dashboards.map(({ title }) => ({ label: title })), withBackground: true }), _jsx(DisabledTooltip, { title: (dashboards?.length || 0) > 5 ? 'Maximum panel number reached' : '', disabledColor: (dashboards?.length || 0) > 5, children: _jsx(IconButton, { type: "button", disableRipple: true, tabIndex: -1, size: "small", onClick: handleAdd, disabled: (dashboards?.length || 0) > 5, edge: "end", "aria-label": "add new dashboard", children: _jsx(AddOutlinedIcon, { fontSize: "inherit" }) }) }), _jsx(Box, { flexGrow: 1 }), _jsx(DashboardMenu, { onRemove: handleRemove })] }), activeDashboard && _jsx(Panel, { ...activeDashboard })] }));
};
export default CustomDashboards;
