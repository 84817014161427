import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState, createContext } from 'react';
import { useUpdateUserOnboarding, useUserInfoData } from '@emme/shared/queries/Users';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { sleep } from 'utils/util';
export var OnboardingType;
(function (OnboardingType) {
    OnboardingType[OnboardingType["FIRST"] = 0] = "FIRST";
    OnboardingType[OnboardingType["WELCOME_BACK"] = 1] = "WELCOME_BACK";
    OnboardingType[OnboardingType["HELP"] = 2] = "HELP";
})(OnboardingType || (OnboardingType = {}));
export const OnboardingContext = createContext({
    open: false,
    hidden: false,
    onboardingType: undefined,
    activeStep: 0,
    showSkip: false,
    allSteps: [],
    openOnboarding: console.error,
    closeOnboarding: console.error,
    hideOnboarding: console.error,
    changeActiveStep: console.error,
    changeAllSteps: console.error,
    toggleSkip: console.error,
});
export function OnboardingProvider({ children }) {
    const { tablet } = useBreakpoint();
    const [onboardingType, setOnboardingType] = useState();
    const [hidden, setHidden] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [allSteps, setAllSteps] = useState([]);
    const [showSkip, setShowSkip] = useState(false);
    const { mutateAsync: updateUserOnboarding } = useUpdateUserOnboarding();
    const { data: user } = useUserInfoData();
    const shouldInitFirstOnboarding = user && !user.onboardingFinished && onboardingType === undefined;
    useEffect(() => {
        if (shouldInitFirstOnboarding) {
            setOnboardingType(OnboardingType.FIRST);
        }
    }, [shouldInitFirstOnboarding]);
    const changeActiveStep = useCallback(async (value) => {
        if (tablet || allSteps[activeStep]?.anchorElementID === allSteps[value]?.anchorElementID) {
            setActiveStep(value);
        }
        else {
            setHidden(true);
            await sleep(100);
            setActiveStep(value);
            setHidden(false);
        }
    }, [activeStep, allSteps, tablet]);
    const changeAllSteps = useCallback((value) => {
        if (JSON.stringify(value) !== JSON.stringify(allSteps)) {
            setAllSteps([...value]);
        }
    }, [allSteps]);
    const toggleSkip = useCallback((value) => {
        setHidden(value);
        setShowSkip(value);
    }, []);
    const closeOnboarding = useCallback(async () => {
        setHidden(true);
        if (!user?.onboardingFinished) {
            await updateUserOnboarding(true);
        }
        setOnboardingType(undefined);
        await sleep(200);
        setHidden(false);
        setActiveStep(0);
    }, [user?.onboardingFinished, updateUserOnboarding]);
    const hideOnboarding = useCallback(() => {
        setHidden(true);
    }, []);
    const contextValue = useMemo(() => ({
        open: onboardingType !== undefined,
        hidden,
        onboardingType,
        activeStep,
        showSkip,
        allSteps,
        openOnboarding: setOnboardingType,
        closeOnboarding,
        hideOnboarding,
        changeActiveStep,
        changeAllSteps,
        toggleSkip,
    }), [
        hidden,
        onboardingType,
        activeStep,
        showSkip,
        allSteps,
        closeOnboarding,
        hideOnboarding,
        changeActiveStep,
        changeAllSteps,
        toggleSkip,
    ]);
    return _jsx(OnboardingContext.Provider, { value: contextValue, children: children });
}
