import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Box from '@mui/material/Box';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
import { EmmeTable } from '../Table';
import { getProvisionsHeadCells, getProvisionTableData, getSubscriptionsHeadCells, getSubscriptionTableData, } from './util';
import OrderTradesDialog from './OrderTradesDialog';
import { CancelOrderDialog } from './CancelOrderDialog';
const Wrapper = ({ verticalRows, size, children, }) => verticalRows ? (_jsx(Box, { sx: {
        table: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, min-content)',
            gridTemplateRows: `repeat(${size}, min-content)`,
            gridAutoFlow: 'column',
            px: 5,
            py: 3,
            rowGap: 7,
            columnGap: 9,
        },
        thead: {
            display: 'contents',
        },
        tbody: {
            display: 'contents',
        },
        tr: {
            display: 'contents',
        },
        'th:first-of-type': {
            paddingLeft: 0,
        },
        'td:first-of-type': {
            paddingLeft: 0,
        },
        div: {
            alignItems: 'flex-start',
            justifyContent: 'initial',
        },
        th: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            textAlign: 'left',
            lineHeight: 1,
            padding: 0,
        },
        td: {
            display: 'block',
            textAlign: 'left',
            padding: 0,
        },
    }, children: children })) : children;
export function OrdersTable({ orders, type, liquidityMarket, onOrderCancel, withCancelButton = false, withTradesButton = false, verticalRows = false, ...tableProps }) {
    const [selectedOrder, setSelectedOrder] = useState(undefined);
    const [orderToCancel, setOrderToCancel] = useState(undefined);
    const { tablet } = useBreakpoint();
    const { data: emmeCoin } = useMarketInfoLatestCoinData('emme');
    const { data: quoteCoin } = useMarketInfoLatestCoinData(liquidityMarket?.quoteCoin);
    const headCells = type === 'provisions'
        ? getProvisionsHeadCells({ withCancelButton, withTradesButton, verticalRows })
        : getSubscriptionsHeadCells({
            withCancelButton,
            withTradesButton,
            verticalRows,
            quoteCoin: liquidityMarket?.quoteCoin,
        });
    const rows = orders.map((data) => {
        const cancelButtonProps = {
            onClick: () => setOrderToCancel(data.id),
            fullWidth: tablet,
        };
        const tradesButtonProps = {
            onClick: () => setSelectedOrder(data),
            fullWidth: tablet,
        };
        let buttonDataProps;
        if (withCancelButton && withTradesButton) {
            buttonDataProps = [cancelButtonProps, tradesButtonProps];
        }
        else if (withCancelButton) {
            buttonDataProps = cancelButtonProps;
        }
        else if (withTradesButton) {
            buttonDataProps = tradesButtonProps;
        }
        return type === 'provisions'
            ? getProvisionTableData({
                data: data,
                liquidityMarket,
                emmeCoin,
                button: buttonDataProps,
            })
            : getSubscriptionTableData({
                data: data,
                emmeCoin,
                quoteCoin,
                button: buttonDataProps,
            });
    });
    return (_jsxs(Wrapper, { verticalRows: verticalRows, size: headCells.length, children: [_jsx(EmmeTable, { rows: rows, inputHeadCells: headCells, ...tableProps }), selectedOrder && (_jsx(OrderTradesDialog, { selectedOrder: selectedOrder, liquidityMarket: liquidityMarket, isProvisionSide: type === 'provisions', onClose: () => setSelectedOrder(undefined) })), orderToCancel && (_jsx(CancelOrderDialog, { type: type, orderId: orderToCancel, liquidityMarket: liquidityMarket, onOrderCancel: onOrderCancel, onClose: () => setOrderToCancel(undefined) }))] }));
}
OrdersTable.defaultProps = {
    withTradesButton: false,
    withCancelButton: false,
    verticalRows: false,
    onOrderCancel: undefined,
};
