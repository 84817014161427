export function getLiquidityPriceData() {
    return new Array(200).fill(undefined).flatMap((_, index) => {
        const base = Math.random() * 80 + 20;
        const height = Math.random() * 5 + 10;
        return [
            {
                x: index + 60000,
                y: base + height,
                y0: base,
                type: 'bottom',
            },
            {
                x: index + 60000,
                y: base + 2 * height,
                y0: base + height,
                type: 'top',
            },
        ];
    });
}
export function getLiquidityVolumeData() {
    return new Array(20).fill(undefined).flatMap((_, index) => [
        {
            x: index * 10 + 60000,
            y: Math.random() * 10 + 80,
        },
    ]);
}
export function getLiquidityPercentageData() {
    return new Array(20).fill(undefined).flatMap((_, index) => [
        {
            x: index * 10 + 60000,
            y: Math.random() * 10 + 40,
        },
    ]);
}
export function getSimulatedMarketDepthData() {
    return new Array(20).fill(undefined).flatMap((_, index) => [
        {
            x: index * 10 + 60000,
            y: Math.random() * 10 + 40,
        },
    ]);
}
