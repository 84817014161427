import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { OnboardingContext } from 'context/OnboardingProvider';
const OnboardingStepper = ({ disableNext, disableBack }) => {
    const { activeStep, changeActiveStep, allSteps } = useContext(OnboardingContext);
    const { tablet } = useBreakpoint();
    const showBackButton = activeStep > 0;
    const showNextButton = activeStep > 0 && activeStep < allSteps.length - 1 && allSteps[activeStep]?.stepNumber !== 3;
    return (_jsx(MobileStepper, { variant: "dots", steps: allSteps.length, position: "static", activeStep: activeStep, backButton: _jsx(Box, { sx: { minWidth: '110px' }, children: showBackButton && (_jsx(Button, { size: "small", color: "inherit", variant: "outlined", onClick: () => changeActiveStep(activeStep - 1), sx: {
                    minWidth: '110px',
                    fontSize: tablet ? 12 : undefined,
                }, disabled: disableBack, children: "Previous" })) }), nextButton: _jsx(Box, { sx: { minWidth: '110px' }, children: showNextButton && (_jsx(Button, { size: "small", color: "primary", variant: "outlined", onClick: () => changeActiveStep(activeStep + 1), sx: {
                    border: (t) => `1px solid ${t.palette.primary.main}`,
                    minWidth: '110px',
                    fontSize: tablet ? 12 : undefined,
                }, disabled: disableNext, children: "Next" })) }), sx: { backgroundColor: 'transparent' } }));
};
OnboardingStepper.defaultProps = {
    disableBack: false,
    disableNext: false,
};
export default OnboardingStepper;
