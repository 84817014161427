import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useBreakpoint } from 'hooks/useBreakpoint';
import MainLayout from 'layout/MainLayout';
import { MobileDrawer } from 'layout/Navbar/components';
import { TitleWithRoutes, ListItemLink } from './components';
const AccountPageLayout = ({ title, navigationList, navigationIcons, children, }) => {
    const location = useLocation();
    const { tablet } = useBreakpoint();
    const selectListVariant = () => Object.keys(navigationList).map((key) => {
        if (key.split('/')?.length <= 2)
            return null;
        return (_jsx(ListItemLink, { to: key, primaryText: navigationList[key], iconChild: navigationIcons[key], phoneWidth: tablet, isSelected: location.pathname === key }, key));
    });
    return (_jsx(MainLayout, { children: _jsxs(Container, { maxWidth: false, disableGutters: true, sx: { maxWidth: '1024px', px: [4, 7, 9] }, children: [_jsx(TitleWithRoutes, { title: title, navigationList: navigationList, phoneWidth: tablet, children: tablet && (_jsx(MobileDrawer, { drawerVariant: "bottom", iconToShow: MoreVertOutlinedIcon, children: selectListVariant() })) }), _jsxs(Stack, { sx: {
                        flexDirection: ['column', undefined, 'row'],
                        alignItems: ['stretch', undefined, 'flex-start'],
                        justifyContent: 'flex-start',
                    }, children: [!tablet && (_jsx(Stack, { alignItems: "flex-start", sx: { width: '240px' }, children: selectListVariant() })), _jsx(Paper, { sx: {
                                width: [undefined, undefined, '650px'],
                                backgroundColor: (t) => t.palette.neutralOne.dark,
                                py: 8,
                                px: [2, 5],
                                borderRadius: (t) => t.spacing(5),
                                boxShadow: '0px 64px 64px -48px rgba(15, 15, 15, 0.1)',
                            }, children: children })] })] }) }));
};
export default AccountPageLayout;
