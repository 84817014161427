import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { getErrorFormatter, lowerCaseRegex, numberRegex, specialCharacterRegex, upperCaseRegex, } from 'utils/util';
import { AlreadyHaveAnAccountLink } from 'components/AlreadyHaveAnAccountLink';
import { PasswordTextField } from 'components/Inputs';
import TermsCheckbox from './TermsCheckbox';
const validationSchema = Yup.object({
    email: Yup.string()
        .label('Email address')
        .email('It should be valid email address!')
        .required('Required'),
    password: Yup.string()
        .label('Password')
        .min(8, 'Length must be at least 8 letters!')
        .max(100, 'Length must be less than 100 letters!')
        .matches(lowerCaseRegex, 'Password should contain at least 1 small letter')
        .matches(upperCaseRegex, 'Password should contain at least 1 capital letter')
        .matches(numberRegex, 'Password should contain at least 1 number')
        .matches(specialCharacterRegex, 'Password should contain at least 1 special character (e.g. !#$%&*-?@^)')
        .required(),
    confirmPassword: Yup.string().when('password', (password, field) => {
        if (!password)
            return field;
        return field
            .required('Confirm password is required')
            .oneOf([Yup.ref('password')], 'Passwords must match');
    }),
    termsOfService: Yup.boolean()
        .required('The terms and conditions must be accepted.')
        .oneOf([true], 'The terms and conditions must be accepted.'),
});
const SignupForm = ({ onSubmit, loading }) => {
    const { handleSubmit, handleChange, values, errors, touched, setFieldTouched, isValid } = useFormik({
        initialValues: {
            termsOfService: false,
            email: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema,
        onSubmit: ({ email, password, termsOfService }) => onSubmit({ email: email.trim(), password, termsOfService }),
    });
    const formatError = getErrorFormatter(touched, errors);
    const touchAllFields = () => {
        setFieldTouched('email');
        setFieldTouched('password');
        setFieldTouched('confirmPassword');
        setFieldTouched('termsOfService');
    };
    return (_jsxs(Stack, { component: "form", onSubmit: handleSubmit, noValidate: true, autoComplete: "off", gap: 3, children: [_jsx(TextField, { label: "Email Address", value: values.email, onChange: handleChange, onBlur: () => setFieldTouched('email'), name: "email", fullWidth: true, autoComplete: "new-email", required: true, autoFocus: true, helperText: formatError('email'), error: touched.email && Boolean(errors.email) }), _jsx(PasswordTextField, { label: "Password", value: values.password, onChange: handleChange, onBlur: () => setFieldTouched('password'), name: "password", fullWidth: true, autoComplete: "new-password", helperText: formatError('password'), error: touched.password && Boolean(errors.password) }), _jsx(PasswordTextField, { label: "Confirm password", value: values.confirmPassword, onChange: handleChange, onBlur: () => setFieldTouched('confirmPassword'), name: "confirmPassword", fullWidth: true, autoComplete: "new-confirmPassword", helperText: formatError('confirmPassword'), error: touched.confirmPassword && Boolean(errors.confirmPassword) }), _jsx(TermsCheckbox, { touched: Boolean(touched.termsOfService), values: values.termsOfService, handleChange: handleChange, errors: errors.termsOfService || '', setFieldTouched: () => setFieldTouched('termsOfService') }), _jsx("div", { role: "button", onClick: () => touchAllFields(), onKeyPress: () => touchAllFields(), tabIndex: -1, children: _jsx(LoadingButton, { variant: "contained", type: "submit", fullWidth: true, size: "large", loading: loading, disabled: !touched?.email || !isValid, children: _jsx(Typography, { variant: "h6", children: "Sign up" }) }) }), _jsx(AlreadyHaveAnAccountLink, {})] }));
};
export default SignupForm;
