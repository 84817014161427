import { jsx as _jsx } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const MobileSpreadValue = ({ spread, sx, ...rest }) => (_jsx(Box, { sx: {
        display: ['block', undefined, 'none'],
        backgroundColor: (t) => t.palette.neutralsThree.main,
        width: '40px',
        textAlign: 'center',
        borderRadius: 1,
        py: 1,
        ...sx,
    }, ...rest, children: _jsx(Typography, { variant: "subtitle1", color: "textPrimary", component: "span", children: spread.toFixed(2) }) }));
export default MobileSpreadValue;
