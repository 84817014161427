import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { OverviewContext } from 'context/Overview/OverviewProvider';
import { CoinIcon } from 'components/CoinIcon';
export function StepConfirmation() {
    const { completeSelectedStep } = useContext(OverviewContext);
    const handleCompleteClick = useCallback(() => {
        completeSelectedStep();
    }, [completeSelectedStep]);
    return (_jsxs(Stack, { gap: 6, children: [_jsx(Typography, { variant: "h6", children: "Search" }), _jsxs(Stack, { typography: "subtitle1", sx: {
                    borderRadius: 2,
                    bgcolor: ({ palette }) => `${palette.neutralsFour.main}20`,
                    p: 5,
                    color: ({ palette }) => palette.text.primary,
                }, children: [_jsx(Box, { children: "You are ready to start earning $EMME!" }), _jsx(Box, { children: "You could earn more $EMME by checking the steps that have this icon:" })] }), _jsxs(Stack, { direction: "row", gap: 2, typography: "subtitle1", alignItems: "center", sx: {
                    borderRadius: 2,
                    bgcolor: ({ palette }) => `${palette.neutralsFour.main}20`,
                    p: 5,
                    color: ({ palette }) => palette.text.primary,
                }, children: [_jsx(Box, { children: "Estimated earnings at" }), _jsx(Typography, { fontWeight: 700, children: "0.4500" }), _jsx(CoinIcon, { coin: "emme" }), _jsx(Box, { children: "per day" })] }), _jsx(Button, { type: "button", variant: "outlined", color: "inherit", onClick: handleCompleteClick, sx: { minWidth: '260px', alignSelf: 'center' }, children: "Continue" })] }));
}
