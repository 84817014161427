import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useCallback, createContext, useMemo, useContext } from 'react';
import { StorageKey } from '@emme/shared/model/enums';
import { OnlineStatusContext } from 'context/OnlineStatusProvider';
import { storage } from '@emme/shared';
export const SocketContext = createContext({
    isOpen: false,
    currentMessages: [],
    sendMessage: console.error,
    removeMessage: console.error,
    updateIsOpen: console.error,
    closeSocket: console.error,
});
const SocketProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentMessages, setCurrentMessages] = useState([]);
    const { checkAvailability } = useContext(OnlineStatusContext);
    const sendMessage = useCallback((query) => {
        if (!query)
            return;
        if (query?.dataRequest && typeof query.dataRequest !== 'string') {
            const dataRequest = JSON.stringify(query.dataRequest);
            setCurrentMessages((current) => [...current, JSON.stringify({ ...query, dataRequest })]);
            return;
        }
        setCurrentMessages((current) => [...current, JSON.stringify(query)]);
    }, []);
    const removeMessage = useCallback((query) => {
        if (!query)
            return;
        const newMessages = (currentMessages || []).slice();
        const index = newMessages.findIndex((msg) => msg === query);
        if (index > -1) {
            newMessages.splice(index, 1);
        }
        setCurrentMessages(newMessages);
    }, [currentMessages]);
    const closeSocket = useCallback(() => {
        setIsOpen(false);
        const token = storage.get(StorageKey.EMME_TOKEN);
        if (token) {
            checkAvailability();
        }
    }, [checkAvailability]);
    const value = useMemo(() => ({
        isOpen,
        currentMessages,
        sendMessage,
        removeMessage,
        updateIsOpen: setIsOpen,
        closeSocket,
    }), [isOpen, currentMessages, sendMessage, removeMessage, closeSocket]);
    return _jsx(SocketContext.Provider, { value: value, children: children });
};
export default SocketProvider;
