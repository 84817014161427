import { jsx as _jsx } from "react/jsx-runtime";
import Avatar from '@mui/material/Avatar';
import { COIN_API_URL } from '@emme/shared/apiConfig';
import { DefaultCoin } from '@emme/shared/icons';
export const CoinIcon = ({ coin, size, coinBackgroundColor }) => (_jsx(Avatar, { src: coin && coin !== '-' ? `${COIN_API_URL}/coins/${coin}/icon` : undefined, alt: coin?.toUpperCase() || 'unknown coin', sx: {
        height: size,
        width: size,
        bgcolor: ({ palette }) => palette.mode === 'dark' ? coinBackgroundColor : palette.neutralsSeven.main,
    }, children: _jsx(DefaultCoin, {}) }));
CoinIcon.defaultProps = {
    size: '12px',
    coinBackgroundColor: 'transparent',
};
