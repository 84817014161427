import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import BigNumber from 'bignumber.js';
import { CssBaseline } from '@mui/material';
import { queryClient } from '@emme/shared';
import { AnimatedLocationProvider } from 'context/AnimatedLocationProvider';
import { BreakpointProvider } from 'context/BreakpointProvider';
import { OnboardingProvider } from 'context/OnboardingProvider';
import { EMMEThemeProvider } from 'context/ThemeProvider';
import OnlineStatusProvider from 'context/OnlineStatusProvider';
import AlertProvider from 'context/AlertProvider';
import AppRoutes from './routing/Routes';
BigNumber.config({
    FORMAT: {
        groupSize: 3,
        groupSeparator: ',',
        decimalSeparator: '.',
    },
});
const ClientComponents = () => (_jsx(OnlineStatusProvider, { children: _jsx(AlertProvider, { children: _jsx(BreakpointProvider, { children: _jsx(AnimatedLocationProvider, { children: _jsx(OnboardingProvider, { children: _jsx(AppRoutes, {}) }) }) }) }) }));
const App = () => (_jsx(BrowserRouter, { children: _jsxs(EMMEThemeProvider, { children: [_jsx(CssBaseline, {}), _jsx(SnackbarProvider, { maxSnack: 3, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, children: _jsxs(QueryClientProvider, { client: queryClient, children: [_jsx(ClientComponents, {}), _jsx(ReactQueryDevtools, { initialIsOpen: false })] }) })] }) }));
export default App;
