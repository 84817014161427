import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-new */
import { useEffect, useContext, useState } from 'react';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import { StorageKey } from '@emme/shared/model/enums';
import { usePersistedState } from 'hooks/usePersistedState';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { NoHoverButton } from 'components/Buttons';
import { AVAILABLE_INDICATORS, AVAILABLE_INTERVALS, AVAILABLE_STYLES, } from 'pages/DashboardPage/components/tradingViewOptions';
import { NoData } from './NoData';
const CONTAINER_ID = 'trading-view-container';
const UNSUPPORTED_EXCHANGES = ['mock-exchange', 'lbank'];
const selectSx = {
    borderRadius: '999px',
    px: 3,
    minWidth: ['120px', undefined, undefined, undefined, '140px'],
    height: '28px',
    backgroundColor: 'transparent',
    svg: {
        fontSize: '14px',
    },
};
const defaultState = {
    interval: 3,
    style: 1,
    indicators: [''],
};
const TradingViewChart = () => {
    const theme = useTheme();
    const { selectedLiquidityMarket, selectedExchange } = useContext(LiquidityContext);
    const [tvScriptLoaded, setTvScriptLoaded] = useState(Boolean(window.TradingView));
    const [chartState, setChartState] = usePersistedState(defaultState, StorageKey.EMME_CHART_STATE);
    const hasSelectedLiquidityMarket = Boolean(selectedLiquidityMarket);
    const exchangeIdentifier = selectedExchange?.id.split('-')[0].toUpperCase();
    const symbol = !UNSUPPORTED_EXCHANGES.includes(selectedExchange?.id) &&
        `${exchangeIdentifier}:${selectedLiquidityMarket?.baseCoin}${selectedLiquidityMarket?.quoteCoin}`;
    const backgroundColor = theme.palette.neutralOne.dark;
    useEffect(() => {
        let interval;
        if (!tvScriptLoaded) {
            interval = setInterval(() => {
                if (window.TradingView)
                    setTvScriptLoaded(true);
            }, 100);
        }
        return interval ? () => clearInterval(interval) : undefined;
    }, [tvScriptLoaded]);
    useEffect(() => {
        const TradingViewWidget = window.TradingView?.widget;
        if (!hasSelectedLiquidityMarket || !symbol || !TradingViewWidget || !tvScriptLoaded) {
            return;
        }
        new TradingViewWidget({
            container_id: CONTAINER_ID,
            autosize: true,
            symbol,
            interval: AVAILABLE_INTERVALS[chartState.interval].value,
            style: AVAILABLE_STYLES[chartState.style].value,
            studies: chartState.indicators,
            timezone: 'exchange',
            theme: 'dark',
            withdateranges: false,
            hide_side_toolbar: true,
            hide_top_toolbar: true,
            allow_symbol_change: false,
            enable_publishing: false,
            save_image: false,
            locale: 'en',
            overrides: {
                'chartProperties.paneProperties.backgroundType': 'solid',
                'chartProperties.paneProperties.background': backgroundColor,
            },
        });
    }, [hasSelectedLiquidityMarket, symbol, chartState, backgroundColor, tvScriptLoaded]);
    const handleStyleChange = (event) => setChartState((state) => ({ ...state, style: Number(event.target.value) || 0 }));
    const handleIndicatorsChange = ({ target: { value } }) => setChartState((state) => ({
        ...state,
        indicators: typeof value === 'string' ? value.split(',') : value,
    }));
    const handleIntervalChange = (interval) => () => setChartState((state) => ({ ...state, interval }));
    const handleResetClick = () => {
        setChartState(defaultState);
    };
    const shouldDisplayResetButton = chartState.interval !== defaultState.interval ||
        chartState.style !== defaultState.style ||
        chartState.indicators.toString() !== '';
    let errorMessage = '';
    if (!symbol) {
        errorMessage = 'Exchange not supported by TradingView';
    }
    else if (!window.TradingView) {
        errorMessage = 'Could not load TradingView. Please reload the page';
    }
    if (!tvScriptLoaded) {
        return _jsx(LoadingOverlay, { isLoading: true, sx: { height: '100%', width: '100%' } });
    }
    if (errorMessage) {
        return _jsx(NoData, { message: errorMessage });
    }
    return (_jsxs(Stack, { gap: 2, sx: { height: '100%', width: '100%' }, children: [_jsxs(Stack, { direction: "row", alignItems: "center", gap: 4, sx: { flex: 'none', px: 5 }, children: [AVAILABLE_INTERVALS.map(({ name }, index) => (_jsx(NoHoverButton, { onClick: handleIntervalChange(index), sx: { minWidth: 'initial', px: 0 }, disableRipple: true, children: _jsx(Typography, { variant: "subtitle2", color: index === chartState.interval ? 'textPrimary' : 'textSecondary', component: "span", children: name }) }, name))), _jsx(Select, { "aria-label": "Chart style", value: chartState.style, onChange: handleStyleChange, IconComponent: ExpandMoreOutlined, sx: {
                            ml: 6,
                            ...selectSx,
                        }, children: AVAILABLE_STYLES.map(({ name }, index) => (_jsx(MenuItem, { value: index, children: name }, name))) }), _jsx(Select, { "aria-label": "Indicators", multiple: true, value: chartState.indicators, onChange: handleIndicatorsChange, renderValue: () => 'Indicators', IconComponent: ExpandMoreOutlined, sx: selectSx, MenuProps: {
                            sx: {
                                maxHeight: '40vh',
                            },
                        }, children: AVAILABLE_INDICATORS.map(({ name, value }) => (_jsx(MenuItem, { value: value, sx: { py: 4 }, disabled: chartState.indicators.length > 3 && !chartState.indicators.includes(value), children: name }, value))) }), _jsx(NoHoverButton, { sx: {
                            ml: 5,
                            display: ['none', undefined, 'block'],
                            visibility: shouldDisplayResetButton ? 'visible' : 'hidden',
                            opacity: shouldDisplayResetButton ? 1 : 0,
                            transition: 'all 0.2s',
                            px: 0,
                            minWidth: 0,
                        }, color: "inherit", onClick: handleResetClick, disableRipple: true, children: _jsx(Typography, { variant: "subtitle2", component: "span", children: "Reset" }) })] }), hasSelectedLiquidityMarket && _jsx(Box, { sx: { flex: 'auto' }, id: CONTAINER_ID })] }));
};
export default TradingViewChart;
