import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import { useLiquidityMarketsData } from '@emme/shared/queries/LiquidityMarkets';
import { useUserExchangeCredentialsData } from '@emme/shared/queries/Users';
import { useExchangesData } from '@emme/shared/queries/Exchanges';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { OverviewContext } from 'context/Overview/OverviewProvider';
import { ExchangeAvatar } from 'components/ExchangeAvatar';
import { ApiKeyDialog } from 'components/Dialogs';
import { defaultSelection } from './StepSearch';
export function Step1Exchange() {
    const [suggestedExchangeIds, setSuggestedExchangeIds] = useState([]);
    const { selectedExchange, changeExchange } = useContext(liquidityContext);
    const { completeSelectedStep, preferredCoin } = useContext(OverviewContext);
    const { data: exchanges } = useExchangesData();
    const { data: availableCredentials } = useUserExchangeCredentialsData();
    const { data: allMarkets } = useLiquidityMarketsData({
        includeTicker: false,
    });
    const preferredCoinNotSelected = !preferredCoin || preferredCoin === defaultSelection.toUpperCase();
    useEffect(() => {
        if (preferredCoinNotSelected) {
            setSuggestedExchangeIds([]);
            return;
        }
        if (allMarkets?.totalNumber && exchanges?.length) {
            setSuggestedExchangeIds([
                ...new Set((allMarkets?.elements || [])
                    .filter((all) => all.baseCoin === preferredCoin || all.quoteCoin === preferredCoin)
                    .map(({ exchangeId }) => exchangeId)),
            ]);
        }
    }, [allMarkets, exchanges, preferredCoin, preferredCoinNotSelected]);
    const handleSet = () => {
        completeSelectedStep();
    };
    return (_jsxs(Stack, { gap: 6, children: [_jsx(Typography, { variant: "h6", children: "Select Exchange" }), _jsxs(Stack, { gap: 6, direction: "row", justifyContent: "space-between", children: [suggestedExchangeIds?.length !== 0 && (_jsx(List, { dense: true, subheader: _jsx(Typography, { variant: "h6", children: "Exchanges with preferred coin:" }), children: exchanges?.length &&
                            exchanges.map((exchange) => {
                                if (!suggestedExchangeIds.includes(exchange.id)) {
                                    return;
                                }
                                const isDisabled = !availableCredentials ||
                                    !availableCredentials.some((a) => a.exchangeId === exchange.id);
                                return (_jsxs(ListItemButton, { divider: true, selected: exchange?.id === selectedExchange?.id, onClick: () => changeExchange(exchange?.id), sx: {
                                        border: '1px solid',
                                        borderColor: isDisabled ? 'transparent' : 'primary.main',
                                        borderRadius: 2,
                                    }, children: [_jsx(Typography, { color: "textSecondary", component: "span", pr: 2, maxHeight: "40px", children: _jsx(ExchangeAvatar, { exchange: exchange?.id, sx: { height: 'auto' } }) }), _jsx(ListItemText, { primary: exchange?.name?.split(' ')[0], primaryTypographyProps: { variant: 'body1', mt: 1 } })] }, exchange.id));
                            }) })), (preferredCoinNotSelected || exchanges?.length !== suggestedExchangeIds?.length) && (_jsx(List, { dense: true, subheader: _jsx(Typography, { variant: "h6", children: "All Available Exchanges:" }), children: (exchanges || []).map((exchange) => {
                            if (suggestedExchangeIds.includes(exchange.id)) {
                                return;
                            }
                            const isDisabled = !availableCredentials ||
                                !availableCredentials.some((a) => a.exchangeId === exchange.id);
                            return (_jsxs(ListItemButton, { divider: true, selected: exchange?.id === selectedExchange?.id, onClick: () => changeExchange(exchange?.id), sx: {
                                    border: '1px solid',
                                    borderColor: isDisabled ? 'transparent' : 'primary.main',
                                    borderRadius: 2,
                                }, children: [_jsx(Typography, { color: "textSecondary", component: "span", pr: 2, maxHeight: "40px", children: _jsx(ExchangeAvatar, { exchange: exchange?.id, sx: { height: 'auto' } }) }), _jsx(ListItemText, { primary: exchange?.name?.split(' ')[0], primaryTypographyProps: { variant: 'body1', mt: 1 } })] }, exchange.id));
                        }) })), _jsx(Stack, { gap: 6, width: "50%", children: Boolean(selectedExchange) && (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h6", children: "Add Api Keys:" }), _jsx(ApiKeyDialog, { onboarding: true, overview: true, onSubmit: handleSet })] })) })] })] }));
}
