import { useContext, useMemo } from 'react';
import { useUserExchangeCredentialsData } from '@emme/shared/queries/Users';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
import { minOverviewCoinAmountUsd } from 'utils/util';
import { useOverviewBalancesState } from './useOverviewBalance';
export function useOverviewStepErrors(selectedCoins, riskLevel, lastCompletedStep) {
    const { selectedExchange, isLoading: isLoadingExchanges } = useContext(liquidityContext);
    const { data: credentials, isLoading: isLoadingCredentials } = useUserExchangeCredentialsData();
    const balancesState = useOverviewBalancesState(selectedCoins);
    const hasApiKey = credentials?.find(({ exchangeId }) => exchangeId === selectedExchange?.id);
    const totalSelectedMarketVolume = selectedCoins.reduce((total, { amount }) => total + amount, 0);
    const isLoading = isLoadingExchanges || isLoadingCredentials;
    return useMemo(() => ({
        stepErrors: isLoading
            ? []
            : [
                // root
                null,
                // Search:
                null,
                // Exchange:
                hasApiKey ? null : 'Exchange API key is not set up',
                // Market:
                totalSelectedMarketVolume >= minOverviewCoinAmountUsd
                    ? null
                    : 'Not enough market volume is selected',
                // Balance:
                balancesState ? null : 'Not enough balance on exchange',
                // Risk:
                riskLevel !== undefined ? null : 'Risk level is not selected',
                // Confirmation:
                null, // dummy
            ].map((error, index) => 
            // Do not mark steps that have not been completed yet as error steps
            index > lastCompletedStep ? null : error),
        isLoading,
    }), [hasApiKey, totalSelectedMarketVolume, balancesState, riskLevel, lastCompletedStep, isLoading]);
}
