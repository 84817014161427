import { useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { useUserExchangesBalanceData } from '@emme/shared/queries/Users';
import { useMarketInfoCoinsData } from '@emme/shared/queries/Coins';
import { minOverviewCoinAmountUsd } from 'utils/util';
import liquidityContext from 'context/liquidityMarket/liquidityContext';
export function useOverviewBalances(selectedCoins) {
    const { selectedExchange } = useContext(liquidityContext);
    const { data: exchangeBalances } = useUserExchangesBalanceData();
    const { data: coinData } = useMarketInfoCoinsData((selectedCoins.map((c) => c.id) || []).join(','));
    const selectedExchangeBalances = useMemo(() => (exchangeBalances?.find(({ exchangeId }) => exchangeId === selectedExchange?.id)?.balances ||
        []).map(({ currency, available }) => ({ coin: currency, available })), [exchangeBalances, selectedExchange?.id]);
    const minBalances = useMemo(() => selectedCoins.map((coin) => {
        const priceUsd = coinData?.find((c) => c.symbol === coin?.id)?.priceUsd;
        return {
            coin: coin?.id,
            minimum: new BigNumber(minOverviewCoinAmountUsd)
                .dividedBy(new BigNumber(priceUsd || 1))
                .toFixed(8),
        };
    }), [coinData, selectedCoins]);
    const availableBalanceMap = useMemo(() => {
        const map = new Map();
        selectedCoins.forEach((coin) => {
            const { minimum = 0 } = minBalances.find((minBalance) => minBalance.coin === coin.id) || {};
            const available = Number(selectedExchangeBalances.find((exchangeBalance) => exchangeBalance.coin === coin.id)
                ?.available || 0);
            map.set(coin.id, { minimum: Number(minimum), available });
        });
        return map;
    }, [minBalances, selectedCoins, selectedExchangeBalances]);
    return availableBalanceMap;
}
export function useOverviewBalancesState(selectedCoins) {
    const balances = useOverviewBalances(selectedCoins);
    return [...balances.values()].every(({ minimum, available }) => minimum <= available);
}
export function useCoinBalance(coin) {
    const { selectedExchange } = useContext(liquidityContext);
    const { data: exchangeBalances } = useUserExchangesBalanceData();
    const balances = exchangeBalances?.find(({ exchangeId }) => exchangeId === selectedExchange?.id)?.balances || [];
    return new BigNumber(balances.find(({ currency }) => currency === coin)?.available || 0);
}
