import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { EmmeDialogTitle } from './components/EmmeDialogTitle';
const DialogLayout = ({ title, onClose, children, PaperProps, ...rest }) => (_jsxs(Dialog, { PaperProps: {
        ...PaperProps,
        sx: { borderRadius: 4, ...PaperProps?.sx },
    }, onClose: onClose, ...rest, children: [title && (_jsx(DialogTitle, { children: _jsx(EmmeDialogTitle, { onClose: onClose, children: title }) })), _jsx(DialogContent, { children: children })] }));
DialogLayout.defaultProps = {
    title: undefined,
};
export default DialogLayout;
