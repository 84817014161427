import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Navbar from 'layout/Navbar';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
const WidgetRoot = () => (_jsxs(Box, { sx: {
        display: 'grid',
        gridTemplateRows: 'auto minmax(0, 1fr)',
        gridTemplateAreas: `
        "header"
        "middle-grid"
      `,
        gap: '4px',
        height: '100vh',
        maxHeight: '100vh',
        minHeight: 0,
        backgroundColor: (t) => t.palette.background.default,
    }, children: [_jsx(Box, { sx: { gridArea: 'header' }, children: _jsx(Navbar, {}) }), _jsx(Box, { sx: { gridArea: 'middle-grid', overflow: 'auto', minHeight: 0, pb: 2 }, children: _jsx(Outlet, {}) })] }));
export default WidgetRoot;
