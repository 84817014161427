import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
const NoHoverButton = withStyles(() => ({
    root: {
        '&:hover': {
            background: 'none',
        },
    },
}))(Button);
export default NoHoverButton;
