import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import BigNumber from 'bignumber.js';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { darken } from '@mui/material';
import Box from '@mui/material/Box';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { OverviewContext, OverviewStatus, OverviewSteps } from 'context/Overview/OverviewProvider';
import { useMarketInfoLatestCoinData } from '@emme/shared/queries/Coins';
import { useUserBalanceData } from '@emme/shared/queries/Users';
import { OverviewCardLayout } from 'layout/Overview';
import { EmmeEarningAnimation } from 'components/Animations';
import { CoinIcon } from 'components/CoinIcon';
import { OverviewWelcome } from '@emme/shared/icons';
const standardButtonSx = {
    bgcolor: 'neutralsThree.main',
    ':hover': {
        backgroundColor: ({ palette }) => darken(palette.neutralsThree.main, 0.3),
    },
    ':disabled': {
        color: 'text.secondary',
        backgroundColor: ({ palette }) => darken(palette.neutralsThree.main, 0.3),
    },
};
const statusCss = {
    [OverviewStatus.ERROR]: {
        stackSx: {
            bgcolor: 'emmeError.main',
            borderColor: 'error.main',
        },
        avatarIcon: _jsx(ErrorOutlineOutlinedIcon, { fontSize: "inherit" }),
        titleNode: 'Disabled',
        buttonStartIcon: _jsx(ErrorOutlineOutlinedIcon, {}),
        buttonText: 'Fix issue',
    },
    [OverviewStatus.EARNING]: {
        stackSx: {
            bgcolor: 'emmeSecondary.main',
            borderColor: 'emmeSecondary.main',
        },
        titleNode: 'Earnings',
        buttonStartIcon: _jsx(PauseCircleOutlinedIcon, {}),
        buttonSx: standardButtonSx,
        buttonText: 'Pause',
    },
    [OverviewStatus.WELCOME]: {
        stackSx: {
            bgcolor: 'neutralsThree.main',
            borderColor: 'neutralsThree.main',
        },
        avatarIcon: _jsx(OverviewWelcome, {}),
        titleNode: 'Welcome!',
        buttonText: 'Start earning',
    },
    [OverviewStatus.PAUSED]: {
        stackSx: {
            bgcolor: 'warning.main',
            borderColor: 'emmeInfo.main',
            color: 'neutralsThree.main',
        },
        avatarIcon: _jsx(PlayCircleOutlinedIcon, { fontSize: "inherit" }),
        titleNode: 'Paused',
        buttonStartIcon: _jsx(PlayCircleOutlinedIcon, {}),
        buttonSx: standardButtonSx,
        buttonText: 'Resume',
    },
};
const CardBody = ({ earningStatus, emmeBalance, usdBalance, }) => {
    switch (earningStatus) {
        case OverviewStatus.ERROR:
            return _jsx(Typography, { variant: "h6", children: "Some message depending on which step has failed" });
        case OverviewStatus.WELCOME:
        case OverviewStatus.LOADING:
            return _jsx(Typography, { variant: "h6", children: "Complete the steps below to start earning $EMME" });
        default:
            return (_jsxs(Stack, { direction: "row", sx: { alignItems: 'center', justifyContent: 'space-between' }, children: [earningStatus === OverviewStatus.PAUSED && (_jsxs(Typography, { color: "inherit", lineHeight: 1.4, children: ["Projected", _jsx("br", {}), "per hour"] })), _jsxs(Stack, { direction: "column", alignItems: earningStatus === OverviewStatus.PAUSED ? 'flex-end' : 'flex-start', gap: 1, children: [_jsxs(Stack, { direction: "row", gap: 1, sx: { alignItems: 'flex-end' }, children: [_jsx(Box, { children: emmeBalance }), _jsx(CoinIcon, { coin: "emme", size: 20, coinBackgroundColor: "neutralOne.main" }), earningStatus === OverviewStatus.EARNING && (_jsx(Box, { typography: "h6", color: "text.primary", whiteSpace: "nowrap", ml: 2, children: "per hour" }))] }), _jsx(Typography, { variant: "subtitle1", color: "inherit", children: `$${usdBalance}` })] })] }));
    }
};
export function EarningsCard() {
    const { earningStatus, lastCompletedStep, nextErrorStep, changeEarningStatus, changeSelectedStep, } = useContext(OverviewContext);
    const { data: balance } = useUserBalanceData();
    const { data: emmeCoinData } = useMarketInfoLatestCoinData('emme');
    const emmeBalance = balance ? new BigNumber(balance.available).dividedBy(1000).toFormat(4) : '-';
    const usdBalance = balance && emmeCoinData
        ? new BigNumber(balance.available).multipliedBy(emmeCoinData.priceUsd).toFormat(2)
        : '-';
    const { stackSx, avatarIcon, titleNode, buttonStartIcon, buttonSx, buttonText } = statusCss[earningStatus === OverviewStatus.LOADING ? OverviewStatus.WELCOME : earningStatus];
    return (_jsxs(Stack, { direction: "row", alignItems: "center", sx: {
            height: '100%',
            border: '1px solid',
            borderRadius: 1,
            transition: 'background-color 0.3s',
            ...stackSx,
        }, children: [_jsx(Box, { sx: {
                    width: ['110px', undefined, undefined, '142px'],
                    flex: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '90px',
                }, children: avatarIcon || _jsx(EmmeEarningAnimation, {}) }), _jsx(OverviewCardLayout, { title: titleNode, sx: {
                    bgcolor: 'transparent',
                    pl: 0,
                    pb: 3,
                    flex: 'auto',
                }, children: _jsxs(Stack, { justifyContent: "space-between", flex: "auto", children: [_jsx(CardBody, { earningStatus: earningStatus, usdBalance: usdBalance, emmeBalance: emmeBalance }), _jsx(Button, { variant: "contained", type: "button", color: earningStatus === OverviewStatus.ERROR ? 'error' : 'primary', size: "large", disabled: lastCompletedStep !== OverviewSteps.CONFIRMATION, tabIndex: -1, onClick: () => earningStatus === OverviewStatus.ERROR && nextErrorStep
                                ? changeSelectedStep(nextErrorStep)
                                : changeEarningStatus(earningStatus === OverviewStatus.EARNING
                                    ? OverviewStatus.PAUSED
                                    : OverviewStatus.EARNING), startIcon: buttonStartIcon || undefined, sx: {
                                borderRadius: 2,
                                maxWidth: '120px',
                                height: '30px',
                                ...buttonSx,
                                justifyContent: 'flex-start',
                            }, children: _jsx(Typography, { children: buttonText }) })] }) })] }));
}
