import { QueryCache, QueryClient } from '@tanstack/react-query';
const defaultOptions = {
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
    staleTime: 1000,
};
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: { ...defaultOptions },
    },
    queryCache: new QueryCache({
        onSuccess: () => {
            if (JSON.stringify(queryClient.getDefaultOptions()?.queries) !== JSON.stringify(defaultOptions)) {
                queryClient.setDefaultOptions({
                    queries: { ...defaultOptions },
                });
            }
        },
        onError: (error) => {
            const user = queryClient.getQueryData(['users', 'me']);
            if (typeof error === 'string' && error.toLowerCase().includes('api failed') && user) {
                queryClient.resetQueries(['users', 'me'], { exact: true });
                queryClient.clear();
            }
        },
    }),
});
