import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { useExchangesData } from '@emme/shared/queries/Exchanges';
const ExchangeSelectInput = ({ selectProps, value, onChange, renderMenuItem, }) => {
    const { selectedExchange } = useContext(LiquidityContext);
    const { data: exchanges } = useExchangesData();
    useEffect(() => {
        if (selectedExchange && !value) {
            onChange(selectedExchange.id);
        }
    }, [selectedExchange, value, onChange]);
    const handleChangeExchange = (event) => {
        if (exchanges && event.target.value) {
            const exchangeId = event.target.value;
            onChange(exchangeId);
        }
    };
    return (_jsx(Select, { value: value || '', "aria-label": "Exchange", onChange: handleChangeExchange, disabled: !exchanges?.length, IconComponent: ExpandMoreOutlined, ...selectProps, sx: {
            px: 3,
            minWidth: '140px',
            minHeight: '28px',
            ...selectProps?.sx,
        }, children: exchanges?.length ? (exchanges.map((ex) => (_jsx(MenuItem, { value: ex.id, children: renderMenuItem(ex.name) }, ex.id)))) : (_jsx(MenuItem, { value: "" })) }));
};
ExchangeSelectInput.defaultProps = {
    selectProps: {},
    renderMenuItem: (exchangeName) => exchangeName,
};
export default ExchangeSelectInput;
