import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { OrderTabsContext } from 'context/OrderTabsProvider';
import { EmmeOrderTabs, OrderTabs } from 'components/Tabs';
import TradeHistoryTab from './components/TradeHistoryTab';
import OrdersTab from './components/OrdersTab';
const PrivateOrderBook = () => {
    const { activeTab, activeSideTab, changeTabs } = useContext(OrderTabsContext);
    const TabComponent = activeTab === OrderTabs.TRADE_HISTORY ? TradeHistoryTab : OrdersTab;
    return (_jsxs(Stack, { alignItems: "stretch", sx: { height: '100%' }, children: [_jsx(EmmeOrderTabs, { activeTab: activeTab, activeSideTab: activeSideTab, onChangeTab: (tab) => changeTabs(tab, undefined), onChangeSideTab: (tab) => changeTabs(undefined, tab), showTradeHistory: true }), _jsx(Box, { sx: { flex: 'auto', overflow: 'auto' }, children: _jsx(TabComponent, { tab: activeTab, sideTab: activeSideTab }) })] }));
};
export default PrivateOrderBook;
