import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useResendSignupEmail } from '@emme/shared/queries/Auth';
import { VerificationPageLayout } from 'layout/AuthPage';
const AccountVerificationPage = () => {
    const location = useLocation();
    const userInfo = location.state;
    const { mutateAsync: resend } = useResendSignupEmail();
    return (_jsxs(VerificationPageLayout, { title: "Verify your email", captcha: "APPLICATION_PAGE_CREATE_ACCOUNT_CONFIRMATION_RESEND", onResend: resend, children: ["To access EM.ME, click the verification button in the email we sent to", ' ', _jsx("b", { children: userInfo?.email }), "."] }));
};
export default AccountVerificationPage;
