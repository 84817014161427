import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import Popover from '@mui/material/Popover';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import LiquidityContext from 'context/liquidityMarket/liquidityContext';
import { MarketSelectLogoButton } from 'components/Buttons';
import MarketSelectLogoBody from 'components/MarketSelectLogoBody';
const enableSearch = false;
const MarketSelectLogo = ({ customSize }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { selectedLiquidityMarket } = useContext(LiquidityContext);
    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(MarketSelectLogoButton, { onClick: handleOpen, sx: { pr: 2, pl: 3 }, disabled: !selectedLiquidityMarket?.quoteCoin || !selectedLiquidityMarket?.baseCoin, endIcon: anchorEl ? _jsx(KeyboardArrowUpOutlinedIcon, {}) : _jsx(KeyboardArrowDownOutlinedIcon, {}), quoteCoin: selectedLiquidityMarket?.quoteCoin, baseCoin: selectedLiquidityMarket?.baseCoin, customSize: customSize }), _jsx(Popover, { anchorEl: anchorEl, open: Boolean(anchorEl) && Boolean(selectedLiquidityMarket), onClose: handleClose, anchorReference: undefined, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, transformOrigin: { vertical: 'top', horizontal: 'center' }, PaperProps: {
                    sx: {
                        height: enableSearch ? 330 : 270,
                        width: 320,
                        backgroundColor: (t) => t.palette.neutralOne.main,
                    },
                }, sx: { p: 0 }, children: _jsx(MarketSelectLogoBody, { enableSearch: enableSearch, onSelect: handleClose }) })] }));
};
MarketSelectLogo.defaultProps = {
    customSize: undefined,
};
export default MarketSelectLogo;
