import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { formatSpread } from 'utils/util';
import { PlaceOrderTooltip } from 'components/Tooltips';
const SpreadSliderTooltip = ({ showTooltip, children }) => showTooltip ? (_jsx(PlaceOrderTooltip, { title: showTooltip
        ? `Separation of the orders placed in the exchange from the price spread point.
          The lower the spread, the higher the chance is for orders to be filled and become volume.`
        : '', placement: "top-start", children: children })) : (children);
const SpreadSlider = ({ value, onChange, sx, showTooltip }) => (_jsxs(Stack, { direction: "row", alignItems: "stretch", pl: 5, pr: 7, gap: 5, sx: sx, children: [_jsx(SpreadSliderTooltip, { showTooltip: showTooltip, children: _jsxs(Stack, { pt: 3, children: [_jsxs(Stack, { direction: "row", typography: "subtitle1", alignItems: "center", spacing: 1, sx: { cursor: showTooltip ? 'help' : undefined }, children: [showTooltip && _jsx(InfoOutlinedIcon, { fontSize: "inherit", color: "inherit" }), _jsx("span", { children: "Spread" })] }), _jsx(Typography, { variant: "body3", color: "primary", align: showTooltip ? 'center' : 'inherit', children: formatSpread(value) })] }) }), _jsx(Slider, { value: value, step: 0.05, min: 0.25, max: 4, name: "spread", onChange: (e, newValue) => {
                onChange(newValue);
            }, marks: [
                { label: '0.25%', value: 0.25 },
                { label: '4.00%', value: 4 },
            ], size: "small", track: false, getAriaLabel: () => 'Spread' })] }));
SpreadSlider.defaultProps = {
    sx: undefined,
    showTooltip: false,
};
export default SpreadSlider;
