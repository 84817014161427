import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { OverviewContext } from 'context/Overview/OverviewProvider';
import { useButtonLabel } from './hooks';
const SliderSubText = ({ children }) => (_jsx(Box, { typography: "body3", display: "flex", justifyContent: "space-between", color: "text.secondary", children: children }));
export function Step4RiskLevel() {
    const { riskLevel: contextRiskLevel, setRiskLevel: setContextRiskLevel, completeSelectedStep, } = useContext(OverviewContext);
    const [riskLevel, setRiskLevel] = useState(contextRiskLevel === undefined ? 0.5 : contextRiskLevel);
    const buttonLabel = useButtonLabel('Risk Level');
    const handleChange = useCallback((event, value) => {
        if (typeof value === 'number') {
            setRiskLevel(value);
        }
    }, []);
    let riskDescription = '';
    if (riskLevel < 0.1) {
        riskDescription = 'Very low risk';
    }
    else if (riskLevel < 0.3) {
        riskDescription = 'Low risk';
    }
    else if (riskLevel < 0.7) {
        riskDescription = 'Moderate risk';
    }
    else if (riskLevel < 0.9) {
        riskDescription = 'High risk';
    }
    else {
        riskDescription = 'Very high risk';
    }
    const handleCompleteClick = useCallback(() => {
        setContextRiskLevel(riskLevel);
        completeSelectedStep();
    }, [completeSelectedStep, riskLevel, setContextRiskLevel]);
    return (_jsxs(Stack, { gap: 6, children: [_jsx(Typography, { variant: "h6", children: "Set Risk Level" }), _jsx(Typography, { lineHeight: "14px", children: "The closer to a bid/ask, the more likely of fulfilling an order and creating volume, the greater the reward in $EMME (but the potential greater risk to lose coins if the market price suddenly changes the wrong way)" }), _jsx(Typography, { textAlign: "center", variant: "h6", component: "span", mt: 8, children: riskDescription }), _jsxs(Stack, { mb: 8, children: [_jsx(Slider, { value: riskLevel, min: 0, max: 1, step: 0.01, marks: [{ value: 0.5 }], onChange: handleChange }), _jsxs(SliderSubText, { children: [_jsx(Box, { children: "Low risk" }), _jsx(Box, { children: "High risk" })] }), _jsxs(SliderSubText, { children: [_jsx(Box, { children: "Low reward" }), _jsx(Box, { children: "High reward" })] })] }), _jsx(Button, { type: "button", variant: "outlined", color: "inherit", onClick: handleCompleteClick, sx: { minWidth: '260px', alignSelf: 'center' }, children: buttonLabel })] }));
}
