import { jsx as _jsx } from "react/jsx-runtime";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
const VolumeFormContainer = ({ children, maxContentHeight, }) => (_jsx(Box, { sx: {
        backgroundColor: (t) => t.palette.neutralOne.main,
        px: 3,
        borderRadius: '4px',
        flexGrow: 1,
        pb: [10, undefined, 3],
    }, children: _jsx(Stack, { sx: {
            '@media screen and (min-width: 900px) and (min-height: 1100px)': {
                maxHeight: maxContentHeight,
            },
            height: '100%',
        }, children: children }) }));
export default VolumeFormContainer;
