import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
const DashboardMenu = ({ onRemove }) => {
    const [anchorElMore, setAnchorElMore] = useState(null);
    const handleClickMore = (event) => {
        setAnchorElMore(event.currentTarget);
    };
    const handleCloseMore = () => setAnchorElMore(null);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { type: "button", disableRipple: true, tabIndex: -1, size: "small", onClick: handleClickMore, "aria-label": "dashboard menu", "aria-controls": "more-vertical", "aria-haspopup": "true", children: _jsx(MoreVertOutlinedIcon, { fontSize: "inherit" }) }), _jsxs(Menu, { id: "more-vertical", anchorEl: anchorElMore, keepMounted: true, open: Boolean(anchorElMore), onClose: handleCloseMore, anchorReference: undefined, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'center' }, children: [_jsx(Typography, { variant: "h6", component: "span", display: "flex", px: 5, pt: 2, pb: 4, children: "Dashboard Menu" }), _jsxs(MenuItem, { onClick: handleCloseMore, disabled: true, children: [_jsx(ListItemAvatar, { sx: { minWidth: 0, pr: 2 }, children: _jsx(DriveFileRenameOutlineOutlinedIcon, { fontSize: "small" }) }), _jsx(ListItemText, { primary: "Rename panel", primaryTypographyProps: {
                                    textAlign: 'left',
                                    component: 'div',
                                    variant: 'subtitle2',
                                } })] }), _jsx(Divider, { component: "li", variant: "middle" }), _jsxs(MenuItem, { onClick: handleCloseMore, disabled: true, children: [_jsx(ListItemAvatar, { sx: { minWidth: 0, pr: 2 }, children: _jsx(AddOutlinedIcon, { fontSize: "small" }) }), _jsx(ListItemText, { primary: "Add widget", primaryTypographyProps: {
                                    textAlign: 'left',
                                    component: 'div',
                                    variant: 'subtitle2',
                                } })] }), _jsx(Divider, { component: "li", variant: "middle" }), _jsxs(MenuItem, { onClick: () => {
                            handleCloseMore();
                            onRemove();
                        }, sx: {
                            color: ({ palette }) => palette.error.main,
                        }, children: [_jsx(ListItemAvatar, { sx: { minWidth: 0, pr: 2 }, children: _jsx(RemoveOutlinedIcon, { fontSize: "small" }) }), _jsx(ListItemText, { primary: "Remove Dashboard", primaryTypographyProps: {
                                    textAlign: 'left',
                                    component: 'div',
                                    variant: 'subtitle2',
                                } })] })] })] }));
};
export default DashboardMenu;
