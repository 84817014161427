import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { useOverviewBalances } from 'context/Overview/hooks/useOverviewBalance';
import { OverviewContext } from 'context/Overview/OverviewProvider';
import { useButtonLabel } from './hooks';
export function Step3Balance() {
    const { completeSelectedStep, selectedCoins, toggleCoinSelected } = useContext(OverviewContext);
    const balances = useOverviewBalances(selectedCoins);
    const buttonLabel = useButtonLabel('Balance');
    const missingBalances = [...balances.entries()].filter(([, { minimum, available }]) => minimum > available);
    const okBalances = [...balances.entries()].filter(([, { minimum, available }]) => minimum <= available);
    const handleRemoveCoin = (coin) => () => {
        selectedCoins.filter(({ id }) => id.includes(coin)).forEach(({ id }) => toggleCoinSelected(id));
    };
    return (_jsxs(Stack, { gap: 6, children: [_jsx(Typography, { variant: "h6", children: "Ensure Funds" }), missingBalances.length ? (_jsxs(Box, { sx: {
                    border: ({ palette }) => `1px solid ${palette.error.main}`,
                    borderRadius: 2,
                    p: 5,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, auto) min-content',
                    gap: 6,
                }, children: [_jsx(Box, { children: "coin" }), _jsx(Box, { children: "minimum" }), _jsx(Box, { children: "available" }), _jsx(Box, {}), missingBalances.map(([coin, { minimum, available }]) => (_jsxs(Fragment, { children: [_jsx(Box, { children: coin }), _jsx(Box, { children: minimum }), _jsx(Box, { children: available }), _jsx(Box, { sx: { display: 'flex', justifyContent: 'center' }, children: _jsx(Button, { onClick: handleRemoveCoin(coin), sx: { fontSize: '10px', my: '-10px' }, endIcon: _jsx(DeleteOutline, {}), color: "error", children: "remove" }) })] }, coin)))] })) : (_jsx(Typography, { color: "emmeSecondary.main", children: "All balances are OK" })), Boolean(okBalances.length) && (_jsxs(Box, { sx: {
                    border: ({ palette }) => `1px solid ${palette.emmeSecondary.main}`,
                    borderRadius: 2,
                    p: 5,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, auto)',
                    gap: 6,
                }, children: [_jsx(Box, { children: "coin" }), _jsx(Box, { children: "minimum" }), _jsx(Box, { children: "available" }), okBalances.map(([coin, { minimum, available }]) => (_jsxs(Fragment, { children: [_jsx(Box, { children: coin }), _jsx(Box, { children: minimum }), _jsx(Box, { children: available })] }, coin)))] })), _jsx(Button, { onClick: completeSelectedStep, variant: "outlined", color: "inherit", sx: { alignSelf: 'center' }, disabled: Boolean(missingBalances.length), children: buttonLabel })] }));
}
