import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useLottie } from 'lottie-react';
import { OverviewContext, OverviewStatus } from 'context/Overview/OverviewProvider';
const EmmeEarningAnimation = () => {
    const { earningStatus } = useContext(OverviewContext);
    const [animation, setAnimation] = useState(undefined);
    const loadData = useCallback(async () => {
        try {
            const res = await axios.get('data/emmeEarning.json');
            setAnimation(res.data);
        }
        catch (error) {
            console.debug(error);
        }
    }, []);
    useEffect(() => {
        loadData();
    }, [loadData]);
    const { View, stop, play, pause } = useLottie({ loop: true, animationData: animation }, {
        stroke: earningStatus === OverviewStatus.EARNING ? 'green' : undefined,
        height: '142px',
    });
    useEffect(() => {
        earningStatus === OverviewStatus.ERROR
            ? stop()
            : earningStatus !== OverviewStatus.EARNING
                ? pause()
                : play();
    }, [earningStatus, pause, play, stop]);
    return View;
};
export default EmmeEarningAnimation;
