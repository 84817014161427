import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import BigNumber from 'bignumber.js';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import { ColumnAlign, ColumnSort } from '@emme/shared/model/table.model';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { getCellAlignment } from './tableUtil';
import { TableEmpty, TableLoadingSkeleton } from '.';
const setComparatorValue = (data, additionalInfo) => {
    const value = typeof data === 'object' && additionalInfo?.value ? data[additionalInfo?.value] : data;
    if (!value) {
        return additionalInfo?.isNumber || additionalInfo?.isBigNumber ? -1 : '';
    }
    if (additionalInfo?.isNumber && typeof value !== 'number') {
        return Number(value || '-1');
    }
    if (additionalInfo?.isBigNumber) {
        return BigNumber.isBigNumber(value) ? value.toNumber() : value;
    }
    return value || '';
};
function descendingComparator(a, b, orderBy, additionalInfo) {
    const first = setComparatorValue(a[orderBy], additionalInfo);
    const second = setComparatorValue(b[orderBy], additionalInfo);
    if (second < first) {
        return -1;
    }
    if (second > first) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy, sortValues) {
    return order === ColumnSort.DESC
        ? (a, b) => descendingComparator(a, b, orderBy, orderBy && sortValues && sortValues[orderBy])
        : (a, b) => -descendingComparator(a, b, orderBy, orderBy && sortValues && sortValues[orderBy]);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0)
            return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead({ order, orderBy, headCells, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (_jsx(TableHead, { children: _jsx(TableRow, { children: headCells.map((headCell) => (_jsx(TableCell, { align: getCellAlignment(headCell), sortDirection: orderBy === headCell.id ? order : false, sx: {
                    pr: orderBy !== '' && getCellAlignment(headCell) === ColumnAlign.CENTER ? 0 : 3,
                    pl: orderBy !== '' && getCellAlignment(headCell) === ColumnAlign.CENTER ? 6 : 3,
                }, children: _jsx(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : ColumnSort.ASC, onClick: createSortHandler(headCell.id), hideSortIcon: orderBy === '', "aria-label": orderBy === headCell.id
                        ? order === ColumnSort.DESC
                            ? 'sorted descending'
                            : 'sorted ascending'
                        : undefined, sx: orderBy === ''
                        ? {
                            cursor: 'default',
                            '&:hover': { color: 'inherit' },
                        }
                        : { svg: { mx: 1, fontSize: 16 } }, children: _jsx(Typography, { variant: "subtitle1", children: headCell.label }) }) }, headCell.id))) }) }));
}
const EmmeTable = ({ rows, rowsPerPage, initialSortBy, initialSortDirection, sortValues, inputHeadCells, customVerticalPadding, rowsPerPageOptions, totalLength, page, tableMinWidth, stickyHeader, hideBody, limitRowsToHeight, onRowClick, onPageChange, onRowsPerPageChange, isLoading, alternatingRowColors, collapsibleRowOpen, selectedRowId, customEmptyTableText, useEndRows, }) => {
    const [order, setOrder] = useState(initialSortDirection);
    const [orderBy, setOrderBy] = useState(initialSortBy || '');
    const [minHeight, setMinHeight] = useState(0);
    const [rowHeight, setRowHeight] = useState(0);
    const [maxRows, setMaxRows] = useState(0);
    const { phone } = useBreakpoint();
    const containerEl = useRef(null);
    const rowEl = useRef(null);
    const showTablePagination = Boolean(onPageChange &&
        collapsibleRowOpen === undefined &&
        totalLength &&
        rowsPerPageOptions &&
        totalLength > rowsPerPageOptions[0]);
    if (limitRowsToHeight && maxRows > 0) {
        rows = useEndRows ? rows.slice(-maxRows - 1) : rows.slice(0, maxRows + 1);
    }
    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (showTablePagination) {
            setMinHeight((c) => Math.max(c, containerEl.current?.getBoundingClientRect().height || 0));
        }
        else if (limitRowsToHeight) {
            setRowHeight(rowEl.current?.getBoundingClientRect().height || 0);
        }
        else {
            setMinHeight(0);
            setRowHeight(0);
        }
    }, [rows.length, showTablePagination, isLoading, limitRowsToHeight]);
    useEffect(() => {
        if (!limitRowsToHeight)
            return;
        if (rowHeight > 0 && limitRowsToHeight > 0) {
            setMaxRows(Math.floor(limitRowsToHeight / rowHeight) - 1);
        }
    }, [limitRowsToHeight, rowHeight]);
    useEffect(() => {
        if (collapsibleRowOpen) {
            setMinHeight(0);
        }
    }, [collapsibleRowOpen]);
    const handleRequestSort = (_event, property) => {
        if (!orderBy)
            return;
        const isAsc = orderBy === property && order === ColumnSort.ASC;
        setOrder(isAsc ? ColumnSort.DESC : ColumnSort.ASC);
        setOrderBy(property);
        onPageChange && onPageChange(0);
    };
    const handleChangePage = (event, newPage) => {
        onPageChange && onPageChange(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        if (newRowsPerPage < rowsPerPage) {
            setMinHeight(0);
        }
        onRowsPerPageChange && onRowsPerPageChange(newRowsPerPage);
    };
    const handleRowClick = (id) => {
        onRowClick && onRowClick(id);
    };
    return (_jsxs(Stack, { height: "100%", overflow: "hidden", children: [_jsx(TableContainer, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    maxHeight: '100%',
                    minHeight,
                    pb: 2,
                    ...(limitRowsToHeight !== undefined ? { overflowY: 'hidden' } : {}),
                }, ref: containerEl, children: _jsxs(Table, { sx: { minWidth: tableMinWidth }, size: "small", stickyHeader: stickyHeader, children: [!limitRowsToHeight && (_jsx(EnhancedTableHead, { order: order, orderBy: orderBy, onRequestSort: handleRequestSort, headCells: inputHeadCells })), !hideBody && (_jsxs(_Fragment, { children: [isLoading && (_jsx(TableLoadingSkeleton, { inputRowsPerPage: rowsPerPage, inputHeadCells: inputHeadCells })), Boolean(!isLoading && rows.length) && (_jsx(TableBody, { children: stableSort(rows, getComparator(order, orderBy, sortValues))
                                        .filter((row) => collapsibleRowOpen === undefined || row.id === collapsibleRowOpen)
                                        .map((row, index) => (_createElement(TableRow, { ...(index === 0 ? { ref: rowEl } : {}), hover: true, tabIndex: -1, key: row.id, onClick: () => handleRowClick(typeof row.id === 'string' ? row.id : JSON.stringify(row.id)), selected: Boolean(!collapsibleRowOpen &&
                                            (row.isSelected || (selectedRowId && selectedRowId === row.id))), sx: {
                                            ':nth-of-type(odd):not(.Mui-selected):not(:hover)': alternatingRowColors
                                                ? {
                                                    backgroundColor: (t) => t.palette.neutralOne.main,
                                                }
                                                : undefined,
                                            transition: 'background-color 0.2s ease',
                                            cursor: onRowClick ? 'pointer' : undefined,
                                        } }, inputHeadCells.map((cell) => {
                                        const { Component } = cell;
                                        return (_jsx(TableCell, { align: getCellAlignment(cell), sx: {
                                                py: customVerticalPadding,
                                                minWidth: cell.minWidth || 'initial',
                                                ...(cell?.wrap
                                                    ? { whiteSpace: 'normal', wordBreak: 'break-word' }
                                                    : {}),
                                            }, variant: "body", children: _jsx(Typography, { component: "span", children: Component ? (_jsx(Component, { columnProps: cell.componentProps, data: row[cell.id] })) : (String(row[cell.id])) }) }, cell.id));
                                    })))) })), Boolean(!isLoading && !rows.length) && (_jsx(TableEmpty, { inputHeadCells: inputHeadCells, customEmptyTableText: customEmptyTableText }))] }))] }) }), showTablePagination && rowsPerPage && (_jsx(Box, { children: _jsx(TablePagination, { rowsPerPageOptions: rowsPerPageOptions, component: "div", count: totalLength || rows.length, rowsPerPage: rowsPerPage, page: page || 0, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, labelRowsPerPage: "Items per page:", showFirstButton: !phone && page > 1, showLastButton: !phone && (totalLength || 0) > rowsPerPage, SelectProps: {
                        sx: {
                            fontSize: '12px',
                            borderRadius: 2,
                            backgroundColor: '#FFF1',
                            height: '28px',
                            mr: !phone ? undefined : 3,
                            svg: {
                                fontSize: '12px',
                                mr: 2,
                            },
                        },
                        IconComponent: ExpandMoreOutlined,
                    } }) }))] }));
};
EmmeTable.defaultProps = {
    initialSortBy: '',
    initialSortDirection: ColumnSort.DESC,
    sortValues: undefined,
    totalLength: undefined,
    page: 0,
    customVerticalPadding: 1,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50],
    tableMinWidth: 0,
    onRowClick: undefined,
    onPageChange: undefined,
    onRowsPerPageChange: undefined,
    stickyHeader: false,
    hideBody: false,
    limitRowsToHeight: undefined,
    isLoading: false,
    alternatingRowColors: false,
    collapsibleRowOpen: undefined,
    selectedRowId: undefined,
    customEmptyTableText: 'Table is empty',
    useEndRows: false,
};
export default EmmeTable;
