import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { RouteEnum } from '@emme/shared/model/enums';
import { useUserInfoData } from '@emme/shared/queries/Users';
import { useAppInfoData } from '@emme/shared/queries/App';
import { useLogout } from '@emme/shared/queries/Auth';
import { AlertContext } from 'context/AlertProvider';
import { VersionTooltip } from 'components/Tooltips';
import { EMMELogo } from '@emme/shared/icons';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { MobileLoggedInLinks, LoggedInLinks } from './components';
const Navbar = ({ disabled }) => {
    const navigate = useNavigate();
    const { tablet } = useBreakpoint();
    const { setAlert } = useContext(AlertContext);
    const logoutUser = useLogout();
    const { data: user } = useUserInfoData();
    const { data: appInfo } = useAppInfoData();
    const handleLogout = async () => {
        try {
            await logoutUser.mutateAsync();
            navigate(RouteEnum.Login, { replace: true });
        }
        catch (error) {
            setAlert(error || 'Could not properly logout', 'error');
        }
    };
    return (_jsxs(Toolbar, { sx: {
            '@media screen and (min-height: 400px)': {
                height: '80px',
            },
            px: 7,
            pointerEvents: disabled ? 'none' : 'initial',
            opacity: disabled ? 0.5 : 1,
            transition: 'opacity 0.3s',
        }, disableGutters: true, component: "nav", children: [_jsx(VersionTooltip, { children: _jsx(Button, { type: "button", disableFocusRipple: true, disableRipple: true, disableTouchRipple: true, component: RouterLink, to: RouteEnum.Dashboard, sx: {
                        p: 2,
                        m: -2,
                        height: '40px',
                        borderRadius: 0,
                        ':hover': {
                            background: 'none',
                        },
                        ':focus': {
                            background: ({ palette }) => palette.neutralsTwo.main,
                        },
                        color: (t) => t.palette.text.primary,
                    }, "aria-label": "EMME Home", children: _jsx(EMMELogo, { height: "34" }) }) }), _jsx("div", { style: { flexGrow: 1 } }), !tablet && user && (_jsx(Typography, { color: "textSecondary", variant: "h6", height: "100%", display: "flex", alignItems: "center", children: _jsx(LoggedInLinks, { phoneWidth: tablet, user: user, handleLogout: handleLogout }) })), tablet && _jsx(MobileLoggedInLinks, { user: user, appInfo: appInfo, handleLogout: handleLogout })] }));
};
Navbar.defaultProps = {
    disabled: false,
};
export default Navbar;
