import { jsx as _jsx } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import { ColumnAlign } from '@emme/shared/model/table.model';
export const TypographyCell = ({ columnProps = {}, data }) => {
    const { text, ...props } = data;
    return (_jsx(Typography, { component: "span", ...columnProps, ...props, children: text }));
};
TypographyCell.alignment = ColumnAlign.LEFT;
TypographyCell.defaultProps = {
    columnProps: {},
};
