import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import { DialogLayout } from 'layout/Dialogs';
import { HelpMenu } from 'pages/HelpPage/components/HelpMenu';
export const HelpDialog = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { color: "inherit", sx: { mr: 3 }, onClick: handleOpen, size: "small", "aria-label": "Help", children: _jsx(HelpCenterOutlinedIcon, { fontSize: "small" }) }), isOpen && (_jsx(DialogLayout, { open: true, title: "How can we help you?", onClose: handleClose, children: _jsx(HelpMenu, { onClose: handleClose }) }))] }));
};
